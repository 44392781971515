import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Radio,
} from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { ColDef, IHeaderParams } from 'ag-grid-community';
import { Fragment, useContext } from 'react';
import { useSelector } from 'react-redux';
import { IGetContractDetailsForItem } from '../../../CLM/Services/CLM.service';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWLink } from '../../../Common/FWLink';
import { FWTypography } from '../../../Common/FWTypograhy';
import SignupPendingIcon from '../../../Global/Components/SignupPendingIcon';
import TagsDisplay from '../../../Global/Components/TagsDisplay';
import VendorVerificationStatusIcon from '../../../Global/Components/VendorVerificationStatusIcon';
import { IGlobalAppStore } from '../../../Redux/Store';
import { VendorAnalyticsDetailsType } from '../../Components/Buyer/ItemVendorDetails';
import { VendorContactDetailChip } from '../../Components/Buyer/VendorContactDetailChip';
import { vendorAndItemIconMap } from '../../FILETODELETE';
import { IVendorForEventItem } from '../../Interfaces/Buyer/RFQ.model';

import ContractIcon from '../../../Assets/Icons/contract.svg';
import { AuthContext } from '../../../Contexts/AuthContext';
import { nestedDeepEqual } from '../../../Global/Helpers/helpers';

export const vendorPreferredForItem = (status: 'STANDARD' | 'PREFERRED') => {
    if (status === 'STANDARD') return false;
    return true;
};

type vendorTableRowParam = {
    data: IVendorForEventItem;
    context: {
        editVendorContacts: (
            vendor_id: string,
            active_element: HTMLAnchorElement | null
        ) => void;
        contractDetails: {
            [ContractId: string]: IGetContractDetailsForItem;
        };
        currencyCodeSymbol: string;
        selectNewContract: (contractUid: string) => void;
        handleSelectionContractPopUp: (contractUid: string) => void;
    };
    value: any;
};

export const VendorTagsCell = (params: vendorTableRowParam) => {
    const allVendorMasterData = useSelector(
        (state: IGlobalAppStore) => state.VendorMasterStore,
        nestedDeepEqual
    );

    const tags =
        allVendorMasterData[params.data.seller_entity.entity_id]?.tags ?? [];
    return <TagsDisplay tags={tags} />;
};

export const VendorContactCell = (params: vendorTableRowParam) => {
    const { authData } = useContext(AuthContext);

    const enterprise_id =
        useContext(AuthContext).authData.details?.enterprise_id;
    return (
        <Box display="flex" justifyContent={'space-between'} width={'100%'}>
            <Box flex={5} overflow={'hidden'} component={'span'}>
                <VendorContactDetailChip
                    sellerContact={params.data.seller_contacts}
                />
            </Box>
            {authData?.details?.role === 'ADMIN' &&
                !['FW Test Vendor 01', 'FW Test Vendor 02'].includes(
                    params.data.seller_entity.entity_name.trim()
                ) && (
                    <FWTooltip
                        title={
                            enterprise_id !==
                            'efe4f580-badc-4210-9b9f-761dccdd74f9'
                                ? 'Add Vendor contact'
                                : 'Feature not available in demo mode'
                        }
                    >
                        <Box flex={1} component={'span'}>
                            <IconButton
                                disabled={
                                    enterprise_id ===
                                    'efe4f580-badc-4210-9b9f-761dccdd74f9'
                                }
                                size="small"
                                onClick={(event: any) => {
                                    params.context.editVendorContacts(
                                        params.data.seller_entity.entity_id,
                                        event.currentTarget
                                    );
                                }}
                            >
                                <Box
                                    color={
                                        enterprise_id !==
                                        'efe4f580-badc-4210-9b9f-761dccdd74f9'
                                            ? 'primary.main'
                                            : 'gray'
                                    }
                                >
                                    <i className="bi bi-plus-circle"></i>
                                </Box>
                            </IconButton>
                        </Box>
                    </FWTooltip>
                )}
        </Box>
    );
};

export const RFQItemVendorTablecolumns = (
    event_type: 'DIRECT' | 'FROM_EVENT',
    selectedVendorRowIDs: GridSelectionModel,
    setSelectedVendorRowIDs: (row_ids: GridSelectionModel) => void,
    openVendorAnalyticsPopup: (
        vendorDetails: VendorAnalyticsDetailsType
    ) => void,
    vendorDetails: IVendorForEventItem[] | null
): ColDef[] => {
    return [
        {
            field: 'id',
            headerName: 'No.',
            width: 120,
            headerComponent: (props: IHeaderParams) => {
                const totalNumberOfVendorINTable =
                    vendorDetails?.length ?? null;

                return (
                    <>
                        {' '}
                        <Box marginLeft={'11px'}>
                            <FormControlLabel
                                label="No."
                                control={
                                    <Checkbox
                                        checked={
                                            totalNumberOfVendorINTable ===
                                            selectedVendorRowIDs.length
                                        }
                                        indeterminate={
                                            totalNumberOfVendorINTable !==
                                                selectedVendorRowIDs.length &&
                                            selectedVendorRowIDs.length > 0
                                        }
                                        onChange={(e) => {
                                            if (vendorDetails) {
                                                if (e.target.checked) {
                                                    setSelectedVendorRowIDs(
                                                        vendorDetails
                                                            .map((v) => v.id)
                                                            .filter(
                                                                (v) =>
                                                                    v !==
                                                                    undefined
                                                            ) as string[]
                                                    );
                                                } else {
                                                    setSelectedVendorRowIDs([]);
                                                }
                                            }
                                        }}
                                        size="small"
                                        color={
                                            totalNumberOfVendorINTable !==
                                            selectedVendorRowIDs.length
                                                ? 'secondary'
                                                : undefined
                                        }
                                    />
                                }
                            />
                        </Box>
                    </>
                );
            },
            cellRenderer: (params: vendorTableRowParam) => {
                return (
                    <>
                        {event_type === 'DIRECT' && (
                            <Radio
                                checked={
                                    params.data.id !== null &&
                                    params.data.id !== undefined &&
                                    selectedVendorRowIDs.includes(
                                        params.data.id
                                    )
                                        ? true
                                        : false
                                }
                                onChange={() => {
                                    if (
                                        params.data.id !== null &&
                                        params.data.id !== undefined
                                    ) {
                                        setSelectedVendorRowIDs([
                                            params.data.id,
                                        ]);
                                    }
                                }}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                                size={'small'}
                            />
                        )}
                        {event_type === 'FROM_EVENT' && (
                            <Checkbox
                                checked={
                                    params.data.id !== null &&
                                    params.data.id !== undefined &&
                                    selectedVendorRowIDs.includes(
                                        params.data.id
                                    )
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    if (
                                        params.data.id !== null &&
                                        params.data.id !== undefined
                                    ) {
                                        if (e.target.checked) {
                                            setSelectedVendorRowIDs([
                                                ...selectedVendorRowIDs,
                                                params.data.id,
                                            ]);
                                        } else {
                                            setSelectedVendorRowIDs([
                                                ...selectedVendorRowIDs.filter(
                                                    (id) =>
                                                        params.data.id !== id
                                                ),
                                            ]);
                                        }
                                    }
                                }}
                                inputProps={{ 'aria-label': 'A' }}
                                size={'small'}
                            />
                        )}
                        <FWTypography>{(params.data.no ?? 0) + 1}</FWTypography>
                    </>
                );
            },
            // checkboxSelection: event_type === 'FROM_EVENT',
            // headerCheckboxSelection: event_type === 'FROM_EVENT',
            // headerCheckboxSelectionFilteredOnly: event_type === 'FROM_EVENT',
        },
        {
            field: 'vendor_code',
            headerName: 'Vendor ID',
            width: 250,
            cellRenderer: (params: vendorTableRowParam) => {
                return <FWTypography>{params.value}</FWTypography>;
            },
        },
        {
            field: 'seller_entity.seller_entity_name',
            headerName: 'Company name',
            flex: 1,
            cellRenderer: (params: vendorTableRowParam) => {
                const rowData: IVendorForEventItem = params.data;

                return (
                    <>
                        &nbsp;
                        <FWLink
                            onClick={() =>
                                openVendorAnalyticsPopup({
                                    sellerEntityUuid:
                                        rowData.seller_entity.entity_id,
                                    sellerEntityName:
                                        rowData.seller_entity.entity_name,
                                })
                            }
                        >
                            {params.value}
                        </FWLink>
                        {vendorPreferredForItem(params.data.status) && (
                            <>
                                {' '}
                                &nbsp;
                                <FWTooltip title="Preferred for this item">
                                    <Box color="primary.main">
                                        <i className="bi bi-check-circle-fill" />
                                    </Box>
                                </FWTooltip>
                            </>
                        )}
                        {params.data.seller_entity.entity_id in
                            vendorAndItemIconMap &&
                            vendorAndItemIconMap[
                                params.data.seller_entity.entity_id
                            ].icon.map((iconName, index) => (
                                <Fragment key={index}>
                                    &nbsp; &nbsp;
                                    <FWTooltip
                                        title={
                                            vendorAndItemIconMap[
                                                params.data.seller_entity
                                                    .entity_id
                                            ].iconTooltips[index]
                                        }
                                    >
                                        <Box
                                            color={
                                                vendorAndItemIconMap[
                                                    params.data.seller_entity
                                                        .entity_id
                                                ].iconColors[index]
                                            }
                                            className="flex flex--aic"
                                        >
                                            {vendorAndItemIconMap[
                                                params.data.seller_entity
                                                    .entity_id
                                            ].iconValues[index] !== null && (
                                                <FWTypography>
                                                    {
                                                        vendorAndItemIconMap[
                                                            params.data
                                                                .seller_entity
                                                                .entity_id
                                                        ].iconValues[index]
                                                    }
                                                </FWTypography>
                                            )}
                                            &nbsp;
                                            <i className={iconName} />
                                        </Box>
                                    </FWTooltip>
                                </Fragment>
                            ))}
                        {params.data.contract_id &&
                            params.data.contract_id in
                                params.context.contractDetails && (
                                // <Box className="flex flex-aic">
                                //     <ContractDetailPopup
                                //         contractDetails={
                                //             params.context.contractDetails[
                                //                 params.data.contract_id
                                //             ]
                                //         }
                                //         currencyCodeSymbol={
                                //             params.context.currencyCodeSymbol
                                //         }
                                //         handleUseContract={() =>
                                //             params.context.selectNewContract(
                                //                 params.data.contract_id ?? ''
                                //             )
                                //         }
                                //     />
                                // </Box>
                                <Box>
                                    <IconButton
                                        // sx={{
                                        //     width: '16px',
                                        //     height: '16px',
                                        // }}
                                        onClick={() =>
                                            params.context.handleSelectionContractPopUp(
                                                params.data.contract_id ?? ''
                                            )
                                        }
                                    >
                                        <Box>
                                            <img src={ContractIcon} alt={''} />
                                        </Box>
                                    </IconButton>
                                </Box>
                            )}
                        <>
                            &nbsp;
                            <VendorVerificationStatusIcon
                                verificationStatus={
                                    params.data.seller_entity
                                        .verification_status
                                }
                            />
                        </>
                        {params.data.seller_entity.is_virtual && (
                            <>
                                &nbsp;
                                <SignupPendingIcon />
                            </>
                        )}
                        {params.data.seller_entity.entity_id ===
                            'fe7b74a1-7bd7-4445-a250-8b6ced759b38' && (
                            <Fragment>
                                &nbsp; &nbsp;
                                <FWTooltip title={'FW Recommended vendor'}>
                                    <img
                                        style={{
                                            height: 'auto',
                                            width: '2rem',
                                        }}
                                        src="/logo.png"
                                        alt="factwise logo"
                                    />
                                </FWTooltip>
                            </Fragment>
                        )}
                    </>
                );
            },
            valueGetter: (params: any) => {
                return params.data.seller_entity.entity_name;
            },
            getQuickFilterText: (params) =>
                params.data.seller_entity.entity_name,
        },
        {
            field: 'seller_entity.seller_entity_name',
            headerName: 'Tags',
            flex: 1,
            cellRenderer: VendorTagsCell,
        },
        {
            field: 'entity_type',
            headerName: 'Contact',
            flex: 1,
            cellRenderer: VendorContactCell,
            getQuickFilterText: (params) =>
                params.data.seller_entity.contacts &&
                params.data.seller_entity.contacts.emails.length > 0
                    ? params.data.seller_entity.contacts.emails[0]
                    : '',
        },
    ];
};
