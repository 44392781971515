import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FWAutocomplete from '../../Common/FWAutocomplete';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWInput } from '../../Common/FWInput';
import { FWTypography } from '../../Common/FWTypograhy';
// import { store } from '../../Redux/Store';
import IProject, { ProjectStatusEnum } from '../interface/project.model';
import {
    // projectApiSlice,
    useListProjectsViaDasboardMutation,
} from '../services/project.services';
import { projectNone } from '../../Events/Components/Utils';

export interface IFWAutoCompleteForProjectProps {
    entityId: string;
    onChange: (
        newProjectDetail: {
            project_id: string;
            project_code: string;
            project_name: string;
        } | null
    ) => void;
    projectTab: string;
    selectedProjectId: string | null;
    error?: boolean;
    helperText?: string;
    selectedProjectName: string | null;
    selectedProjectCode: string | null;
    isProjectFieldMandatory?: boolean;
    disabled?: boolean;
}

const LOAD_MORE_PROJECT: IProject = {
    attachments: [],
    createdByUserId: '',
    createdByUsername: '',
    createdOn: '',
    customerEntity: {
        entity_id: '',
        entity_name: '',
    },
    description: '',
    entityName: 'LOAD_MORE_PROJECT',
    entityUid: '',
    internalNotes: '',
    linkedCostCenterCount: 0,
    listCostCenterNames: [],
    modifiedByUsername: '',
    modifiedOn: '',
    projectCode: 'LOAD_MORE_PROJECT',
    projectCustomSections: [],
    projectManagers: [],
    projectName: '',
    projectStatus: ProjectStatusEnum.DRAFT,
    projectUUid: 'LOAD_MORE_PROJECT',
    status: ProjectStatusEnum.ACTIVE,
    submittedOn: '',
    templateId: '',
    validityFrom: '',
    validityTo: '',
};

const FWAutoCompleteForProject = ({
    entityId,
    onChange,
    projectTab,
    selectedProjectId,
    selectedProjectName,
    selectedProjectCode,
    error,
    helperText,
    isProjectFieldMandatory,
    disabled = false,
}: IFWAutoCompleteForProjectProps) => {
    const [pageNo, setPageNo] = useState(1);

    // const [searchText, setSearchText] = useState('');

    const [listOfProjects, setListOfProjects] = useState<IProject[]>([]);

    //RTKS
    const [fetchList, { data: listOfProjectsRespone }] =
        useListProjectsViaDasboardMutation({
            fixedCacheKey: 'projectautocomplete',
        });

    const fetchData = useCallback(
        (searchText: string, pageNo: number) => {
            try {
                fetchList({
                    page_number: pageNo,
                    search_text: searchText,
                    entity_id: entityId,
                    tab: projectTab,
                    query_data: {},
                    sort_fields: [],
                    filters: null,
                });
            } catch (err) {}
        },
        [entityId, fetchList, projectTab]
    );

    useEffect(() => {
        try {
            if (Boolean(entityId)) {
                fetchData('', pageNo);
            }
        } catch (err) {}
    }, [entityId, fetchData, pageNo]);

    const debouncedSearchText = useMemo(
        () =>
            debounce((searchText: string) => {
                fetchData(searchText, 1);
            }, 500),
        [fetchData]
    );

    const handleSearchTextChange = useCallback(
        (searchText: string) => {
            debouncedSearchText(searchText);
        },
        [debouncedSearchText]
    );

    // useEffect(() => {
    //     handleSearchTextChange(searchText);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchText]);

    useEffect(() => {
        if (
            listOfProjectsRespone !== undefined &&
            listOfProjectsRespone.data?.length > 0
        ) {
            setListOfProjects((prev) => {
                let getUniqueProjectList: IProject[] = [];

                prev.forEach((project) => {
                    if (
                        !getUniqueProjectList.some(
                            (pro) => pro.projectUUid === project.projectUUid
                        )
                    ) {
                        getUniqueProjectList.push(project);
                    }
                });

                listOfProjectsRespone.data.forEach((project) => {
                    if (
                        !getUniqueProjectList.some(
                            (pro) => pro.projectUUid === project.projectUUid
                        )
                    ) {
                        getUniqueProjectList.push(project);
                    }
                });

                return getUniqueProjectList;
            });
        }
    }, [listOfProjectsRespone]);

    const listOfProjectsWithAddOption = useMemo(() => {
        const doesSelectedProjectExistsInOptions = selectedProjectId
            ? listOfProjects.some(
                  (projectOption) =>
                      projectOption.projectUUid === selectedProjectId
              )
            : true;

        const updatedListOfProjects: IProject[] =
            doesSelectedProjectExistsInOptions
                ? listOfProjects
                : [
                      ...listOfProjects,
                      {
                          attachments: [],
                          createdByUserId: '',
                          createdByUsername: '',
                          createdOn: '',
                          customerEntity: {
                              entity_id: '',
                              entity_name: '',
                          },
                          description: '',
                          entityName: 'LOAD_MORE_PROJECT',
                          entityUid: '',
                          internalNotes: '',
                          linkedCostCenterCount: 0,
                          listCostCenterNames: [],
                          modifiedByUsername: '',
                          modifiedOn: '',
                          projectCode: selectedProjectCode ?? '',
                          projectCustomSections: [],
                          projectManagers: [],
                          projectName: selectedProjectName ?? '',
                          projectStatus: ProjectStatusEnum.DRAFT,
                          projectUUid: selectedProjectId ?? '',
                          status: ProjectStatusEnum.ACTIVE,
                          submittedOn: '',
                          templateId: '',
                          validityFrom: '',
                          validityTo: '',
                      },
                  ];

        if (
            listOfProjectsRespone !== undefined &&
            listOfProjectsRespone.metadata.has_next
        ) {
            return [...updatedListOfProjects, LOAD_MORE_PROJECT];
        }

        return updatedListOfProjects;
    }, [
        listOfProjects,
        listOfProjectsRespone,
        selectedProjectId,
        selectedProjectCode,
        selectedProjectName,
    ]);

    // const myOptions = useMemo(() => {
    //     if (isProjectFieldMandatory) {
    //         return listOfProjectsWithAddOption;
    //     }
    //     let naIndex = listOfProjectsWithAddOption.findIndex(
    //         (project) => project.projectUUid === 'NA'
    //     );
    //     if (naIndex === -1) {
    //         listOfProjectsWithAddOption.push(projectNone);

    //         return listOfProjectsWithAddOption;
    //     }
    //     return listOfProjectsWithAddOption;
    // }, [isProjectFieldMandatory, listOfProjectsWithAddOption]);

    const myOptions = useMemo(() => {
        let updatedList = listOfProjectsWithAddOption.filter(
            (project) => project.projectUUid !== 'NA'
        );

        if (!isProjectFieldMandatory) {
            if (
                listOfProjectsRespone !== undefined &&
                listOfProjectsRespone.metadata.has_next
            ) {
                // Add projectNone before the LOAD_MORE_PROJECT
                updatedList.splice(updatedList.length - 1, 0, projectNone);
            } else {
                // Simply push projectNone at the end
                updatedList.push(projectNone);
            }
        }

        return updatedList;
    }, [
        isProjectFieldMandatory,
        listOfProjectsWithAddOption,
        listOfProjectsRespone,
    ]);

    const selectedProject = useMemo(() => {
        if (selectedProjectId !== null) {
            return (
                myOptions.find(
                    (project) => project.projectUUid === selectedProjectId
                ) ?? null
            );
        }
    }, [myOptions, selectedProjectId]);

    useEffect(() => {
        return () => {
            // udit u r lazy query and then fatching the data so dw about resetapistate it will always give new data and i am removing this cause this is clearing all the api state deta
            // store.dispatch(projectApiSlice.util.resetApiState());
            // store.dispatch(projectApiSlice.util.resetApiState());
            // setSearchText('');
        };
    }, []);
    return (
        <>
            <FWAutocomplete
                popupIcon={
                    <Box fontSize={'16px'}>
                        <i className="bi bi-chevron-down"></i>
                    </Box>
                }
                ListboxProps={{ style: { maxHeight: '300px' } }}
                disableClearable
                options={myOptions}
                disabled={disabled}
                getOptionLabel={(option: IProject) =>
                    option.projectUUid === 'NA'
                        ? 'NA(None)'
                        : `${option.projectCode} (${option.projectName})`
                }
                value={
                    isProjectFieldMandatory
                        ? selectedProject
                        : selectedProject ?? projectNone
                }
                isOptionEqualToValue={(option: IProject, value: IProject) =>
                    option.projectUUid === value.projectUUid
                }
                sx={{}}
                onChange={(e, newValue: IProject) => {
                    e.stopPropagation();
                    onChange({
                        project_code: newValue.projectCode,
                        project_id: newValue.projectUUid,
                        project_name: newValue.projectName,
                    });
                }}
                onInputChange={(event, value) => {
                    if (event === null) return;

                    if (event.type === 'change') handleSearchTextChange(value);
                }}
                // inputValue={searchText}
                // inputValue={
                //     searchText ||
                //     (selectedProject
                //         ? `${selectedProject?.projectCode} (${selectedProject?.projectName})`
                //         : searchText)
                // }
                renderInput={(params) => (
                    <FWInput
                        {...params}
                        error={error}
                        helper={{
                            text: helperText ?? '',
                        }}
                    />
                )}
                renderOption={(props, option: IProject) => {
                    return option.projectUUid === 'LOAD_MORE_PROJECT' ? (
                        <FWMenuItem
                            key={option.projectUUid}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setPageNo((prev) => prev + 1);
                            }}
                        >
                            <FWTypography color={'primary.main'}>
                                Show more results...
                            </FWTypography>
                        </FWMenuItem>
                    ) : (
                        <FWMenuItem
                            {...props}
                            key={option.projectUUid}
                            value={option.projectUUid}
                        >
                            <FWTypography>
                                {option.projectUUid === 'NA'
                                    ? 'NA(None)'
                                    : option.projectName}
                            </FWTypography>
                        </FWMenuItem>
                    );
                }}
            />
        </>
    );
};

export default FWAutoCompleteForProject;
