import { memo, useEffect, useState } from 'react';
import {
    FWAccordion,
    FWAccordionDetails,
    FWAccordionSummary,
} from '../../../../Common/FWAccordian';
import { Box, Checkbox, Grid, IconButton } from '@mui/material';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { useSelector } from 'react-redux';
import { IGlobalAppStore } from '../../../../Redux/Store';
import { IRfqTemplate } from '../../../../Global/Interfaces/TemplateInterface';
import useIntegrationWarningHook from '../../../../ItemAnalytics/Hooks/useIntegrationWarningHook';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { cloneDeep } from 'lodash';
import { nestedDeepEqual } from '../../../../Global/Helpers/helpers';

const WarningAccordion = ({
    requisition,
    rfqTemplate,
    reqsStatus,
    setReqsStatus,
    handleFieldsForExportChange,
}: {
    requisition: any;
    rfqTemplate: IRfqTemplate | undefined;
    reqsStatus: any[];
    setReqsStatus: React.Dispatch<React.SetStateAction<any[]>>;
    handleFieldsForExportChange: (
        fields: any[],
        customFields: any[],
        requistionId: string
    ) => void;
}) => {
    const [expanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState(true);
    const requisitionTemplate = useSelector(
        (store: IGlobalAppStore) =>
            store.EventIntegrationStore.requisitionTemplates?.find(
                (req) =>
                    req.templateId ===
                    requisition.additional_details.template_id
            ),
        nestedDeepEqual
    );
    const source = useSelector(
        (store: IGlobalAppStore) => store.EventIntegrationStore.source
    );
    const { fields, setFields } = useIntegrationWarningHook(
        requisitionTemplate,
        rfqTemplate,
        requisition,
        handleFieldsForExportChange,
        source
    );

    const handleRequisitionStatus = () => {
        let temp = reqsStatus.map((data) => {
            if (data.id === requisition.requisition_id) {
                return {
                    ...data,
                    checked: !data.checked,
                };
            } else return data;
        });
        setReqsStatus(temp);
    };
    useEffect(() => {
        if (reqsStatus.length !== 0) {
            let selectAllFields = false;
            let condition = reqsStatus.find(
                (data) => data.id === requisition.requisition_id
            );
            if (condition) selectAllFields = condition.checked;
            let newFields = cloneDeep(fields);
            if (!selectAllFields) {
                newFields = newFields.map((field) => {
                    return {
                        ...field,
                        isChecked: false,
                    };
                });
            } else {
                newFields = newFields.map((field) => {
                    return {
                        ...field,
                        isChecked: true,
                    };
                });
            }
            setFields(newFields);
        }
        //eslint-disable-next-line
    }, [reqsStatus]);
    const handleFieldSelection = (data: any) => {
        let newFields = cloneDeep(fields);
        newFields = newFields.map((field) => {
            if (field.label === data.label) {
                return {
                    ...field,
                    isChecked: !field.isChecked,
                };
            } else return field;
        });
        setFields(newFields);
    };
    return (
        <>
            <FWAccordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                sx={{
                    '&': {
                        border: '0px',
                        padding: '0px',
                        marginRight: '10px',
                    },
                    '&::before': {
                        height: '0px',
                        border: '0px',
                    },
                }}
            >
                <FWAccordionSummary expandIcon={null}>
                    <div className="flex flex--jcc flex--aic">
                        <Checkbox
                            checked={selected}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelected(!selected);
                                handleRequisitionStatus();
                            }}
                            color="success"
                        />
                        {`${requisition.requisition_name} (${requisition.custom_requisition_id})`}
                        <Box paddingLeft={2}>
                            {expanded ? (
                                <i
                                    className="bi bi-chevron-up"
                                    style={{ fontSize: '15px' }}
                                ></i>
                            ) : (
                                <i
                                    className="bi bi-chevron-down"
                                    style={{ fontSize: '15px' }}
                                ></i>
                            )}
                        </Box>
                    </div>
                </FWAccordionSummary>
                <FWAccordionDetails>
                    {/* <Grid
                        paddingLeft={3}
                        item
                        xs={12}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <Checkbox
                            checked={selectAllFields}
                            color="success"
                            onChange={(e, checked) => {
                                setSelectAllFields(!selectAllFields);
                                handleSelectAllOptions();
                            }}
                        />
                        <FWTypography>Select all</FWTypography>
                    </Grid> */}
                    <Grid
                        container
                        paddingLeft={3}
                        xs={12}
                        gridTemplateColumns={'50% 50%'}
                        display={'grid'}
                    >
                        {' '}
                        {fields.map((data) => {
                            return (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        display={'flex'}
                                        alignItems={'center'}
                                    >
                                        <Checkbox
                                            disabled={
                                                data.label === 'Quantity'
                                                    ? true
                                                    : !data.heirarchy
                                                    ? true
                                                    : !data.fieldExists
                                            }
                                            checked={
                                                data.label === 'Quantity'
                                                    ? true
                                                    : !data.fieldExists
                                                    ? false
                                                    : !data.heirarchy
                                                    ? false
                                                    : data.isChecked
                                            }
                                            color="success"
                                            onChange={(e, checked) => {
                                                handleFieldSelection(data);
                                            }}
                                        />
                                        <FWTypography
                                            color={
                                                !data.fieldExists
                                                    ? 'error.main'
                                                    : data.heirarchy
                                                    ? ''
                                                    : '#bebebe'
                                            }
                                        >
                                            {data.label}
                                        </FWTypography>
                                        {!data.fieldExists ? (
                                            <FWTooltip
                                                title={`Following field does not exist in the event template`}
                                                style={{
                                                    border: '0px',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                <IconButton color={'primary'}>
                                                    <i
                                                        className="bi bi-info-circle"
                                                        style={{
                                                            fontSize: '15px',
                                                        }}
                                                    ></i>
                                                </IconButton>
                                            </FWTooltip>
                                        ) : (
                                            !data.heirarchy && (
                                                <>
                                                    <FWTooltip
                                                        title={`Following field does not exist at the same level in the event template`}
                                                        style={{
                                                            border: '0px',
                                                        }}
                                                    >
                                                        <IconButton
                                                            color={'primary'}
                                                        >
                                                            <i
                                                                className="bi bi-info-circle"
                                                                style={{
                                                                    fontSize:
                                                                        '15px',
                                                                    paddingLeft:
                                                                        '5px',
                                                                }}
                                                            ></i>
                                                        </IconButton>
                                                    </FWTooltip>
                                                </>
                                            )
                                        )}
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                </FWAccordionDetails>
            </FWAccordion>
        </>
    );
};

export default memo(WarningAccordion);
