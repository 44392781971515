import { EntityStatus } from '../../Global/Interfaces/EntityInterface';
import {
    BuyerSellerStatus,
    IEntityViewBuyerMaster,
} from '../../Organizations/SellerAdmin/BuyerMaster/Interfaces/SellerAdminBuyerInterfaces.model';
import { ProjectStatusEnum } from '../../ProjectGlCostCenter/interface/project.model';
import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';

export const ccNone = {
    costCenterUid: 'NA',
    costCenterId: '',
    costCenterName: 'NA(None)',
    type: '',
    entitiesNames: [],
    buyerEntites: [
        {
            entity_cost_centre_id: '',
            buyer_entity_name: '',
            created_datetime: '',
            modified_datetime: '',
            deleted_datetime: null,
            status: '',
            created_by_user: '',
            modified_by_user: '',
            deleted_by_user: null,
            cost_centre: '',
            buyer_entity: '',
            currency_code: '',
        },
    ],
    buyerEntityCount: 0,
    status: '',
    createdBy: '',
    createdOn: '',
};
export const glNone = {
    glUid: 'NA',
    glCode: '',
    glAccountName: 'NA(None)',
    status: '',
    buyerEntityCount: 0,
    entitiesNames: [],
    createdOn: '',
    buyerEntites: [
        {
            entity_cost_centre_id: '',
            buyer_entity_name: '',
            created_datetime: '',
            modified_datetime: '',
            deleted_datetime: null,
            status: '',
            created_by_user: '',
            modified_by_user: '',
            deleted_by_user: null,
            cost_centre: '',
            buyer_entity: '',
            currency_code: '',
        },
    ],
};
export const projectNone = {
    attachments: [],
    createdByUserId: '',
    createdByUsername: '',
    createdOn: '',
    customerEntity: {
        entity_id: '',
        entity_name: '',
    },
    description: '',
    entityName: 'LOAD_MORE_PROJECT',
    entityUid: '',
    internalNotes: '',
    linkedCostCenterCount: 0,
    listCostCenterNames: [],
    modifiedByUsername: '',
    modifiedOn: '',
    projectCode: 'NA',
    projectCustomSections: [],
    projectManagers: [],
    projectName: 'None',
    projectStatus: ProjectStatusEnum.DRAFT,
    projectUUid: 'NA',
    status: ProjectStatusEnum.ACTIVE,
    submittedOn: '',
    templateId: '',
    validityFrom: '',
    validityTo: '',
};

export const customerNone: IEntityViewBuyerMaster = {
    entity_buyer_master_id: '',
    buyer_entity: {
        entity_id: 'NA',
        entity_name: 'NA(None)',
        entity_logo: [],
        entity_description: '',
        buyer_status: EntityStatus.ACTIVE,
        seller_status: EntityStatus.ACTIVE,
        contacts: {
            emails: [],
            websites: [],
            phone_numbers: [],
        },
        verification_status: VerificationStatus.VERIFIED,
        is_virtual: false,
    },
    enterprise_buyer_master: {
        enterprise_buyer_master_id: '',
        buyer_code: '',
        buyer_name: 'NA(None)',
        status: BuyerSellerStatus.ACTIVE,
        tags: [],
    },

    primary_buyer_contact: {
        buyer_contact_id: '',
        seller_entity: null,
        buyer_entity: '',
        full_name: '',
        primary_email: '',
        emails: [],
        phone_numbers: [],
        tags: [],
        is_primary: true,
        user: '',
        status: BuyerSellerStatus.ACTIVE,
        designation: '',
        department: '',
        notes:'',
    },

    created_datetime: '',
    modified_datetime: '',
    deleted_datetime: null,
    created_by_user: '',
    modified_by_user: '',
    deleted_by_user: null,
    seller_enterprise: '',
    seller_entity: '',
    buyer_enterprise: '',
};
