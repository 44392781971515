import { Box, DialogContent, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FWIconButton } from '../../Common/FWButton';
import { FWCombinedInput } from '../../Common/FWCombinedInput';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWInput } from '../../Common/FWInput';
import FWPopover from '../../Common/FWPopover';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import FWSelect from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import { useMemo } from 'react';
import {
    getPaymentTermsAppliedFromMenuItems,
    getPaymentTermsPeriodMenuItems,
} from '../../Global/Components/PaymentTermsMenuItems';
import {
    IPaymentTerms,
    IPaymentTermsAllocation,
    IPaymentTermsInvoiceItemValues,
    IPaymentTermsPoItemPercentages,
    PaymentTermsInvoiceItemAppliedFromType,
    StandardPaymentReferenceDateEnum,
} from '../../Global/Interfaces/PaymentTermsInterface';
import { PeriodEnum } from '../../Global/Interfaces/PeriodInterface';

export const PrepaymentUi = ({
    label = 'Prepayment percentage',
    prepaymentPercentage,
    updatePrepaymentPercentage,
    fieldError,
}: {
    label?: string;
    prepaymentPercentage: string;
    updatePrepaymentPercentage: (newPrepaymentPercentage: string) => void;
    fieldError: string | null;
}) => {
    return (
        <Box>
            <FWTypography color="text.secondary">{label}</FWTypography>
            <FWInput
                allowOnly="PERCENT"
                InputProps={{
                    inputProps: { min: 0, max: 100 },
                }}
                iconEnd="%"
                value={prepaymentPercentage}
                onChange={(e) => {
                    updatePrepaymentPercentage(
                        e.target.value !== '' ? e.target.value : '0'
                    );
                }}
                maxNumberOfDecimal={4}
                error={fieldError ? true : false}
                helper={{
                    text: fieldError ?? '',
                }}
            />
        </Box>
    );
};

export type PaymentTermsUiErrorMessagesAllocationRow = {
    allocationPercentage: string | null;
    terms: string | null;
    period: string | null;
    deliverableName: string | null;
    date: string | null;
};

export type PaymentTermsUiErrorMessages = {
    poItemPercentages?: {
        overallPercentage: string | null;
        rowErrors: PaymentTermsUiErrorMessagesAllocationRow[];
    };
    invoiceItemValues?: {
        terms: string | null;
        period: string | null;
        appliedFrom: string | null;
    };
};

export type PaymentTermsUpdateFunctions = {
    invoiceItemValues: {
        updateTerms: (newTerms: string) => void;
        updatePeriod: (newPeriod: PeriodEnum) => void;
        updateAppliedFrom: (
            newAppliedFrom: PaymentTermsInvoiceItemAppliedFromType
        ) => void;
    };
    poItemPercentages: {
        updateAllocationList: (newList: IPaymentTermsAllocation[]) => void;
    };
};

type RowType = 'FIXED_DATE' | 'DROPDOWN_DELIVERABLE' | 'CUSTOM_DELIVERABLE';

export const PaymentTermsUi = ({
    paymentTerms,
    standardReferenceDateOptions,
    customReferenceDateOptions,
    allowManualDeliverable,
    label,
    labelRightElements,
    updateFunctions,
    errorMessages,
    type,
    fieldError,
    showPrepaymentField,
    prepaymentPercentage,
    updatePrepaymentPercentage,
    popupOpen,
    onClose,
    isRequired,
}: {
    paymentTerms: IPaymentTerms;
    standardReferenceDateOptions: {
        [key in StandardPaymentReferenceDateEnum]: {
            label: string;
            enabled: boolean;
        };
    };
    customReferenceDateOptions: string[];
    allowManualDeliverable: boolean;
    label: string;
    labelRightElements?: JSX.Element;
    updateFunctions: PaymentTermsUpdateFunctions;
    errorMessages: PaymentTermsUiErrorMessages;
    type: 'BUYER' | 'SELLER';
    showPrepaymentField: boolean;
    prepaymentPercentage: string;
    updatePrepaymentPercentage: (newVal: string) => void;
    fieldError: string | null;
    popupOpen: boolean;
    onClose: () => void;
    isRequired?: boolean;
}) => {
    return (
        <FWPopupWithChildren
            open={popupOpen}
            title={`Edit ${label}`}
            handleClose={onClose}
            size="large"
        >
            <DialogContent>
                <PaymentTermsBoxUi
                    isRequired={isRequired}
                    paymentTerms={paymentTerms}
                    standardReferenceDateOptions={standardReferenceDateOptions}
                    customReferenceDateOptions={customReferenceDateOptions}
                    allowManualDeliverable={allowManualDeliverable}
                    label={label}
                    labelRightElements={labelRightElements}
                    updateFunctions={updateFunctions}
                    errorMessages={errorMessages}
                    type={type}
                    fieldError={fieldError}
                    showPrepaymentField={showPrepaymentField}
                    prepaymentPercentage={prepaymentPercentage}
                    updatePrepaymentPercentage={updatePrepaymentPercentage}
                />
            </DialogContent>
        </FWPopupWithChildren>
    );
};
export const PaymentTermsBoxUi = ({
    paymentTerms,
    standardReferenceDateOptions,
    customReferenceDateOptions,
    allowManualDeliverable,
    label,
    labelRightElements,
    updateFunctions,
    errorMessages,
    type,
    fieldError,
    showPrepaymentField,
    prepaymentPercentage,
    updatePrepaymentPercentage,
    isRequired,
}: {
    paymentTerms: IPaymentTerms;
    standardReferenceDateOptions: {
        [key in StandardPaymentReferenceDateEnum]: {
            label: string;
            enabled: boolean;
        };
    };
    customReferenceDateOptions: string[];
    allowManualDeliverable: boolean;
    label: string;
    labelRightElements?: JSX.Element;
    updateFunctions: PaymentTermsUpdateFunctions;
    errorMessages: PaymentTermsUiErrorMessages;
    type: 'BUYER' | 'SELLER';
    showPrepaymentField: boolean;
    prepaymentPercentage: string;
    updatePrepaymentPercentage: (newVal: string) => void;
    fieldError: string | null;
    isRequired?: boolean;
}) => {
    const fullyPrepaid = +paymentTerms.prepaymentPercentage >= 100;

    const getInitRowTypes = (): RowType[] => {
        const initRowTypes: RowType[] = [];
        if (paymentTerms.paymentType === 'poItemPercentages') {
            paymentTerms.list.forEach((row) => {
                if (row.milestoneType === 'FIXED_DATE') {
                    initRowTypes.push('FIXED_DATE');
                } else {
                    if (
                        customReferenceDateOptions.includes(row.deliverableName)
                    ) {
                        initRowTypes.push('DROPDOWN_DELIVERABLE');
                    } else {
                        initRowTypes.push('CUSTOM_DELIVERABLE');
                    }
                }
            });
        }
        return initRowTypes;
    };

    useEffect(() => {
        setRowTypes(getInitRowTypes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentTerms.paymentType]);

    const [rowTypes, setRowTypes] = React.useState<RowType[]>(
        getInitRowTypes()
    );

    const changeRowType = (index: number, newRowType: RowType) => {
        if (
            paymentTerms.paymentType === 'poItemPercentages' &&
            paymentTerms.list[index] &&
            rowTypes[index]
        ) {
            const newRowTypes = [...rowTypes];
            newRowTypes[index] = newRowType;
            setRowTypes(newRowTypes);

            const newAllocationList = cloneDeep(paymentTerms.list);
            let originalRow = newAllocationList[index];
            let updatedRow: IPaymentTermsAllocation;
            switch (newRowType) {
                case 'FIXED_DATE':
                    updatedRow = {
                        poItemPercentage: originalRow.poItemPercentage,
                        milestoneType: 'FIXED_DATE',
                        // Initialize to 1 month from today
                        paymentDate: moment()
                            .add(1, 'month')
                            .format('YYYY-MM-DD'),
                    };
                    break;
                case 'CUSTOM_DELIVERABLE':
                    updatedRow = {
                        poItemPercentage: originalRow.poItemPercentage,
                        milestoneType: 'DELIVERABLE',
                        terms:
                            originalRow.milestoneType === 'DELIVERABLE'
                                ? originalRow.terms
                                : '',
                        period:
                            originalRow.milestoneType === 'DELIVERABLE'
                                ? originalRow.period
                                : PeriodEnum.DAYS,
                        deliverableName: '',
                    };
                    break;
                case 'DROPDOWN_DELIVERABLE':
                    updatedRow = {
                        poItemPercentage: originalRow.poItemPercentage,
                        milestoneType: 'DELIVERABLE',
                        terms:
                            originalRow.milestoneType === 'DELIVERABLE'
                                ? originalRow.terms
                                : '',
                        period:
                            originalRow.milestoneType === 'DELIVERABLE'
                                ? originalRow.period
                                : PeriodEnum.DAYS,
                        deliverableName: customReferenceDateOptions[0] ?? '',
                    };
            }
            newAllocationList[index] = updatedRow;
            updateFunctions.poItemPercentages.updateAllocationList(
                newAllocationList
            );
        }
    };

    const deleteRow = (index: number) => {
        if (
            paymentTerms.paymentType === 'poItemPercentages' &&
            paymentTerms.list[index] &&
            rowTypes[index]
        ) {
            const newList = cloneDeep(paymentTerms.list);
            newList.splice(index, 1);
            const newRowTypes = [...rowTypes];
            newRowTypes.splice(index, 1);
            setRowTypes(newRowTypes);
            updateFunctions.poItemPercentages.updateAllocationList(newList);
        }
    };

    return (
        <>
            {showPrepaymentField && (
                <Box>
                    <PrepaymentUi
                        prepaymentPercentage={prepaymentPercentage}
                        updatePrepaymentPercentage={updatePrepaymentPercentage}
                        fieldError={fieldError}
                    />
                </Box>
            )}
            &nbsp;
            <Box className="flex flex--aic">{labelRightElements}</Box>
            <FWTooltip
                title={fullyPrepaid ? 'Not required as prepayment is 100%' : ''}
            >
                {paymentTerms.paymentType === 'invoiceItemValues' ? (
                    <Box>
                        <PaymentTermsInvoiceItemUi
                            isRequired={isRequired}
                            label={label}
                            paymentTerms={paymentTerms}
                            standardReferenceDateOptions={
                                standardReferenceDateOptions
                            }
                            allowManualDeliverable={allowManualDeliverable}
                            customReferenceDateOptions={
                                customReferenceDateOptions
                            }
                            updateFunctions={updateFunctions}
                            errorMessages={errorMessages}
                            user_type={type}
                        />
                    </Box>
                ) : (
                    <Box>
                        <PaymentTermsPoItemUi
                            label={label}
                            paymentTerms={paymentTerms}
                            deliverableNameDropdownOptions={
                                customReferenceDateOptions
                            }
                            allowManualDeliverable={allowManualDeliverable}
                            updateFunctions={updateFunctions}
                            errorMessages={errorMessages}
                            rowTypes={rowTypes}
                            changeRowType={changeRowType}
                            deleteRow={deleteRow}
                            setRowTypes={setRowTypes}
                        />
                    </Box>
                )}
            </FWTooltip>
        </>
    );
};

const PaymentTermsInvoiceItemUi = ({
    label = 'Payment terms',
    paymentTerms,
    standardReferenceDateOptions,
    customReferenceDateOptions,
    allowManualDeliverable,
    updateFunctions,
    errorMessages,
    user_type,
    isRequired,
}: {
    label: string;
    paymentTerms: IPaymentTermsInvoiceItemValues;
    standardReferenceDateOptions: {
        [key in StandardPaymentReferenceDateEnum]: {
            label: string;
            enabled: boolean;
        };
    };
    customReferenceDateOptions: string[];
    allowManualDeliverable: boolean;
    updateFunctions: PaymentTermsUpdateFunctions;
    errorMessages: PaymentTermsUiErrorMessages;
    user_type: 'BUYER' | 'SELLER';
    isRequired?: boolean;
}) => {
    const fullyPrepaid = +paymentTerms.prepaymentPercentage >= 100;

    let allAppliedFromOptions = [...customReferenceDateOptions];

    if (standardReferenceDateOptions.DISPATCH_DATE.enabled) {
        allAppliedFromOptions.push('DISPATCH_DATE');
    }
    if (standardReferenceDateOptions.INVOICE_DATE.enabled) {
        allAppliedFromOptions.push('INVOICE_DATE');
    }
    if (standardReferenceDateOptions.RECEIPT_DATE.enabled) {
        allAppliedFromOptions.push('RECEIPT_DATE');
    }

    const initShowCustomEntryField =
        allowManualDeliverable &&
        paymentTerms.appliedFrom &&
        paymentTerms.appliedFrom.referenceDateLabel &&
        !allAppliedFromOptions.includes(
            paymentTerms.appliedFrom.referenceDateLabel
        );

    const [showCustomEntryField /* setShowCustomEntryField */] = React.useState(
        initShowCustomEntryField
    );

    const disablePaymentTermsDuration = paymentTerms.appliedFrom
        ? paymentTerms.appliedFrom.referenceDateLabel === 'NA'
        : false;

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FWTypography color="text.secondary">
                    {label} duration
                </FWTypography>
                <FWTooltip
                    title={
                        disablePaymentTermsDuration
                            ? 'Not required as NA is selected'
                            : ''
                    }
                >
                    <Box>
                        <FWCombinedInput
                            allowOnly="NUMBER"
                            textFieldValue={paymentTerms.terms}
                            textFieldOnChange={(e) => {
                                updateFunctions.invoiceItemValues.updateTerms(
                                    e.target.value !== '' ? e.target.value : '0'
                                );
                            }}
                            error={
                                errorMessages.invoiceItemValues?.terms
                                    ? true
                                    : false
                            }
                            selectFieldMenuItems={getPaymentTermsPeriodMenuItems()}
                            selectFieldOnChange={(e: any) => {
                                if (e.target.value in PeriodEnum) {
                                    const newPeriod = e.target
                                        .value as PeriodEnum;
                                    updateFunctions.invoiceItemValues.updatePeriod(
                                        newPeriod
                                    );
                                }
                            }}
                            selectFieldValue={paymentTerms.period}
                            isDropLeft={false}
                            helperText={
                                errorMessages.invoiceItemValues?.terms ?? ''
                            }
                            inputDisabled={
                                fullyPrepaid || disablePaymentTermsDuration
                            }
                            selectDisabled={
                                fullyPrepaid || disablePaymentTermsDuration
                            }
                        />
                    </Box>
                </FWTooltip>
            </Grid>
            <Grid item xs={6}>
                <FWTypography color="text.secondary">
                    {label} applied from
                </FWTypography>

                {user_type === 'BUYER' && showCustomEntryField ? (
                    <FWInput
                        value={
                            paymentTerms.appliedFrom
                                ? paymentTerms.appliedFrom.referenceDateLabel
                                : ''
                        }
                        onChange={(e) =>
                            updateFunctions.invoiceItemValues.updateAppliedFrom(
                                {
                                    referenceDateCategory: 'CUSTOM',
                                    referenceDateLabel: e.target.value,
                                }
                            )
                        }
                        error={
                            errorMessages.invoiceItemValues?.appliedFrom
                                ? true
                                : false
                        }
                        helper={{
                            text:
                                errorMessages.invoiceItemValues?.appliedFrom ??
                                '',
                        }}
                        disabled={fullyPrepaid}
                    />
                ) : (
                    <FWSelect
                        fullWidth
                        value={
                            paymentTerms.appliedFrom
                                ? paymentTerms.appliedFrom.referenceDateLabel
                                : ''
                        }
                        onChange={(e: any) => {
                            const newReferenceDateLabel = e.target.value;
                            let newAppliedFrom = paymentTerms.appliedFrom;
                            if (
                                newReferenceDateLabel in
                                    StandardPaymentReferenceDateEnum ||
                                newReferenceDateLabel === 'NA'
                            ) {
                                newAppliedFrom = {
                                    referenceDateCategory: 'STANDARD',
                                    referenceDateLabel: newReferenceDateLabel,
                                };
                            } else {
                                newAppliedFrom = {
                                    referenceDateCategory: 'CUSTOM',
                                    referenceDateLabel: newReferenceDateLabel,
                                };
                            }
                            updateFunctions.invoiceItemValues.updateAppliedFrom(
                                newAppliedFrom
                            );

                            if (
                                newAppliedFrom.referenceDateLabel === 'NA' ||
                                null
                            ) {
                                // updateFunctions.invoiceItemValues.updatePeriod(
                                //     'NA'
                                // );
                                // updateFunctions.invoiceItemValues.updateTerms(
                                //     '0'
                                // );
                            }
                        }}
                        error={
                            errorMessages.invoiceItemValues?.appliedFrom
                                ? true
                                : false
                        }
                        disabled={fullyPrepaid}
                    >
                        {getPaymentTermsAppliedFromMenuItems({
                            standardReferenceDateOptions:
                                standardReferenceDateOptions,
                            customReferenceDateOptions:
                                customReferenceDateOptions,
                            isRequired,
                        })}
                    </FWSelect>
                )}
            </Grid>
        </Grid>
    );
};

const PaymentTermsPoItemUi = ({
    label,
    paymentTerms,
    deliverableNameDropdownOptions,
    allowManualDeliverable,
    updateFunctions,
    errorMessages,
    rowTypes,
    changeRowType,
    deleteRow,
    setRowTypes,
}: {
    label: string;
    paymentTerms: IPaymentTermsPoItemPercentages;
    deliverableNameDropdownOptions: string[];
    allowManualDeliverable: boolean;
    updateFunctions: PaymentTermsUpdateFunctions;
    errorMessages: PaymentTermsUiErrorMessages;
    rowTypes: RowType[];
    changeRowType: (index: number, newRowType: RowType) => void;
    deleteRow: (index: number) => void;
    setRowTypes: React.Dispatch<React.SetStateAction<RowType[]>>;
}) => {
    const fullyPrepaid = +paymentTerms.prepaymentPercentage >= 100;
    const allocationPercentageField = (index: number) => {
        const row = paymentTerms.list[index]
            ? cloneDeep(paymentTerms.list[index])
            : null;

        const updateAllocation = (newValue: string) => {
            const newList = cloneDeep(paymentTerms.list);
            newList[index].poItemPercentage = newValue;
            updateFunctions.poItemPercentages.updateAllocationList(newList);
        };

        const fieldError =
            errorMessages.poItemPercentages?.rowErrors[index]
                ?.allocationPercentage ?? null;

        return row ? (
            <FWInput
                allowOnly="DECIMAL_NUMBER"
                InputProps={{
                    inputProps: { min: 0, max: 100, step: 0.01 },
                }}
                iconEnd="%"
                maxNumberOfDecimal={4}
                value={row.poItemPercentage}
                onChange={(e) => updateAllocation(e.target.value)}
                error={fieldError ? true : false}
                disabled={fullyPrepaid}
            />
        ) : (
            <></>
        );
    };

    const durationField = (index: number) => {
        const row = paymentTerms.list[index]
            ? cloneDeep(paymentTerms.list[index])
            : null;
        if (row && row.milestoneType === 'DELIVERABLE') {
            const updateTerms = (newValue: string) => {
                row.terms = newValue;
                const newList = cloneDeep(paymentTerms.list);
                newList[index] = row;
                updateFunctions.poItemPercentages.updateAllocationList(newList);
            };

            const updatePeriod = (newValue: PeriodEnum) => {
                row.period = newValue;
                const newList = cloneDeep(paymentTerms.list);
                newList[index] = row;
                updateFunctions.poItemPercentages.updateAllocationList(newList);
            };

            const fieldError =
                errorMessages.poItemPercentages?.rowErrors[index]?.terms ??
                null;

            return (
                <FWCombinedInput
                    allowOnly="NUMBER"
                    textFieldValue={row.terms}
                    textFieldOnChange={(e) => {
                        if (e.target.value === '0') {
                            // updateTerms('');
                            updateTerms(e.target.value);
                        }
                        const value =
                            e.target.value === ''
                                ? '0'
                                : e.target.value.replace(/^0+/, '');
                        updateTerms(value);
                    }}
                    error={
                        fieldError || isDuplicateValue.includes(index)
                            ? true
                            : false
                    }
                    selectFieldMenuItems={getPaymentTermsPeriodMenuItems()}
                    selectFieldOnChange={(e: any) => {
                        if (e.target.value in PeriodEnum) {
                            const newPeriod = e.target.value as PeriodEnum;
                            updatePeriod(newPeriod);
                        }
                    }}
                    selectFieldValue={row.period}
                    isDropLeft={false}
                    inputDisabled={fullyPrepaid}
                    selectDisabled={fullyPrepaid}
                />
            );
        }
        return <></>;
    };

    const customDeliverableNameField = (index: number) => {
        const row = paymentTerms.list[index]
            ? cloneDeep(paymentTerms.list[index])
            : null;
        if (row && row.milestoneType === 'DELIVERABLE') {
            const updateDeliverableName = (newValue: string) => {
                row.deliverableName = newValue;
                const newList = cloneDeep(paymentTerms.list);
                newList[index] = row;
                updateFunctions.poItemPercentages.updateAllocationList(newList);
            };

            const fieldError =
                errorMessages.poItemPercentages?.rowErrors[index]
                    ?.deliverableName ?? null;

            return (
                <FWSelect
                    fullWidth
                    value={row.deliverableName}
                    onChange={(e: any) => {
                        updateDeliverableName(e.target.value);
                    }}
                    error={
                        fieldError || isDuplicateValue.includes(index)
                            ? true
                            : false
                    }
                    disabled={fullyPrepaid}
                >
                    {deliverableNameDropdownOptions.map((option) => (
                        <FWMenuItem value={option}>{option}</FWMenuItem>
                    ))}
                </FWSelect>
            );
        }
        return <></>;
    };

    const selectDeliverableNameField = (index: number) => {
        const row = paymentTerms.list[index]
            ? cloneDeep(paymentTerms.list[index])
            : null;
        if (row && row.milestoneType === 'DELIVERABLE') {
            const updateDeliverableName = (newValue: string) => {
                row.deliverableName = newValue;
                const newList = cloneDeep(paymentTerms.list);
                newList[index] = row;
                updateFunctions.poItemPercentages.updateAllocationList(newList);
            };

            const fieldError =
                errorMessages.poItemPercentages?.rowErrors[index]
                    ?.deliverableName ?? null;

            return (
                <FWSelect
                    fullWidth
                    value={row.deliverableName}
                    onChange={(e: any) => {
                        updateDeliverableName(e.target.value);
                    }}
                    error={
                        fieldError || isDuplicateValue.includes(index)
                            ? true
                            : false
                    }
                    disabled={fullyPrepaid}
                >
                    {deliverableNameDropdownOptions.map((option) => (
                        <FWMenuItem value={option}>{option}</FWMenuItem>
                    ))}
                </FWSelect>
            );
        }
        return <></>;
    };

    const [toggleRowAnchorEl, setToggleRowAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const [menuIndex, setMenuIndex] = React.useState<number | null>(null);

    const closeMenu = () => {
        setToggleRowAnchorEl(null);
        setMenuIndex(null);
    };

    useEffect(() => {
        if (
            paymentTerms.paymentType === 'poItemPercentages' &&
            paymentTerms.list.length === 0
        ) {
            const newList = cloneDeep(paymentTerms.list);
            const newItem: IPaymentTermsAllocation = {
                poItemPercentage: '',
                milestoneType: 'DELIVERABLE',
                deliverableName: '',
                terms: '30',
                period: PeriodEnum.DAYS,
            };
            if (newItem) {
                newList.push({ ...newItem, poItemPercentage: '' });
                const addedRowType = rowTypes[rowTypes.length - 1];
                setRowTypes([...rowTypes, addedRowType]);
            }
            updateFunctions.poItemPercentages.updateAllocationList(newList);
        }
    }, [
        paymentTerms.list,
        paymentTerms.paymentType,
        rowTypes,
        setRowTypes,
        updateFunctions.poItemPercentages,
    ]);

    const OptionsColumn = (
        index: number,
        setRowTypes: React.Dispatch<React.SetStateAction<RowType[]>>
    ) => {
        const rowType = rowTypes[index];
        if (!rowType) {
            return <></>;
        }

        const addNewPoAllocationRow = () => {
            if (paymentTerms.paymentType === 'poItemPercentages') {
                const newList = cloneDeep(paymentTerms.list);
                const newItem = newList[newList.length - 1];
                if (newItem) {
                    newList.push({ ...newItem, poItemPercentage: '' });
                    const addedRowType = rowTypes[rowTypes.length - 1];
                    setRowTypes([...rowTypes, addedRowType]);
                }
                updateFunctions.poItemPercentages.updateAllocationList(newList);
            }
        };
        const isDisabled = paymentTerms.list.length === 1 || fullyPrepaid;
        return (
            <Box className="flex flex--aic">
                <FWTooltip title={fullyPrepaid ? '' : 'Delete row'}>
                    <Box>
                        <FWIconButton
                            size="small"
                            disabled={isDisabled}
                            color="error"
                            onClick={() => {
                                deleteRow(index);
                            }}
                        >
                            <i className="bi bi-dash-circle" />
                        </FWIconButton>
                    </Box>
                </FWTooltip>
                {paymentTerms.paymentType === 'poItemPercentages' &&
                    index === paymentTerms.list.length - 1 && (
                        <>
                            &nbsp;
                            <FWTooltip
                                title={fullyPrepaid ? '' : 'Add deliverable'}
                            >
                                <Box color="primary">
                                    <FWIconButton
                                        size="small"
                                        onClick={addNewPoAllocationRow}
                                        disabled={fullyPrepaid}
                                    >
                                        <i className="bi bi-plus-circle" />
                                    </FWIconButton>
                                </Box>
                            </FWTooltip>
                        </>
                    )}
                <FWTooltip title="More options">
                    <Box>
                        <FWIconButton
                            size="small"
                            color="primary"
                            onClick={(e: any) => {
                                setToggleRowAnchorEl(e.currentTarget);
                                setMenuIndex(index);
                            }}
                            disabled={fullyPrepaid}
                        >
                            <i className="bi bi-three-dots" />
                        </FWIconButton>
                    </Box>
                </FWTooltip>

                <FWPopover
                    open={Boolean(toggleRowAnchorEl) && menuIndex === index}
                    anchorEl={toggleRowAnchorEl}
                    onClose={closeMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {rowType !== 'FIXED_DATE' && (
                        <FWMenuItem
                            onClick={() => {
                                changeRowType(index, 'FIXED_DATE');
                                closeMenu();
                            }}
                        >
                            Use fixed payment date
                        </FWMenuItem>
                    )}

                    {rowType !== 'DROPDOWN_DELIVERABLE' && (
                        <FWMenuItem
                            onClick={() => {
                                changeRowType(index, 'DROPDOWN_DELIVERABLE');
                                closeMenu();
                            }}
                        >
                            Use standard deliverable
                        </FWMenuItem>
                    )}

                    {allowManualDeliverable &&
                        rowType !== 'CUSTOM_DELIVERABLE' && (
                            <FWMenuItem
                                onClick={() => {
                                    changeRowType(index, 'CUSTOM_DELIVERABLE');
                                    closeMenu();
                                }}
                            >
                                Enter custom deliverable
                            </FWMenuItem>
                        )}
                </FWPopover>
            </Box>
        );
    };

    const dateColumn = (index: number) => {
        const row = paymentTerms.list[index];
        if (row && row.milestoneType === 'FIXED_DATE') {
            const updateDate = (newDate: string) => {
                const newList = cloneDeep(paymentTerms.list);
                const newRow = newList[index];
                if (newRow.milestoneType === 'FIXED_DATE') {
                    newRow.paymentDate = newDate;
                }
                updateFunctions.poItemPercentages.updateAllocationList(newList);
            };

            const fieldError =
                errorMessages.poItemPercentages?.rowErrors[index]?.date ?? null;

            return (
                <FWInput
                    type="date"
                    required
                    value={row.paymentDate}
                    onChange={(e) => {
                        updateDate(e.target.value);
                    }}
                    disabled={+paymentTerms.prepaymentPercentage >= 100}
                    InputProps={{
                        inputProps: {
                            min: moment().format('YYYY-MM-DD'),
                        },
                    }}
                    error={fieldError ? true : false}
                />
            );
        }
        return <></>;
    };

    const allocationPercError =
        errorMessages.poItemPercentages?.overallPercentage ?? null;
    // console.log('Amaan',paymentTerms.list)

    // const isDuplicateValue=(newItem:any,itemList:any)=>{
    //     for (const item of itemList) {
    //         if (
    //             item.deliverableName === newItem.deliverableName &&
    //             item.milestoneType === newItem.milestoneType &&
    //             item.period === newItem.period &&
    //             item.poItemPercentage === newItem.poItemPercentage &&
    //             item.terms === newItem.terms
    //         ) {
    //             return true;
    //         }

    //     }
    //     return false;
    // }

    const isDuplicateValue = useMemo(() => {
        const itemList = paymentTerms.list;
        const seen: { [key: string]: number } = {};
        const duplicateIndices: number[] = [];

        for (let i = 0; i < itemList.length; i++) {
            const { poItemPercentage, ...rest } = itemList[i];
            const itemKey = JSON.stringify(rest);

            if (seen[itemKey] !== undefined) {
                duplicateIndices.push(seen[itemKey]);
                duplicateIndices.push(i);
            } else {
                seen[itemKey] = i;
            }
        }

        const uniqueDuplicates = duplicateIndices.filter(
            (value, index, self) => self.indexOf(value) === index
        );

        return uniqueDuplicates;
    }, [paymentTerms.list]);

    return (
        <div>
            {
                <PoItemPaymentTermsHeaders
                    allocationPercError={allocationPercError}
                    label={label}
                />
            }
            <Grid container spacing={2}>
                {paymentTerms.list.map((row, index) => {
                    const rowType = rowTypes[index];
                    if (row.milestoneType === 'DELIVERABLE') {
                        return (
                            <>
                                <Grid item xs={3}>
                                    {allocationPercentageField(index)}
                                </Grid>
                                {/* <Grid item xs={3}>
                                    {durationField(index)}
                                </Grid> */}

                                <Grid item xs={3}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {durationField(index)}
                                        {isDuplicateValue.includes(index) && (
                                            <FWTypography
                                                color={'error'}
                                                fontSize={'10px'}
                                            >
                                                Same terms cannot be added
                                            </FWTypography>
                                        )}
                                    </div>
                                </Grid>

                                <Grid item xs={3}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {rowType === 'DROPDOWN_DELIVERABLE'
                                            ? selectDeliverableNameField(index)
                                            : customDeliverableNameField(index)}
                                        {isDuplicateValue.includes(index) && (
                                            <FWTypography
                                                color={'error'}
                                                fontSize={'10px'}
                                            >
                                                Same terms cannot be added
                                            </FWTypography>
                                        )}
                                    </div>
                                </Grid>

                                {/* <Grid item xs={3}>
                                    {rowType === 'DROPDOWN_DELIVERABLE'
                                        ? selectDeliverableNameField(index)
                                        : customDeliverableNameField(index)}
                                </Grid> */}
                                <Grid item xs={3}>
                                    <Box>
                                        {OptionsColumn(index, setRowTypes)}
                                    </Box>
                                </Grid>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <Grid item xs={3}>
                                    {allocationPercentageField(index)}
                                </Grid>
                                <Grid item xs={3}>
                                    <FWTypography
                                        color="text.secondary"
                                        lineHeight={'2.5rem'}
                                        textAlign={'center'}
                                    >
                                        -
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    {dateColumn(index)}
                                </Grid>
                                <Grid item xs={3}>
                                    {OptionsColumn(index, setRowTypes)}
                                </Grid>
                            </>
                        );
                    }
                })}
            </Grid>
        </div>
    );
};

const PoItemPaymentTermsHeaders = ({
    allocationPercError,
    label = 'Payment terms',
}: {
    allocationPercError: string | null;
    label?: string;
}) => (
    <Grid container spacing={2}>
        <Grid item xs={3}>
            <Box className="flex flex--aic" marginBottom={1}>
                <FWTypography>Percentage</FWTypography>
                &nbsp;
                <FWTooltip title="Percentage of PO value">
                    <Box color="primary.main">
                        <i className="bi bi-info-circle" />
                    </Box>
                </FWTooltip>
                {allocationPercError && (
                    <>
                        &nbsp;
                        <FWTooltip title={allocationPercError}>
                            <Box color="error.main">
                                <i className="bi bi-exclamation-circle" />
                            </Box>
                        </FWTooltip>
                    </>
                )}
            </Box>
        </Grid>
        <Grid item xs={3}>
            <FWTypography>{label} duration </FWTypography>
        </Grid>
        <Grid item xs={3}>
            <FWTypography>Applied from</FWTypography>
        </Grid>
        {/* <Box className="flex flex--aic" paddingLeft={'0.5rem'}></Box>
        <Box className="flex flex--aic" paddingLeft={'0.5rem'}>
            <FWTypography>Applied from</FWTypography>
        </Box>
        <Box className="flex flex--aic" paddingLeft={'0.5rem'}></Box> */}
    </Grid>
);
