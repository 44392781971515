import { ColDef } from 'ag-grid-community';
import moment from 'moment';
import { FWTypography } from '../../../Common/FWTypograhy';
import EntityVendorsListOptionsMenu from '../Components/Vendors/EntityVendorsListOptionsMenu';
import {
    EnterprisePrimaryContactCell,
    EnterpriseSecondaryContactCell,
    EntityPrimaryContactCell,
} from '../Components/Vendors/PrimaryContactCell';
import VendorEntitiesLinkedCell from '../Components/Vendors/VendorEntitiesCell';
import VendorNameCell from '../Components/Vendors/VendorNameCell';
import VendorsListOptionsMenu from '../Components/Vendors/VendorsListOptionsMenu';
import {
    IEntityVendorListSummary,
    IVendorListSummary,
    VendorStatus,
    VendorVerificationStatus,
} from '../Interfaces/VendorsInterface';
import {
    DASHBOARD_FIELD,
    DASHBOARD_FIELD_COLUMN,
    DASHBOARD_FIELD_NAME,
    DASHBOARD_FIELD_TYPE,
    DASHBOARD_SECTION_NAME,
    TEMPLATE_FIELD_TYPE,
    TVendorList,
} from '../../../ProjectGlCostCenter/interface/project.model';
import { CustomFieldType } from '../../../Global/Interfaces/TemplateInterface';
import {
    IFieldTypeLabel,
    PROJECT_DASHBOARD_FIELD_DELIMITER,
} from '../../../ProjectGlCostCenter/helpers/projectDashboardHelper';
import { cloneDeep } from 'lodash';

export const vendorsListColumns: (
    showOptions: boolean,
    isSingleEntity: boolean
) => ColDef[] = (showOptions, isSingleEntity) => {
    const columns: ColDef[] = [
        {
            field: 'vendor_code',
            headerName: 'Vendor code',
            width: 250,
            pinned: 'left',
            refData: {
                [DASHBOARD_FIELD_NAME]: 'vendor_code',
                [DASHBOARD_FIELD_COLUMN]: 'Vendor code',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: ({ data }: { data: IVendorListSummary }) => (
                <FWTypography>{data.vendorCode}</FWTypography>
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IVendorListSummary }) =>
                data.vendorCode,
            getQuickFilterText: ({ data }: { data: IVendorListSummary }) =>
                data.vendorCode,
        },
        {
            field: 'vendor_name',
            headerName: 'Vendor name',
            flex: 1,
            minWidth: 400,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'vendor_name',
                [DASHBOARD_FIELD_COLUMN]: 'Vendor name',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: VendorNameCell,
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IVendorListSummary }) =>
                data.vendorName,
            getQuickFilterText: ({ data }: { data: IVendorListSummary }) =>
                data.vendorName,
        },
        {
            field: 'vendor_contact',
            headerName: 'Primary contact',
            width: 400,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'vendor_contact',
                [DASHBOARD_FIELD_COLUMN]: 'Primary contact',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.CHOICE,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: EnterprisePrimaryContactCell,
            sortable: false,
            filter: true,
            valueGetter: ({ data }: { data: IVendorListSummary }) =>
                data.primaryContact.fullName,
            getQuickFilterText: ({ data }: { data: IVendorListSummary }) =>
                data.primaryContact.fullName,
        },
        {
            field: 'secondaryContact',
            headerName: 'Secondary contact(s)',
            width: 250,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'secondaryContact',
                [DASHBOARD_FIELD_COLUMN]: 'Secondary contact(s)',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.CHOICE,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: EnterpriseSecondaryContactCell,
            sortable: false,
            filter: true,
            valueGetter: ({ data }: { data: IVendorListSummary }) =>
                data.secondaryContact?.length > 0
                    ? data.secondaryContact?.length.toString()
                    : '-',
            getQuickFilterText: ({ data }: { data: IVendorListSummary }) =>
                data.secondaryContact?.length > 0
                    ? data.secondaryContact?.length.toString()
                    : '-',
        },

        {
            field: 'modified_datetime',
            headerName: 'Modified on',
            width: 220,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'modified_datetime',
                [DASHBOARD_FIELD_COLUMN]: 'Modified on',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: CustomFieldType.DATETIME,
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: ({ data }: { data: IVendorListSummary }) => (
                <FWTypography color="text.secondary">
                    {data.modifiedDateTime}
                </FWTypography>
            ),
            sortable: true,
            filter: true,
            valueGetter: ({ data }: { data: IVendorListSummary }) =>
                data.modifiedDateTime,
            getQuickFilterText: ({ data }: { data: IVendorListSummary }) =>
                data.modifiedDateTime,
            comparator: (a, b) => {
                return moment(a).isAfter(moment(b)) ? 1 : -1;
            },
        },
        ...(showOptions
            ? [
                  {
                      field: 'options',
                      headerName: 'Options',
                      pinned: 'right',
                      headerClass: 'center',
                      width: 110,
                      cellRenderer: VendorsListOptionsMenu,
                  },
              ]
            : []),
    ];
    if (!isSingleEntity) {
        columns.splice(4, 0, {
            field: 'buyerEntities',
            headerName: 'Assigned entities',
            width: 250,
            refData: {
                [DASHBOARD_FIELD_NAME]: 'buyerEntities',
                [DASHBOARD_FIELD_COLUMN]: 'Assigned entities',
                [DASHBOARD_SECTION_NAME]: '',
                [DASHBOARD_FIELD]: 'BUILTIN',
                [DASHBOARD_FIELD_TYPE]: 'SHORTTEXT',
                [TEMPLATE_FIELD_TYPE]: 'FW',
            },
            cellRenderer: VendorEntitiesLinkedCell,
            sortable: false,
            filter: true,
            valueGetter: ({ data }: { data: IVendorListSummary }) =>
                data.buyerEntities
                    ? data.buyerEntities.map((ent) => ent.entityName).join(', ')
                    : '',
            getQuickFilterText: ({ data }: { data: IVendorListSummary }) =>
                data.buyerEntities
                    ? data.buyerEntities.map((ent) => ent.entityName).join(', ')
                    : '',
        });
    }

    return columns;
};

export const getPOSellerDashboardFields = (
    VendorListOfAllUser: TVendorList,
    currentTab: string
) => {
    const fieldTypeLabels = [...vendorAdminFieldTypeLabel(currentTab, true)];
    const fieldTypeLabelWithOption = getVendorTypeLabelsWithOptions(
        VendorListOfAllUser,
        fieldTypeLabels
    );

    return {
        poEventsAnotherFieldTypeLabel: fieldTypeLabelWithOption,
    };
};

const getVendorTypeLabelsWithOptions = (
    VendorListOfAllUser: TVendorList,
    fieldTypeLabels: IFieldTypeLabel[]
) => {
    const fieldTypeLabelsWithOptions = fieldTypeLabels.map((option) => {
        if (option.column === 'Primary contact') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = VendorListOfAllUser.primary_contacts
                .filter(
                    (buyer) =>
                        Boolean(buyer.email) &&
                        Boolean(buyer.name) &&
                        Boolean(buyer.vendor_contact_id)
                )
                .map(
                    (buyer) =>
                        `${buyer.name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${buyer.vendor_contact_id}`
                );
            return updatedOption;
        } else if (option.column === 'Secondary contact(s)') {
            let updatedOption = cloneDeep(option);
            updatedOption.choices = VendorListOfAllUser.secondary_contacts
                .filter(
                    (entity) =>
                        Boolean(entity.email) &&
                        Boolean(entity.name) &&
                        Boolean(entity.vendor_contact_id)
                )
                .map(
                    (entity) =>
                        `${entity.name}${PROJECT_DASHBOARD_FIELD_DELIMITER}${entity.vendor_contact_id}`
                );
            return updatedOption;
        }
        return option;
    });

    return fieldTypeLabelsWithOptions;
};

export const vendorAdminFieldTypeLabel = (
    currentTab: string,
    isSingleEntity: boolean
): IFieldTypeLabel[] => {
    const fields: IFieldTypeLabel[] = [
        {
            label: 'Vendor code',
            column: 'Vendor code',
            fieldLabelWithSectionName: 'Vendor code',
            isFieldFWFieldInCustomSection: false,
            fieldType: 'SHORTTEXT',
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        },
        {
            label: 'Vendor name',
            column: 'Vendor name',
            fieldLabelWithSectionName: 'Vendor name',
            isFieldFWFieldInCustomSection: false,
            fieldType: 'SHORTTEXT',
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        },
        {
            label: 'Primary contact',
            column: 'Primary contact',
            fieldLabelWithSectionName: 'Primary contact',
            isFieldFWFieldInCustomSection: false,
            fieldType: CustomFieldType.CHOICE,
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        },
        {
            label: 'Secondary contact(s)',
            column: 'Secondary contact(s)',
            fieldLabelWithSectionName: 'Secondary contact(s)',
            isFieldFWFieldInCustomSection: false,
            fieldType: CustomFieldType.CHOICE,
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        },
        // {
        //     label: 'Assigned entities',
        //     column: 'Assigned entities',
        //     fieldLabelWithSectionName: 'Assigned entities',
        //     isFieldFWFieldInCustomSection: false,
        //     fieldType: 'SHORTTEXT',
        //     is_built_in: true,
        //     sectionName: null,
        //     sectionAlternameName: null,
        //     choices: [],
        // },
        {
            label: 'Modified on',
            column: 'Modified on',
            fieldLabelWithSectionName: 'Modified on',
            isFieldFWFieldInCustomSection: false,
            fieldType: CustomFieldType.DATETIME,
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        },
    ];
    if (!isSingleEntity) {
        fields.push({
            label: 'Assigned entities',
            column: 'Assigned entities',
            fieldLabelWithSectionName: 'Assigned entities',
            isFieldFWFieldInCustomSection: false,
            fieldType: 'SHORTTEXT',
            is_built_in: true,
            sectionName: null,
            sectionAlternameName: null,
            choices: [],
        });
    }

    return fields;
};

export const entityVendorsListColumns: (showOptions: boolean) => ColDef[] = (
    showOptions
) => [
    {
        field: 'vendorCode',
        headerName: 'Vendor code',
        width: 170,
        cellRenderer: ({ data }: { data: IEntityVendorListSummary }) => (
            <FWTypography>{data.vendorCode}</FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IEntityVendorListSummary }) =>
            data.vendorCode,
        getQuickFilterText: ({ data }: { data: IEntityVendorListSummary }) =>
            data.vendorCode,
    },
    {
        field: 'vendorName',
        headerName: 'Vendor name',
        flex: 1,
        minWidth: 400,
        cellRenderer: VendorNameCell,
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IEntityVendorListSummary }) =>
            data.vendorName,
        getQuickFilterText: ({ data }: { data: IEntityVendorListSummary }) =>
            data.vendorName,
    },
    {
        field: 'primaryContactName',
        headerName: 'Primary contact',
        width: 190,
        cellRenderer: EntityPrimaryContactCell,
        sortable: false,
        filter: true,
        valueGetter: ({ data }: { data: IEntityVendorListSummary }) =>
            data.primaryContact.fullName,
        getQuickFilterText: ({ data }: { data: IEntityVendorListSummary }) =>
            data.primaryContact.fullName,
    },
    // {
    //     field: 'preferredItemsCount',
    //     headerName: 'Item(s)',
    //     width: 110,
    //     cellRenderer: ({ data }: { data: IEntityVendorListSummary }) => (
    //         <div className="flex flex--aic flex--jcsb">
    //             <FWIcon
    //                 name="bi bi-patch-check"
    //                 color="success.main"
    //                 className="mr--5"
    //             />
    //             <FWTypography color="text.secondary">
    //                 {data.preferredItemsCount}
    //             </FWTypography>
    //         </div>
    //     ),
    //     sortable: true,
    //     filter: true,
    //     valueGetter: ({ data }: { data: IEntityVendorListSummary }) =>
    //         data.preferredItemsCount,
    //     getQuickFilterText: ({ data }: { data: IEntityVendorListSummary }) =>
    //         data.preferredItemsCount.toString(),
    // },
    {
        field: 'modifiedDateTime',
        headerName: 'Modified on',
        width: 220,
        cellRenderer: ({ data }: { data: IEntityVendorListSummary }) => (
            <FWTypography color="text.secondary">
                {data.modifiedDateTime}
            </FWTypography>
        ),
        sortable: true,
        filter: true,
        valueGetter: ({ data }: { data: IEntityVendorListSummary }) =>
            data.modifiedDateTime,
        getQuickFilterText: ({ data }: { data: IEntityVendorListSummary }) =>
            data.modifiedDateTime,
        comparator: (a, b) => {
            return moment(a).isAfter(moment(b)) ? 1 : -1;
        },
    },
    ...(showOptions
        ? [
              {
                  field: 'options',
                  headerName: 'Options',
                  headerClass: 'center',
                  width: 110,
                  cellRenderer: EntityVendorsListOptionsMenu,
              },
          ]
        : []),
];

export const vendorStatusMap: {
    [key: string]: string;
} = {
    [VendorStatus.ACTIVE]: 'Active',
    [VendorStatus.INACTIVE]: 'Disabled',
    [VendorVerificationStatus.INVITATION_HOLD]: 'Invitation on hold',
    [VendorVerificationStatus.INVITATION_PENDING]: 'Invitation pending',
    [VendorVerificationStatus.UNVERIFIED]: 'Unverified',
    [VendorVerificationStatus.VERIFIED]: 'Verified',
    [VendorVerificationStatus.PENDING]: 'Verification Pending',
    [VendorStatus.INVITED]: 'Invitation pending',
};

export const vendorStatusMapService: {
    [key: string]: VendorStatus;
} = {
    ACTIVE: VendorStatus.ACTIVE,
    INACTIVE: VendorStatus.INACTIVE,
    INVITED: VendorStatus.INVITED,
};

export const vendorVerificationStatusMapService: {
    [key: string]: VendorVerificationStatus;
} = {
    VERIFIED: VendorVerificationStatus.VERIFIED,
    UNVERIFIED: VendorVerificationStatus.UNVERIFIED,
    INVITATION_PENDING: VendorVerificationStatus.INVITATION_PENDING,
    INVITED: VendorVerificationStatus.INVITATION_PENDING,
    PENDING: VendorVerificationStatus.PENDING,
};
