import { Box } from '@mui/system';
import {
    calculateAddtionalCost,
    convertAdditionalCostBackendToFrontend,
} from '../../AdditionalCost/helpers';
import { roundToNDecimalPlace } from '../../Common/CurrencyUtilsComponent';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import { paymentTermsFormatter } from '../../Events/RFQAnalytics/Helpers/HelperFunction';
import {
    isValueValidForStringArrNull,
    stringValueForStringArrNull,
} from '../../Global/Helpers/helpers';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../Global/Interfaces/TemplateInterface';
import { IPurchaseOrderItem as poItemInterface2 } from '../../Models/AwardsAndPO.model';
import { IPurchaseOrderItem as poItemInterface1 } from '../Interfaces/PO.model';

export const getPoReviewColumnWidths = ({
    rfqTemplate,
    pdfWindowOpened,
    type,
}: {
    rfqTemplate: IRfqTemplate;
    pdfWindowOpened?: boolean;
    type: 'SUMMARY' | 'DETAILS';
}) => {
    const numOptionalColumns =
        (rfqTemplate.fieldSettings.standardFields.ADDITIONAL_COSTS.buyerSettings
            .showField
            ? 1
            : 0) +
        (rfqTemplate.fieldSettings.standardFields.DISCOUNT.buyerSettings
            .showField
            ? 1
            : 0) +
        (rfqTemplate.fieldSettings.standardFields.TAX.buyerSettings.showField
            ? 1
            : 0);

    const colPercArr = [3, 19, 14, 14, 14]; //Initialized with static columns before optional columns. Total & Add notes columns come last.

    let optionalColumnsRemaining = numOptionalColumns;
    for (let i = 0; i < 3; i++) {
        if (optionalColumnsRemaining) {
            colPercArr.push(14);
            optionalColumnsRemaining--;
        } else {
            colPercArr.push(0);
        }
    }
    colPercArr.push(14); //Total

    if (
        pdfWindowOpened === null ||
        pdfWindowOpened === undefined ||
        pdfWindowOpened === false
    ) {
        colPercArr.push(5); //Add notes
    }

    const idealTotal = 97;
    const actualTotal = colPercArr.reduce((a, b) => a + b, 0);
    const multFactor = idealTotal / actualTotal;
    const colPercArrAdjusted = colPercArr.map((x) => x * multFactor);

    let finalNumericArr = colPercArrAdjusted;
    if (type === 'DETAILS') {
        let srNoCol = colPercArrAdjusted[0];
        let sectionNameCol = 18.25;
        let lastDummyCol = 2.5;
        let detailsCol =
            colPercArrAdjusted.reduce((a, b) => a + b, 0) -
            srNoCol -
            sectionNameCol -
            lastDummyCol;

        finalNumericArr = [srNoCol, sectionNameCol, detailsCol, lastDummyCol];
    }
    const colPercStringArr = finalNumericArr.map((x) => `${x.toFixed(2)}%`);
    const finalText = colPercStringArr.join(' ');
    return finalText;
};

export const getPoReviewSectionStrings = (
    item: poItemInterface1 | poItemInterface2,
    rfqTemplate: IRfqTemplate,
    decimalPlaces: number
) => {
    const essentialTermsArr: string[] = [];
    let essentialTermsDescriptions: { label: string; description: string }[] =
        [];
    // const essentialTermsDescriptions: {}
    rfqTemplate.sections.ESSENTIAL_TERMS.fieldList.forEach((curField) => {
        if (curField.fieldType === 'CUSTOM') {
            const fieldSettings =
                rfqTemplate.fieldSettings.customFields[curField.field];
            if (fieldSettings && fieldSettings.buyerSettings.showField) {
                let customFieldText = findCustomFieldText(curField.field, item);
                if (customFieldText) {
                    essentialTermsArr.push(customFieldText);
                    essentialTermsDescriptions.push({
                        label: fieldSettings.label,
                        description: fieldSettings.description,
                    });
                }
            }
        }
    });
    const essentialTermsText = essentialTermsArr.join(' | ');
    essentialTermsDescriptions = essentialTermsDescriptions.filter(
        (d) => d.label.length > 0 && d.description.length > 0
    );

    let descriptionText = [
        item.item_information.buyer_item_description.trim().length > 0
            ? item.item_information.buyer_item_description.trim()
            : '',
        item.item_information.custom_ids
            ? item.item_information.custom_ids
                  .map((id) => `${id.name}: ${id.value}`)
                  .join(' | ')
            : '',
    ]
        .filter((text) => text.length > 0)
        .join(' | ');

    let buyerItemSpecsText = item.attributes
        .filter((attr) => attr.attribute_id !== null)
        .map(
            (attr) =>
                `${attr.attribute_name}: ${attr.attribute_values
                    .map((val) => val.value)
                    .join('/')}`
        )
        .join(' | ');
    let buyerItemCustomSpecsText = item.attributes
        .filter((attr) => attr.attribute_id === null)
        .map(
            (attr) =>
                `${attr.attribute_name}: ${attr.attribute_values
                    .map((val) => val.value)
                    .join('/')}`
        );

    const prepaymentText =
        rfqTemplate.fieldSettings.standardFields.PAYMENT_TERMS.sellerSettings
            .showField &&
        rfqTemplate.fieldSettings.standardFields.PAYMENT_TERMS
            .paymentTermOptions.prepaymentAllowed
            ? `Prepayment: ${
                  item.paymentTerms &&
                  +item.paymentTerms.prepaymentPercentage > 0
                      ? `${item.paymentTerms.prepaymentPercentage}%`
                      : 'None'
              }`
            : '';

    const paymentTermsText = rfqTemplate.fieldSettings.standardFields
        .PAYMENT_TERMS.sellerSettings.showField
        ? `${
              rfqTemplate.fieldSettings.standardFields.PAYMENT_TERMS.label
          }: ${paymentTermsFormatter({
              paymentTerms: item.paymentTerms,
              includePrepaymentString: false,
              delimiter: ';',
          })}`
        : '';

    const leadTimeText = rfqTemplate.fieldSettings.standardFields.LEAD_TIME
        .sellerSettings.showField
        ? `${rfqTemplate.fieldSettings.standardFields.LEAD_TIME.label}: ${
              item.procurement_information.lead_time ?? ''
          } ${
              item.procurement_information.lead_time_period?.toLowerCase() ??
              'NA(None)'
          }`
        : '';

    const incotermsText = rfqTemplate.fieldSettings.standardFields.INCOTERMS
        .sellerSettings.showField
        ? `${rfqTemplate.fieldSettings.standardFields.INCOTERMS.label}: ${item.incoterm_abbreviation}`
        : '';

    const grToleranceText = rfqTemplate.fieldSettings.standardFields
        .GR_TOLERANCE.sellerSettings.showField
        ? +item.quantity_information.quantity_tolerance_percentage > 0
            ? `${
                  rfqTemplate.fieldSettings.standardFields.GR_TOLERANCE.label
              }: ${FWNumberFormatter(
                  +item.quantity_information.quantity_tolerance_percentage
              )}%`
            : ''
        : '';

    const paymentAndDeliveryArr: string[] = [];
    let paymentAndDeliveryDescriptions: {
        label: string;
        description: string;
    }[] = [];
    for (let curField of rfqTemplate.sections.PAYMENT_AND_DELIVERY_TERMS
        .fieldList) {
        if (curField.fieldType === 'STANDARD') {
            if (
                curField.field === TemplateStandardFieldsEnum.PAYMENT_TERMS &&
                paymentTermsText.length > 0
            ) {
                if (prepaymentText.length > 0) {
                    paymentAndDeliveryArr.push(prepaymentText);
                }
                paymentAndDeliveryArr.push(paymentTermsText);
                paymentAndDeliveryDescriptions.push({
                    label: rfqTemplate.fieldSettings.standardFields[
                        curField.field
                    ].label,
                    description:
                        rfqTemplate.fieldSettings.standardFields[curField.field]
                            .description,
                });
            } else if (
                curField.field === TemplateStandardFieldsEnum.LEAD_TIME &&
                leadTimeText.length > 0
            ) {
                paymentAndDeliveryArr.push(leadTimeText);
                paymentAndDeliveryDescriptions.push({
                    label: rfqTemplate.fieldSettings.standardFields[
                        curField.field
                    ].label,
                    description:
                        rfqTemplate.fieldSettings.standardFields[curField.field]
                            .description,
                });
            } else if (
                curField.field === TemplateStandardFieldsEnum.INCOTERMS &&
                incotermsText.length > 0
            ) {
                paymentAndDeliveryArr.push(incotermsText);
                paymentAndDeliveryDescriptions.push({
                    label: rfqTemplate.fieldSettings.standardFields[
                        curField.field
                    ].label,
                    description:
                        rfqTemplate.fieldSettings.standardFields[curField.field]
                            .description,
                });
            } else if (
                curField.field === TemplateStandardFieldsEnum.GR_TOLERANCE &&
                grToleranceText.length > 0
            ) {
                paymentAndDeliveryArr.push(grToleranceText);
                paymentAndDeliveryDescriptions.push({
                    label: rfqTemplate.fieldSettings.standardFields[
                        curField.field
                    ].label,
                    description:
                        rfqTemplate.fieldSettings.standardFields[curField.field]
                            .description,
                });
            }
        } else {
            const fieldSettings =
                rfqTemplate.fieldSettings.customFields[curField.field];
            if (fieldSettings && fieldSettings.sellerSettings.showField) {
                let customFieldText = findCustomFieldText(curField.field, item);
                //
                if (customFieldText) {
                    paymentAndDeliveryArr.push(customFieldText);
                    paymentAndDeliveryDescriptions.push({
                        label: fieldSettings.label,
                        description: fieldSettings.description,
                    });
                }
            }
        }
    }

    const paymentAndDeliveryText = paymentAndDeliveryArr
        .filter((text) => text.length > 0)
        .join(' | ');

    paymentAndDeliveryDescriptions = paymentAndDeliveryDescriptions.filter(
        (d) => d.label.length > 0 && d.description.length > 0
    );

    const additionalDetailsArr: string[] = [
        item.item_information.item_additional_details,
    ];
    let additionalDetailsDescriptions: {
        label: string;
        description: string;
    }[] = [];
    for (let curField of rfqTemplate.sections.ADDITIONAL_DETAILS.fieldList) {
        if (curField.fieldType === 'STANDARD') {
            //None yet
        } else {
            const fieldSettings =
                rfqTemplate.fieldSettings.customFields[curField.field];
            if (fieldSettings && fieldSettings.sellerSettings.showField) {
                let customFieldText = findCustomFieldText(
                    curField.field.trim(),
                    item
                );
                if (customFieldText) {
                    additionalDetailsArr.push(customFieldText);
                    additionalDetailsDescriptions.push({
                        label: fieldSettings.label,
                        description: fieldSettings.description,
                    });
                }
            }
        }
    }

    const additionalDetailsText = additionalDetailsArr
        .filter((x) => x.length > 0)
        .join(' | ');
    additionalDetailsDescriptions = additionalDetailsDescriptions.filter(
        (d) => d.label.length > 0 && d.description.length > 0
    );

    // differecnt additional cost taxes
    // additional cost details text
    const additionalCostText =
        item?.pricing_information?.additional_costs
            ?.map((cost) => {
                const additionalCost = calculateAddtionalCost({
                    additionalCost: convertAdditionalCostBackendToFrontend([
                        cost,
                    ]),
                    baseQuantity: item.quantity_information.quantity,
                    baseRate: item.pricing_information.price,
                    type: 'ADDITIONAL_COST',
                });
                // const percentageDeviation =
                //     (additionalCost.effectiveRateDeviation /
                //         +item.pricing_information.price) *
                //     100;
                return `${cost.cost_name}: ${
                    item.pricing_information.currency_symbol
                }${roundToNDecimalPlace(
                    additionalCost.additionalCostValue,
                    decimalPlaces
                )}`;
            })
            .join(' | ') ?? '';

    // additional cost details text
    const taxesText =
        item.pricing_information.taxes
            ?.map((cost) => {
                const additionalCost = calculateAddtionalCost({
                    additionalCost: convertAdditionalCostBackendToFrontend([
                        cost,
                    ]),
                    baseQuantity: item.quantity_information.quantity,
                    baseRate: item.pricing_information.price,
                    type: 'TAX',
                    discountAdditionalCost:
                        convertAdditionalCostBackendToFrontend(
                            item.pricing_information.discounts
                        ),
                });
                // const percentageDeviation =
                //     (additionalCost.effectiveRateDeviation /
                //         +item.pricing_information.price) *
                //     100;
                return `${cost.cost_name} : ${
                    item.pricing_information.currency_symbol
                }${roundToNDecimalPlace(
                    additionalCost.additionalCostValue,
                    decimalPlaces
                )}`;
            })
            .join(' | ') ?? '';

    // additional cost details text
    const discountsText =
        item.pricing_information.discounts
            ?.map((cost) => {
                const additionalCost = calculateAddtionalCost({
                    additionalCost: convertAdditionalCostBackendToFrontend([
                        cost,
                    ]),
                    baseQuantity: item.quantity_information.quantity,
                    baseRate: item.pricing_information.price,
                    type: 'DISCOUNT',
                });
                // const percentageDeviation =
                //     (additionalCost.effectiveRateDeviation /
                //         +item.pricing_information.price) *
                //     100;
                return `${cost.cost_name} : -${
                    item.pricing_information.currency_symbol
                }${roundToNDecimalPlace(
                    additionalCost.additionalCostValue,
                    decimalPlaces
                )}`;
            })
            .join(' | ') ?? '';

    return {
        essentialTermsText,
        essentialTermsDescriptions,
        descriptionText,
        buyerItemSpecsText,
        buyerItemCustomSpecsText,
        paymentAndDeliveryText,
        paymentAndDeliveryDescriptions,
        additionalDetailsText,
        additionalDetailsDescriptions,
        additionalCostText,
        taxesText,
        discountsText,
    };
};

export const findCustomFieldText = (
    fieldName: string,
    item: poItemInterface1 | poItemInterface2
): string | null => {
    if (item.custom_sections) {
        for (let section of item.custom_sections) {
            for (let field of section.custom_fields) {
                if (field.name === fieldName) {
                    if (isValueValidForStringArrNull(field.value))
                        return `${field.name}: ${stringValueForStringArrNull(
                            field.value
                        )}`;
                }
            }
        }
    }
    if (item.custom_sections) {
        for (let section of item.custom_sections) {
            for (let field of section.custom_fields) {
                if (field.name === fieldName) {
                    if (isValueValidForStringArrNull(field.value))
                        console.error('field.name', field.name, field.value);
                    return `${field.name}: ${stringValueForStringArrNull(
                        field.value ?? 'NA(None)'
                    )}`;
                }
            }
        }
    }
    return null;
};

export const tableFieldDesc = (desc: string) => {
    if (desc.length === 0) return;
    return (
        <>
            {/* &nbsp; */}
            <FWTooltip
                title={desc}
                placement="right"
                style={{ marginLeft: '5px' }}
            >
                <Box color="primary.main">
                    <i className="bi bi-info-circle" />
                </Box>
            </FWTooltip>
        </>
    );
};
