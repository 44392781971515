import { GridApi } from 'ag-grid-community';
import { isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../Contexts/AuthContext';
import { NavContext } from '../../Contexts/NavContext';
import { IVendorBuyerEntity } from '../../Organizations/Admin/Interfaces/VendorsInterface';
import { IGlobalAppStore } from '../../Redux/Store';
import { BLANK_DASHBOARD_FILTER } from '../constants/projectDashboardConstants';
import { BLANK_DASHBOARD_SORT } from '../constants/projectDashboardSortConstants';
import {
    getAllDashboardColumns,
    getProjectDashboardFields,
    IFieldTypeLabel,
} from '../helpers/projectDashboardHelper';
import IProject, {
    DashBoardFilter,
    DashboardSort,
    ProjectStatusEnum,
} from '../interface/project.model';
import {
    useGetListOfAllProjectTemplatesQuery,
    useGetListOfAllUserListQuery,
    useListProjectsViaDasboardMutation,
} from '../services/project.services';

export const useProjectDashboard = () => {
    //states
    const [filterByEntity, setFilterByEntity] = useState<string>('global');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
    const [filterByStatus, setFilterByStatus] = useState<string>('all');
    const [showRefreshIcon, setShowRefreshIcon] = useState(false);
    const [filterByTemplateAndSelection, setFilterByTemplateAndSelection] =
        useState<string[]>([]);

    const [page, setPage] = useState<number>(1);
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const [templateSectionList, setTemplateSectionList] = useState<string[]>(
        []
    );

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowData, setRowData] = useState<IProject[]>([]);

    const [totalPages, setTotalPages] = useState(1);

    const [sortField, setSortField] = useState<DashboardSort[]>([
        BLANK_DASHBOARD_SORT,
    ]);

    const onChangeShowRefreshIconShow = useCallback((showIcon: boolean) => {
        setShowRefreshIcon(showIcon);
    }, []);

    const [dashboardFilter, setDashboardFilter] =
        useState<DashBoardFilter | null>({
            and_: [
                {
                    and_: [],
                    or_: [],
                    single: BLANK_DASHBOARD_FILTER,
                },
            ],
            or_: [],
            single: null,
        });

    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );

    const [fieldTypeLabels, setFieldTypeLabels] = useState<IFieldTypeLabel[]>(
        []
    );

    //hooks
    const { authData, checkPermission } = useContext(AuthContext);
    const { currentTab } = useContext(NavContext);
    const history = useHistory();

    //RTKs
    const { data: projectTemplateList } = useGetListOfAllProjectTemplatesQuery({
        templateType: 'PROJECT',
    });

    const { data: listOffAllUserList } = useGetListOfAllUserListQuery({});

    const [fetchList, { data, isLoading }] =
        useListProjectsViaDasboardMutation();

    const [totalItems, setTotalItems] = useState<number>(0);

    useEffect(() => {
        if (data?.counts.all !== undefined) {
            setTotalItems(data.counts.all);
        }
    }, [data]);
    //memos
    const canUserEditOrDeleteProject = useMemo(() => {
        return checkPermission('CUSTOMIZE', 'PROJECT', 'PROJECT_EDIT', null);
    }, [checkPermission]);

    const canUserCreateProject = useMemo(() => {
        return checkPermission('CUSTOMIZE', 'PROJECT', 'PROJECT_CREATE', null);
    }, [checkPermission]);

    const canUserAssignPMFromAdmin = useMemo(() => {
        return checkPermission(
            'CUSTOMIZE',
            'PROJECT',
            'PROJECT_ASSIGN_MANAGERS',
            null
        );
    }, [checkPermission]);

    const canUserAssignUserFromAdmin = useMemo(() => {
        return checkPermission(
            'CUSTOMIZE',
            'PROJECT',
            'PROJECT_ASSIGN_USERS',
            null
        );
    }, [checkPermission]);

    const fetchDashboardData = useCallback(() => {
        //  setIsLoading(true);
        try {
            if (
                !(sortField.length === 1 && !Boolean(sortField[0].field)) &&
                !isEqual(dashboardFilter?.single, BLANK_DASHBOARD_FILTER)
            )
                fetchList({
                    page_number: page,
                    search_text: search,
                    entity_id:
                        filterByEntity === 'global'
                            ? undefined
                            : filterByEntity,
                    tab: currentTab === '' ? 'in_progress' : currentTab,
                    query_data: ['all', 'ALL'].includes(filterByStatus)
                        ? {}
                        : {
                              status:
                                  filterByStatus === ProjectStatusEnum.ACTIVE
                                      ? ProjectStatusEnum.ACTIVE
                                      : ProjectStatusEnum.INACTIVE,
                          },
                    sort_fields: sortField,
                    items_per_page: rowsPerPage,
                    filters: dashboardFilter,
                });
        } catch (err) {
            //  setIsLoading(false)
        }
    }, [
        currentTab,
        dashboardFilter,
        fetchList,
        filterByEntity,
        filterByStatus,
        page,
        rowsPerPage,
        search,
        sortField,
    ]);

    const dashboardColumns = useMemo(() => {
        if (projectTemplateList !== undefined && listOffAllUserList) {
            const {
                listOfAllFieldsAcrossTemplate,
                fieldTypeLabels,
                defaultTemplateDetails,
                templateAndSectionList,
            } = getProjectDashboardFields(
                projectTemplateList,
                filterByTemplateAndSelection,
                listOffAllUserList,
                currentTab
            );

            setFieldTypeLabels(fieldTypeLabels);
            setTemplateSectionList(templateAndSectionList);
            setIsDataLoaded(true);
            return getAllDashboardColumns(
                listOfAllFieldsAcrossTemplate,
                filterByStatus,
                currentTab,
                SINGLE_ENTITY || filterByEntity !== 'global',
                authData.details?.role === 'ADMIN' ||
                    canUserEditOrDeleteProject,
                history,
                defaultTemplateDetails,
                fetchDashboardData
            );
        }
        return [];
    }, [
        SINGLE_ENTITY,
        authData.details?.role,
        canUserEditOrDeleteProject,
        currentTab,
        fetchDashboardData,
        filterByEntity,
        filterByStatus,
        filterByTemplateAndSelection,
        history,
        listOffAllUserList,
        projectTemplateList,
    ]);

    const cookieName: string = useMemo(() => {
        return authData.details?.name
            ? `${authData.details?.name}project - dashboard`
            : 'project-dashboard';
    }, [authData.details?.name]);

    const dashboardFilterKey = useMemo(() => {
        return `${cookieName}-Filters`;
    }, [cookieName]);

    const dashboardSortKey = useMemo(() => {
        return `${cookieName}-Sort`;
    }, [cookieName]);

    //callbacks
    const handleSelectedTemplateSectionChange = useCallback(
        (selectedTemplateSection: string[]) => {
            setFilterByTemplateAndSelection(selectedTemplateSection);
        },
        []
    );

    const handleBuyerEntityChange = useCallback(
        (newBuyerEntity: IVendorBuyerEntity | null) => {
            if (newBuyerEntity) {
                if (newBuyerEntity.entityUid === 'GLOBAL_ENTITY') {
                    setFilterByEntity('global');
                } else {
                    setFilterByEntity(newBuyerEntity?.entityUid);
                }
            } else {
                setFilterByEntity('global');
            }
        },
        []
    );

    const onPageChange = useCallback((newPage: number) => {
        setPage(newPage);
    }, []);

    const onSearchChange = useCallback((search: string) => {
        setSearch(search);
    }, []);

    const onRowsPerPageChange = useCallback((rowsPerPage: number) => {
        setRowsPerPage(rowsPerPage);
    }, []);

    const onDashboardFilterChange = useCallback(
        (dashboardFilter: DashBoardFilter | null) => {
            setDashboardFilter(dashboardFilter);
            setPage(1);
            if (dashboardFilter) {
                localStorage.setItem(
                    dashboardFilterKey,
                    JSON.stringify(dashboardFilter)
                );
            } else {
                localStorage.removeItem(dashboardFilterKey);
            }
        },
        [dashboardFilterKey]
    );

    const onSortFieldChange = useCallback(
        (sortFields: DashboardSort[]) => {
            setSortField(sortFields);
            if (sortFields) {
                localStorage.setItem(
                    dashboardSortKey,
                    JSON.stringify(sortFields)
                );
            } else {
                localStorage.removeItem(dashboardSortKey);
            }
            // setSortField(sortFields);
        },
        [dashboardSortKey]
    );

    //useEffect
    useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);

    // const fetchDashboardData = useCallback(() => {
    //     fetchList({
    //         page_number: page,
    //         search_text: search,
    //         entity_id: filterByEntity === 'global' ? undefined : filterByEntity,
    //         tab: currentTab === '' ? 'in_progress' : currentTab,
    //         query_data: ['all', 'ALL'].includes(filterByStatus)
    //             ? {}
    //             : {
    //                   status:
    //                       filterByStatus === ProjectStatusEnum.ACTIVE
    //                           ? ProjectStatusEnum.ACTIVE
    //                           : ProjectStatusEnum.INACTIVE,
    //               },
    //         sort_fields: sortField,
    //         items_per_page: rowsPerPage,
    //         filters: dashboardFilter,
    //     });
    // }, [
    //     currentTab,
    //     dashboardFilter,
    //     fetchList,
    //     filterByEntity,
    //     filterByStatus,
    //     page,
    //     rowsPerPage,
    //     search,
    //     sortField,
    // ]);

    const onRefreshIconClick = useCallback(() => {
        if (gridApi) {
            gridApi.setFilterModel(null);
            //  gridApi.setSortModel([]); // Clear all sort fields
        }

        setSearch('');
        setSortField([]);
        setDashboardFilter(null);
        setPage(1);
        fetchList({
            page_number: page,
            search_text: search,
            entity_id: filterByEntity === 'global' ? undefined : filterByEntity,
            tab: currentTab === '' ? 'in_progress' : currentTab,
            query_data: ['all', 'ALL'].includes(filterByStatus)
                ? {}
                : {
                      status:
                          filterByStatus === ProjectStatusEnum.ACTIVE
                              ? ProjectStatusEnum.ACTIVE
                              : ProjectStatusEnum.INACTIVE,
                  },
            sort_fields: sortField,
            items_per_page: rowsPerPage,
            filters: dashboardFilter,
        });
    }, [
        currentTab,
        dashboardFilter,
        fetchList,
        filterByEntity,
        filterByStatus,
        page,
        rowsPerPage,
        search,
        sortField,
        gridApi,
    ]);

    useEffect(() => {
        const sortDataFromLS = localStorage.getItem(dashboardSortKey);

        if (sortDataFromLS !== null) {
            const parsedSortedData = JSON.parse(sortDataFromLS);
            setSortField(parsedSortedData);
        } else {
            setSortField([]);
        }
    }, [dashboardSortKey]);

    useEffect(() => {
        const filterDataFromLS = localStorage.getItem(dashboardFilterKey);

        if (filterDataFromLS !== null) {
            const parsedFilterData = JSON.parse(filterDataFromLS);
            setDashboardFilter(parsedFilterData);
        } else {
            setDashboardFilter(null);
        }
    }, [dashboardFilterKey]);

    // useEffect(() => {
    //     if (data) {
    //         setRowData(data.data);
    //         setTotalPages(data.metadata.total_pages);
    //     }
    // }, [data, listOffAllUserList]);
    useEffect(() => {
        if (data) {
            setRowData((prev) => {
                const isListEqual = isEqual(
                    (data.data ?? []).map((data: IProject, i: number) => ({
                        ...data,
                        id: i,
                    })),
                    prev
                );
                setShowRefreshIcon(!isListEqual);
                return data.data;
            });
            setTotalPages(data.metadata.total_pages ?? 1);
        }
    }, [data, listOffAllUserList]);

    useEffect(() => {
        if (filterByEntity !== 'global') {
            setFilterByStatus('all');
        }
    }, [filterByEntity]);

    return {
        isLoading,
        canUserEditOrDeleteProject,
        canUserCreateProject,
        canUserAssignPMFromAdmin,
        canUserAssignUserFromAdmin,
        dashboardColumns,
        templateSectionList,
        filterByEntity,
        isDataLoaded,
        dashboardFilter,
        totalPages,
        rowData,
        page,
        search,
        fieldTypeLabels,
        sortField,
        fetchDashboardData,
        handleSelectedTemplateSectionChange,
        handleBuyerEntityChange,
        onRowsPerPageChange,
        onDashboardFilterChange,
        onChangeShowRefreshIconShow,
        onPageChange,
        onSearchChange,
        onSortFieldChange,
        onRefreshIconClick,
        showRefreshIcon,
        totalItems,
    };
};
