import {
    Autocomplete,
    Avatar,
    Box,
    CircularProgress,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    TextField,
} from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { clone, cloneDeep, isEmpty } from 'lodash';
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { AutocompleteStyledPaper } from '../../../../Common/FWAutocomplete';
import { FWButton } from '../../../../Common/FWButton';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { isValidEmail } from '../../../../Common/Utils/ValidationUtils';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { useGetSellerContactForBuyerEntityQuery } from '../../../../Services/events/eventOperations';
import { IVendorContact } from '../../Interfaces/VendorsInterface';
import { updateContactInDirectory } from '../../Services/VendorsService';

interface IVendorContactBoxProps {
    open: boolean;
    data: IVendorContact;
    entityUid: string;
    vendorUid: string;
    onUpdateSuccess: (values: IVendorContact) => void;
    handlePopupToggle: () => void;
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER';
    index?: number;
    disabled?: boolean;
}

const VendorEntityContactPopup = ({
    open,
    data,
    entityUid,
    vendorUid,
    onUpdateSuccess,
    handlePopupToggle,
    portal,
    index = 0,
    disabled,
}: IVendorContactBoxProps) => {
    const [vendorEmails, setVendorEMails] = useState<string[]>([]);
    // const [alreadyCCedEmails, setAlreadyCCemails] = useState<null | string[]>(
    //     null
    // );
    // const [alreadySecondaryEmails, setAlreadySecondaryEmails] = useState<
    //     null | string[]
    // >(null);
    const { authData } = useContext(AuthContext);

    const handleClosePopup = () => {
        formController.setValues(cloneDeep(data));
        handlePopupToggle();
    };

    const handleSubmit = async (values: IVendorContact) => {
        const contactUpdated = await updateContactInDirectory(
            entityUid,
            data.vendorContactUid,
            vendorUid,
            values,
            portal
        );
        if (!contactUpdated) {
            toast.error(`Failed to update contact`);
        } else {
            formController.setValues(cloneDeep(values));
            handleClosePopup();
            values.index = index;
            onUpdateSuccess(values);
        }
    };

    const formController = useFormik<IVendorContact>({
        initialValues: cloneDeep(data),
        validationSchema: Yup.object({
            fullName: Yup.string().trim().required('Full name is required'),
            buyerContacts: Yup.array().of(
                Yup.object().shape({
                    number: Yup.string()
                        .min(8, 'Min 8 chars')
                        .max(10, 'Max 10 chars'),
                })
            ),
        }),
        onSubmit: handleSubmit,
    });

    const formValues = useMemo(
        () => formController.values,
        [formController.values]
    );

    const emails = useMemo(
        () => [
            ...data.sellerEmails.filter((e) => e.visible).map((e) => e.email),
            ...data.buyerEmails.filter((e) => e.visible).map((e) => e.email),
        ],
        [data.buyerEmails, data.sellerEmails]
    );

    const isSecondaryEmailValid = () => {
        if (formController.values.secondaryEmails?.length === 0) return true;

        return !formController.values.secondaryEmails
            // eslint-disable-next-line array-callback-return
            ?.map((email, idx) => {
                const temp = [...formController.values.secondaryEmails];

                temp.splice(idx, 1);

                if (
                    email.length === 0 ||
                    !isValidEmail(email) ||
                    temp?.includes(email) ||
                    formController.values.secondaryEmails?.includes(emails[0])
                ) {
                    return false;
                }
            })
            ?.includes(false);
    };

    const handleSecondaryEmailUpdate = (e: any) => {
        const temp = clone(formController.values);
        temp.secondaryEmails[e.target.name.split('-')[1]] = e.target.value;
        formController.setValues(temp);
    };

    const handleRemovingSecondaryEmail = (index: number) => {
        const temp = clone(formController.values);
        temp.secondaryEmails.splice(index, 1);
        formController.setValues(temp);
    };

    const secondayEmailAlreadyExists = useCallback(
        (email: string, index: number) => {
            return formController.values.secondaryEmails?.some(
                (e, idx) => index !== idx && e === email
            );
        },
        [formController.values.secondaryEmails]
    );

    // const handleValueUpdating = useCallback(
    //     (newValues: any) => {
    //         formController.setValues({ ...formValues, ...newValues });
    //     },
    //     [formController, formValues]
    // );

    // useEffect(() => {
    //     // formController.setValues({
    //     //     ...cloneDeep(data),
    //     //     CCEmail: alreadyCCedEmails,
    //     //     secondaryEmails: alreadySecondaryEmails,
    //     // });
    //     handleValueUpdating({
    //         secondaryEmails: alreadySecondaryEmails,
    //     });
    // }, [alreadySecondaryEmails, handleValueUpdating]);

    // useEffect(() => {
    //     formController.setValues({
    //         ...formValues,
    //         secondaryEmails: alreadySecondaryEmails,
    //     });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [alreadySecondaryEmails]);

    // useEffect(() => {
    //     formController.setValues(cloneDeep(data));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [data]);
    const { data: getSellerContactForBuyerEntityData } =
        useGetSellerContactForBuyerEntityQuery({
            buyer_entity_id: entityUid,
            portal,
            seller_entity_id: vendorUid,
        });
    useEffect(() => {
        if (!secondayEmailAlreadyExists && getSellerContactForBuyerEntityData) {
            setVendorEMails(
                getSellerContactForBuyerEntityData
                    .map((v) => (v.primary_email ? v.primary_email : ''))
                    ?.filter(
                        (v) => !emails?.includes(v.trim()) || v.length === 0
                    )
            );
            const emailszxc = getSellerContactForBuyerEntityData.find((v) =>
                emails?.includes(v.primary_email ?? '')
            );

            // setAlreadyCCemails(
            //     emailszxc?.emails
            //         ?.filter((e) => e.type === 'CC')
            //         .map((e) => e.email) ?? []
            // );
            const asdasaz = getSellerContactForBuyerEntityData.find(
                (c) => c.primary_email! === emails[0]
            );
            // setAlreadySecondaryEmails(
            //     asdasaz?.emails
            //         ?.filter((e) => e.type === 'SECONDARY')
            //         .map((e) => e.email) ?? []
            // );

            formController.setValues({
                ...formValues,
                CCEmail:
                    emailszxc?.emails
                        ?.filter((e) => e.type === 'CC')
                        .map((e) => e.email) ?? [],
                secondaryEmails:
                    asdasaz?.emails
                        ?.filter((e) => e.type === 'SECONDARY')
                        .map((e) => e.email) ?? [],
            });
        }
    }, [
        emails,
        entityUid,
        formController,
        formValues,
        getSellerContactForBuyerEntityData,
        portal,
        secondayEmailAlreadyExists,
        vendorUid,
    ]);

    return (
        <>
            {/* contact edit popup */}
            <FWPopupWithChildren
                open={open}
                handleClose={handleClosePopup}
                title={`Edit contact`}
            >
                <FormikProvider value={formController}>
                    <form onSubmit={formController.handleSubmit}>
                        <DialogContent sx={{ padding: '12px 24px' }}>
                            <Grid container marginTop="15px" columnSpacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                    marginBottom="10px"
                                >
                                    <Avatar
                                        alt={data.fullName}
                                        src={data.fullName}
                                        className="mb--5"
                                        style={{
                                            width: 60,
                                            height: 60,
                                        }}
                                    />
                                    <FWTypography sx={{ maxWidth: '100%' }}>
                                        {data.fullName}
                                    </FWTypography>
                                    <FWTypography
                                        sx={{ maxWidth: '100%' }}
                                        variant="caption"
                                        color="text.secondary"
                                    >
                                        {emails.join(', ')}
                                    </FWTypography>
                                </Grid>

                                <Grid item xs={12} marginTop="15px">
                                    <Grid
                                        container
                                        columnSpacing={2}
                                        rowGap={2}
                                    >
                                        <Grid item xs={12}>
                                            <FWInput
                                                label="Full name"
                                                value={
                                                    formController.values
                                                        .fullName
                                                }
                                                name="fullName"
                                                onChange={
                                                    formController.handleChange
                                                }
                                                onBlur={
                                                    formController.handleBlur
                                                }
                                                error={
                                                    formController.touched
                                                        .fullName &&
                                                    !isEmpty(
                                                        formController.errors
                                                            .fullName
                                                    )
                                                }
                                                helper={{
                                                    text:
                                                        (formController.touched
                                                            .fullName &&
                                                            formController
                                                                .errors
                                                                .fullName) ||
                                                        '',
                                                }}
                                                // allowOnly="TEXT"
                                                required
                                                disabled={disabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FieldArray
                                                name="buyerContacts"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        <div className="flex flex--aic">
                                                            <FWTypography
                                                                sx={{
                                                                    color: 'text.secondary',
                                                                }}
                                                            >
                                                                Phone (optional)
                                                            </FWTypography>
                                                            {/* <FWTooltip title="Add contact">
                                                                <div>
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            arrayHelpers.push(
                                                                                {
                                                                                    number: '',
                                                                                    visible:
                                                                                        true,
                                                                                }
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            ml: '5px',
                                                                            padding: 0,
                                                                            height: '24px',
                                                                        }}
                                                                        disableRipple
                                                                    >
                                                                        <FWIcon
                                                                            name="bi bi-plus-circle"
                                                                            size={
                                                                                17
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </div>
                                                            </FWTooltip> */}
                                                        </div>
                                                        {data.sellerContacts
                                                            ?.filter(
                                                                (con) =>
                                                                    con.visible
                                                            )
                                                            .map(
                                                                (
                                                                    { number },
                                                                    idx
                                                                ) => {
                                                                    return (
                                                                        <FWInput
                                                                            key={
                                                                                idx
                                                                            }
                                                                            value={
                                                                                number
                                                                            }
                                                                            sx={{
                                                                                mb: '10px',
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        {formController.values.buyerContacts.map(
                                                            (
                                                                { number },
                                                                index
                                                            ) => {
                                                                const touched: any =
                                                                    formController
                                                                        .touched
                                                                        .buyerContacts &&
                                                                    formController
                                                                        .touched
                                                                        .buyerContacts[
                                                                        index
                                                                    ];
                                                                const isTouched =
                                                                    touched &&
                                                                    touched.number;
                                                                const error: any =
                                                                    formController
                                                                        .errors
                                                                        .buyerContacts &&
                                                                    formController
                                                                        .errors
                                                                        .buyerContacts[
                                                                        index
                                                                    ];
                                                                const hasError =
                                                                    !isEmpty(
                                                                        error &&
                                                                            error.number
                                                                    );
                                                                return (
                                                                    <div
                                                                        className="mb--10"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <FWInput
                                                                            value={
                                                                                number
                                                                            }
                                                                            allowOnly="TEL"
                                                                            name={`buyerContacts.${index}.number`}
                                                                            onChange={
                                                                                formController.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formController.handleBlur
                                                                            }
                                                                            // iconEnd={
                                                                            //     <IconButton
                                                                            //         color="error"
                                                                            //         onClick={() => {
                                                                            //             arrayHelpers.remove(
                                                                            //                 index
                                                                            //             );
                                                                            //         }}
                                                                            //         sx={{
                                                                            //             padding: 0,
                                                                            //         }}
                                                                            //         disabled={
                                                                            //             formController
                                                                            //                 .values
                                                                            //                 .buyerContacts
                                                                            //                 .length ===
                                                                            //             1
                                                                            //         }
                                                                            //     >
                                                                            //         <FWIcon
                                                                            //             name="bi bi-dash-circle"
                                                                            //             size={
                                                                            //                 20
                                                                            //             }
                                                                            //         />
                                                                            //     </IconButton>
                                                                            // }
                                                                            error={
                                                                                isTouched &&
                                                                                hasError
                                                                            }
                                                                            helper={{
                                                                                text:
                                                                                    (isTouched &&
                                                                                        error &&
                                                                                        error.number) ||
                                                                                    '',
                                                                            }}
                                                                            disabled={
                                                                                disabled
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        {false && data.isPrimary && (
                                            <Grid item xs={12}>
                                                <Box>
                                                    <FWTypography color="text.secondary">
                                                        CC to
                                                    </FWTypography>
                                                    {/* <FWSelect
                                                    // label="CC Email"
                                                    value={
                                                        formController.values
                                                            .CCEmail ?? []
                                                    }
                                                    name="CC Email"
                                                    // onChange={() => {}}
                                                    onBlur={
                                                        formController.handleBlur
                                                    }
                                                    error={
                                                        formController.touched
                                                            .fullName &&
                                                        !isEmpty(
                                                            formController
                                                                .errors.fullName
                                                        )
                                                    }
                                                    fullWidth
                                                    multiple
                                                >
                                                    {vendorEmails.map(
                                                        (email, idx) => (
                                                            <FWMenuItem
                                                                key={idx}
                                                                value={email}
                                                                onClick={() => {
                                                                    handleEmailSelection(
                                                                        email
                                                                    );
                                                                }}
                                                            >
                                                                {email}
                                                            </FWMenuItem>
                                                        )
                                                    )}
                                                </FWSelect> */}
                                                    <Autocomplete
                                                        multiple
                                                        PaperComponent={(
                                                            props
                                                        ) => (
                                                            <AutocompleteStyledPaper
                                                                {...props}
                                                            />
                                                        )}
                                                        onChange={(
                                                            e,
                                                            newValue
                                                        ) => {
                                                            formController.setValues(
                                                                {
                                                                    ...formController.values,
                                                                    CCEmail:
                                                                        newValue,
                                                                }
                                                            );
                                                        }}
                                                        value={
                                                            formController
                                                                .values.CCEmail
                                                        }
                                                        options={vendorEmails?.filter(
                                                            (e) =>
                                                                !formController.values.CCEmail?.includes(
                                                                    e
                                                                )
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    sx={{
                                                                        minWidth:
                                                                            '300px',
                                                                    }}
                                                                    onKeyDown={(
                                                                        event: any
                                                                    ) => {
                                                                        if (
                                                                            event.key ===
                                                                            'Backspace'
                                                                        ) {
                                                                            event.stopPropagation();
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                        disabled={disabled}
                                                    />
                                                </Box>
                                            </Grid>
                                        )}
                                        {portal === 'BUYER_MASTER' && (
                                            <Grid item xs={12}>
                                                <FWInput
                                                    label="Designation"
                                                    value={
                                                        formController.values
                                                            .designation
                                                    }
                                                    name="designation"
                                                    onChange={
                                                        formController.handleChange
                                                    }
                                                    onBlur={
                                                        formController.handleBlur
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        {portal === 'BUYER_MASTER' && (
                                            <Grid item xs={12}>
                                                <FWInput
                                                    label="Department"
                                                    value={
                                                        formController.values
                                                            .department
                                                    }
                                                    name="department"
                                                    onChange={
                                                        formController.handleChange
                                                    }
                                                    onBlur={
                                                        formController.handleBlur
                                                    }
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <FWInput
                                                multiline
                                                maxRows={4}
                                                minRows={2}
                                                label="Notes"
                                                value={
                                                    formController.values.notes
                                                }
                                                name="notes"
                                                onChange={
                                                    formController.handleChange
                                                }
                                                onBlur={
                                                    formController.handleBlur
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <FWTypography
                                                    paddingTop={'8px'}
                                                >
                                                    Secondary email
                                                </FWTypography>
                                                <FWTooltip
                                                    title="Add secondary email"
                                                    className="addIcon"
                                                >
                                                    <div>
                                                        <IconButton
                                                            onClick={() => {
                                                                formController.setValues(
                                                                    {
                                                                        ...formController.values,
                                                                        secondaryEmails:
                                                                            [
                                                                                ...formController
                                                                                    .values
                                                                                    .secondaryEmails,
                                                                                '',
                                                                            ],
                                                                    }
                                                                );
                                                            }}
                                                            disabled={disabled}
                                                        >
                                                            <FWIcon
                                                                color={
                                                                    !disabled
                                                                        ? 'primary.main'
                                                                        : 'secondary.main'
                                                                }
                                                                name="bi bi-plus-circle"
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </FWTooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container xs={12}>
                                                    {formController.values?.secondaryEmails?.map(
                                                        (email, idx) => (
                                                            <Fragment key={idx}>
                                                                <Grid
                                                                    item
                                                                    xs={11}
                                                                >
                                                                    {!disabled ? (
                                                                        <FWInput
                                                                            name={`name-${idx}`}
                                                                            value={
                                                                                email
                                                                            }
                                                                            onChange={
                                                                                handleSecondaryEmailUpdate
                                                                            }
                                                                            error={
                                                                                !isValidEmail(
                                                                                    email
                                                                                ) ||
                                                                                email.length ===
                                                                                    0 ||
                                                                                secondayEmailAlreadyExists(
                                                                                    email,
                                                                                    idx
                                                                                )
                                                                            }
                                                                            helper={{
                                                                                text:
                                                                                    email.length ===
                                                                                    0
                                                                                        ? 'Email is Required'
                                                                                        : !isValidEmail(
                                                                                              email
                                                                                          )
                                                                                        ? 'Enter a valid email'
                                                                                        : secondayEmailAlreadyExists(
                                                                                              email,
                                                                                              idx
                                                                                          )
                                                                                        ? 'Email already exists'
                                                                                        : email ===
                                                                                          emails[0]
                                                                                        ? 'Email already exists'
                                                                                        : '',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <FWTypography>
                                                                            {
                                                                                email
                                                                            }
                                                                        </FWTypography>
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={1}
                                                                >
                                                                    <FWTooltip
                                                                        title="Remove secondary email"
                                                                        className="removeIcon"
                                                                    >
                                                                        <div>
                                                                            {!disabled && (
                                                                                <IconButton
                                                                                    onClick={() =>
                                                                                        handleRemovingSecondaryEmail(
                                                                                            idx
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FWIcon
                                                                                        color="error.main"
                                                                                        name="bi bi-dash-circle"
                                                                                    />
                                                                                </IconButton>
                                                                            )}
                                                                        </div>
                                                                    </FWTooltip>
                                                                </Grid>
                                                            </Fragment>
                                                        )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        {authData?.details?.role === 'ADMIN' && (
                            <DialogActions
                                sx={{ padding: '12px 24px 24px 24px' }}
                            >
                                <FWButton
                                    variant="outlined"
                                    onClick={handleClosePopup}
                                >
                                    Cancel
                                </FWButton>
                                {!disabled && (
                                    <>
                                        <span
                                            style={{ width: '0.75rem' }}
                                        ></span>
                                        <FWButton
                                            variant="contained"
                                            type="submit"
                                            disabled={
                                                !formController.isValid ||
                                                formController.isSubmitting ||
                                                !isSecondaryEmailValid()
                                            }
                                        >
                                            {formController.isSubmitting ? (
                                                <CircularProgress
                                                    size={24}
                                                    style={{ color: '#c4c4c8' }}
                                                />
                                            ) : (
                                                'Save'
                                            )}
                                        </FWButton>
                                    </>
                                )}
                            </DialogActions>
                        )}
                    </form>
                </FormikProvider>
            </FWPopupWithChildren>
        </>
    );
};

export default VendorEntityContactPopup;
