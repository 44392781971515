import {
    Box,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Fade,
    Popper,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { FWButton } from '../../../Common/FWButton';
import FWIcon from '../../../Common/FWIcon';
import { FWTypography } from '../../../Common/FWTypograhy';
import {
    BLANK_DASHBOARD_FILTER,
    convertDashboardFieldToFilter,
    FieldTypeLabel,
    formatDashboardFilterForBE,
    formatDashboardFilterForFE,
    handleFilterValueChange,
    IFilterColumnMenuList,
    TFilterUpdateParams,
} from '../../constants/projectDashboardConstants';
import { IFieldTypeLabel } from '../../helpers/projectDashboardHelper';
import { DashBoardFilter } from '../../interface/project.model';
import './ProjectDashboardFilterFieldRow';
import ProjectDashboardFilterFieldRow from './ProjectDashboardFilterFieldRow';
import { areAllFieldsFilled } from './ProjectDashboardFilterInput';

export interface IDashboardFilterPopupProps {
    open: boolean;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
    fieldTypeLabels: IFieldTypeLabel[];
    filterFieldToNameMap: Record<string, string>;
    builtInFieldsMap: Record<string, string>;
    handleFilterApply: (filter: DashBoardFilter | null) => Promise<void>;
    maximumColumnsSelected: boolean;
    currentTab: string;
    filterColumnMenuList: IFilterColumnMenuList[];
    isLoading: boolean;
    handleColumnMoved: () => void;
    onAddFilterFieldClick: (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>,

        checked: boolean,
        id: string,
        label: string
    ) => void;
    onFilterCountChange: (value: number) => void;
    defaultValue: DashBoardFilter | null;
    dashboardType: string;
}

const DUMMY_FILTER_STATE = {
    and_: [
        {
            and_: [],
            or_: [],
            single: BLANK_DASHBOARD_FILTER,
        },
    ],
    or_: [],
    single: null,
};

const DashboardFilterPopup = ({
    open,
    anchorEl,
    maximumColumnsSelected,
    filterColumnMenuList,
    fieldTypeLabels,
    builtInFieldsMap,
    filterFieldToNameMap,
    handleColumnMoved,
    onAddFilterFieldClick,
    handleFilterApply,
    handleClose,
    isLoading,
    currentTab,
    defaultValue,
    onFilterCountChange,
    dashboardType,
}: IDashboardFilterPopupProps) => {
    const [dashboardFilters, setDashboardFilters] = useState<DashBoardFilter>({
        and_: [
            {
                and_: [],
                or_: [],
                single: BLANK_DASHBOARD_FILTER,
            },
        ],
        or_: [],
        single: null,
    });

    const [isClickAwayEnabled, setIsClickAwayEnabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading((prev) => {
            if (prev && !isLoading) handleClose();

            return isLoading;
        });
    }, [handleClose, isLoading]);

    useEffect(() => {
        if (defaultValue) {
            if (defaultValue.and_.length === 0) {
                setDashboardFilters(
                    formatDashboardFilterForFE(
                        DUMMY_FILTER_STATE,
                        filterFieldToNameMap,
                        currentTab,
                        dashboardType
                    )
                );
            } else {
                setDashboardFilters(
                    formatDashboardFilterForFE(
                        defaultValue,
                        filterFieldToNameMap,
                        currentTab,
                        dashboardType
                    )
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, defaultValue, setDashboardFilters, filterFieldToNameMap]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [addFilterAnchor, setAddFilterAnchor] = useState<null | HTMLElement>(
        null
    );

    const [filters, setFilters] = useState(dashboardFilters.and_);

    useEffect(() => {
        setFilters(dashboardFilters.and_);
    }, [dashboardFilters]);
    // callbacks
    const updateFilterObject = useCallback(
        (index: number, updatedField: TFilterUpdateParams) => {
            setDashboardFilters((prev) => {
                let newPrev = cloneDeep(prev);

                let singleFilter = newPrev.and_[index].single;

                if (singleFilter !== null) {
                    if (updatedField.key === 'VALUE') {
                        newPrev.and_[index].single = handleFilterValueChange(
                            singleFilter,
                            updatedField.value
                        );
                    } else if (updatedField.key === 'FIELD') {
                        singleFilter.field = updatedField.value;
                        if (updatedField.type)
                            singleFilter.type = updatedField.type;
                        singleFilter.field_type = updatedField.isBuiltIn
                            ? 'BUILTIN'
                            : updatedField.isBuiltIn === null
                            ? null
                            : 'CUSTOM_FIELD';

                        singleFilter.section = updatedField.section;

                        singleFilter.value = null;
                        singleFilter.value_from = null;
                        singleFilter.value_to = null;
                        singleFilter.values = [];
                        singleFilter.datetime_from = null;
                        singleFilter.datetime_to = null;
                    } else {
                        singleFilter.condition_type = updatedField.value;
                        if (
                            ['BLANK', 'NOT_BLANK'].includes(updatedField.value)
                        ) {
                            singleFilter = {
                                ...singleFilter,
                                value: BLANK_DASHBOARD_FILTER.value,
                                datetime_from:
                                    BLANK_DASHBOARD_FILTER.datetime_from,
                                datetime_to: BLANK_DASHBOARD_FILTER.datetime_to,
                                value_from: BLANK_DASHBOARD_FILTER.value_from,
                                value_to: BLANK_DASHBOARD_FILTER.value_to,
                                values: BLANK_DASHBOARD_FILTER.values,
                            };
                        }
                    }
                }
                return newPrev;
            });
        },
        [setDashboardFilters]
    );

    const handleAppendNewFilter = useCallback(
        (field: FieldTypeLabel) => {
            const newFilter = convertDashboardFieldToFilter(field);

            setDashboardFilters((prev) => {
                let newPrev = cloneDeep(prev);

                newPrev.and_.push({
                    and_: [],
                    or_: [],
                    single: newFilter,
                });
                return newPrev;
            });
        },
        [setDashboardFilters]
    );

    const handleResetFilters = useCallback(() => {
        setDashboardFilters({
            and_: [
                {
                    and_: [],
                    or_: [],
                    single: BLANK_DASHBOARD_FILTER,
                },
            ],
            or_: [],
            single: null,
        });
        handleFilterApply(null);
        handleClose();
        onFilterCountChange(0);
    }, [handleClose, handleFilterApply, onFilterCountChange]);

    const handleAddFilter = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAddFilterAnchor(event.currentTarget);
            handleAppendNewFilter({
                column: '',
                fieldType: '',
                label: '',
                sectionName: '',
                is_built_in: false,
                choices: [],
            });
        },
        [handleAppendNewFilter]
    );

    const resetField = useCallback(
        (index: number, resetType: 'FIELD' | 'CONDITION') => {
            setDashboardFilters((prev) => {
                let newPrev = cloneDeep(prev);

                const filter = newPrev.and_[index].single;

                if (newPrev.and_[index].single) {
                    if (resetType === 'FIELD') {
                        newPrev.and_[index].single = cloneDeep(
                            BLANK_DASHBOARD_FILTER
                        );
                    } else if (resetType === 'CONDITION' && filter !== null) {
                        newPrev.and_[index].single = {
                            ...BLANK_DASHBOARD_FILTER,
                            field: filter?.field,
                            section: filter.section,
                            field_type: filter.field_type,
                            type: filter.type,
                        };
                    }
                }

                return newPrev;
            });
        },
        []
    );

    const isApplyButtonEnabled = areAllFieldsFilled(filters);

    const removeSortRow = useCallback(
        (filterIdxToBeRemoved: number) => {
            setDashboardFilters((prev) => {
                let newPrev = cloneDeep(prev);

                newPrev.and_.splice(filterIdxToBeRemoved, 1);

                return newPrev;
            });
        },
        [setDashboardFilters]
    );

    const handleApplyClick = () => {
        const formattedDashboardFilter = formatDashboardFilterForBE(
            dashboardFilters,
            builtInFieldsMap
        );
        handleFilterApply(formattedDashboardFilter);
        // handleClose();
    };

    const getSelectedFields = (filters: DashBoardFilter[]) => {
        return filters
            .map((filter) => filter.single?.field)
            .filter(
                (field) => field !== null && field !== undefined
            ) as string[];
    };

    const selectedFields = getSelectedFields(filters);

    const getFilteredCount = (filters: DashBoardFilter[]) => {
        return filters.filter((filter) => filter.single?.condition_type).length;
    };

    const totalFilters = getFilteredCount(dashboardFilters.and_);

    // Notify parent component about filter count change
    useEffect(() => {
        if (onFilterCountChange) {
            onFilterCountChange(totalFilters);
        }
    }, [totalFilters, onFilterCountChange]);
    useEffect(() => {
        if (open) {
            setTimeout(() => setIsClickAwayEnabled(true), 80);
        } else {
            setIsClickAwayEnabled(false);
        }
    }, [open]);

    return (
        <ClickAwayListener
            onClickAway={isClickAwayEnabled ? handleClose : () => {}}
        >
            <Popper
                id={'filterPopper'}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                transition
                style={{
                    zIndex: 10,
                    borderRadius: '15px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box
                            sx={{
                                minWidth: 600,
                                bgcolor: '#fff',
                                boxShadow: 24,
                                p: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'flex-start',
                                borderRadius: '15px',
                                border: '1px secondary solid',
                            }}
                        >
                            <FWTypography
                                variant="h5"
                                sx={{ ml: 0.5, fontSize: '15px' }}
                            >
                                Apply Filters
                            </FWTypography>

                            <Divider sx={{ width: '100%', my: 1.5 }} />

                            {dashboardFilters.and_.map((andFilter, index) => (
                                <ProjectDashboardFilterFieldRow
                                    andFilter={andFilter}
                                    disableRemoveRow={
                                        dashboardFilters.and_.length === 1
                                    }
                                    filterFieldToNameMap={filterFieldToNameMap}
                                    resetField={resetField}
                                    removeSortRow={removeSortRow}
                                    fieldTypeLabels={fieldTypeLabels}
                                    index={index}
                                    updateFilterObject={updateFilterObject}
                                    selectedFields={selectedFields}
                                />
                            ))}

                            <FWButton
                                variant="text"
                                onClick={handleAddFilter}
                                sx={{
                                    display: 'flex',
                                    mt: 1,
                                    gap: 0.5,
                                    alignItems: 'center',
                                    color: 'black',
                                    // '&:hover': {
                                    //     backgroundColor: '#EEEDEB',
                                    // },
                                }}
                                disabled={!isApplyButtonEnabled}
                            >
                                <FWIcon size={24} name={'bi bi-plus'} />
                                <FWTypography
                                    variant="h5"
                                    fontSize={15}
                                    sx={{ textTransform: 'none' }}
                                >
                                    <span>Add filters</span>
                                </FWTypography>
                            </FWButton>

                            {/* </Box> */}

                            <Divider sx={{ width: '100%', my: 1.5 }} />

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <FWButton
                                    variant="text"
                                    onClick={handleResetFilters}
                                    disabled={
                                        !Boolean(
                                            dashboardFilters.and_[
                                                dashboardFilters.and_.length - 1
                                            ]?.single?.field
                                        )
                                    }
                                >
                                    <FWTypography
                                        variant="h5"
                                        fontSize={15}
                                        color={'black'}
                                        sx={{
                                            textTransform: 'none',
                                        }}
                                    >
                                        Clear all filters
                                    </FWTypography>
                                </FWButton>

                                <div style={{ display: 'flex' }}>
                                    <FWButton
                                        sx={{
                                            // mt: 1,
                                            mr: 1,
                                            marginLeft: 'auto',
                                            textAlign: 'center',
                                            display: 'flex',
                                            paddingX: 2,
                                            // justifyContent: 'flex-end',
                                        }}
                                        variant="outlined"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </FWButton>

                                    <FWButton
                                        sx={{
                                            // mt: 1,
                                            mr: 1,
                                            marginLeft: 'auto',
                                            textAlign: 'center',
                                            display: 'flex',
                                            paddingX: 2,
                                            // justifyContent: 'flex-end',
                                        }}
                                        variant="contained"
                                        disabled={
                                            !isApplyButtonEnabled || loading
                                        }
                                        onClick={handleApplyClick}
                                    >
                                        {loading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            'Apply'
                                        )}
                                    </FWButton>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </ClickAwayListener>
    );
};

export default memo(DashboardFilterPopup);
