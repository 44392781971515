import {
    Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
} from '@mui/material';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { FWButton, FWIconButton } from '../../../../Common/FWButton';
import { FWInput } from '../../../../Common/FWInput';
import FWPortal from '../../../../Common/FWPortal';
import { FWTypography } from '../../../../Common/FWTypograhy';
import ItemCustomIds from '../../Components/ItemDirectory/ItemCustomIds';
import ItemSpecifications from '../../Components/ItemDirectory/ItemSpecifications';
import ItemStandardTerms, {
    STField,
} from '../../Components/ItemDirectory/ItemStandardTerms';
import {
    ItemReducerAction,
    ItemUpdateActions,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import {
    IItemCustomId,
    IItemDetails,
    IItemMeasurementUnits,
    IItemStandardTermsOptions,
    IMeasurement,
} from '../../Interfaces/ItemDirectoryInterface';
import {
    IItemAddEditResponse,
    useGetItemTagLinkToVendorTabCountMutation,
    validateItemCode,
} from '../../Services/ItemDirectoryService';
import { InfoBlock, LongtextBox } from './ItemDetailsContainer';

import { debounce, isEmpty, isEqual } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetAdditionalCostFromAdminQuery } from '../../../../AdditionalCost/Services/additionalCosts.services';
import { IAdditionalCostsBackend } from '../../../../AdditionalCost/models/AdditionalCost.model';
import { capitaliseFirstLetter } from '../../../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import { AutocompleteStyledPaper } from '../../../../Common/FWAutocomplete';
import { FWCombinedInput } from '../../../../Common/FWCombinedInput';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import LazyFallback from '../../../../Components/Shared/LazyFallBack';
import { AuthContext } from '../../../../Contexts/AuthContext';
import TagsInput from '../../../../Global/Components/TagsInput';
import { ICurrencyDetails } from '../../../../Models/Currency.model';
import { IGlobalAppStore } from '../../../../Redux/Store';
import { FieldNameDescription } from '../../../../SRM/Components/DraftDocumentCustomFields';
import UOMMeasurementViewPopup from '../../../../UOM&Currency/Components/UOM/UOMMeasurementViewPopup';
import {
    IINewItemTemplate,
    useNewItemAdditionalCost,
} from '../../Hooks/ItemDirectory/ItemAdditionalCostHook';
import ItemAdditionalCost from './ItemAdditionalCost';
import ItemCustomFields from './ItemCustomFields';
import {} from '../../../../Specifications/Models/SpecificationInterfaces';
import { ICustomAttributeNew } from '../../../../Models/Attributes.model';

export enum ItemPageType {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

interface IItemAddEditContainerProps {
    type: ItemPageType;
    data: IItemDetails;
    ogData: IItemDetails;
    measurements: IItemMeasurementUnits;
    fetchItemMeasurements: () => void;
    itemTemplateSectionList?: IINewItemTemplate | undefined;
    setItemTemplateSectionList?: Dispatch<
        SetStateAction<IINewItemTemplate | undefined>
    >;
    getTemplateFields?: () => void;
    globalTags: string[];
    currencies: ICurrencyDetails[];
    isValidDetails: boolean;
    event?: boolean;
    handleChange: (action: ItemReducerAction) => void;
    handleItemSave: () => Promise<IItemAddEditResponse>;
    emitSaveAction: (
        response: IItemAddEditResponse,
        isFinishedGood: boolean
    ) => void;
    shouldRedirect?: boolean;
}

const ItemAddEditContainer = ({
    type,
    data,
    ogData,
    handleChange,
    measurements,
    globalTags,
    currencies,
    itemTemplateSectionList,
    getTemplateFields,
    setItemTemplateSectionList,
    isValidDetails,
    handleItemSave,
    emitSaveAction,
    event,
    shouldRedirect = true,
    fetchItemMeasurements,
}: IItemAddEditContainerProps) => {
    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );

    const history = useHistory();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [newMeasurementUnitPopup, setNewMeasurementUnitPopup] =
        useState(false);
    const [
        newMeasurementUnitSelectedFromPopup,
        setNewMeasurementUnitSelectedFromPopup,
    ] = useState<string | null>(null);
    const [allMeasurementUnits, setAllMeasurementUnits] =
        useState<IItemMeasurementUnits>({});
    const categoriesOrder = [
        'UNITS',
        'WEIGHT',
        'VOLUME',
        'LENGTH',
        'AREA',
        'TEMPERATURE',
        'ENERGY',
        'TIME',
        'OTHER',
    ];

    const measurementsArrayUnsorted = Object.keys(allMeasurementUnits).map(
        (item) => allMeasurementUnits[item]
    );

    const measurementsArray = measurementsArrayUnsorted
        .filter((unit) => unit.isVerifiedUnit)
        .concat(
            measurementsArrayUnsorted.filter((unit) => !unit.isVerifiedUnit)
        );

    useEffect(() => {
        if (newMeasurementUnitSelectedFromPopup) {
            const foundMeasurementUnit = measurementsArray.find(
                (measure) => measure.id === newMeasurementUnitSelectedFromPopup
            );

            if (foundMeasurementUnit) {
                handleChange({
                    type: ItemUpdateActions.MEASUREMENTS,
                    value: [foundMeasurementUnit],
                });
                setNewMeasurementUnitSelectedFromPopup(null);
            }
        }
    }, [handleChange, measurementsArray, newMeasurementUnitSelectedFromPopup]);

    const customSort = (a: IMeasurement, b: IMeasurement) =>
        categoriesOrder.indexOf(a.category) -
        categoriesOrder.indexOf(b.category);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let res = measurementsArray.sort(customSort);

    const openMenu = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setMenuAnchorEl(null);
    };

    const { authData } = useContext(AuthContext);

    useEffect(() => {
        setAllMeasurementUnits(measurements);
        return () => {
            if (setItemTemplateSectionList)
                setItemTemplateSectionList(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measurements]);

    const handleSTValuesChange = (
        type: STField,
        value: IItemStandardTermsOptions | null
    ) => {
        handleChange({
            type: ItemUpdateActions[type],
            value,
        });
    };

    const handleSpecificationEmitData = (value: ICustomAttributeNew[]) => {
        handleChange({
            type: ItemUpdateActions.SPECIFICATION,
            value,
        });
    };

    const handleCustomIdsEmitData = (value: IItemCustomId[]) => {
        handleChange({
            type: ItemUpdateActions.CUSTOM_IDS,
            value,
        });
    };

    const handleValueChange = (event: {
        target: { name: ItemUpdateActions; value: string };
    }) => {
        handleChange({
            type: ItemUpdateActions[event.target.name],
            value: event.target.value,
        } as ItemReducerAction);
    };

    const [fetchItemTagCount] = useGetItemTagLinkToVendorTabCountMutation({
        fixedCacheKey: 'itemTagsLinkToVendor',
    });

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();

        const itemSaved = await handleItemSave();
        fetchItemTagCount({
            query_data: {
                tag_type: 'ITEM',
            },
        });
        emitSaveAction(itemSaved, itemSaved.isFinishedGood);
        if (shouldRedirect) history.push('/admin/items/item-directory');
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [itemId, setItemId] = useState<string>('');

    const location = useLocation();
    useEffect(() => {
        if (type === 'EDIT') {
            let itemEditPage = location.pathname.split('/').filter(Boolean);

            let itemid = itemEditPage.reduce(
                (longest: string, current: string) =>
                    current.length > longest.length ? current : longest,
                ''
            );

            setItemId(itemid);
        }
    }, [location.pathname, type]);

    const {
        newItemTemplateSectionList,
        newGetTemplateFields,
        customFieldSetting,
    } = useNewItemAdditionalCost(handleChange, type, data);

    const [templateDetails, setTemplateDetails] = useState<IINewItemTemplate>();

    useEffect(() => {
        if (type === ItemPageType.ADD && newItemTemplateSectionList) {
            setTemplateDetails(newItemTemplateSectionList);
        } else if (type === ItemPageType.EDIT && itemTemplateSectionList) {
            setTemplateDetails(itemTemplateSectionList);
        }
    }, [itemTemplateSectionList, newItemTemplateSectionList, type]);

    useEffect(() => {
        if (getTemplateFields) getTemplateFields();
    }, [getTemplateFields]);

    const checkCustomFieldError = useCallback(() => {
        return (
            data?.custom_sections &&
            data.custom_sections[0]?.custom_fields?.some((field) => {
                let fieldInfo =
                    templateDetails?.custom_sections[0]?.custom_fields?.find(
                        (tempField) => tempField.name === field.name
                    );

                if (
                    fieldInfo &&
                    fieldInfo?.is_required &&
                    (field.value === '' ||
                        field.value === null ||
                        (Array.isArray(field.value) &&
                            field.value.length === 0))
                ) {
                    return true;
                }
                return false;
            })
        );
    }, [data.custom_sections, templateDetails?.custom_sections]);

    const [misMatchedCosts, setMisMatchedCosts] =
        useState<IAdditionalCostsBackend[]>();

    const { data: adminAdditionalCost } = useGetAdditionalCostFromAdminQuery(
        {}
    );

    const fillInMisMatchedFields = useCallback(() => {
        if (adminAdditionalCost) {
            let fieldsInDataNotInTemplate = data?.additionalCost?.filter(
                (cost) =>
                    !templateDetails?.additionalCosts[0]?.fields?.some(
                        (c) =>
                            c.additional_cost_information.additional_cost_id ===
                            cost.additional_cost_id
                    )
            );
            setMisMatchedCosts(fieldsInDataNotInTemplate);
        }
    }, [
        adminAdditionalCost,
        data?.additionalCost,
        templateDetails?.additionalCosts,
    ]);

    const handleMismatchedCostRemove = (
        type: 'SINGLE' | 'ALL',
        costId: string | null = null
    ) => {
        let filteredAdditionalCostList: IAdditionalCostsBackend[] = [];
        if (type === 'SINGLE') {
            filteredAdditionalCostList = data?.additionalCost?.filter(
                (cost) => cost.additional_cost_id !== costId
            );
        } else {
            filteredAdditionalCostList = data.additionalCost?.filter(
                (cost) =>
                    !templateDetails?.additionalCosts[0]?.fields.some(
                        (tempCost) =>
                            tempCost.additional_cost_information
                                .additional_cost_id === cost.additional_cost_id
                    )
            );
        }
        handleChange({
            type: ItemUpdateActions.ADDITIONAL_COST,
            value: filteredAdditionalCostList,
        });
        handleChange({
            type: ItemUpdateActions.BUYER_ADDITIONAL_COST,
            value: filteredAdditionalCostList,
        });
        handleChange({
            type: ItemUpdateActions.SELLER_ADDITIONAL_COST,
            value: filteredAdditionalCostList,
        });
    };

    useEffect(() => {
        debouncedSearch(data.itemCode);
        //eslint-disable-next-line
    }, [data.itemCode]);

    const [codeError, setError] = useState(false);
    const debouncedSearch = useMemo(
        () =>
            debounce(async (text: string) => {
                try {
                    let resp: any = await validateItemCode(text.trim());
                    if (resp.data.item_exists) {
                        setError(true);
                    } else setError(false);
                } catch (err) {
                    console.error(err);
                }
            }, 1000),
        []
    );
    useEffect(() => {
        fillInMisMatchedFields();
    }, [fillInMisMatchedFields]);
    useEffect(() => {
        setAllMeasurementUnits(measurements);
    }, [measurements]);

    useEffect(() => {
        newGetTemplateFields();
    }, [newGetTemplateFields]);

    const BottomButtons = (
        <Grid container className="flex--jcfs pr--15 mt--10">
            <Grid item>
                <FWButton
                    variant="contained"
                    onClick={handleFormSubmit}
                    disabled={
                        !isValidDetails ||
                        (codeError && type === ItemPageType.ADD) ||
                        isEqual(ogData, data) ||
                        checkCustomFieldError() ||
                        misMatchedCosts?.length !== 0 ||
                        data.additionalCost.some(
                            (ac) => !Boolean(ac.cost_value?.toString())
                        )
                    }
                >
                    {false ? (
                        <CircularProgress
                            size={24}
                            style={{
                                color: '#c4c4c8',
                            }}
                        />
                    ) : (
                        'Save'
                    )}
                </FWButton>
            </Grid>
        </Grid>
    );

    if (templateDetails === undefined) {
        return <LazyFallback />;
    }

    return (
        <>
            {/* {hookState.state === HookStateValue.LOADING && <LazyFallback />} */}
            <Grid container>
                <InfoBlock item xs={3}>
                    <FieldNameDescription
                        name={templateDetails.itemFields['Item_code'].name}
                        description={
                            templateDetails.itemFields['Item_code'].description
                        }
                        required={
                            templateDetails.itemFields['Item_code'].is_required
                        }
                    />
                    <FWInput
                        value={data.itemCode}
                        name={ItemUpdateActions.ITEM_CODE}
                        onChange={handleValueChange}
                        disabled={
                            !(authData.details?.role === 'ADMIN') ||
                            type === ItemPageType.EDIT
                        }
                        error={
                            (codeError || data.itemCode === '') &&
                            type === ItemPageType.ADD
                        }
                        helper={{
                            text:
                                data.itemCode === '' &&
                                type === ItemPageType.ADD
                                    ? 'Item code cannot be empty'
                                    : codeError && type === ItemPageType.ADD
                                    ? `Duplicate item code`
                                    : '',
                            textColor: 'error',
                        }}
                    />
                </InfoBlock>
                <InfoBlock item xs={3}>
                    <FWTypography color="secondary">Item type</FWTypography>
                    <Autocomplete
                        size="small"
                        fullWidth
                        PaperComponent={(props) => (
                            <AutocompleteStyledPaper {...props} />
                        )}
                        id="user-detail-reports-to"
                        options={['FINISHED_GOOD', 'RAW_MATERIAL']}
                        getOptionLabel={(
                            option: 'FINISHED_GOOD' | 'RAW_MATERIAL'
                        ) =>
                            `${capitaliseFirstLetter(
                                option.toLowerCase().replaceAll('_', ' ')
                            )}`
                        }
                        renderOption={(
                            props: any,
                            option: 'FINISHED_GOOD' | 'RAW_MATERIAL'
                        ) => {
                            return (
                                <li {...props} key={option}>
                                    {capitaliseFirstLetter(
                                        option
                                            .toLowerCase()
                                            .replaceAll('_', ' ')
                                    )}
                                </li>
                            );
                        }}
                        value={data.item_type}
                        onChange={(
                            e: any,
                            options: 'FINISHED_GOOD' | 'RAW_MATERIAL'
                        ) => {
                            handleChange({
                                type: ItemUpdateActions.ITEM_TYPE,
                                value: options,
                            });
                        }}
                        isOptionEqualToValue={(
                            opt: 'FINISHED_GOOD' | 'RAW_MATERIAL',
                            val: 'FINISHED_GOOD' | 'RAW_MATERIAL'
                        ) => {
                            return opt === val;
                        }}
                        renderInput={(params) => (
                            <>
                                <TextField {...params} variant="outlined" />
                            </>
                        )}
                        disableClearable
                        disabled={!(authData.details?.role === 'ADMIN')}
                    />
                </InfoBlock>

                <InfoBlock item xs={6}>
                    <FieldNameDescription
                        name={templateDetails.itemFields['Item_name'].name}
                        description={
                            templateDetails.itemFields['Item_name'].description
                        }
                        required={
                            templateDetails.itemFields['Item_name'].is_required
                        }
                    />
                    <FWInput
                        // label="Item name"
                        value={data.itemName}
                        name={ItemUpdateActions.NAME}
                        onChange={handleValueChange}
                        disabled={!(authData.details?.role === 'ADMIN')}
                    />
                </InfoBlock>
            </Grid>

            <Grid container>
                <InfoBlock item xs={12}>
                    <Grid container>
                        <Grid item xs={12} style={{ marginBottom: '30px' }}>
                            <FormControl fullWidth>
                                <div className="flex flex--aic">
                                    <FieldNameDescription
                                        name={
                                            templateDetails.itemFields[
                                                'Measurement_unit'
                                            ].name
                                        }
                                        description={
                                            templateDetails.itemFields[
                                                'Measurement_unit'
                                            ].description
                                        }
                                        required={
                                            templateDetails.itemFields[
                                                'Measurement_unit'
                                            ].is_required
                                        }
                                    />
                                    &nbsp;
                                    {authData.details?.role === 'ADMIN' && (
                                        <FWTooltip title="Add measurement unit">
                                            <Box
                                                color="primary"
                                                sx={{ margin: '0px' }}
                                            >
                                                <FWIconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setNewMeasurementUnitPopup(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <i className="bi bi-plus-circle" />
                                                </FWIconButton>
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </div>

                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    PaperComponent={(props) => (
                                        <AutocompleteStyledPaper {...props} />
                                    )}
                                    groupBy={(option) => option.category}
                                    id="user-detail-reports-to"
                                    options={measurementsArray}
                                    getOptionLabel={(option: IMeasurement) =>
                                        `${option.name} ${
                                            option.abbreviation
                                                ? `(${option.abbreviation})`
                                                : ''
                                        }`
                                    }
                                    renderOption={(
                                        props: any,
                                        option: IMeasurement
                                    ) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                {option.name}{' '}
                                                {Boolean(option.abbreviation)
                                                    ? `(${option.abbreviation})`
                                                    : ``}
                                            </li>
                                        );
                                    }}
                                    value={data.measurementUnits[0]}
                                    onChange={(
                                        e: any,
                                        options: IMeasurement
                                    ) => {
                                        handleChange({
                                            type: ItemUpdateActions.MEASUREMENTS,
                                            value: [options],
                                        });
                                    }}
                                    isOptionEqualToValue={(
                                        opt: IMeasurement,
                                        val: IMeasurement
                                    ) => {
                                        return opt.id === val.id;
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                            />
                                        </>
                                    )}
                                    disableClearable
                                    disabled={
                                        !(authData.details?.role === 'ADMIN')
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {!templateDetails?.itemFields.Description.is_hidden && (
                            <LongtextBox container>
                                <FieldNameDescription
                                    name={
                                        templateDetails.itemFields[
                                            'Description'
                                        ].name
                                    }
                                    description={
                                        templateDetails.itemFields[
                                            'Description'
                                        ].description
                                    }
                                    required={
                                        templateDetails.itemFields[
                                            'Description'
                                        ].is_required
                                    }
                                />
                                <FWInput
                                    multiline
                                    maxRows={4}
                                    minRows={2}
                                    sx={{ paddingRight: '0' }}
                                    value={data.description}
                                    name={ItemUpdateActions.DESCRIPTION}
                                    onChange={handleValueChange}
                                    disabled={
                                        !(authData.details?.role === 'ADMIN')
                                    }
                                    error={
                                        templateDetails.itemFields[
                                            'Description'
                                        ].is_required &&
                                        isEmpty(data.description)
                                    }
                                    helper={{
                                        text:
                                            templateDetails.itemFields[
                                                'Description'
                                            ].is_required &&
                                            isEmpty(data.description)
                                                ? 'Input is required'
                                                : '',
                                    }}
                                />
                            </LongtextBox>
                        )}
                        {!templateDetails?.itemFields.Tag.is_hidden && (
                            <Grid item xs={12} style={{ marginBottom: '30px' }}>
                                <FormControl fullWidth>
                                    <Box
                                        className="flex flex--aic"
                                        sx={{ marginBottom: '4px' }}
                                    >
                                        <FWTypography color="text.secondary">
                                            Item Tags
                                        </FWTypography>
                                    </Box>
                                    <TagsInput
                                        value={data.tags ?? []}
                                        updateData={(newValue) =>
                                            handleChange({
                                                type: ItemUpdateActions.TAGS,
                                                value: newValue,
                                            })
                                        }
                                        options={globalTags}
                                        disabled={
                                            !(
                                                authData.details?.role ===
                                                'ADMIN'
                                            )
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        )}

                        <Grid container>
                            <InfoBlock
                                item
                                xs={12}
                                sx={{ alignSelf: 'flex-end' }}
                            >
                                <FormGroup sx={{ flexDirection: 'row' }}>
                                    {!templateDetails?.itemFields
                                        .Procurement_item.is_hidden && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data.isBuyer}
                                                    onChange={(e) => {
                                                        const checkValue =
                                                            e.target.checked;
                                                        if (
                                                            checkValue ||
                                                            data.isSeller
                                                        )
                                                            handleChange({
                                                                type: ItemUpdateActions.IS_BUYER,
                                                                value: checkValue,
                                                            });
                                                    }}
                                                    disabled={
                                                        !(
                                                            authData.details
                                                                ?.role ===
                                                            'ADMIN'
                                                        )
                                                    }
                                                />
                                            }
                                            label="Buyer"
                                        />
                                    )}
                                    {!templateDetails?.itemFields.Sales_item
                                        .is_hidden && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data.isSeller}
                                                    onChange={(e) => {
                                                        const checkValue =
                                                            e.target.checked;
                                                        if (
                                                            checkValue ||
                                                            data.isBuyer
                                                        )
                                                            handleChange({
                                                                type: ItemUpdateActions.IS_SELLER,
                                                                value: checkValue,
                                                            });
                                                    }}
                                                    disabled={
                                                        !(
                                                            authData.details
                                                                ?.role ===
                                                            'ADMIN'
                                                        )
                                                    }
                                                />
                                            }
                                            label="Seller"
                                        />
                                    )}
                                </FormGroup>
                            </InfoBlock>
                            {(!templateDetails?.itemFields
                                .Procurement_item_price.is_hidden ||
                                !templateDetails?.itemFields.Sales_item_price
                                    .is_hidden) && (
                                <InfoBlock item xs={8}>
                                    <Box
                                        className={'flex flex--aic'}
                                        marginBottom={'30px'}
                                        gap={2}
                                    >
                                        {data.isBuyer &&
                                            !templateDetails?.itemFields
                                                .Procurement_item_price
                                                .is_hidden && (
                                                <Grid item xs={6}>
                                                    <FWCombinedInput
                                                        error={
                                                            templateDetails
                                                                ?.itemFields
                                                                .Procurement_item_price
                                                                .is_required &&
                                                            (!Boolean(
                                                                data
                                                                    .buyerPricingInformation
                                                                    .price
                                                            ) ||
                                                                data
                                                                    .buyerPricingInformation
                                                                    .currencyCodeId ===
                                                                    null)
                                                        }
                                                        selectDisabled={
                                                            !(
                                                                authData.details
                                                                    ?.role ===
                                                                'ADMIN'
                                                            )
                                                        }
                                                        inputDisabled={
                                                            !(
                                                                authData.details
                                                                    ?.role ===
                                                                'ADMIN'
                                                            )
                                                        }
                                                        required={true}
                                                        isDropLeft={true}
                                                        label="Buyer default price"
                                                        allowOnly="DECIMAL_NUMBER"
                                                        textFieldplaceholder="Enter price"
                                                        selectFieldplaceholder="Select currency"
                                                        textFieldValue={
                                                            data
                                                                .buyerPricingInformation
                                                                .price || ''
                                                        }
                                                        textFieldOnChange={(
                                                            e
                                                        ) =>
                                                            handleChange({
                                                                type: ItemUpdateActions.BUYER_PRICING_INFORMATION,
                                                                value: {
                                                                    ...data.buyerPricingInformation,
                                                                    price: +e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }
                                                        selectFieldValue={
                                                            data
                                                                .buyerPricingInformation
                                                                .currencyCodeId ||
                                                            ''
                                                        }
                                                        selectFieldOnChange={(
                                                            e
                                                        ) =>
                                                            handleChange({
                                                                type: ItemUpdateActions.BUYER_PRICING_INFORMATION,
                                                                value: {
                                                                    ...data.buyerPricingInformation,
                                                                    currencyCodeId:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        selectFieldMenuItems={currencies.map(
                                                            (val, i) => (
                                                                <FWMenuItem
                                                                    value={
                                                                        val.entry_id
                                                                    }
                                                                    key={i}
                                                                >
                                                                    {`${val.currency_code_abbreviation} (${val.currency_symbol})`}
                                                                </FWMenuItem>
                                                            )
                                                        )}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor:
                                                                        'white',
                                                                    borderRadius:
                                                                        '12px',
                                                                    boxShadow:
                                                                        '0px 5px 16px 3px #00000024',
                                                                    maxHeight:
                                                                        '30vh',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                        {data.isSeller &&
                                            !templateDetails?.itemFields
                                                .Sales_item_price.is_hidden && (
                                                <Grid item xs={6}>
                                                    <FWCombinedInput
                                                        required={true}
                                                        isDropLeft={true}
                                                        label="Seller default price"
                                                        allowOnly="DECIMAL_NUMBER"
                                                        textFieldplaceholder="Enter price"
                                                        selectFieldplaceholder="Select currency"
                                                        textFieldValue={
                                                            data
                                                                .sellerPricingInformation
                                                                .price || ''
                                                        }
                                                        textFieldOnChange={(
                                                            e
                                                        ) =>
                                                            handleChange({
                                                                type: ItemUpdateActions.SELLER_PRICING_INFORMATION,
                                                                value: {
                                                                    ...data.sellerPricingInformation,
                                                                    price: +e
                                                                        .target
                                                                        .value,
                                                                },
                                                            })
                                                        }
                                                        selectFieldValue={
                                                            data
                                                                .sellerPricingInformation
                                                                .currencyCodeId ||
                                                            ''
                                                        }
                                                        selectFieldOnChange={(
                                                            e
                                                        ) =>
                                                            handleChange({
                                                                type: ItemUpdateActions.SELLER_PRICING_INFORMATION,
                                                                value: {
                                                                    ...data.sellerPricingInformation,
                                                                    currencyCodeId:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        selectFieldMenuItems={currencies.map(
                                                            (val, i) => (
                                                                <FWMenuItem
                                                                    value={
                                                                        val.entry_id
                                                                    }
                                                                    key={i}
                                                                >
                                                                    {`${val.currency_code_abbreviation} (${val.currency_symbol})`}
                                                                </FWMenuItem>
                                                            )
                                                        )}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor:
                                                                        'white',
                                                                    borderRadius:
                                                                        '12px',
                                                                    boxShadow:
                                                                        '0px 5px 16px 3px #00000024',
                                                                    maxHeight:
                                                                        '30vh',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                    </Box>
                                </InfoBlock>
                            )}
                        </Grid>
                    </Grid>

                    <Grid marginBottom={2}>
                        {!templateDetails?.additionalCosts[0]?.isHidden && (
                            <Grid item xs={4}>
                                <ItemAdditionalCost
                                    data={data}
                                    handleChange={handleChange}
                                    templateDetails={templateDetails}
                                    buyerCurrency={
                                        currencies.find(
                                            (currency) =>
                                                currency.entry_id ===
                                                data.buyerPricingInformation
                                                    .currencyCodeId
                                        )?.currency_name ?? ''
                                    }
                                    sellerCurrency={
                                        currencies.find(
                                            (currency) =>
                                                currency.entry_id ===
                                                data.sellerPricingInformation
                                                    .currencyCodeId
                                        )?.currency_name ?? ''
                                    }
                                    misMatchedCosts={misMatchedCosts ?? []}
                                    tooltipTextForEachRemove=""
                                    removeAdditionalCosts={(
                                        type: 'SINGLE' | 'ALL',
                                        costId?: string | null
                                    ) => {
                                        handleMismatchedCostRemove(
                                            type,
                                            costId
                                        );
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>

                    {!templateDetails?.itemFields.Notes.is_hidden && (
                        <LongtextBox>
                            <FWInput
                                error={
                                    templateDetails?.itemFields.Notes
                                        .is_required && isEmpty(data.notes)
                                }
                                helper={{
                                    text:
                                        templateDetails?.itemFields.Notes
                                            .is_required && isEmpty(data.notes)
                                            ? 'Input is required'
                                            : '',
                                }}
                                label={`Notes and external links ${
                                    templateDetails?.itemFields.Notes
                                        .is_required
                                        ? ''
                                        : '(optional)'
                                }`}
                                multiline
                                maxRows={4}
                                minRows={2}
                                value={data.notes || ''}
                                onChange={(e) =>
                                    handleChange({
                                        type: ItemUpdateActions.NOTES,
                                        value: e.target.value,
                                    })
                                }
                                disabled={!(authData.details?.role === 'ADMIN')}
                            />
                        </LongtextBox>
                    )}

                    {!templateDetails?.itemFields.Internal_notes?.is_hidden && (
                        <LongtextBox>
                            <FieldNameDescription
                                name={
                                    templateDetails.itemFields['Internal_notes']
                                        .name
                                }
                                description={
                                    templateDetails.itemFields['Internal_notes']
                                        .description
                                }
                                required={
                                    templateDetails.itemFields['Internal_notes']
                                        .is_required
                                }
                            />
                            <FWInput
                                error={
                                    templateDetails.itemFields['Internal_notes']
                                        .is_required &&
                                    isEmpty(data.internal_notes)
                                }
                                helper={{
                                    text:
                                        templateDetails?.itemFields.Notes
                                            .is_required &&
                                        isEmpty(data.internal_notes)
                                            ? 'Input is required'
                                            : '',
                                }}
                                multiline
                                maxRows={4}
                                minRows={2}
                                value={data.internal_notes || ''}
                                onChange={(e) =>
                                    handleChange({
                                        type: ItemUpdateActions.INTERNAL_NOTES,
                                        value: e.target.value,
                                    })
                                }
                                disabled={!(authData.details?.role === 'ADMIN')}
                            />
                        </LongtextBox>
                    )}

                    {!Boolean(
                        templateDetails?.itemFields?.Specification?.is_hidden ??
                            true
                    ) && (
                        <InfoBlock item xs={12} sx={{ mb: 0, pr: 0 }}>
                            <FieldNameDescription
                                name={
                                    templateDetails.itemFields['Specification']
                                        .name
                                }
                                description={
                                    templateDetails.itemFields['Specification']
                                        .description
                                }
                                required={
                                    templateDetails.itemFields['Specification']
                                        .is_required
                                }
                            />

                            <Grid container sx={{ width: '100%' }}>
                                <ItemSpecifications
                                    data={data.attributes ?? []}
                                    emitSpecificationAction={
                                        handleSpecificationEmitData
                                    }
                                />
                            </Grid>
                        </InfoBlock>
                    )}
                </InfoBlock>

                {data.standardTerms.lead &&
                    data.standardTerms.payment &&
                    data.standardTerms.prePayment && (
                        <InfoBlock item xs={6}>
                            <FWTypography>Standard terms</FWTypography>
                            <ItemStandardTerms
                                data={data.standardTerms}
                                emitSTValuesChange={handleSTValuesChange}
                                anchorEl={menuAnchorEl}
                                onClose={closeMenu}
                                openMenu={openMenu}
                                closeMenu={closeMenu}
                            />
                        </InfoBlock>
                    )}
            </Grid>

            {!templateDetails?.itemFields.Identifier_name?.is_hidden && (
                <Grid item xs={event ? 12 : 6} sx={{ pr: event ? '15px' : 0 }}>
                    <FWTypography
                        sx={{ mb: '5px', fontWeight: 'bold' }}
                        color="text.secondary"
                    >
                        Custom IDs (optional)
                    </FWTypography>
                    <ItemCustomIds
                        data={data.customIds}
                        emitCustomIdsAction={handleCustomIdsEmitData}
                    />
                </Grid>
            )}

            {data?.custom_sections && (
                <Grid item xs={12} sx={{ pr: '15px' }}>
                    <FWTypography sx={{ mb: '5px' }} color="text.secondary">
                        Additional information
                    </FWTypography>

                    <ItemCustomFields
                        custom_sections={data?.custom_sections}
                        handleChange={handleChange}
                        newItemTemplateSectionList={templateDetails}
                        customFieldTemplateSetting={customFieldSetting}
                    />
                </Grid>
            )}

            {event && <Grid item xs={12} height="30px"></Grid>}

            {authData.details?.role === 'ADMIN' &&
                (SINGLE_ENTITY && type === ItemPageType.EDIT ? (
                    <FWPortal id="item-bottom-buttons">
                        {BottomButtons}
                    </FWPortal>
                ) : (
                    BottomButtons
                ))}
            <UOMMeasurementViewPopup
                open={newMeasurementUnitPopup}
                handleClose={() => {
                    setNewMeasurementUnitPopup(false);
                }}
                onSaved={(measurementUditId: string) => {
                    fetchItemMeasurements();
                    setNewMeasurementUnitSelectedFromPopup(measurementUditId);
                }}
            />
        </>
    );
};

export default ItemAddEditContainer;
