import { cloneDeep } from 'lodash';
import { getFieldTypeContraintsFromBackend } from '../../FieldTypes/helpers/fieldTypesHelpers';
import { convertTemplateConstraintsItemToCustomFIeldType } from '../../Global/Helpers/templateApiSlice';
import { IBuyerDetails } from '../../Organizations/SellerAdmin/BuyerMaster/Interfaces/SellerAdminBuyerInterfaces.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import {
    blankVisitTemplate,
    VisitTemplateStandardFieldBackendNames,
} from '../Hooks/VisitConstants';
import { ICreateVisit } from '../Models/Visits';
import { IVisitBackendResponse } from '../Models/VisitTemplateBackend';

export const visitApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getVisitTemplateById: build.query<
            // IVisitTemplate,
            any,
            { entityID: string; templateId: string }
        >({
            query: ({ entityID, templateId }) => ({
                url: `/module_templates/${entityID}/${templateId}`,
            }),
            transformResponse: (
                baseQueryReturnValue: IVisitBackendResponse
            ) => {
                const response = cloneDeep(blankVisitTemplate);
                response.templateId = baseQueryReturnValue.template_id;
                response.templateName = baseQueryReturnValue.name;

                const templateSections = baseQueryReturnValue.section_list.map(
                    (section) => {
                        return {
                            ...section,
                            section_items: section.section_items.map(
                                (item: any) => {
                                    return {
                                        ...item,
                                        alternate_name:
                                            item.alternate_name.replace(
                                                '_',
                                                ''
                                            ),
                                        name: item.name.replace('_', ''),
                                    };
                                }
                            ),
                        };
                    }
                );

                baseQueryReturnValue = {
                    ...baseQueryReturnValue,
                    section_list: templateSections,
                };
                const visitDetailsSection =
                    baseQueryReturnValue.section_list.find(
                        (section) => section.name === 'Visit Fields'
                    );

                response.sections.VISIT_DETAILS = {
                    fieldList: [],
                    label:
                        visitDetailsSection &&
                        visitDetailsSection.alternate_name
                            ? visitDetailsSection.alternate_name
                            : 'Visit Details',
                    assigned_users: visitDetailsSection?.assigned_users ?? [],
                    section_type: 'OTHER',
                };

                visitDetailsSection?.section_items?.forEach((sectionItem) => {
                    if (sectionItem.is_builtin_field) {
                        response.sections.VISIT_DETAILS.fieldList.push({
                            fieldType: 'STANDARD',
                            field: VisitTemplateStandardFieldBackendNames[
                                sectionItem.name
                            ],
                        });

                        response.fieldSettings.standardFields[
                            VisitTemplateStandardFieldBackendNames[
                                sectionItem.name
                            ]
                        ] = {
                            label: sectionItem.alternate_name
                                ? sectionItem.alternate_name
                                : sectionItem.name,
                            buyerSettings: {
                                required: sectionItem.is_required,
                                showField: !(
                                    sectionItem.additional_information
                                        .is_hidden ?? false
                                ),
                            },
                            description: sectionItem.description,
                            sellerSettings: {
                                negotiable:
                                    !(
                                        sectionItem.additional_information
                                            .is_hidden ?? false
                                    ) &&
                                    (sectionItem.additional_information
                                        .is_visible ??
                                        true) &&
                                    (sectionItem.additional_information
                                        .is_negotiable ??
                                        true),
                                showField:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                required:
                                    sectionItem.is_mandatory &&
                                    sectionItem.additional_information
                                        .is_visible,
                            },
                            standardFieldCategory: 'STANDARD',
                        };
                    } else {
                        response.sections.VISIT_DETAILS.fieldList.push({
                            field: sectionItem.name,
                            fieldType: 'CUSTOM',
                        });

                        let formattedData = {
                            label: sectionItem.alternate_name
                                ? sectionItem.alternate_name
                                : sectionItem.name,
                            description: sectionItem.description,
                            buyerSettings: {
                                required: sectionItem.is_required,
                                showField:
                                    !(
                                        sectionItem.additional_information
                                            .is_hidden ?? false
                                    ) &&
                                    (sectionItem.additional_information
                                        .is_visible ??
                                        true),
                            },
                            sellerSettings: {
                                negotiable:
                                    !(
                                        sectionItem.additional_information
                                            .is_hidden ?? false
                                    ) &&
                                    (sectionItem.additional_information
                                        .is_visible ??
                                        true) &&
                                    (sectionItem.additional_information
                                        .is_negotiable ??
                                        true),
                                required:
                                    sectionItem.is_mandatory &&
                                    sectionItem.additional_information
                                        .is_visible,
                                showField:
                                    sectionItem.additional_information
                                        .is_visible ?? true,
                                isMandatory: true,
                            },
                            type: convertTemplateConstraintsItemToCustomFIeldType(
                                sectionItem.constraints.field_type
                            ),
                            constraints: getFieldTypeContraintsFromBackend({
                                field_type: sectionItem.constraints.field_type,
                                choices: sectionItem.constraints.choices,
                                max_limit: sectionItem.constraints.max_limit,
                                min_limit: sectionItem.constraints.min_limit,
                                choice_type:
                                    sectionItem.constraints.choice_type,
                            }),
                        };
                        response.fieldSettings.customFields = {
                            ...response.fieldSettings.customFields,
                            [sectionItem.name]: formattedData,
                        };
                    }
                });
                return response;
            },
        }),

        createVisitApi: build.mutation<any, { payload: ICreateVisit }>({
            query: ({ payload }) => {
                return {
                    url: '/visit/create/',
                    method: 'POST',
                    body: payload,
                };
            },
        }),

        getCustomerAddresses: build.query<IBuyerDetails, any>({
            query: ({ enterprise_buyer_master_id }) => ({
                url: `/organization/buyer_master/${enterprise_buyer_master_id}/admin/`,
            }),
        }),
        getVisitTemplateList: build.query<any, {}>({
            query: () => `/module_templates/?template_type=VISIT`,
            transformResponse: (baseQueryReturnValue: any) => {
                const data = cloneDeep(baseQueryReturnValue);
                return data[0].templates;
            },
        }),
    }),
});

export const {
    useGetVisitTemplateByIdQuery,
    useCreateVisitApiMutation,
    useGetCustomerAddressesQuery,
    useGetVisitTemplateListQuery,
} = visitApiSlice;
