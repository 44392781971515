import { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, IGlobalAppStore } from '../../Redux/Store';
import { AuthContext } from '../../Contexts/AuthContext';
import {
    IOnboardingCheckListData,
    OnboardingCheckListSubMenuKeys,
    fetchCheckListData,
    setTrueChecklistData,
} from '../Slices/OnBoardingEventChecklist';
import { updateOnboardingInformation } from '../../Organizations/Admin/Services/EnterpriseService';
import { nestedDeepEqual } from '../../Global/Helpers/helpers';

const useEventOnboardingChecklist = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { authData, checkPermission } = useContext(AuthContext);
    const onboardingCheckList: IOnboardingCheckListData[] = useSelector(
        (state: IGlobalAppStore) => state.OnBoardingChecklistStore,
        nestedDeepEqual
    );
    const [checkListFullfilled, setCheckListFullfilled] = useState<boolean>(
        !(
            checkPermission('BUYER', 'EVENT', 'APPROVE', null) &&
            checkPermission('BUYER', 'PURCHASE_ORDER', 'APPROVE', null)
        ) ||
            authData.details?.onboarding_information?.checklist ||
            onboardingCheckList.every((module) => {
                return module.checked;
            })
    );

    const getLatestOnOnboardingChecklist =
        useCallback((): OnboardingCheckListSubMenuKeys | null => {
            if (!checkListFullfilled) {
                for (let i = 0; i < onboardingCheckList.length; i++) {
                    const menuItem = onboardingCheckList[i];
                    if (!menuItem.checked) {
                        for (let j = 0; j < menuItem.subMenus.length; j++) {
                            const subMenu = menuItem.subMenus[j];
                            if (!subMenu.subMenuChecked) {
                                return subMenu.subMenuKey;
                            }
                        }
                    }
                }
                return null;
            }
            return null;
        }, [checkListFullfilled, onboardingCheckList]);

    const callChecklistData = useCallback(() => {
        if (!checkListFullfilled && authData.details?.role)
            dispatch(fetchCheckListData(authData.details?.role));
    }, [checkListFullfilled, authData.details?.role, dispatch]);

    const skipChecklist = useCallback(async () => {
        if (!checkListFullfilled && authData.details?.role) {
            await updateOnboardingInformation({
                onboarding_information: {
                    onboarding:
                        authData?.details?.onboarding_information.onboarding ??
                        false,
                    welcome_pop_up:
                        authData?.details?.onboarding_information
                            .welcome_pop_up ?? false,
                    checklist: true,
                },
            });
            dispatch(
                setTrueChecklistData({
                    type: 'SET_TRUE',
                    data: onboardingCheckList,
                })
            );
            setCheckListFullfilled(true);
        }
    }, [
        dispatch,
        checkListFullfilled,
        authData.details?.role,
        authData?.details?.onboarding_information,
        onboardingCheckList,
    ]);

    const eventModule = onboardingCheckList[0];
    const [
        EventDefaultOnboardingChecked,
        EventAddressOnboardingChecked,
        EventItemOnboardingChecked,
        EventVendorsOnboardingChecked,
        EventSubmittedOnboardingChecked,
    ] = [
        ...eventModule.subMenus.map(
            (subMenu) => checkListFullfilled || subMenu.subMenuChecked
        ),
    ];

    const negotiateModule = onboardingCheckList[1];
    const [
        negotiateReviewOnboardingChecked,
        negotiateSubmitOnboardingChecked,
        negotiateEndEventOnboardingChecked,
    ] = [
        ...negotiateModule.subMenus.map(
            (subMenu) => checkListFullfilled || subMenu.subMenuChecked
        ),
    ];

    const poModule = onboardingCheckList[2];
    const [poAllocateOnboardingChecked, poReviewOnboardingChecked] = [
        ...poModule.subMenus.map(
            (subMenu) => checkListFullfilled || subMenu.subMenuChecked
        ),
    ];

    // useEffect(() => {
    //     updateOnboardingInformation({
    //         onboarding_information: {
    //             onboarding:
    //                 authData?.details?.onboarding_information.onboarding ??
    //                 false,
    //             welcome_pop_up:
    //                 authData?.details?.onboarding_information.welcome_pop_up ??
    //                 false,
    //             checklist: false,
    //         },
    //     });
    // }, [authData]);

    return {
        skipChecklist,
        checkListFullfilled,
        callChecklistData,
        getLatestOnOnboardingChecklist,
        onboardingCheckList,
        EventDefaultOnboardingChecked,
        EventAddressOnboardingChecked,
        EventItemOnboardingChecked,
        EventSubmittedOnboardingChecked,
        EventVendorsOnboardingChecked,
        negotiateReviewOnboardingChecked,
        negotiateSubmitOnboardingChecked,
        negotiateEndEventOnboardingChecked,
        poAllocateOnboardingChecked,
        poReviewOnboardingChecked,
    };
};

export default useEventOnboardingChecklist;
