import { FormControlLabel, Checkbox } from '@mui/material';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import FWSelect, { OpenDownIcon } from '../../../../Common/FWSelect';
import { planUpgradeTooltipText } from '../../../../FeatureAccess/Utils/planUpgradeTooltipText';
import { ICurrencyDetails } from '../../../../Models/Currency.model';
import {
    IASSettings,
    IAdminSettingModule,
    ASOptionType,
    ASModuleSetting,
} from '../../Interfaces/AdminSettingsInterface';
import { IsFeatureAvailableContext } from '../../Pages/Settings/AdminSettingsHomePage';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { FWTypography } from '../../../../Common/FWTypograhy';

const ModuleSetting = ({
    setting,
    currencies,
    setModuleValues,
    childIndex,
}: {
    setting: IASSettings;
    currencies: ICurrencyDetails[];
    setModuleValues: Dispatch<SetStateAction<IAdminSettingModule>>;
    childIndex: number;
}) => {
    const { settings, option } = setting;

    const isFeatureAvailable = useContext(IsFeatureAvailableContext); // using context from adminSettingsHomePage to eliminate prop drilling
    const { authData } = useContext(AuthContext);

    const handleValueChange = (
        e: any,
        field: 'CHECKBOX' | 'INPUT' | 'SELECT'
    ) => {
        setModuleValues((prevValues) => {
            const s = prevValues.settings[childIndex].settings;
            switch (s.type) {
                case ASOptionType.BOOLEAN:
                    s.enabled = e.target.checked;
                    break;
                case ASOptionType.MAX_CURRENCY:
                    switch (field) {
                        case 'CHECKBOX':
                            s.enabled = e.target.checked;
                            if (!e.target.checked) {
                                s.value = '';
                                s.currencyUid = '';
                            }
                            break;
                        case 'INPUT':
                            s.value = e.target.value;
                            break;
                        case 'SELECT':
                            s.currencyUid = e.target.value;
                            break;
                    }
                    break;
                case ASOptionType.MAX_VALUE:
                    switch (field) {
                        case 'CHECKBOX':
                            s.enabled = e.target.checked;
                            if (!e.target.checked) {
                                s.value = '';
                            }
                            break;
                        case 'INPUT':
                            s.value = e.target.value;
                            break;
                    }
                    break;

                case ASOptionType.CHOICE:
                    s.selected_option = e.target.value;
                    console.log('handlee', s);
                    break;
            }
            return { ...prevValues };
        });
    };

    if (isFeatureAvailable) {
        console.log('setting', setting);
        switch (settings.type) {
            case ASOptionType.BOOLEAN:
                return (
                    <div className="flex flex--aic">
                        <FWTooltip
                            title={
                                (option ===
                                    ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS &&
                                    !isFeatureAvailable(
                                        'feature-event-customattributes-enabled'
                                    )) ||
                                ((option ===
                                    ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE ||
                                    option ===
                                        ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS ||
                                    option ===
                                        ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS ||
                                    option ===
                                        ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS) &&
                                    !isFeatureAvailable(
                                        'feature-admin-eventsettings-enabled'
                                    )) ||
                                (option ===
                                    ASModuleSetting.GR_ALLOW_INV_CREATION &&
                                    !isFeatureAvailable(
                                        'feature-admin-grsettings-enabled'
                                    )) ||
                                ((option ===
                                    ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS ||
                                    option ===
                                        ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE ||
                                    option ===
                                        ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE ||
                                    option ===
                                        ASModuleSetting.QC_GENERATE_SEPARATE_ARN) &&
                                    !isFeatureAvailable(
                                        'feature-admin-qcsettings-enabled'
                                    )) ||
                                ((option ===
                                    ASModuleSetting.PAYMENT_ALLOW_PREPAYMENT_MORE_THAN_PO ||
                                    option ===
                                        ASModuleSetting.PAYMENTS_ALLOW_BEFORE_QC ||
                                    option ===
                                        ASModuleSetting.INVOICE_AUTO_HOLD) &&
                                    !isFeatureAvailable(
                                        'feature-admin-allsettings-enabled'
                                    ))
                                    ? planUpgradeTooltipText.featureUnavailableText
                                    : ''
                            }
                        >
                            <FormControlLabel
                                control={<Checkbox />}
                                label={settings.text}
                                disabled={
                                    authData.details?.role !== 'ADMIN' ||
                                    (option ===
                                        ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS &&
                                        !isFeatureAvailable(
                                            'feature-event-customattributes-enabled'
                                        )) ||
                                    ((option ===
                                        ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS) &&
                                        !isFeatureAvailable(
                                            'feature-admin-eventsettings-enabled'
                                        )) ||
                                    (option ===
                                        ASModuleSetting.GR_ALLOW_INV_CREATION &&
                                        !isFeatureAvailable(
                                            'feature-admin-grsettings-enabled'
                                        )) ||
                                    ((option ===
                                        ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS ||
                                        option ===
                                            ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE ||
                                        option ===
                                            ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE ||
                                        option ===
                                            ASModuleSetting.QC_GENERATE_SEPARATE_ARN) &&
                                        !isFeatureAvailable(
                                            'feature-admin-qcsettings-enabled'
                                        )) ||
                                    ((option ===
                                        ASModuleSetting.PAYMENT_ALLOW_PREPAYMENT_MORE_THAN_PO ||
                                        option ===
                                            ASModuleSetting.PAYMENTS_ALLOW_BEFORE_QC ||
                                        option ===
                                            ASModuleSetting.INVOICE_AUTO_HOLD) &&
                                        !isFeatureAvailable(
                                            'feature-admin-allsettings-enabled'
                                        ))
                                }
                                checked={
                                    ((option ===
                                        ASModuleSetting.PAYMENT_ALLOW_PREPAYMENT_MORE_THAN_PO ||
                                        option ===
                                            ASModuleSetting.PAYMENTS_ALLOW_BEFORE_QC) &&
                                        !isFeatureAvailable(
                                            'feature-admin-allsettings-enabled'
                                        )) ||
                                    ((option ===
                                        ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS) &&
                                        !isFeatureAvailable(
                                            'feature-admin-eventsettings-enabled'
                                        )) ||
                                    (option ===
                                        ASModuleSetting.GR_ALLOW_INV_CREATION &&
                                        !isFeatureAvailable(
                                            'feature-admin-grsettings-enabled'
                                        )) ||
                                    ((option ===
                                        ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS ||
                                        option ===
                                            ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE ||
                                        option ===
                                            ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE ||
                                        option ===
                                            ASModuleSetting.QC_GENERATE_SEPARATE_ARN) &&
                                        !isFeatureAvailable(
                                            'feature-admin-qcsettings-enabled'
                                        ))
                                        ? true //for payments it is checked
                                        : (option ===
                                              ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS &&
                                              !isFeatureAvailable(
                                                  'feature-event-customattributes-enabled'
                                              )) ||
                                          (option ===
                                              ASModuleSetting.INVOICE_AUTO_HOLD &&
                                              !isFeatureAvailable(
                                                  'feature-admin-allsettings-enabled'
                                              ))
                                        ? false
                                        : settings.enabled
                                }
                                onChange={(e) => {
                                    handleValueChange(e, 'CHECKBOX');
                                }}
                            />
                        </FWTooltip>
                        {!isEmpty(settings.tooltip) && (
                            <FWTooltip title={settings.tooltip}>
                                <div>
                                    <FWIcon
                                        name="bi bi-info-circle"
                                        color="primary.main"
                                    />
                                </div>
                            </FWTooltip>
                        )}
                    </div>
                );
            case ASOptionType.MAX_CURRENCY:
                return (
                    <>
                        <div className="flex flex--aic">
                            <FormControlLabel
                                control={<Checkbox />}
                                label={settings.text}
                                checked={settings.enabled}
                                onChange={(e) => {
                                    handleValueChange(e, 'CHECKBOX');
                                }}
                                disabled={authData.details?.role !== 'ADMIN'}
                            />
                            {!isEmpty(settings.tooltip) && (
                                <FWTooltip title={settings.tooltip}>
                                    <div>
                                        <FWIcon
                                            name="bi bi-info-circle"
                                            color="primary.main"
                                        />
                                    </div>
                                </FWTooltip>
                            )}
                        </div>
                        <div className="pl--35 mb--10">
                            <div
                                style={{ width: '355px' }}
                                className="flex flex--aife"
                            >
                                <div
                                    style={{
                                        width: '50%',
                                    }}
                                >
                                    <FWInput
                                        allowOnly="DECIMAL_NUMBER"
                                        label={settings.label}
                                        value={settings.value}
                                        onChange={(e) => {
                                            handleValueChange(e, 'INPUT');
                                        }}
                                        disabled={
                                            authData.details?.role !==
                                                'ADMIN' || !settings.enabled
                                        }
                                        error={
                                            settings.enabled &&
                                            isEmpty(settings.value.trim())
                                        }
                                        sx={{
                                            '.MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderTopRightRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                },
                                        }}
                                    />
                                </div>
                                <FWSelect
                                    sx={{
                                        width: '50%',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }}
                                    value={
                                        currencies.length > 0
                                            ? settings.currencyUid
                                            : ' '
                                    }
                                    onChange={(e) => {
                                        handleValueChange(e, 'SELECT');
                                    }}
                                    disabled={!settings.enabled}
                                    error={
                                        settings.enabled &&
                                        isEmpty(settings.currencyUid)
                                    }
                                >
                                    <FWMenuItem value=" " disabled>
                                        Select currency
                                    </FWMenuItem>
                                    {currencies.map((currency) => {
                                        return (
                                            <FWMenuItem
                                                key={currency.entry_id}
                                                value={currency.entry_id}
                                            >
                                                {
                                                    currency.currency_code_abbreviation
                                                }{' '}
                                                ({currency.currency_symbol})
                                            </FWMenuItem>
                                        );
                                    })}
                                </FWSelect>
                            </div>
                        </div>
                    </>
                );
            case ASOptionType.MAX_VALUE:
                return (
                    <>
                        <div className="flex flex--aic">
                            <FWTooltip
                                title={
                                    (option ===
                                        ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS &&
                                        !isFeatureAvailable(
                                            'feature-event-customattributes-enabled'
                                        )) ||
                                    ((option ===
                                        ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS ||
                                        option ===
                                            ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS) &&
                                        !isFeatureAvailable(
                                            'feature-admin-eventsettings-enabled'
                                        )) ||
                                    (option === ASModuleSetting.GR_TOLERANCE &&
                                        !isFeatureAvailable(
                                            'feature-admin-grsettings-enabled'
                                        )) ||
                                    ((option ===
                                        ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS ||
                                        option ===
                                            ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE ||
                                        option ===
                                            ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE ||
                                        option ===
                                            ASModuleSetting.QC_GENERATE_SEPARATE_ARN) &&
                                        !isFeatureAvailable(
                                            'feature-admin-qcsettings-enabled'
                                        ))
                                        ? planUpgradeTooltipText.featureUnavailableText
                                        : ''
                                }
                            >
                                <FormControlLabel
                                    disabled={
                                        authData.details?.role !== 'ADMIN' ||
                                        (option ===
                                            ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS &&
                                            !isFeatureAvailable(
                                                'feature-event-customattributes-enabled'
                                            )) ||
                                        ((option ===
                                            ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS) &&
                                            !isFeatureAvailable(
                                                'feature-admin-eventsettings-enabled'
                                            )) ||
                                        (option ===
                                            ASModuleSetting.GR_TOLERANCE &&
                                            !isFeatureAvailable(
                                                'feature-admin-grsettings-enabled'
                                            )) ||
                                        ((option ===
                                            ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS ||
                                            option ===
                                                ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE ||
                                            option ===
                                                ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE ||
                                            option ===
                                                ASModuleSetting.QC_GENERATE_SEPARATE_ARN) &&
                                            !isFeatureAvailable(
                                                'feature-admin-qcsettings-enabled'
                                            ))
                                    }
                                    control={<Checkbox />}
                                    label={settings.text}
                                    checked={
                                        (option ===
                                            ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS &&
                                            !isFeatureAvailable(
                                                'feature-event-customattributes-enabled'
                                            )) ||
                                        ((option ===
                                            ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS) &&
                                            !isFeatureAvailable(
                                                'feature-admin-eventsettings-enabled'
                                            )) ||
                                        (option ===
                                            ASModuleSetting.GR_TOLERANCE &&
                                            !isFeatureAvailable(
                                                'feature-admin-grsettings-enabled'
                                            )) ||
                                        ((option ===
                                            ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS ||
                                            option ===
                                                ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE ||
                                            option ===
                                                ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE ||
                                            option ===
                                                ASModuleSetting.QC_GENERATE_SEPARATE_ARN) &&
                                            !isFeatureAvailable(
                                                'feature-admin-qcsettings-enabled'
                                            ))
                                            ? true
                                            : settings.enabled
                                    }
                                    onChange={(e: any) => {
                                        handleValueChange(e, 'CHECKBOX');
                                    }}
                                />
                            </FWTooltip>
                            {!isEmpty(settings.tooltip) && (
                                <FWTooltip title={settings.tooltip}>
                                    <div>
                                        <FWIcon
                                            name="bi bi-info-circle"
                                            color="primary.main"
                                        />
                                    </div>
                                </FWTooltip>
                            )}
                        </div>
                        <div className="pl--35 mb--10">
                            <div style={{ width: '355px' }}>
                                <FWInput
                                    allowOnly="DECIMAL_NUMBER"
                                    label={settings.label}
                                    value={
                                        (option ===
                                            ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS &&
                                            !isFeatureAvailable(
                                                'feature-event-customattributes-enabled'
                                            )) ||
                                        ((option ===
                                            ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS) &&
                                            !isFeatureAvailable(
                                                'feature-admin-eventsettings-enabled'
                                            )) ||
                                        (option ===
                                            ASModuleSetting.GR_TOLERANCE &&
                                            !isFeatureAvailable(
                                                'feature-admin-grsettings-enabled'
                                            )) ||
                                        ((option ===
                                            ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS ||
                                            option ===
                                                ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE ||
                                            option ===
                                                ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE ||
                                            option ===
                                                ASModuleSetting.QC_GENERATE_SEPARATE_ARN) &&
                                            !isFeatureAvailable(
                                                'feature-admin-qcsettings-enabled'
                                            ))
                                            ? 0
                                            : settings.value
                                    }
                                    sx={{
                                        width: '50%',
                                        mr: '5px',
                                    }}
                                    onChange={(e) => {
                                        if (+e.target.value <= 100) {
                                            handleValueChange(e, 'INPUT');
                                        } else {
                                        }
                                        // handleValueChange(e, 'INPUT');
                                    }}
                                    disabled={
                                        authData.details?.role !== 'ADMIN' ||
                                        (option ===
                                            ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS &&
                                            !isFeatureAvailable(
                                                'feature-event-customattributes-enabled'
                                            )) ||
                                        !settings.enabled ||
                                        ((option ===
                                            ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS ||
                                            option ===
                                                ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS) &&
                                            !isFeatureAvailable(
                                                'feature-admin-eventsettings-enabled'
                                            )) ||
                                        (option ===
                                            ASModuleSetting.GR_TOLERANCE &&
                                            !isFeatureAvailable(
                                                'feature-admin-grsettings-enabled'
                                            )) ||
                                        ((option ===
                                            ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS ||
                                            option ===
                                                ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE ||
                                            option ===
                                                ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE ||
                                            option ===
                                                ASModuleSetting.QC_GENERATE_SEPARATE_ARN) &&
                                            !isFeatureAvailable(
                                                'feature-admin-qcsettings-enabled'
                                            ))
                                    }
                                    error={
                                        settings.enabled &&
                                        isEmpty(settings.value.trim())
                                    }
                                />
                            </div>
                        </div>
                    </>
                );
            case ASOptionType.CHOICE:
                return (
                    <div
                        style={{
                            marginLeft: '32px',
                        }}
                    >
                        <FWTypography>
                            Approval hierarchy to be followed for the quote
                            approval process
                        </FWTypography>

                        <FWSelect
                            placeholder="Select an option"
                            IconComponent={OpenDownIcon}
                            sx={{
                                minWidth: 300,
                                marginTop: '5px',
                            }}
                            size="small"
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        bgcolor: 'white',
                                        maxHeight: '300px',
                                        boxShadow:
                                            '0px 5px 16px #00000024!important;',
                                        width: '300px',
                                    },
                                },
                            }}
                            value={settings.selected_option}
                            onChange={(e) => handleValueChange(e, 'SELECT')}
                        >
                            <FWMenuItem value="CREATOR">Creator</FWMenuItem>
                            <FWMenuItem value="FIRST_SUBMITTED_BY">
                                First Quote Submitted by
                            </FWMenuItem>
                        </FWSelect>
                    </div>
                );
            default:
                return <></>;
        }
    } else {
        return <></>;
    }
};

export default ModuleSetting;
