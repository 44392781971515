import { ICustomTemplateList } from '../../../Global/Interfaces/CustomTemplate.interface';

export interface IAdminSettingsSkipApproval {
    amount: string;
    currencyUid: string;
    currencySymbol: string;
    currency: string;
}

export type CustomTemplateItemTypes =
    | 'text'
    | 'heading'
    | 'date'
    | 'number'
    | '';

export interface IAdminCustomField {
    name: string;
    type: CustomTemplateItemTypes;
    mandatory: boolean;
}

export interface IChecklistSummary {
    title: string;
    checklistFields: IAdminCustomField[];
}

export interface IChecklistFieldData {
    mandatory: boolean;
    value: string;
    type: CustomTemplateItemTypes;
}

export interface IChecklistRowData {
    [key: string]: IChecklistFieldData;
}

export interface IChecklistData {
    [key: string]: IChecklistRowData;
}

export enum ASModule {
    // removed this for prod
    // ADMIN = 'ADMIN',
    EVENT = 'EVENT',
    PURCHASE_ORDER = 'PURCHASE_ORDER',
    INVOICE = 'INVOICE',
    GOODS_RECEIPT = 'GOODS_RECEIPT',
    QUALITY_CHECK = 'QUALITY_CHECK',
    PAYMENT = 'PAYMENT',
    REQUISITION = 'REQUISITIONS',
    PROJECT = 'PROJECT',
    QUOTE_CALCULATOR = 'QUOTE_CALCULATOR',
}

export enum ASModuleSetting {
    // admin
    // removed this for prod
    // MAX_DECIMAL_PRECISION = 'MAX_DECIMAL_PRECISION',

    // Event
    EVENT_ALLOW_CUSTOM_SPECS = 'EVENT_ALLOW_CUSTOM_SPECS',
    EVENT_ALLOW_SHARE_TARGET_PRICE = 'EVENT_ALLOW_SHARE_TARGET_PRICE',
    EVENT_ALLOW_ADDING_ITEMS = 'EVENT_ALLOW_ADDING_ITEMS',
    EVENT_PRICE_LIMIT = 'EVENT_PRICE_LIMIT',
    EVENT_ALLOW_NON_PREFERRED_VENDORS = 'EVENT_ALLOW_NON_PREFERRED_VENDORS',
    // The below setting means that the user can add a vendor to the event
    // even if they are not present in the enterprise vendor master list (i.e. by the search bar on add items page)
    EVENT_ALLOW_INVITING_VENDORS = 'EVENT_ALLOW_INVITING_VENDORS',
    EVENT_ALLOW_ADDING_ADDRESS = 'EVENT_ALLOW_ADDING_ADDRESS',
    EVENT_AWARD_TOLERANCE = 'EVENT_AWARD_TOLERANCE',
    EVENT_MANDATORY_EXCLUDE_ITEM_NOTE = 'EVENT_MANDATORY_EXCLUDE_ITEM_NOTE',
    EVENT_IS_SELLER_ALLOWED_TO_RESPOND_TO_EXCLUDED_ITEMS = 'EVENT_IS_SELLER_ALLOWED_TO_RESPOND_TO_EXCLUDED_ITEMS',
    // for combine bom
    COMBINE_BOM_ITEMS = 'COMBINE_BOM_ITEMS',
    EVENT_SHOW_ALTERNATES_SELLER = 'EVENT_SHOW_ALTERNATES_SELLER',

    // PO
    PO_ISSUE_VALUE_LIMIT = 'PO_ISSUE_VALUE_LIMIT',
    PO_TOLERANCE = 'PO_TOLERANCE',

    // Invoice
    INVOICE_AUTO_HOLD = 'INVOICE_AUTO_HOLD',

    // GR
    GR_ALLOW_INV_CREATION = 'GR_ALLOW_INV_CREATION',
    GR_TOLERANCE = 'GR_TOLERANCE',

    // QC
    QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS = 'QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS',
    QC_ALLOW_UNVERIFIED_INVOICE = 'QC_ALLOW_UNVERIFIED_INVOICE',
    QC_PRIMARY_SAMPLE_PERCENTAGE = 'QC_PRIMARY_SAMPLE_PERCENTAGE',
    // QC_GENERATE_AR_POST_GR = 'QC_GENERATE_AR_POST_GR',
    QC_GENERATE_SEPARATE_ARN = 'QC_GENERATE_SEPARATE_ARN',

    // Payment
    PAYMENT_ADVANCE_DAYS_COUNT = 'PAYMENT_ADVANCE_DAYS_COUNT',
    PAYMENT_ALLOW_PREPAYMENT_MORE_THAN_PO = 'PAYMENT_ALLOW_PREPAYMENT_MORE_THAN_PO',
    PAYMENTS_ALLOW_BEFORE_QC = 'PAYMENTS_ALLOW_BEFORE_QC',
    PAYMENT_ALLOW_UNVERIFIED_INVOICE = 'PAYMENT_ALLOW_UNVERIFIED_INVOICE',

    //Requisiitons
    REQUISITION_ALLOW_PRICING_ANALYTICS = 'REQUISITION_ALLOW_PRICING_ANALYTICS',

    //Project
    PROJECT_ALLOW_INVITING_CUSTOMERS = 'PROJECT_ALLOW_INVITING_CUSTOMERS',

    // Quote Calculator
    COSTING_SHEET_ASSIGN_PROJECT_CREATOR = 'COSTING_SHEET_ASSIGN_PROJECT_CREATOR',
    COSTING_SHEET_APPROVAL_BASED_ON = 'COSTING_SHEET_APPROVAL_BASED_ON',
}

export enum ASOptionType {
    BOOLEAN = 'BOOLEAN',
    MAX_CURRENCY = 'MAX_CURRENCY',
    MAX_VALUE = 'MAX_VALUE',
    CHOICE = 'CHOICE',
}

interface ICommonASOptions {
    text: string;
    tooltip: string;
    enabled: boolean;
}

export interface IASBooleanOption extends ICommonASOptions {
    type: ASOptionType.BOOLEAN;
}

export interface IASCurrencyOption extends ICommonASOptions {
    type: ASOptionType.MAX_CURRENCY;
    label: string;
    value: string;
    currency: string;
    currencyUid: string;
    currencySymbol: string;
    currencyName: string;
}
export interface IASPercentageOption extends ICommonASOptions {
    type: ASOptionType.MAX_VALUE;
    label: string;
    value: string;
}
export interface IASChoiceOption extends ICommonASOptions {
    type: ASOptionType.CHOICE;
    label: string;
    value: string;
    selected_option: string;
}

export type ASOptionTypes =
    | IASBooleanOption
    | IASCurrencyOption
    | IASPercentageOption
    | IASChoiceOption;

export interface IASSettings {
    option: ASModuleSetting;
    settings: ASOptionTypes;
}

export interface IAdminSettingModule {
    module: ASModule;
    settings: IASSettings[];
}

export type IAdminCustomFieldsAndChecklist = {
    [key in ASModule]: {
        customFields: ICustomTemplateList[];
        checklist: ICustomTemplateList[];
    };
};
