import { Box, Chip, Typography } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';

type Props = {
    tags: string[];
    maxTags?: number;
    strictMode?: boolean;
};

const TagsDisplay = ({ tags, maxTags = 3, strictMode = false }: Props) => {
    console.log('tags', tags);
    return (
        <>
            <Box sx={{ display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
                {tags
                    .slice(
                        0,
                        strictMode
                            ? maxTags
                            : tags.length === maxTags + 1
                            ? maxTags + 1
                            : maxTags
                    )
                    .map((tag, idx) => {
                        return (
                            <Chip
                                key={tag + idx}
                                size="small"
                                variant="outlined"
                                label={tag}
                                sx={{
                                    marginRight: '0.25rem',
                                    backgroundColor: 'rgba(35, 131, 226, 0.03)',
                                    color: 'rgb(35, 131, 226)',
                                    border: ' 1px solid rgba(35, 131, 226, 0.35)',
                                    lineHeight: '1.5rem',
                                }}
                            />
                        );
                    })}

                {tags.length > maxTags + 1 && (
                    <FWTooltip title={`${tags.slice(maxTags).join(', ')}`}>
                        <Typography color={'primary'}>
                            {`+${tags.slice(maxTags).length}`}
                        </Typography>
                    </FWTooltip>
                )}
            </Box>

            {/* {tags.length > maxTags + 1 && (
                <FWTooltip title={`${tags.slice(maxTags).join(', ')}`}>
                    <Chip
                        variant="outlined"
                        color="primary"
                        label={`+${tags.slice(maxTags).length}`}
                        sx={{
                            borderRadius: '16px!important',
                            marginRight: '0.25rem',
                            backgroundColor: 'rgba(35, 131, 226, 0)',
                            color: 'rgb(35, 131, 226)',
                            border: '0px',
                            height: '24px',
                        }}
                    />
                </FWTooltip>
            )} */}
        </>
    );
};

export default TagsDisplay;
