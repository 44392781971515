import {
    Box,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Fade,
    Grid,
    Popper,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FWButton } from '../../../Common/FWButton';
import FWIcon from '../../../Common/FWIcon';
import { FWTypography } from '../../../Common/FWTypograhy';
import { CustomFieldType } from '../../../Global/Interfaces/TemplateInterface';
import { FieldTypeLabel } from '../../constants/projectDashboardConstants';
import {
    BLANK_DASHBOARD_SORT,
    formatDashboardSortForBE,
    formatDashboardSortForFE,
} from '../../constants/projectDashboardSortConstants';
import { DashboardSort } from '../../interface/project.model';
import ProjectDashboardSortFieldRow from './ProjectDashboardSortFieldRow';
import ProjectDashboardSortFieldRowContent from './ProjectDashboardSortFieldRowContent';

export interface IDashboardSortPopupProps {
    open: boolean;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
    fieldTypeLabels: FieldTypeLabel[];
    isLoading: boolean;
    handleSortApply: (filter: DashboardSort[]) => void;
    defaultValue: DashboardSort[];
    currentTab: string;
    builtInFieldsMap: Record<string, string>;
    filterFieldToNameMap: Record<string, string>;
    onSortStateChange?: (isSorted: boolean) => void;
    dashboardType: string;
}

const DashboardSortPopup = ({
    anchorEl,
    fieldTypeLabels,
    builtInFieldsMap,
    filterFieldToNameMap,
    open,
    handleSortApply,
    handleClose,
    defaultValue,
    currentTab,
    isLoading,
    onSortStateChange,
    dashboardType,
}: IDashboardSortPopupProps) => {
    console.log('artistt', fieldTypeLabels);
    const [dashboardSort, setDashboardSort] = useState<DashboardSort[]>([
        BLANK_DASHBOARD_SORT,
    ]);
    const [isClickAwayEnabled, setIsClickAwayEnabled] = useState(false);

    useEffect(() => {
        setDashboardSort((prev) => {
            const updatedSort =
                defaultValue.length === 0
                    ? [BLANK_DASHBOARD_SORT]
                    : formatDashboardSortForFE(
                          cloneDeep(defaultValue),
                          filterFieldToNameMap,
                          currentTab,
                          dashboardType
                      );
            if (onSortStateChange) {
                onSortStateChange(
                    updatedSort.some((sort) => Boolean(sort.field))
                );
            }

            return updatedSort;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, defaultValue, filterFieldToNameMap, onSortStateChange]);

    // useEffect(() => {
    //     setDashboardSort((prev) => {
    //         const updatedSort =
    //             defaultValue.length === 0
    //                 ? [BLANK_DASHBOARD_SORT]
    //                 : formatDashboardSortForFE(
    //                       cloneDeep(defaultValue),
    //                       filterFieldToNameMap,
    //                       currentTab
    //                   );
    //         // Notify parent of the sort state
    //         if (onSortStateChange) {
    //             onSortStateChange(
    //                 updatedSort.some((sort) => Boolean(sort.field))
    //             );
    //         }
    //         return updatedSort;
    //     });
    // }, [currentTab, defaultValue, filterFieldToNameMap, onSortStateChange]);

    const updateSortList = useCallback(
        (
            index,
            updatedField:
                | {
                      type: 'FIELD';
                      fieldType: CustomFieldType;
                      value: string;
                      isBuiltIn: boolean | null;
                  }
                | {
                      type: 'ORDER';
                      ascending: boolean;
                  }
        ) => {
            setDashboardSort((prev) => {
                let newPrev = cloneDeep(prev);

                if (updatedField.type === 'FIELD') {
                    newPrev[index].field = updatedField.value;
                    newPrev[index].type = updatedField.fieldType;
                    newPrev[index].field_type = updatedField.isBuiltIn
                        ? 'BUILTIN'
                        : updatedField.isBuiltIn === null
                        ? null
                        : 'CUSTOM_FIELD';
                } else {
                    newPrev[index].ascending = updatedField.ascending;
                }
                return newPrev;
            });
        },
        [setDashboardSort]
    );

    const reorderSortRows = useCallback(
        (originalRowIndex: number, newRowIndex: number) => {
            setDashboardSort((prev) => {
                let newPrev = cloneDeep(prev);

                const [cost] = newPrev.splice(originalRowIndex, 1);

                newPrev.splice(newRowIndex, 0, cost);

                return newPrev;
            });
        },
        [setDashboardSort]
    );

    const handleAddSortRowClick = useCallback(() => {
        setDashboardSort((prev) => [...prev, BLANK_DASHBOARD_SORT]);
    }, [setDashboardSort]);

    const handleResetSort = useCallback(() => {
        setDashboardSort([BLANK_DASHBOARD_SORT]);
        handleSortApply([]);
        handleClose();
    }, [handleClose, handleSortApply, setDashboardSort]);

    const removeSortRow = useCallback(
        (indexToBeRemoved) => {
            setDashboardSort((prev) => {
                let newPrev = cloneDeep(prev);

                newPrev.splice(indexToBeRemoved, 1);

                return newPrev;
            });
        },
        [setDashboardSort]
    );

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading((prev) => {
            if (prev && !isLoading) handleClose();

            return isLoading;
        });
    }, [handleClose, isLoading]);

    const selectedFields = dashboardSort.map((sort) => sort.field);

    // const isSorted = dashboardSort.some((sort) => Boolean(sort.field));
    useEffect(() => {
        if (open) {
            setTimeout(() => setIsClickAwayEnabled(true), 80);
        } else {
            setIsClickAwayEnabled(false);
        }
    }, [open]);

    console.log('cloneDeep', cloneDeep(dashboardSort));

    return (
        <ClickAwayListener
            onClickAway={isClickAwayEnabled ? handleClose : () => {}}
        >
            <Popper
                id={'sortPopper'}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                transition
                style={{
                    // top: '300px',
                    // left: '200px',

                    zIndex: 10,
                    borderRadius: '15px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={0}>
                        <Box
                            sx={{
                                minWidth: 600,
                                bgcolor: '#fff',
                                boxShadow: 24,
                                p: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'flex-start',
                                borderRadius: '15px',
                                border: '1px secondary solid',
                            }}
                        >
                            <FWTypography
                                variant="h5"
                                fontSize={15}
                                sx={{ ml: 0.5 }}
                            >
                                Apply Sorting
                            </FWTypography>

                            <Divider sx={{ width: '100%', my: 1.5 }} />
                            <DragDropContext
                                onDragEnd={(result) => {
                                    if (!result.destination) return;
                                    reorderSortRows(
                                        result.source.index,
                                        result.destination.index
                                    );
                                }}
                            >
                                <Droppable
                                    droppableId={
                                        'sort-row' +
                                        Math.random() +
                                        Math.random()
                                    }
                                    mode="virtual"
                                    renderClone={(
                                        provided,
                                        snapshot,
                                        rubric
                                    ) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <ProjectDashboardSortFieldRowContent
                                                fieldTypeLabels={
                                                    fieldTypeLabels
                                                }
                                                sortRow={
                                                    dashboardSort[
                                                        rubric.source.index
                                                    ]
                                                }
                                                singleSortField={
                                                    dashboardSort.length === 1
                                                }
                                                removeSortRow={removeSortRow}
                                                index={rubric.source.index}
                                                updateSortList={updateSortList}
                                                provided={provided}
                                                selectedFields={selectedFields}
                                            />
                                            <div> </div>
                                        </div>
                                    )}
                                >
                                    {(provided, snapshot) => (
                                        <Grid
                                            container
                                            direction={'column'}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            sx={{
                                                height: 'max-content',
                                            }}
                                        >
                                            {dashboardSort.map(
                                                (sortRow, index) => (
                                                    <ProjectDashboardSortFieldRow
                                                        selectedFields={
                                                            selectedFields
                                                        }
                                                        sortRow={sortRow}
                                                        fieldTypeLabels={
                                                            fieldTypeLabels
                                                        }
                                                        singleSortField={
                                                            dashboardSort.length ===
                                                            1
                                                        }
                                                        removeSortRow={
                                                            removeSortRow
                                                        }
                                                        index={index}
                                                        updateSortList={
                                                            updateSortList
                                                        }
                                                    />
                                                )
                                            )}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            {/* <Box
                                sx={{
                                    // mt: 1,
                                    mr: 1,
                                    marginLeft: 'auto',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    gap: 1.5, // Add some space between the buttons
                                }}
                            > */}
                            <FWButton
                                variant="text"
                                onClick={handleAddSortRowClick}
                                disabled={dashboardSort.some(
                                    (sort) => !Boolean(sort.field)
                                )}
                                sx={{
                                    display: 'flex',
                                    gap: 0.5,
                                    alignItems: 'center',
                                    color: 'black',
                                }}
                            >
                                <FWIcon size={24} name={'bi bi-plus'} />
                                <FWTypography
                                    variant="h5"
                                    fontSize={15}
                                    sx={{ textTransform: 'none' }}
                                >
                                    Add sort rows
                                </FWTypography>
                            </FWButton>
                            {/* </Box> */}

                            <Divider sx={{ width: '100%', my: 1.5 }} />

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <FWButton
                                    variant="text"
                                    onClick={handleResetSort}
                                    disabled={
                                        dashboardSort.length === 1 &&
                                        !Boolean(dashboardSort[0].field)
                                    }
                                >
                                    <FWTypography
                                        variant="h5"
                                        fontSize={15}
                                        color={'black'}
                                        sx={{ textTransform: 'none' }}
                                    >
                                        Clear sorting
                                    </FWTypography>
                                </FWButton>

                                <div style={{ display: 'flex' }}>
                                    <FWButton
                                        sx={{
                                            mt: 1,
                                            mr: 1,
                                            marginLeft: 'auto',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            paddingX: 2,
                                        }}
                                        variant="outlined"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </FWButton>

                                    <FWButton
                                        sx={{
                                            mt: 1,
                                            mr: 1,
                                            marginLeft: 'auto',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            paddingX: 2,
                                        }}
                                        variant="contained"
                                        disabled={
                                            dashboardSort.some(
                                                (sort) => !Boolean(sort.field)
                                            ) || loading
                                        }
                                        onClick={() => {
                                            const formatedDashboardSort =
                                                formatDashboardSortForBE(
                                                    dashboardSort,
                                                    builtInFieldsMap
                                                );

                                            handleSortApply(
                                                cloneDeep(formatedDashboardSort)
                                            );
                                        }}
                                    >
                                        {loading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            'Apply'
                                        )}
                                    </FWButton>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </ClickAwayListener>
    );
};

export default memo(DashboardSortPopup);
