import { Box, Checkbox, Chip, IconButton, Popover } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { capitaliseFirstLetter } from '../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import FWAutocomplete, {
    AutocompleteStyledPaper,
} from '../../Common/FWAutocomplete';
import { FWMenuItem } from '../../Common/FWCustomMenu';
import { FWInput } from '../../Common/FWInput';
import { FWTypography } from '../../Common/FWTypograhy';
import SingleFieldTypeRenderer from '../../FieldTypes/components/SingleFieldTypeRenderer';
import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';
import { PROJECT_DASHBOARD_FIELD_DELIMITER } from '../helpers/projectDashboardHelper';
import {
    DashBoardFilter,
    FilterCondition,
    TFilterType,
} from '../interface/project.model';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import '../../Theme/customeDateRange.css';
import { useEffect, useState } from 'react';
import { statusDisplayNameMap } from '../../Components/Buyer/Events/Dashboard/constants';

export interface IFilterColumnMenuList {
    id: string;
    label: string;
    column: string | null;
    checked: boolean;
    sectionName: string | null;
    isFWField: boolean;
    isBuiltIn: boolean;
    width: number | undefined;
    fieldType: CustomFieldType;
    fields: string | null;
}

export const BLANK_DASHBOARD_FILTER: FilterCondition = {
    condition_type: null as any,
    datetime_from: null,
    datetime_to: null,
    field: '',
    field_type: 'BUILTIN',
    section: null,
    type: CustomFieldType.SHORTTEXT,
    value: null,
    value_from: null,
    value_to: null,
    values: [],
};

export interface Condition {
    id: TConditionsValue;
    label: string;
}

export interface FieldTypeLabel {
    label: string;
    column: string;
    choices: string[];
    fieldType: string;
    is_built_in: boolean | null;
    sectionName?: string | null;
}

export type TFilterUpdateParams =
    | {
          key: 'FIELD';
          value: string;
          isBuiltIn: boolean | null;
          type: CustomFieldType | null;
          section: string | null;
      }
    | { key: 'CONDITION'; value: TConditionsValue }
    | { key: 'VALUE'; value: TFilterUpdatedValue };

export type TConditionsValue =
    | 'RANGE'
    | 'CONTAINS'
    | 'NOT_CONTAINS'
    | 'EQUALS'
    | 'NOT_EQUALS'
    | 'STARTS_WITH'
    | 'ENDS_WITH'
    | 'BLANK'
    | 'NOT_BLANK'
    | 'GREATER_THAN'
    | 'LESS_THAN'
    | 'GREATER_THAN_EQUALS'
    | 'LESS_THAN_EQUALS';

export const builtInFieldsMap: Record<string, string> = {
    'Project code': 'project_code',
    'Project name': 'project_name',
    'Project status': 'project_status',
    'Project Manager(s)': 'project_managers',
    Creator: 'created_by_user_name',
    Status: 'status',
    'Submission status': 'project_status',
    'Created on': 'created_datetime',
    'Submitted on': 'modified_datetime',
    'Submitted by': 'modified_by_user_name',
    'Last modified by': 'modified_by_user_name',
    'Modified on': 'modified_datetime',
    'Start Date': 'validity_from',
    'Start date': 'validity_from',
    'End Date': 'validity_to',
    'End date': 'validity_to',
    'Entity name': 'buyer_entity',
    'Customer name': 'customer_entity',
    Description: 'description',
    'Internal notes': 'internal_notes',
    Project: 'project_name',
    Entity: 'buyer_entity_name',
    ACreator: 'created_by',
};

export const filterFieldToNameMap: Record<string, string> = {
    created_by: 'ACreator',
    buyer_entity_name: 'Entity',
    project_name: 'Project name',
    project_code: 'Project code',
    project_status: 'Submission status',
    project_managers: 'Project Manager(s)',
    created_by_user_name: 'Creator',
    status: 'Status',
    created_datetime: 'Created on',
    modified_datetime: 'Submitted on',
    modified_by_user_name: 'Submitted by',
    validity_from: 'Start date',
    validity_to: 'End date',
    buyer_entity: 'Entity name',
    customer_entity: 'Customer name',
    description: 'Description',
    internal_notes: 'Internal notes',
};

export type TFilterUpdatedValue =
    | {
          type: CustomFieldType.BOOLEAN;
          filterOption: TConditionsValue;
          value: string[];
      }
    | {
          type: CustomFieldType.FLOAT;
          filterOption: TConditionsValue;
          value: string | null;
      }
    | {
          type: CustomFieldType.DATETIME | CustomFieldType.DATE;
          filterOption: 'RANGE';
          value_from: string | null;
          value_to: string | null;
      }
    | {
          type: CustomFieldType.DATETIME | CustomFieldType.DATE;
          filterOption: Exclude<TConditionsValue, 'RANGE'>;
          value: string | null;
      }
    | {
          type: CustomFieldType.MULTI_CHOICE;
          filterOption: TConditionsValue;
          value: string[];
      }
    | {
          type: CustomFieldType.CHOICE;
          filterOption: TConditionsValue;
          value: string[];
      }
    | {
          type: CustomFieldType.INTEGER | CustomFieldType.DECIMAL;
          filterOption: TConditionsValue;
          value: string | null;
      }
    | {
          type: CustomFieldType.SHORTTEXT;
          filterOption: TConditionsValue;
          value: string | null;
      }
    | {
          type: CustomFieldType.CURRENCY;
          filterOption: TConditionsValue;
          value: string | null;
      }
    | {
          type: CustomFieldType.LONGTEXT;
          filterOption: TConditionsValue;
          value: string | null;
      }
    | {
          type: CustomFieldType.SHORT_TEXT;
          filterOption: TConditionsValue;
          value: string | null;
      }
    | {
          type: CustomFieldType.LONG_TEXT;
          filterOption: TConditionsValue;
          value: string | null;
      };

export const handleFilterValueChange = (
    filterData: FilterCondition,
    updatedValue: TFilterUpdatedValue
) => {
    let updatedFilterData = cloneDeep(filterData);
    switch (updatedValue.type) {
        case CustomFieldType.DATE:
        case CustomFieldType.DATETIME:
            if (updatedValue.filterOption === 'RANGE') {
                updatedFilterData.datetime_from = updatedValue.value_from;
                updatedFilterData.datetime_to = updatedValue.value_to;
            } else {
                updatedFilterData.datetime_from = updatedValue.value;
            }
            break;
        case CustomFieldType.SHORT_TEXT:
        case CustomFieldType.LONGTEXT:
        case CustomFieldType.LONG_TEXT:
        case CustomFieldType.SHORTTEXT:
            updatedFilterData.value = updatedValue.value;
            break;
        case CustomFieldType.DECIMAL:
        case CustomFieldType.INTEGER:
        case CustomFieldType.FLOAT:
        case CustomFieldType.CURRENCY:
            updatedFilterData.value_from = updatedValue.value;
            break;
        case CustomFieldType.MULTI_CHOICE:
        case CustomFieldType.CHOICE:
        case CustomFieldType.BOOLEAN:
            updatedFilterData.values = updatedValue.value;
    }

    return updatedFilterData;
};

export const getConditions = (field_type: CustomFieldType): Condition[] => {
    switch (field_type) {
        case 'LONGTEXT':
        case CustomFieldType.LONG_TEXT:
        case CustomFieldType.SHORT_TEXT:
        case 'SHORTTEXT':
            return [
                { id: 'CONTAINS', label: 'Contains' },
                { id: 'NOT_CONTAINS', label: 'Not Contains' },
                { id: 'EQUALS', label: 'Equals' },
                { id: 'NOT_EQUALS', label: 'Not Equals' },
                { id: 'STARTS_WITH', label: 'Starts With' },
                { id: 'ENDS_WITH', label: 'Ends With' },
                { id: 'BLANK', label: 'Blank' },
                { id: 'NOT_BLANK', label: 'Not Blank' },
            ];
        case 'DATETIME':
        case 'DATE':
            return [
                { id: 'EQUALS', label: 'Equals' },
                { id: 'GREATER_THAN', label: 'Greater Than' },
                { id: 'LESS_THAN', label: 'Less Than' },
                { id: 'NOT_EQUALS', label: 'Not Equals' },
                { id: 'RANGE', label: 'In Range' },
                { id: 'BLANK', label: 'Blank' },
                { id: 'NOT_BLANK', label: 'Not Blank' },
            ];
        case 'ATTACHMENT':
            return [
                { id: 'BLANK', label: 'Blank' },
                { id: 'NOT_BLANK', label: 'Not Blank' },
            ];
        case 'BOOLEAN':
        case 'CHOICE':
        case 'MULTI_CHOICE':
        case CustomFieldType.BOOLEAN:
            return [
                { id: 'CONTAINS', label: 'Contains' },
                { id: 'BLANK', label: 'Blank' },
            ];
        case 'FLOAT':
        case CustomFieldType.CURRENCY:
        case CustomFieldType.DECIMAL:
        case CustomFieldType.INTEGER:
            return [
                { id: 'EQUALS', label: 'Equals' },
                { id: 'NOT_EQUALS', label: 'Not Equals' },
                { id: 'GREATER_THAN', label: 'Greater Than' },
                { id: 'LESS_THAN', label: 'Less Than' },
                { id: 'GREATER_THAN_EQUALS', label: 'Greater Than or Equals' },
                { id: 'LESS_THAN_EQUALS', label: 'Less Than or Equals' },
                { id: 'BLANK', label: 'Blank' },
                { id: 'NOT_BLANK', label: 'Not Blank' },
            ];
        default:
            return [
                { id: 'CONTAINS', label: 'Contains' },
                { id: 'NOT_CONTAINS', label: 'Not Contains' },
                { id: 'EQUALS', label: 'Equals' },
                { id: 'NOT_EQUALS', label: 'Not Equals' },
                { id: 'GREATER_THAN', label: 'Greater Than' },
                { id: 'LESS_THAN', label: 'Less Than' },
                { id: 'GREATER_THAN_EQUALS', label: 'Greater Than or Equals' },
                { id: 'LESS_THAN_EQUALS', label: 'Less Than or Equals' },
                { id: 'BLANK', label: 'Blank' },
                { id: 'NOT_BLANK', label: 'Not Blank' },
            ];
    }
};

export type TRenderFieldType =
    | {
          type:
              | CustomFieldType.SHORTTEXT
              | CustomFieldType.LONG_TEXT
              | CustomFieldType.SHORT_TEXT
              | CustomFieldType.LONGTEXT
              | CustomFieldType.DECIMAL
              | CustomFieldType.LOCATION
              | CustomFieldType.CURRENCY
              | CustomFieldType.INTEGER
              | CustomFieldType.DATETIME
              | CustomFieldType.FLOAT;
          value: string;
      }
    | {
          type: CustomFieldType.DATE;
          value: string | null;
      }
    | {
          type: CustomFieldType.BOOLEAN;
          value: (string | null)[];
      }
    | {
          type:
              | CustomFieldType.MULTI_CHOICE
              | CustomFieldType.CHOICE
              | CustomFieldType.ATTACHMENT
              | CustomFieldType.TEMPLATE;
          value: string[];
          choices: string[];
      };

const decryptOption = (option: string) => {
    const splittedOption = option.split(PROJECT_DASHBOARD_FIELD_DELIMITER);

    if (splittedOption.length === 2) {
        return {
            label: splittedOption[0],
            value: splittedOption[1],
        };
    } else {
        return {
            label: splittedOption[0],

            value: statusDisplayNameMap[splittedOption[0]] || splittedOption[0],
        };
    }
};

const getInputProps = (
    fieldType: TRenderFieldType,
    customUpdateHandler: (newValue: any) => void,
    filter: any
) => {
    console.log('LMAOOOOOOOOOO', filter.single.field);
    if (
        fieldType.type === CustomFieldType.DATETIME &&
        (filter.single.field === 'Modified on' ||
            filter.single.field === 'Created on' ||
            filter.single.field === 'created_at' ||
            filter.single.field === 'Last edited on')
    ) {
        const maxDateTime = moment().format('YYYY-MM-DDTHH:mm');
        return {
            inputProps: {
                max: maxDateTime,
                onChange: (e: any) => {
                    customUpdateHandler(e.target.value);
                },
            },
        };
    }
    return {};
};

export const RenderInputByFieldType = ({
    condition,
    customUpdateHandler,
    fieldType,
    filter,
}: {
    fieldType: TRenderFieldType;
    customUpdateHandler: (newValue: any) => void;
    condition: TConditionsValue;
    filter: any;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRange, setSelectedRange] = useState<{
        startDate: Date | null;
        endDate: Date | null;
    }>({
        startDate: filter.single?.datetime_from
            ? new Date(filter.single.datetime_from)
            : null,
        endDate: filter.single?.datetime_to
            ? new Date(filter.single.datetime_to)
            : null,
    });

    const [range, setRange] = useState(new Date());

    const open = Boolean(anchorEl);
    const maxDate = new Date();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const convertLocalDateToUTC = (localDate: Date): Date => {
        // Create a new Date object with the local time
        const localTime = new Date(
            localDate.getTime() - localDate.getTimezoneOffset() * 60000
        );
        // Return the Date object in UTC
        return new Date(localTime.toISOString());
    };

    // const convertToIST = (localDate: Date): Date => {
    // // IST is UTC+5:30
    //     const offset = 5.5 * 60 * 60 * 1000; // 5 hours and 30 minutes in milliseconds
    //     const localTime = new Date(localDate.getTime() + offset);
    //     return localTime;
    // };

    // const localStartDate = new Date(filter.single?.datetime_from || new Date());
    // const localEndDate = new Date(filter.single?.datetime_to || new Date());

    // const istStartDate = convertToIST(localStartDate);
    // const istEndDate = convertToIST(localEndDate);

    // console.log("istStartDate", istStartDate, "istEndDate", istEndDate)

    const handleDateRangeChange = (ranges: any) => {
        const { selection } = ranges;
        const newStartDate = selection.startDate;
        const newEndDate = selection.endDate;

        setRange(newEndDate);
        const adjustedStartDate = new Date(newStartDate);
        adjustedStartDate.setHours(0, 0, 0, 0);

        const adjustedEndDate = new Date(newEndDate);
        adjustedEndDate.setHours(23, 59, 59, 999);

        // Convert adjusted dates to UTC
        const startDateUTC = convertLocalDateToUTC(adjustedStartDate);
        const endDateUTC = convertLocalDateToUTC(adjustedEndDate);

        setSelectedRange({
            startDate: new Date(startDateUTC),
            endDate: new Date(endDateUTC),
        });

        customUpdateHandler({
            datetime_from: startDateUTC,
            datetime_to: endDateUTC,
        });
    };

    useEffect(() => {
        setSelectedRange({
            startDate: filter.single?.datetime_from
                ? new Date(filter.single.datetime_from)
                : null,
            endDate: filter.single?.datetime_to
                ? new Date(filter.single.datetime_to)
                : null,
        });
    }, [filter.single?.datetime_from, filter.single?.datetime_to]);

    const popoverStyles = {
        border: '1px solid #ccc',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    };

    const universalDisablingCondition =
        condition === 'BLANK' || condition === 'NOT_BLANK';

    let additionalProps = getInputProps(fieldType, customUpdateHandler, filter);

    const isDateTimeWithMaxDateRestriction =
        fieldType.type === CustomFieldType.DATETIME &&
        (filter.single.field === 'Modified on' ||
            filter.single.field === 'Created on' ||
            filter.single.field === 'created_at');

    const displayDateRange = selectedRange.startDate
        ? `${moment(selectedRange.startDate).format('YYYY-MM-DD')} - ${
              range ? moment(range).format('YYYY-MM-DD') : ''
          }`
        : '';

    switch (fieldType.type) {
        case CustomFieldType.LONGTEXT:
        case CustomFieldType.LONG_TEXT:
            return (
                <SingleFieldTypeRenderer
                    fieldType="LONGTEXT"
                    disabled={universalDisablingCondition}
                    constraints={{
                        fieldType: CustomFieldType.LONGTEXT,
                        maxLimit: 120,
                        minLimit: 0,
                    }}
                    placeholder="Search here..."
                    errors={false}
                    value={fieldType.value}
                    helper={{
                        text: '',
                    }}
                    updateHandler={(newValue: string) => {
                        customUpdateHandler(newValue);
                    }}
                />
            );
        case CustomFieldType.SHORTTEXT:
        case CustomFieldType.SHORT_TEXT:
            return (
                <SingleFieldTypeRenderer
                    fieldType="LONGTEXT"
                    disabled={universalDisablingCondition}
                    constraints={{
                        fieldType: CustomFieldType.LONGTEXT,
                        maxLimit: 15,
                        minLimit: 0,
                    }}
                    placeholder="Search here..."
                    errors={false}
                    value={fieldType.value}
                    helper={{
                        text: '',
                    }}
                    updateHandler={(newValue: string) => {
                        customUpdateHandler(newValue);
                    }}
                />
            );
        case CustomFieldType.DATE:
            if (condition !== 'RANGE') {
                return (
                    <SingleFieldTypeRenderer
                        fieldType="DATE"
                        disabled={universalDisablingCondition}
                        constraints={{
                            fieldType: CustomFieldType.DATE,
                        }}
                        errors={false}
                        value={fieldType.value}
                        helper={{
                            text: '',
                        }}
                        updateHandler={customUpdateHandler}
                    />
                );
            } else {
                return (
                    <>
                        <FWInput
                            sx={{ width: '100%' }}
                            value={displayDateRange}
                            onClick={handleClick}
                            disabled={universalDisablingCondition}
                            placeholder="Select Date Range"
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={handleClick}>
                                        <i
                                            className="bi bi-calendar"
                                            style={{
                                                fontSize: '20px',
                                                color: 'black',
                                            }}
                                        ></i>
                                    </IconButton>
                                ),
                            }}
                            {...additionalProps}
                        />
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: popoverStyles,
                            }}
                        >
                            <DateRange
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                ranges={[
                                    {
                                        startDate: new Date(
                                            filter.single?.datetime_from ||
                                                new Date()
                                        ),
                                        endDate: new Date(
                                            filter.single?.datetime_to ||
                                                new Date()
                                        ),
                                        key: 'selection',
                                    },
                                ]}
                                onChange={handleDateRangeChange}
                                maxDate={
                                    isDateTimeWithMaxDateRestriction
                                        ? maxDate
                                        : undefined
                                } // Conditionally set maxDate
                            />
                        </Popover>
                    </>
                );
            }

        case CustomFieldType.DATETIME:
            if (condition !== 'RANGE') {
                return (
                    <FWInput
                        value={fieldType.value}
                        onChange={(e) => {
                            const value = e.target.value;
                            customUpdateHandler(value);
                        }}
                        disabled={universalDisablingCondition}
                        type="datetime-local"
                        error={false}
                        helper={{
                            text: '',
                        }}
                        {...additionalProps}
                    />
                );
            } else {
                return (
                    <>
                        <FWInput
                            value={displayDateRange}
                            onClick={handleClick}
                            disabled={universalDisablingCondition}
                            placeholder="Select Date Range"
                            {...additionalProps}
                        />
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: popoverStyles,
                            }}
                        >
                            <DateRange
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                ranges={[
                                    {
                                        startDate: new Date(
                                            filter.single?.datetime_from ||
                                                new Date()
                                        ),
                                        endDate: range,
                                        key: 'selection',
                                    },
                                ]}
                                onChange={handleDateRangeChange}
                                maxDate={
                                    isDateTimeWithMaxDateRestriction
                                        ? maxDate
                                        : undefined
                                } // Conditionally set maxDate
                            />
                        </Popover>
                    </>
                );
            }

        case CustomFieldType.FLOAT:
        case CustomFieldType.DECIMAL:
        case CustomFieldType.CURRENCY:
            return (
                <SingleFieldTypeRenderer
                    fieldType="FLOAT"
                    disabled={universalDisablingCondition}
                    constraints={{
                        fieldType: CustomFieldType.FLOAT,
                    }}
                    errors={false}
                    value={fieldType.value}
                    helper={{
                        text: '',
                    }}
                    placeholder="Search here..."
                    updateHandler={customUpdateHandler}
                />
            );
        case CustomFieldType.INTEGER:
            return (
                <SingleFieldTypeRenderer
                    fieldType="INTEGER"
                    disabled={universalDisablingCondition}
                    constraints={{
                        fieldType: CustomFieldType.INTEGER,
                    }}
                    errors={false}
                    value={fieldType.value}
                    helper={{
                        text: '',
                    }}
                    placeholder="Search here..."
                    updateHandler={customUpdateHandler}
                />
            );
        case CustomFieldType.BOOLEAN:
            const optionList = [
                {
                    label: 'Yes',
                    value: 'True',
                },
                {
                    label: 'No',
                    value: 'False',
                },
                {
                    label: 'Blank',
                    value: null,
                },
            ];
            return (
                <FWAutocomplete
                    disabled={universalDisablingCondition}
                    popupIcon={
                        <Box fontSize={'16px'} paddingTop={0.5}>
                            <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                        </Box>
                    }
                    disableClearable
                    disableCloseOnSelect
                    value={optionList.filter((option) =>
                        fieldType.value.includes(option.value)
                    )}
                    options={[
                        {
                            label: 'Yes',
                            value: 'True',
                        },
                        {
                            label: 'No',
                            value: 'False',
                        },
                        {
                            label: 'Blank',
                            value: null,
                        },
                    ]}
                    onChange={(
                        e,
                        newValue: {
                            label: string;
                            value: string | null;
                        }[]
                    ) => {
                        customUpdateHandler(newValue.map((val) => val.value));
                        // customUpdateHandler(
                        //     newValue.map((val) => decryptOption(val).value)
                        // );
                        e.stopPropagation();
                    }}
                    renderOption={(
                        props,
                        option: {
                            label: string;
                            value: string | null;
                        },
                        { selected }
                    ) => {
                        return (
                            <FWMenuItem
                                key={option.label}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: selected
                                        ? 'white !important'
                                        : 'transparent',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(0, 0, 0, 0.08) !important',
                                    },
                                }}
                                {...props}
                            >
                                <Box display="flex" width={'100%'}>
                                    <Checkbox
                                        sx={{
                                            '&.MuiCheckbox-root': {
                                                padding: '0px 9px',
                                            },
                                            '&:selected': {
                                                backgroundColor:
                                                    'white !important',
                                            },
                                        }}
                                        checked={selected}
                                    />
                                    <FWTypography
                                        sx={{
                                            marginRight: '10px',
                                            '&:selected': {
                                                backgroundColor:
                                                    'white !important',
                                            },
                                        }}
                                    >
                                        {option.label}
                                    </FWTypography>
                                </Box>
                            </FWMenuItem>
                        );
                    }}
                    PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                            <AutocompleteStyledPaper
                                {...restPaperProps}
                                sx={{
                                    paddingTop: '10px',
                                }}
                            >
                                {children}
                            </AutocompleteStyledPaper>
                        );
                    }}
                    renderInput={(params) => (
                        <FWInput
                            style={{
                                overflow: 'hidden',
                            }}
                            {...params}
                            iconStart={<i className="bi bi-search"></i>}
                            maxRows={1}
                            error={false}
                            helper={{
                                text: '',
                            }}
                        />
                    )}
                    limitTags={1}
                    sx={{
                        width: '235px',
                    }}
                    // renderTags={(newSelectedValue: string[], getTagProps) =>
                    //     newSelectedValue.map(
                    //         (option, index) =>
                    //             true && (
                    //                 <Chip
                    //                     sx={{
                    //                         maxWidth: `150px !important`,
                    //                         backgroundColor:
                    //                             'rgba(0, 0, 0, 0.08) !important',
                    //                     }}
                    //                     label={option.label}
                    //                     {...getTagProps({ index })}
                    //                     // onDelete={() => {
                    //                     //     const updatedValue =
                    //                     //         newSelectedValue.filter(
                    //                     //             (item) => item !== option
                    //                     //         ) as any;
                    //                     //     // customUpdateHandler(
                    //                     //     //     updatedValue.map(
                    //                     //     //         (ansVal: string) =>
                    //                     //     //             decryptOption(ansVal)
                    //                     //     //                 .value
                    //                     //     //     )
                    //                     //     // );
                    //                     // }}
                    //                     onMouseDown={(event) => {
                    //                         event.stopPropagation();
                    //                         event.preventDefault();
                    //                     }}
                    //                 />
                    //             )
                    //     )
                    // }
                    multiple
                    isOptionEqualToValue={(
                        option: {
                            label: string;
                            value: string | null;
                        },
                        value: any
                    ) => {
                        return value.value === option.value;
                    }}
                />
                // <SingleFieldTypeRenderer
                //     fieldType="BOOLEAN"
                //     disabled={universalDisablingCondition}
                //     constraints={{
                //         fieldType: CustomFieldType.BOOLEAN,
                //     }}
                //     errors={false}
                //     value={fieldType.value}
                //     helper={{
                //         text: '',
                //     }}
                //     updateHandler={customUpdateHandler}
                // />
            );
        case CustomFieldType.CHOICE:
        case CustomFieldType.MULTI_CHOICE:
            return (
                <FWAutocomplete
                    disabled={universalDisablingCondition}
                    popupIcon={
                        <Box fontSize={'16px'} paddingTop={0.5}>
                            <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                        </Box>
                    }
                    disableClearable
                    disableCloseOnSelect
                    value={fieldType.choices?.filter((choice) =>
                        fieldType.value?.includes(decryptOption(choice).value)
                    )}
                    options={
                        fieldType.choices.length > 1
                            ? [
                                  `Select all${PROJECT_DASHBOARD_FIELD_DELIMITER}SELECT_ALL`,
                                  ...fieldType.choices,
                              ]
                            : fieldType.choices ?? []
                    }
                    onChange={(e, newValue: string[]) => {
                        customUpdateHandler(
                            newValue.map((val) => decryptOption(val).value)
                        );
                        e.stopPropagation();
                    }}
                    renderOption={(props, option: string, { selected }) => {
                        const optionValue = decryptOption(option).value;
                        return optionValue === 'SELECT_ALL' ? (
                            <FWMenuItem
                                onClick={() => {
                                    const allOptionsSelected =
                                        fieldType.choices.length ===
                                        fieldType.value.length;

                                    if (allOptionsSelected) {
                                        customUpdateHandler([]);
                                    } else {
                                        customUpdateHandler(
                                            fieldType.choices.map(
                                                (val) =>
                                                    decryptOption(val).value
                                            )
                                        );
                                    }
                                }}
                            >
                                <Box display="flex" width={'100%'}>
                                    <Checkbox
                                        sx={{
                                            '&.MuiCheckbox-root': {
                                                padding: '0px 9px',
                                            },
                                            '&:selected': {
                                                backgroundColor:
                                                    'white !important',
                                            },
                                        }}
                                        checked={
                                            fieldType.choices.length ===
                                            fieldType.value.length
                                        }
                                    />
                                    <FWTypography
                                        sx={{
                                            marginRight: '10px',
                                            '&:selected': {
                                                backgroundColor:
                                                    'white !important',
                                            },
                                        }}
                                    >
                                        {capitaliseFirstLetter(
                                            decryptOption(option).label
                                        )}
                                    </FWTypography>
                                </Box>
                            </FWMenuItem>
                        ) : (
                            <FWMenuItem
                                key={option}
                                // value={option.rfq_item_id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: selected
                                        ? 'white !important'
                                        : 'transparent',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(0, 0, 0, 0.08) !important',
                                    },
                                }}
                                // onClick={(e) => e.stopPropagation()}
                                {...props}
                            >
                                <Box display="flex" width={'100%'}>
                                    <Checkbox
                                        sx={{
                                            '&.MuiCheckbox-root': {
                                                padding: '0px 9px',
                                            },
                                            '&:selected': {
                                                backgroundColor:
                                                    'white !important',
                                            },
                                        }}
                                        checked={selected}
                                        onClick={() => {
                                            let ans: any;
                                            ans = [option];
                                            customUpdateHandler(
                                                ans.map(
                                                    (ansVal: string) =>
                                                        decryptOption(ansVal)
                                                            .value
                                                )
                                            );
                                        }}
                                    />
                                    <FWTypography
                                        sx={{
                                            marginRight: '10px',
                                            '&:selected': {
                                                backgroundColor:
                                                    'white !important',
                                            },
                                        }}
                                    >
                                        {option === 'SST'
                                            ? 'SST'
                                            : capitaliseFirstLetter(
                                                  decryptOption(option).label
                                              )}
                                        {/* {} */}
                                    </FWTypography>
                                </Box>
                            </FWMenuItem>
                        );
                    }}
                    PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                            <AutocompleteStyledPaper
                                {...restPaperProps}
                                sx={{
                                    paddingTop: '10px',
                                }}
                            >
                                {children}
                            </AutocompleteStyledPaper>
                        );
                    }}
                    renderInput={(params) => (
                        <FWInput
                            style={{
                                overflow: 'hidden',
                            }}
                            {...params}
                            iconStart={<i className="bi bi-search"></i>}
                            maxRows={1}
                            // multiline

                            error={false}
                            helper={{
                                text: '',
                            }}
                        />
                    )}
                    sx={{
                        width: '235px',
                    }}
                    renderTags={(newSelectedValue: string[], getTagProps) => {
                        if (newSelectedValue.length > 1) {
                            return [
                                <Chip
                                    sx={{
                                        maxWidth: `115px !important`,
                                        backgroundColor:
                                            'rgba(0, 0, 0, 0.08) !important',
                                    }}
                                    label={
                                        <FWTypography>
                                            {capitaliseFirstLetter(
                                                decryptOption(
                                                    newSelectedValue[0]
                                                ).label
                                            )}
                                        </FWTypography>
                                    }
                                    {...getTagProps({ index: 0 })}
                                />,
                                <FWTypography
                                    key={`more-${newSelectedValue.length}`}
                                    sx={{ marginLeft: '8px' }}
                                >
                                    +{newSelectedValue.length - 1}
                                </FWTypography>,
                            ];
                        }

                        return newSelectedValue.map((option, index) => (
                            <Chip
                                sx={{
                                    maxWidth: `115px !important`,
                                    backgroundColor:
                                        'rgba(0, 0, 0, 0.08) !important',
                                }}
                                label={
                                    <FWTypography>
                                        {option === 'SST'
                                            ? 'SST'
                                            : capitaliseFirstLetter(
                                                  decryptOption(option).label
                                              )}
                                        {/* {capitaliseFirstLetter(
                                            decryptOption(option).label
                                        )} */}
                                    </FWTypography>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    // renderTags={(newSelectedValue: string[], getTagProps) =>
                    //     newSelectedValue.map(
                    //         (option, index) =>
                    //             true && (
                    //                 <Chip
                    //                     sx={{
                    //                         maxWidth: `115px !important`,
                    //                         backgroundColor:
                    //                             'rgba(0, 0, 0, 0.08) !important',
                    //                     }}
                    //                     label={
                    //                         <FWTypography>
                    //                             {capitaliseFirstLetter(
                    //                                 decryptOption(option).label
                    //                             )}
                    //                         </FWTypography>
                    //                     }
                    //                     {...getTagProps({ index })}
                    //                     onDelete={() => {
                    //                         const updatedValue =
                    //                             newSelectedValue.filter(
                    //                                 (item) => item !== option
                    //                             ) as any;
                    //                         customUpdateHandler(
                    //                             updatedValue.map(
                    //                                 (ansVal: string) =>
                    //                                     decryptOption(ansVal)
                    //                                         .value
                    //                             )
                    //                         );
                    //                     }}
                    //                     onMouseDown={(event) => {
                    //                         event.stopPropagation();
                    //                         event.preventDefault();
                    //                     }}
                    //                 />
                    //             )
                    //     )
                    // }
                    multiple
                    isOptionEqualToValue={(option: string, value: any) => {
                        return value === option;
                    }}
                />
            );
        default:
            return <></>;
    }
};

export const convertCustomFieldTypeToTFilterType = (
    fieldType: CustomFieldType
): TFilterType => {
    switch (fieldType) {
        case CustomFieldType.SHORTTEXT:
        case CustomFieldType.SHORT_TEXT:
            return 'SHORTTEXT';
        case CustomFieldType.LONG_TEXT:
        case CustomFieldType.LONGTEXT:
            return 'LONGTEXT';
        case CustomFieldType.DATE:
            return 'DATE';
        case CustomFieldType.DATETIME:
            return 'DATETIME';
        case CustomFieldType.FLOAT:
        case CustomFieldType.DECIMAL:
        case CustomFieldType.INTEGER:
            return 'FLOAT';
        case CustomFieldType.CHOICE:
            return 'CHOICE';
        case CustomFieldType.MULTI_CHOICE:
            return 'MULTI_CHOICE';
        case CustomFieldType.BOOLEAN:
            return 'BOOLEAN';
        default:
            return 'TEXT';
    }
};

export const formatDashboardFilterForBE = (
    filter: DashBoardFilter,
    builtInFieldsMap: Record<string, string>
) => {
    const newFilter = cloneDeep(filter);
    newFilter.and_.forEach((andFilter) => {
        if (andFilter.single) {
            andFilter.single.value = andFilter.single.value || null;
            andFilter.single.datetime_from =
                andFilter.single.datetime_from || null;

            andFilter.single.datetime_to = andFilter.single.datetime_to || null;
            andFilter.single.value_from = andFilter.single.value_from || null;
            andFilter.single.value_to = andFilter.single.value_to || null;
            andFilter.single.section = andFilter.single.section || null;
        }

        if (andFilter.single && andFilter.single.field_type === 'BUILTIN') {
            andFilter.single.field =
                builtInFieldsMap[andFilter.single.field] ??
                andFilter.single.field;
        }
        if (
            andFilter.single &&
            andFilter.single.type === CustomFieldType.DATETIME
        ) {
            andFilter.single.datetime_from =
                moment(andFilter.single.datetime_from)?.toISOString() ?? null;

            if (andFilter.single.datetime_to) {
                andFilter.single.datetime_to =
                    moment(andFilter.single.datetime_to)?.toISOString() ?? null;
            }
        }
        if (andFilter.single && andFilter.single.field === 'section_name') {
            andFilter.single.field_type = null;
            if (andFilter.single.condition_type === 'BLANK') {
                andFilter.single.condition_type = 'CONTAINS';
                andFilter.single.values = [];
            }
        }
        if (
            andFilter.single &&
            andFilter.single.field === 'section_submission_status'
        ) {
            if (
                andFilter.single.values !== null &&
                andFilter.single.values.length > 0
            ) {
                andFilter.single.values = andFilter.single.values.map(
                    (value) => {
                        if (value === 'PENDING') {
                            return 'ASSIGNING_USERS';
                        }
                        return value;
                    }
                );
            }
            if (andFilter.single.condition_type === 'BLANK') {
                andFilter.single.condition_type = 'CONTAINS';
                andFilter.single.values = [];
            }
        }
    });

    newFilter.or_.forEach((orFilter) => {
        if (orFilter.single && orFilter.single.field_type === 'BUILTIN') {
            orFilter.single.field =
                builtInFieldsMap[orFilter.single.field] ??
                orFilter.single.field;
        }

        if (
            orFilter.single &&
            orFilter.single.type === CustomFieldType.DATETIME
        ) {
            orFilter.single.datetime_from = moment(
                orFilter.single.datetime_from
            ).toISOString();

            if (orFilter.single.datetime_to) {
                orFilter.single.datetime_to = moment(
                    orFilter.single.datetime_to
                ).toISOString();
            }
        }
        if (orFilter.single && orFilter.single.field === 'section_name') {
            orFilter.single.field_type = null;
            if (orFilter.single.condition_type === 'BLANK') {
                orFilter.single.condition_type = 'CONTAINS';
                orFilter.single.values = [];
            }
        }
        if (
            orFilter.single &&
            orFilter.single.field === 'section_submission_status'
        ) {
            if (
                orFilter.single.values !== null &&
                orFilter.single.values.length > 0
            ) {
                orFilter.single.values = orFilter.single.values.map((value) => {
                    if (value === 'PENDING') {
                        return 'ASSIGNING_USERS';
                    }
                    return value;
                });
                if (orFilter.single.condition_type === 'BLANK') {
                    orFilter.single.condition_type = 'CONTAINS';
                    orFilter.single.values = [];
                }
            }
        }
    });

    return newFilter;
};

export const formatDashboardFilterForFE = (
    filter: DashBoardFilter,
    filterFieldToNameMap: Record<string, string>,
    currentTab: string, // in_progress - DRAFT | ongoing   | finished,
    dashboardType: string
) => {
    const newFilter = cloneDeep(filter);

    newFilter.and_.forEach((andFilter) => {
        if (andFilter.single && andFilter.single.field_type === 'BUILTIN') {
            console.log('Coming here filter', andFilter.single.field);
            if (andFilter.single.field === 'modified_datetime') {
                if (currentTab === 'ITEMS' || dashboardType === 'Costing') {
                    andFilter.single.field =
                        filterFieldToNameMap[andFilter.single.field] ??
                        andFilter.single.field;
                } else if (
                    currentTab === 'in_progress' ||
                    currentTab === 'CustomerBuyer' ||
                    currentTab === 'VENDORS'
                ) {
                    andFilter.single.field = 'Modified on';
                } else {
                    andFilter.single.field = 'Submitted on';
                }
            } else if (andFilter.single.field === 'modified_by_user_name') {
                if (currentTab === 'ITEMS' || dashboardType === 'Costing') {
                    andFilter.single.field =
                        filterFieldToNameMap[andFilter.single.field] ??
                        andFilter.single.field;
                } else if (currentTab === 'in_progress') {
                    andFilter.single.field = 'Last modified by';
                } else {
                    andFilter.single.field = 'Submitted by';
                }
            } else {
                andFilter.single.field =
                    filterFieldToNameMap[andFilter.single.field] ??
                    andFilter.single.field;
            }
        }

        if (
            andFilter.single &&
            andFilter.single.type === CustomFieldType.DATETIME
        ) {
            if (andFilter.single.datetime_from) {
                andFilter.single.datetime_from = moment(
                    andFilter.single.datetime_from
                ).format('YYYY-MM-DDTHH:mm');
            }
            if (andFilter.single.datetime_to) {
                andFilter.single.datetime_to = moment(
                    andFilter.single.datetime_to
                ).format('YYYY-MM-DDTHH:mm');
            }
        }
    });

    newFilter.or_.forEach((orFilter) => {
        if (orFilter.single && orFilter.single.field_type === 'BUILTIN') {
            orFilter.single.field =
                filterFieldToNameMap[orFilter.single.field] ??
                orFilter.single.field;
        }
    });
    return newFilter;
};

export const convertDashboardFieldToFilter = (field: FieldTypeLabel) => {
    const newDashboardFilter: FilterCondition = {
        ...BLANK_DASHBOARD_FILTER,
        section: field.sectionName ?? null,
        field_type: field.is_built_in
            ? 'BUILTIN'
            : field.is_built_in === null
            ? null
            : 'CUSTOM_FIELD',
        type: field.fieldType as CustomFieldType,
        field: field.column,
    };

    return newDashboardFilter;
};
