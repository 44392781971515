import { Grid, IconButton, styled } from '@mui/material';
import { isEmpty, isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { HookStateValue } from '../../../../Common/Hooks/StateHook';
import LazyFallback from '../../../../Components/Shared/LazyFallBack';
import ItemDetailSummary from '../../Components/ItemDirectory/ItemDetailSummary';
import { useItemAdditionalCost } from '../../Hooks/ItemDirectory/ItemAdditionalCostHook';
import {
    IItemDetailSummaryProviders,
    ItemReducerAction,
    useItemDetailSummary,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import { IEntity } from '../../Interfaces/EnterpriseInterface';
import { IItemAddEditResponse } from '../../Services/ItemDirectoryService';
import ItemAddEditContainer, { ItemPageType } from './ItemAddEditContainer';

export const InfoBlock = styled(Grid)({
    paddingRight: '15px',
    marginBottom: '30px',
});

export const LongtextBox = styled(Grid)({
    marginBottom: '30px',
});

interface IItemDetailsContainerProps {
    itemId: string;
    entities: IEntity[];
    emitName?: (name: string) => void;
}

const ItemDetailsContainer = ({
    itemId,
    entities,
    emitName,
}: IItemDetailsContainerProps) => {
    const { entityUid }: { [key: string]: string } = useParams();

    const isEntityVersion = !isUndefined(entityUid);

    const {
        globalTags,
        // hookState,
        itemDetails,
        itemEditDetails,
        updateEditDetails,
        measurements,
        fetchItemMeasurements,
        currencies,
        isValidDetails,
        handleItemSave,
    }: IItemDetailSummaryProviders = useItemDetailSummary(itemId);

    const [areDetailsValid, setAreDetailsValid] = useState(isValidDetails);

    const [canEdit, setCanEdit] = useState<boolean>(true);

    const handleEditToggle = () => setCanEdit(!canEdit);

    const handleDetailChange = (data: ItemReducerAction) => {
        updateEditDetails(data);
    };

    const handleEmitSaveAction = (msg: IItemAddEditResponse | boolean) => {
        if (!msg) {
            toast.error('Item update failed');
        } else if (typeof msg !== 'boolean') {
            if (msg.success) {
                toast.success('Item updated successfully');
                handleEditToggle();
            } else {
                toast.error('Item update failed');
            }
        }
    };

    useEffect(() => {
        if (emitName) emitName(itemDetails?.itemName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemDetails?.itemName]);

    const {
        getPreselectedAdditionalCostAndCF,
        getTemplateFields,
        itemTemplateSectionList,
        setItemTemplateSectionList,
        hookState,
    } = useItemAdditionalCost(
        ItemPageType.EDIT,
        itemId,
        handleDetailChange,
        itemEditDetails
    );
    useEffect(() => {
        getTemplateFields();
    }, [getTemplateFields]);

    useEffect(() => {
        if (itemId !== '') {
            getPreselectedAdditionalCostAndCF(itemId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId]);
    useEffect(() => {
        let errors = !isValidDetails;
        if (
            itemTemplateSectionList?.itemFields['Description'].is_required &&
            isEmpty(itemEditDetails.description)
        ) {
            errors = true;
        }
        if (
            itemTemplateSectionList?.itemFields.Procurement_item_price
                .is_required &&
            (!Boolean(itemEditDetails.buyerPricingInformation.price) ||
                itemEditDetails.buyerPricingInformation.currencyCodeId === null)
        ) {
            errors = true;
        }
        if (
            itemTemplateSectionList?.itemFields.Notes.is_required &&
            isEmpty(itemEditDetails.notes)
        ) {
            errors = true;
        }

        if (
            itemTemplateSectionList?.itemFields['Internal_notes'].is_required &&
            isEmpty(itemEditDetails.internal_notes)
        ) {
            errors = true;
        }
        setAreDetailsValid(!errors);
    }, [isValidDetails, itemEditDetails, itemTemplateSectionList]);

    if (hookState.state === HookStateValue.LOADING) {
        return <LazyFallback />;
    }
    return (
        <>
            {/* {hookState.state === HookStateValue.LOADING && <LazyFallback />} */}
            <Grid container className="flex--jcfe">
                {!canEdit &&
                    (!(entities.length > 1 && isEntityVersion) ||
                        (entities.length === 1 && isEntityVersion)) && (
                        <IconButton
                            className="ml--5"
                            name="role"
                            onClick={handleEditToggle}
                            sx={{
                                width: '40px',
                                height: '40px',
                                marginTop: '-60px',
                            }}
                            color="primary"
                        >
                            <i className="bi bi-pencil fs--10"></i>
                        </IconButton>
                    )}
            </Grid>
            <Grid item xs={12} marginBottom="10px">
                <FWTypography variant="h4">Item details</FWTypography>
            </Grid>
            {isEntityVersion && (
                <ItemDetailSummary
                    data={itemDetails}
                    measurements={measurements}
                />
            )}
            {!isEntityVersion && (
                <ItemAddEditContainer
                    type={ItemPageType.EDIT}
                    fetchItemMeasurements={fetchItemMeasurements}
                    itemTemplateSectionList={itemTemplateSectionList}
                    setItemTemplateSectionList={setItemTemplateSectionList}
                    getTemplateFields={getTemplateFields}
                    data={itemEditDetails}
                    ogData={itemDetails}
                    handleChange={handleDetailChange}
                    measurements={measurements}
                    currencies={currencies}
                    isValidDetails={areDetailsValid}
                    handleItemSave={handleItemSave}
                    emitSaveAction={handleEmitSaveAction}
                    globalTags={globalTags}
                />
            )}
        </>
    );
};

export default ItemDetailsContainer;
