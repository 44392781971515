import { toast } from 'react-toastify';
import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
} from '../../AdditionalCost/models/AdditionalCost.model';
import { ICustomAttributeNew } from '../../Models/Attributes.model';
import { ICurrencyRateWithoutPrecision } from '../../Models/Currency.model';
import { IMeasurementUnitDetails } from '../../Models/MeasurementUnit.model';
import {
    DashBoardFilter,
    TCostingList,
} from '../../ProjectGlCostCenter/interface/project.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { store } from '../../Redux/Store';
import { del, get, post, put } from '../../Utils/api';
import {
    ICostingCustomConversion,
    ICreateCostingDetails,
} from '../Hooks/useCreateCostingPage';
import {
    AdditionalFinalCostingDataInterface,
    CostingSheetStatusEnum,
    IBOMCostingBackendStructure,
    IBOMDetailsResponse,
    IBuyerContactResponse,
    ICostingBOM,
    ICostingDashboard,
    ICostingDetailsBackendResponse,
    ICostingHistory,
    ICostingHistoryById,
    ICostingItem,
    ICreateCostingSheetPayload,
    IQuoteCustomField,
    IQUoteItemDeliverySchedule,
} from '../Interfaces/CostingInterfaces.interface.';
import {
    BOMBackendPayloadMapperFunction,
    BOMBackendToFrontEndMapperFunction,
    recursivelyMapBackendBOMToFrontendBOM,
} from '../Misc/helperFunctions';

export interface ICostingSheetData {
    costing_sheet_id: string;
    total: number;
    currency_details: {
        currency_code_abbreviation: string;
        currency_name: string;
        currency_symbol: string;
        entry_id: string;
    };
    costing_sheet_items_count: any;
    modified_by_user_name: string;
    item_details: any[];
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    custom_costing_sheet_id: string;
    name: string;
    additional_costs: any[];
    view: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    seller_enterprise: string;
    seller_entity: string;
    currency_code: string;
}

export const fetchAllCostingSheetsApi = (): Promise<ICostingSheetData[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const costing = await get<any>(`/cost_calculator/`);
            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

interface ICostingDashboardData {
    counts: {
        all: number;
    };
    data: ICostingDashboard[];
    metadata: {
        totalPages: number;
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
}

export const costingDashboardApi = ({
    searchText,
    itemsPerPage,
    pageNumber,
    sortFields,
    tab,
    filters,
}: {
    searchText: string;
    itemsPerPage: number;
    pageNumber: number;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    tab: string;
    filters: DashBoardFilter | null;
}): Promise<ICostingDashboardData> => {
    return new Promise(async (resolve, reject) => {
        try {
            let costing = await post<any, ICostingDashboardData>(
                `/dashboard/`,
                {
                    dashboard_view: 'quote_calculator_seller',
                    tab,
                    search_text: searchText,
                    sort_fields: sortFields,
                    items_per_page: itemsPerPage,
                    page_number: pageNumber,
                    filters: filters,
                }
            );
            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const costingApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getListOfQouteCalulator: build.mutation<
            ICostingDashboardData,
            {
                searchText: string;
                itemsPerPage: number;
                pageNumber: number;
                entityUid: string;
                tab: string;
            }
        >({
            query: ({
                searchText,
                tab,
                itemsPerPage,
                pageNumber,
                entityUid,
            }) => ({
                url: `/dashboard/`,
                method: 'POST',
                body: {
                    dashboard_view: 'quote_calculator_seller',
                    tab,
                    search_text: searchText,
                    sort_fields: [],
                    items_per_page: itemsPerPage,
                    page_number: pageNumber,
                    query_data: {
                        entity_id: entityUid,
                    },
                },
            }),
        }),
        getDraftCostingCount: build.mutation<
            {
                tab_counts: {
                    draft: number;
                    ongoing: number;
                    finished: number;
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'quote_calculator_seller',
                },
            }),
        }),
        getCostingSellerListOfAllUserList: build.query<TCostingList, {}>({
            query: () => ({
                url: '/cost_calculator/columns/?enterprise_type=SELLER',
            }),
        }),
    }),
});

export const {
    useGetListOfQouteCalulatorMutation,
    useGetDraftCostingCountMutation,
    useGetCostingSellerListOfAllUserListQuery,
} = costingApiSlice;

export const fetchAllEnterpriseUnits = (): Promise<
    IMeasurementUnitDetails[]
> => {
    return new Promise(async (resolve, reject) => {
        try {
            let measurement_unit_details = await get<any>(
                `/backbone/measurement_unit/`
            );
            resolve(measurement_unit_details.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const createNewCostingSheetApi = (
    costingData: ICreateCostingDetails
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const payload = {
                seller_entity_id: costingData.entity,
                name: costingData.costingName,
                currency_code_id: costingData.currency?.entry_id,
                customer_entity_id: costingData.customer_entity_id
                    ? costingData.customer_entity_id
                    : null,
                total: 0,
                template_id: costingData.templateId,
                admin_conversions_selected: costingData.adminConversions,
                custom_currency_conversions: costingData.customConversions,
                additional_costs: [],
            };
            let costing = await post<any, any>(
                `/cost_calculator/create/`,
                payload
            );
            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const createCostingSheetAPI = async (
    payload: ICreateCostingSheetPayload
) => {
    try {
        let resp = await post<
            ICreateCostingSheetPayload,
            {
                costing_sheet_id: string;
            }
        >(`/cost_calculator/create/`, payload);

        return resp.data;
    } catch (err) {
        throw new Error('Failed to create costing sheet');
    }
};

export const cloneCostingSheetApi = (
    costingId: string,
    name: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const costing = await post<any, any>(
                `/cost_calculator/${costingId}/clone/`,
                { name }
            );
            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const deleteCostingSheetApi = (costingId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let costing = await del<any>(
                `/cost_calculator/${costingId}/delete/`
            );

            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const fetchCostingDetailsApi = (
    costingId: string
): Promise<ICostingDetailsBackendResponse> => {
    return new Promise(async (resolve, reject) => {
        try {
            let costing = await get<ICostingDetailsBackendResponse>(
                `/cost_calculator/${costingId}/`
            );
            console.log('I am fetchingggg naa');
            console.log('I am fetchingggg naa');

            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

// export const CostingApiSlice = baseApiSlice.injectEndpoints()

interface ICostingConversionResponse {
    admin: boolean;
    custom_conversions: {
        from_currency_id: any;
        currency_id: string;
        from_currency_name: string;
        to_currency_name: string;
        to_currency_id: string;
        conversion_rate: string;
    }[];
}

export const fetchCostingCurrencyConversionsApi = (
    costingId: string
): Promise<ICostingCustomConversion> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<ICostingConversionResponse>(
                `/cost_calculator/${costingId}/currency_conversions`
            );

            let costing: ICostingCustomConversion = {
                admin_conversions_selected: resp.data.admin,
                custom_currency_conversions: resp.data.custom_conversions.map(
                    (obj) => ({
                        conversion_rate: obj.conversion_rate,
                        from_currency_id: obj.from_currency_id,
                        to_currency_id: obj.to_currency_id,
                    })
                ),
            };

            resolve(costing);
        } catch (err) {
            reject(err);
        }
    });
};

export const getCostingCurrencyConversionRateWithoutPrecision = async (
    from_currency_id: string,
    to_currency_id: string,
    costing_sheet_id: string,
    vendor_entity_id: string | null
): Promise<ICurrencyRateWithoutPrecision> => {
    try {
        const res = await post<
            {
                from_currency_id: string;
                to_currency_id: string;
                costing_sheet_id: string;
                vendor_entity_id: string;
            },
            ICurrencyRateWithoutPrecision
        >(
            `/cost_calculator/${costing_sheet_id}/currency_conversion/`,
            {
                from_currency_id,
                to_currency_id,
                vendor_entity_id,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

export interface IAdditionalCostStructureForQuote {
    cost_name: string;
    cost_type: CostTypeEnum;
    cost_value: number;
    allocation_type: AllocationTypeEnum | null;
    cost_source: CostSourceEnum | null;
    additional_cost_id: string | null;
    source_currency_id: string | null;
    cost_currency_id: string | null;
    source_value: number | null;
    conversion_rate: number | null;
}

export interface IAdditionalCostStructureForQuoteWithCurrency
    extends IAdditionalCostStructureForQuote {
    currency_id: string | null;
}

export interface IQuoteBOMStructure {
    bom_code: string;
    name: string;
    bom_id: string;
    enterprise_bom_id: string;
    costing_sheet_item_id: string;
}

export interface IQuoteItemError {
    quantity?: string;
    deliverySchedule?: {
        [index: number]: {
            quantity?: string;
            delivery_date?: string;
            cost_centre_id?: string;
            project_id?: string;
            general_ledger_id?: string;
        };
        mismatchFromTotalQuantity?: string;
    };
    rate?: string;
    lead_time?: string;
    notes?: string;
    additionalCost?: string;
    custom_fields?: {
        [field: string]: string;
    };
}

export interface IQuoteItemID {
    costing_sheet_item_id: string;
    item_index: number;
    bom_entry_id: string | null;
    parent_bom_entry_id: string | null;
    list_parent_bom_entry_id: string[];
}

export interface ICostingCalculatorApiResponse {
    costing_sheet_item_id: string;
    delivery_schedule: IQUoteItemDeliverySchedule[];
    measurement_unit_details: {
        measurement_unit_id: string;
        measurement_unit_primary_name: string;
    };
    enterprise_bom_details: any;
    latest_po_item: {
        price: string;
        measurement_unit_id: string;
        measurement_unit_primary_name: string;
    } | null;
    procurement_information: {
        lead_time: string | null;
        lead_time_period: string | null;
    };
    attributes: ICustomAttributeNew[];
    additional_costs: AdditionalFinalCostingDataInterface[];
    total: number;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: string | null;
    quantity: string;
    rate: string;
    vendor_rate: number;
    notes: string | null;
    custom_fields: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: {
            name: string;
            fields: IQuoteCustomField[];
        }[];
    };
    custom_sections: {
        name: string;
        section_type: 'ITEM';
        start_time: string | null;
        custom_fields: IQuoteCustomField[];
    }[];
    conversion_rate: number;
    vendor_entity_details: {
        entity_id: string;
        entity_name: string;
    } | null;
    vendor_currency_details: {
        entry_id: string;
        decimals: number;
        currency_name: string;
        currency_code_abbreviation: string;
        currency_symbol: string;
    } | null;
    projects: {
        project_code: string;
        project_id: string;
        project_name: string;
    }[];
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    measurement_unit: string;
    costing_sheet: string;
    rfq_event_item_id: string | null;
    rfq_event_details: {
        event_id: string;
        custom_event_id: string;
        event_name: string;
        rfq_event_item_id: string;
        rfq_entry_id: string;
    } | null;
    rfq_bid_item_details: {
        rfq_bid_item_id: string;
        pricing_information: {
            price: number;
            currency_name: string;
            currency_symbol: string;
            currency_code_id: string;
            shipping_per_unit: number;
            additional_charges: any[];
            currency_code_abbreviation: string;
        };
        additional_costs: AdditionalFinalCostingDataInterface[];
    } | null;
    enterprise_bom: string | null;
    parent_costing_sheet_item_id?: string | null;
    enterprise_item_details: {
        enterprise_item_id: string;
        name: string;
        code: string;
        custom_ids: {
            custom_ids: {
                name: string;
                value: string;
            }[];
        };
        measurement_units: {
            item_measurement_units: {
                abbreviation: string;
                measurement_unit_id: string;
                measurement_unit_category: string;
                measurement_unit_value_type: string;
                measurement_unit_primary_name: string;
            }[];
        };
        attributes: ICustomAttributeNew[];
    };
}

export interface ICostingItemDashboardResponse {
    data: ICostingCalculatorApiResponse[];
    counts: {
        all: number;
    };
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
}

export const bulkSaveItemsInCostSheet = async (
    costingId: string,
    costingItemData: (ICostingItem | ICostingBOM)[]
) => {
    try {
        const updatedPayload: IBOMCostingBackendStructure[] = [];
        costingItemData.forEach((costingItem) => {
            let formattedData = BOMBackendPayloadMapperFunction(costingItem);

            updatedPayload.push(formattedData);
        });

        await put<any, any>(
            `/cost_calculator/${costingId}/items/bulk_update/`,
            updatedPayload
        );
        store.dispatch(
            costingCalculatorApiSlice.endpoints.fetchCostingCalculatorCSV.initiate(
                {
                    costCalculatUid: costingId,
                },
                {
                    forceRefetch: true,
                }
            )
        );
    } catch (e) {
        toast.error('Error while updating items');
    }
};

export const fetchCostingItemsApi = async (costingId: string) => {
    try {
        let response = await get<ICostingCalculatorApiResponse[]>(
            `/cost_calculator/${costingId}/items/`
        );

        const itemData = response.data;

        return itemData;
    } catch (err) {
        throw err;
    }
};

// export const fetchCostingItemsApi = (
//     costingId: string
// ): Promise<ICostingItemDashboardResponse[]> => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             let response = await get<ICostingItemDashboardResponse>(
//                 `/cost_calculator/${costingId}/items/`
//             );

//             const itemData = response.data.data;

//             // const mappedResp: (ICostingItem | ICostingBOM)[] =
//             //     response.data.map((item) =>
//             //         BOMBackendToFrontEndMapperFunction(item, 'FETCH')
//             //     );

//             resolve(itemData);
//         } catch (err) {
//             reject(err);
//         }
//     });
// };

export const updateCostingCurrencyConversionRate = async (
    costing_sheet_id: string,
    costing_conversions: ICostingCustomConversion
) => {
    try {
        const payload = {
            admin_conversions_selected:
                costing_conversions.admin_conversions_selected,
            custom_currency_conversions:
                costing_conversions.custom_currency_conversions,
        };

        const res = await put<
            any,
            {
                [costing_sheet_item_id: string]: {
                    rate: number;
                    additional_costs: AdditionalFinalCostingDataInterface[];
                };
            }
        >(
            `/cost_calculator/${costing_sheet_id}/currency_conversion/update/`,
            payload
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const doesAdminConversionExists = async (): Promise<boolean> => {
    try {
        const res = await get<any>(
            `/organization/currency/custom_conversion/exists/`
        );
        // resp {"conversion_exists": conversion_exists}
        return res.data.conversion_exists;
    } catch (err) {
        throw err;
    }
};

export const createCostingItemApi = (
    costingId: string,
    costingItemData: ICostingItem | ICostingBOM
): Promise<ICostingItem | ICostingBOM> => {
    const payload = BOMBackendPayloadMapperFunction(costingItemData);
    payload.vendor_entity = null;
    payload.vendor_currency = null;

    return new Promise(async (resolve, reject) => {
        try {
            let costing = await post<any, any>(
                `/cost_calculator/${costingId}/item/create/`,
                payload
            );
            const mappedResp: ICostingItem | ICostingBOM =
                BOMBackendToFrontEndMapperFunction(costing.data, 'CREATE');
            // resolve(costing.data);
            //
            resolve(mappedResp);
        } catch (err) {
            reject(err);
        }
    });
};

export const getCostingSheetHistory = async (costingId: string) => {
    try {
        let res = await get<ICostingHistory[]>(
            `/cost_calculator/${costingId}/history/`
        );

        return res.data;
    } catch (err) {
        throw new Error('Failed to get data.');
    }
};

export const getCostingSheetHistoryDataById = async (
    costingId: string,
    historyId: number
) => {
    try {
        let res = await get<ICostingHistoryById>(
            `/cost_calculator/${costingId}/${historyId}/`
        );
        return res.data;
    } catch (err) {
        throw new Error('Failed to get data.');
    }
};

export const revertCostingData = async (
    costingId: string,
    history_id: number
) => {
    try {
        await get<any>(
            `/cost_calculator/${costingId}/revert/?history_id=${history_id}`
        );
    } catch (err) {
        throw new Error('Failed to get data.');
    }
};

export const deleteCostingItemApi = (
    costingId: string,
    costingItemId: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let costing = await del<any>(
                `/cost_calculator/${costingId}/item/${costingItemId}/delete/`
            );
            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const cloneCostingItemApi = (
    costingId: string,
    costingItemId: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let costing = await post<any, any>(
                `/cost_calculator/${costingId}/item/${costingItemId}/clone/`,
                {}
            );
            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const updateCostingItemApi = (
    costingId: string,
    costingItemData: ICostingItem | ICostingBOM
): Promise<any> => {
    const payload = BOMBackendPayloadMapperFunction(costingItemData, false);
    return new Promise(async (resolve, reject) => {
        try {
            let costing = await put<any, any>(
                `/cost_calculator/${costingId}/item/${costingItemData.uuid}/update/`,
                {
                    ...payload,
                    notes: costingItemData.notes || null,
                    rfq_bid_item: payload.rfq_bid_item,
                }
            );
            resolve(costing.data);
        } catch (err) {
            reject(err);
        }
    });
};

export interface IBOMListForCosting {
    bom_code: string;
    enterprise_bom_id: string;
    is_primary: boolean;
}

export const fetchBOMListApi = (
    finishedGoodItemId: string
): Promise<IBOMListForCosting[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let bomList = await get<IBOMListForCosting[]>(
                `/organization/bom/admin/?finished_good_id=${finishedGoodItemId}`
            );
            resolve(bomList.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const changeCostingSheetStatus = async (
    costingId: string,
    newStatus: CostingSheetStatusEnum,
    notes?: string,
    approver_id?: string
) => {
    try {
        let payload: {
            status: CostingSheetStatusEnum;
            notes?: string;
            approver_id?: string;
        } = {
            status: newStatus,
        };

        if (Boolean(notes)) {
            payload.notes = notes;
        }
        if (Boolean(approver_id)) {
            payload.approver_id = approver_id;
        }

        let res = await put<{}, any>(
            `/cost_calculator/${costingId}/status/`,
            payload
        );
        return res.data;
    } catch (err) {
        throw new Error('Failed to submit quote');
    }
};

export const fetchBOMDetailsApi = (bomId: string): Promise<ICostingBOM> => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await get<IBOMDetailsResponse>(
                `/organization/bom/${bomId}/admin/`
            );

            const bomDetails = response.data;

            const mappedResponse: ICostingBOM =
                recursivelyMapBackendBOMToFrontendBOM(bomDetails);
            //

            resolve(mappedResponse);
        } catch (err) {
            reject(err);
        }
    });
};

export const fetchCostCalculatorCsv = async (
    costCalculatUid: string,
    exportType: 'ITEMS' = 'ITEMS'
) => {
    try {
        const response = await get(
            `/cost_calculator/${costCalculatUid}/export/?export_type=${exportType}`
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const costingCalculatorApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        fetchCostingCalculatorCSV: builder.query<
            any,
            {
                costCalculatUid: string;
                exportType?: 'ITEMS';
            }
        >({
            query: ({ costCalculatUid, exportType = 'ITEMS' }) => ({
                url: `/cost_calculator/${costCalculatUid}/export/?export_type=${exportType}`,
                responseHandler: (response) => response.blob(),
            }),
        }),

        checkCostingSheetDuplicateName: builder.mutation<
            { costing_sheet_exists: boolean },
            { name: string; quoteSheetId: string }
        >({
            query: ({ name, quoteSheetId }) => ({
                url: `/cost_calculator/name/exists/?name=${name}${
                    quoteSheetId ? `&costing_sheet_id=${quoteSheetId}` : ''
                }`,
                method: 'GET',
            }),
        }),
        fetchCustomerContacts: builder.query<
            IBuyerContactResponse[],
            {
                sellerEntityId: string;
                customerEntityId: string;
            }
        >({
            query: ({ sellerEntityId, customerEntityId }) => ({
                url: `/organization/buyer_master/admin/entity/${sellerEntityId}/contacts/?buyer_entity_id=${customerEntityId}`,
            }),
        }),
    }),
});

export const {
    useFetchCostingCalculatorCSVQuery,
    useFetchCustomerContactsQuery,
    useCheckCostingSheetDuplicateNameMutation,
} = costingCalculatorApiSlice;

export const checkDuplicateCostingSheetName = async (
    costingSheetName: string
) => {
    try {
        let res = get<{ costing_sheet_exists: boolean }>(
            `/cost_calculator/name/exists/?name=${costingSheetName}`
        );

        return res;
    } catch (err) {}
};

export const fetchCustomerContacts = async (
    sellerEntityId: string,
    customerEntityId: string
) => {
    try {
        let res = await get<IBuyerContactResponse[]>(
            `/organization/buyer_master/admin/entity/${sellerEntityId}/contacts/?buyer_entity_id=${customerEntityId}`
        );
        return res.data;
    } catch (err) {
        throw new Error('Failed to fetch customer contacts');
    }
};
