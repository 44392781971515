import { Fragment, useState } from 'react';
import { FWTypography } from '../../Common/FWTypograhy';
import { ICostingDashboard } from '../Interfaces/CostingInterfaces.interface.';
import FWPopover from '../../Common/FWPopover';
import { Box, Grid } from '@mui/material';

const EventsCellRenderer = ({ data }: { data: ICostingDashboard }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);
    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Box
                color={data.events?.length > 1 ? 'primary.main' : 'black'}
                sx={{
                    width: '100%',
                    display: 'flex',
                }}
            >
                <div
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e: any) => {
                            e.stopPropagation();
                            if (data.events?.length > 0) handleOpen(e);
                        };
                    }}
                >
                    {data.events?.length === 1
                        ? data.events[0].event_name // Show the event name if only 1 event
                        : data.events?.length > 1
                        ? data.events.length // Show count if multiple events
                        : '-'}{' '}
                    {/* Show hyphen if no events */}
                </div>
            </Box>

            <FWPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container>
                    <div className="p--5">
                        <div className="mb--5">
                            <FWTypography variant="h5" fontWeight={'500'}>
                                Events
                            </FWTypography>
                        </div>
                        {data.events?.map((event, idx) => (
                            <Fragment key={event.rfq_entry_id}>
                                <Grid
                                    item
                                    md={12}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <FWTypography>
                                        {idx + 1}. {event.event_name}
                                    </FWTypography>
                                </Grid>
                            </Fragment>
                        ))}
                    </div>
                </Grid>
            </FWPopover>
        </>
    );
};
export default EventsCellRenderer;
