export enum EntityTypes {
    ADMIN = 'Admin',
    BUYER = 'Procurement',
    SELLER = 'Sales',
    ALL = 'Procurement & Sales',
    CUSTOMIZE = 'Customize',
}

export const setPermissionName = (name: string): string => {
    let label = name;
    switch (name.toUpperCase()) {
        case 'SELLER':
            label = EntityTypes.SELLER;
            break;
        case 'BUYER':
            label = EntityTypes.BUYER;
            break;
        case 'ADMIN':
            label = EntityTypes.ADMIN;
            break;
        case 'CUSTOMIZE':
            label = EntityTypes.CUSTOMIZE;
            break;
    }
    return label;
};

export const setEntityType = (buyerStatus: string, sellerStatus: string) => {
    let entityType = '';
    if (buyerStatus === 'ACTIVE' && sellerStatus === 'INACTIVE')
        entityType = EntityTypes.BUYER;
    if (buyerStatus === 'INACTIVE' && sellerStatus === 'ACTIVE')
        entityType = EntityTypes.SELLER;
    if (buyerStatus === 'ACTIVE' && sellerStatus === 'ACTIVE')
        entityType = EntityTypes.ALL;
    return entityType;
};

export const getEntityType = (type: string) => {
    let entityType = '';
    if (type === 'Procurement') entityType = 'BUYER';
    if (type === 'Sales') entityType = 'SELLER';
    if (type === 'Procurement & Sales') entityType = 'BOTH';
    return entityType;
};

export const permissionTooltipMap: {
    [key: string]: {
        name: string;
        description: string[];
    };
} = {
    EVENT: {
        name: 'Events',
        description: [
            'Users get access to the Events module and can view edit or approve events based on the permissions assigned.',
        ],
    },
    PURCHASE_ORDER: {
        name: 'Purchase Orders',
        description: [
            'Users get access to the Purchase Order module and can view, manage, terminate or approve purchase orders based on the permissions assigned.',
        ],
    },
    CART: {
        name: 'Cart',
        description: [
            'Users get access to the item cart module and can view, edit, create or approve order requests based on the permissions assigned. ',
        ],
    },
    REQUISITION: {
        name: 'Requisition',
        description: ['TOOLTIP FOR REQUISITION'],
    },
    ADMIN: {
        name: 'Admin',
        description: ['Lorem ipsum dolor set amet Admin'],
    },
    BUYER: {
        name: 'Buyer',
        description: [''],
    },
    SELLER: {
        name: 'Sales',
        description: [
            'Users get access to the sales portal and can access events, purchase order and invoice modules.',
        ],
    },
    INVOICE: {
        name: 'Invoice',
        description: [
            'Users get access to the invoice module and can view, verify, manage or terminate invoices based on the permissions assigned.',
        ],
    },
    GOODS_RECEIPT: {
        name: 'Goods receipt',
        description: [
            'Users get access to the Goods receipt module and can view or create Goods receipts based on the permissions assigned.',
        ],
    },
    QUALITY_CHECK: {
        name: 'Quality check',
        description: [
            'Users get access to the QC module and can view or create QCs based on the permissions assigned.',
        ],
    },
    PAYMENTS: {
        name: 'Payments',
        description: [
            'Users get access to the payments module and can view or make payments based on the permissions assigned.',
        ],
    },
    CONTRACT: {
        name: 'Contract',
        description: [
            'Users get access to the contract module and can view, manage, terminate or approve contracts based on the permissions assigned.',
        ],
    },
    SUPPLIER_RELATIONSHIP_MANAGEMENT: {
        name: 'Supplier Relationship Management',
        description: [
            'Users get access to the supplier relationship management module and can view the history of a supplier',
        ],
    },
    SUPPLIER_RELATIONSHIP_MANAGEMENT_SELLER: {
        name: 'Supplier Relationship Management',
        description: [
            'Users get access to the supplier relationship management module and can view the history of a supplier',
        ],
    },
    QUOTE_CALCULATOR: {
        name: 'Quote Calculator',
        description: [
            'Users get access to the quote calculator module and can view, manage, terminate or approve quote calculator based on the permissions assigned.',
        ],
    },
    PROJECT: {
        name: 'Project',
        description: [
            'Users get access to the project module and can view, manage and assign projects based on the permissions assigned.',
        ],
    },
    QUOTE_CALCULATOR_CUSTOMER: {
        name: 'Customer Quote',
        description: [],
    },
    ...(process.env.REACT_APP_ENV !== 'newdbtest1'
        ? { FORM: { name: 'Form', description: [] } }
        : {}),
};
