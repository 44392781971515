import { Box, Divider } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWTypography } from '../../Common/FWTypograhy';
import { IBulkImport } from '../../Global/Interfaces/BulkImportInterface';
import { downloadFile } from '../../Global/Services/FileStorageService';
import { IAttachment } from '../../Models/RFQ.model';

interface IUploadedFilesListProps {
    listFor?: 'ATTACHMENT' | 'BULK_IMPORT';
    existingFiles: IAttachment[] | IBulkImport[]; // existing list of the IAttachment list from your main state
    showRemove?: boolean;
    allowDownload?: boolean;
    type?: 'LIST' | 'COMMA_SEPARATED';
    toggleFileRemove?: (idx: number) => void;
    fromBulkImport?: boolean;
}

const UploadedFilesList = ({
    listFor = 'ATTACHMENT',
    existingFiles,
    toggleFileRemove,
    showRemove = true,
    allowDownload = true,
    type = 'LIST',
    fromBulkImport = false,
}: IUploadedFilesListProps) => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<string[]>([]);

    const handleDownloadFile = async (attachmentId: string) => {
        try {
            setDownloading((prevDownloading) => {
                prevDownloading.push(attachmentId);
                return [...prevDownloading];
            });
            setIsDownloading(true);
            const downloadUrl = await downloadFile(attachmentId);
            window.open(downloadUrl, '_self');
        } catch (error) {
            toast.error('Failed to download file');
        } finally {
            setIsDownloading(false);
            setDownloading((prevDownloading) => {
                const index = prevDownloading.findIndex(
                    (a) => a === attachmentId
                );
                prevDownloading.splice(index, 1);
                return [...prevDownloading];
            });
        }
    };

    const returnFileId = (file: IAttachment | IBulkImport) => {
        return listFor === 'ATTACHMENT'
            ? (file as IAttachment).attachment_id
            : (file as IBulkImport).bulk_import_id;
    };

    const handleDownloadAllFiles = async () => {
        try {
            setIsDownloading(true);
            setDownloading(['_ALL']);
            const downloadUrls = await Promise.all(
                existingFiles.map((data) => downloadFile(returnFileId(data)))
            );
            downloadUrls.forEach((url, idx) => {
                setTimeout(() => {
                    //
                    window.open(url, '_self');
                }, idx * 1000);
            });
        } catch (error) {
        } finally {
            setIsDownloading(false);
            setDownloading([]);
        }
    };

    switch (type) {
        case 'COMMA_SEPARATED':
            return (
                <div
                    style={{
                        width: '100%',
                        marginBottom: '2px',
                    }}
                    className="flex flex--wrap"
                >
                    {allowDownload && existingFiles.length > 1 && (
                        <>
                            <div
                                key={'Download all'}
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                                className="mb--5"
                            >
                                <FWTooltip title="Download all files">
                                    <Box
                                        sx={{
                                            maxWidth: '100%',
                                            pointerEvents:
                                                isDownloading &&
                                                downloading.includes('_ALL')
                                                    ? 'none'
                                                    : undefined,
                                            color:
                                                isDownloading &&
                                                downloading.includes('_ALL')
                                                    ? 'text.secondary'
                                                    : 'primary.main',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            cursor:
                                                isDownloading &&
                                                downloading.includes('_ALL')
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                        }}
                                        onClick={handleDownloadAllFiles}
                                    >
                                        <div className="flex flex--aic">
                                            <FWTypography variant="caption">
                                                [
                                            </FWTypography>
                                            &nbsp;
                                            <i className="bi bi-cloud-download" />
                                            &nbsp;
                                            <FWTypography variant="caption">
                                                Download all
                                            </FWTypography>
                                            &nbsp;{' '}
                                            <FWTypography variant="caption">
                                                ]
                                            </FWTypography>
                                        </div>
                                    </Box>
                                </FWTooltip>
                            </div>
                            <span></span>
                            &nbsp;&nbsp;
                        </>
                    )}
                    {existingFiles.map((data, idx) => {
                        return (
                            <div
                                key={returnFileId(data)}
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                                className="mb--5"
                            >
                                {data.to_be_deleted ? (
                                    <FWTypography
                                        sx={{
                                            textDecoration: 'line-through',
                                            color: 'text.secondary',
                                            maxWidth: '100%',
                                        }}
                                    >
                                        {data.file_name}
                                    </FWTypography>
                                ) : allowDownload ? (
                                    <Box
                                        sx={{
                                            maxWidth: '100%',
                                            pointerEvents:
                                                isDownloading &&
                                                downloading.includes(
                                                    returnFileId(data)
                                                )
                                                    ? 'none'
                                                    : undefined,
                                            color:
                                                isDownloading &&
                                                downloading.includes(
                                                    returnFileId(data)
                                                )
                                                    ? 'text.secondary'
                                                    : 'primary.main',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            cursor:
                                                isDownloading &&
                                                downloading.includes(
                                                    returnFileId(data)
                                                )
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                        }}
                                        onClick={() => {
                                            handleDownloadFile(
                                                returnFileId(data)
                                            );
                                        }}
                                    >
                                        <div className="flex flex--aic">
                                            <Box width="2px" />

                                            <FWTypography
                                                color={
                                                    data.displayColor ??
                                                    'primary.main'
                                                }
                                            >
                                                {`${data.file_name}`}
                                            </FWTypography>
                                        </div>
                                    </Box>
                                ) : (
                                    <FWTypography
                                        sx={{
                                            maxWidth: '100%',
                                        }}
                                        color={
                                            data.displayColor ?? 'primary.main'
                                        }
                                    >
                                        {`${data.file_name}`}
                                    </FWTypography>
                                )}
                                {showRemove && !data.cannotRemove && (
                                    <FWTooltip
                                        title={
                                            data.to_be_deleted
                                                ? 'Restore'
                                                : 'Delete'
                                        }
                                    >
                                        <>
                                            <Box width="2px" />
                                            <Box
                                                color={
                                                    data.to_be_deleted
                                                        ? 'primary.main'
                                                        : 'error.main'
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleFileRemove!(idx);
                                                }}
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <FWIcon
                                                    name={`bi bi-${
                                                        data.to_be_deleted
                                                            ? 'arrow-counterclockwise'
                                                            : 'trash3'
                                                    }`}
                                                    size={16}
                                                />
                                            </Box>
                                        </>
                                    </FWTooltip>
                                )}
                                {existingFiles.length - 1 !== idx && (
                                    <>
                                        <span>,</span>&nbsp;
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        case 'LIST':
            return (
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    {existingFiles.length > 1 && allowDownload && (
                        <>
                            <div
                                key={'Download all'}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    // justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                        pointerEvents:
                                            isDownloading &&
                                            downloading.includes('_ALL')
                                                ? 'none'
                                                : undefined,
                                        color:
                                            isDownloading &&
                                            downloading.includes('_ALL')
                                                ? 'text.secondary'
                                                : 'primary.main',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        cursor:
                                            isDownloading &&
                                            downloading.includes('_ALL')
                                                ? 'not-allowed'
                                                : 'pointer',
                                    }}
                                    onClick={handleDownloadAllFiles}
                                >
                                    <div className="flex flex--aic">
                                        <i className="bi bi-cloud-download" />
                                        &nbsp;
                                        <FWTypography variant="caption">{`Download all`}</FWTypography>
                                    </div>
                                </Box>
                            </div>
                            <div>
                                <Divider sx={{ margin: '0.5rem 0' }} />
                            </div>
                        </>
                    )}
                    {existingFiles.map((data, idx) => {
                        return (
                            <div
                                key={returnFileId(data)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                                className="mb--5"
                            >
                                {data.to_be_deleted ? (
                                    <FWTypography
                                        sx={{
                                            textDecoration: 'line-through',
                                            color: 'text.secondary',
                                            maxWidth: '100%',
                                        }}
                                    >
                                        {data.file_name}
                                    </FWTypography>
                                ) : allowDownload ? (
                                    <Box
                                        sx={{
                                            maxWidth: '100%',
                                            pointerEvents:
                                                isDownloading &&
                                                downloading.includes(
                                                    returnFileId(data)
                                                )
                                                    ? 'none'
                                                    : undefined,
                                            color: !fromBulkImport
                                                ? isDownloading &&
                                                  downloading.includes(
                                                      returnFileId(data)
                                                  )
                                                    ? 'text.secondary'
                                                    : 'primary.main'
                                                : 'text.primary',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            cursor: !fromBulkImport
                                                ? isDownloading &&
                                                  downloading.includes(
                                                      returnFileId(data)
                                                  )
                                                    ? 'not-allowed'
                                                    : 'pointer'
                                                : 'unset',
                                        }}
                                        onClick={() => {
                                            !fromBulkImport &&
                                                handleDownloadFile(
                                                    returnFileId(data)
                                                );
                                        }}
                                    >
                                        <FWTypography>
                                            {data.file_name}
                                        </FWTypography>
                                    </Box>
                                ) : (
                                    <FWTypography
                                        sx={{
                                            maxWidth: '100%',
                                        }}
                                    >
                                        {data.file_name}
                                    </FWTypography>
                                )}
                                {showRemove && !data.cannotRemove && (
                                    <>
                                        <Box width="2px" />
                                        <FWTooltip
                                            title={
                                                data.to_be_deleted
                                                    ? 'Restore'
                                                    : 'Delete'
                                            }
                                        >
                                            <Box
                                                color={
                                                    data.to_be_deleted
                                                        ? 'primary.main'
                                                        : 'error.main'
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleFileRemove!(idx);
                                                }}
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <FWIcon
                                                    name={`bi bi-${
                                                        data.to_be_deleted
                                                            ? 'arrow-counterclockwise'
                                                            : 'trash3'
                                                    }`}
                                                    size={16}
                                                />
                                            </Box>
                                        </FWTooltip>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
    }
};

export default UploadedFilesList;
