import { Box, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../Common/FWTypograhy';
import { useRfqTemplate } from '../../../Global/Hooks/useRfqTemplate';
// import { IRFQLevelCustomField } from '../../Interfaces/Buyer/RFQ.model';
import { getFWDateTime } from '../../../Common/Utils/DateUtils';
import moment from 'moment';
import { ICustomSection } from '../../../Global/Interfaces/TemplateInterface';

interface IRfqCustomFieldsProps {
    custom_sections: ICustomSection[];
    templateId: string;
    entityId: string;
    accessedBy: 'BUYER' | 'VENDOR';
    gridSize: number;
}

export const RfqCustomFieldsViewMode = ({
    custom_sections,
    entityId,
    templateId,
    accessedBy,
    gridSize,
}: IRfqCustomFieldsProps) => {
    const { templateDetails } = useRfqTemplate(templateId, entityId);

    let RFQLevelCustomFields =
        templateDetails.sections.EVENT_DETAILS.fieldList.filter(
            (field) => field.fieldType === 'CUSTOM'
        );

    const checkIfValueIfUrl = (value: string) => {
        const newLocal = `((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`;
        const regex = new RegExp(newLocal);

        if (!(!isEmpty(value?.trim()) && regex.test(value?.trim() ?? ''))) {
            return false;
        }

        return true;
    };

    return (
        <>
            {RFQLevelCustomFields.map((rfqCustomField) => {
                let fieldInfoFromTemplate =
                    templateDetails?.fieldSettings?.customFields[
                        rfqCustomField?.field
                    ];

                const isFieldNegotiable =
                    fieldInfoFromTemplate?.sellerSettings?.negotiable;

                let fieldSectionInEventDetails: ICustomSection | undefined;
                fieldSectionInEventDetails = custom_sections?.find(
                    (section) =>
                        section.name ===
                        templateDetails.sections.EVENT_DETAILS.label
                );

                let fieldInfoFromEventDetails =
                    fieldSectionInEventDetails?.custom_fields
                        ?.filter((field) => {
                            return isFieldNegotiable
                                ? field.is_negotiable
                                : true;
                        })
                        .find(
                            (field) =>
                                field.name === fieldInfoFromTemplate?.label
                        );
                switch (fieldInfoFromTemplate?.constraints?.fieldType) {
                    case 'SHORTTEXT':
                    case 'LONGTEXT':
                        return (accessedBy === 'VENDOR' &&
                            fieldInfoFromTemplate.sellerSettings.showField) ||
                            accessedBy === 'BUYER' ? (
                            <Grid item lg={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>

                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-info-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>

                                {Boolean(fieldInfoFromEventDetails?.value) &&
                                checkIfValueIfUrl(
                                    fieldInfoFromEventDetails?.value as string
                                ) ? (
                                    <a
                                        href={
                                            (fieldInfoFromEventDetails?.value as string) ??
                                            ''
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Typography
                                            color={'primary'}
                                            sx={{
                                                textDecoration: 'none',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {
                                                fieldInfoFromEventDetails?.value as string
                                            }
                                        </Typography>
                                    </a>
                                ) : (
                                    <FWTypography>
                                        {Boolean(
                                            fieldInfoFromEventDetails?.value
                                        )
                                            ? fieldInfoFromEventDetails?.value
                                            : '-'}
                                    </FWTypography>
                                )}
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'DATE':
                        return (accessedBy === 'VENDOR' &&
                            fieldInfoFromTemplate.sellerSettings.showField) ||
                            accessedBy === 'BUYER' ? (
                            <Grid item lg={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>

                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-info-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {Boolean(
                                        fieldInfoFromEventDetails?.value
                                    ) &&
                                    typeof fieldInfoFromEventDetails?.value ===
                                        'string'
                                        ? moment(
                                              fieldInfoFromEventDetails?.value
                                          ).format("DD MMM 'YY")
                                        : '-'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'DATETIME':
                        return (accessedBy === 'VENDOR' &&
                            fieldInfoFromTemplate.sellerSettings.showField) ||
                            accessedBy === 'BUYER' ? (
                            <Grid item lg={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>

                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-info-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {Boolean(
                                        fieldInfoFromEventDetails?.value
                                    ) &&
                                    typeof fieldInfoFromEventDetails?.value ===
                                        'string'
                                        ? getFWDateTime(
                                              fieldInfoFromEventDetails?.value
                                          )
                                        : '-'}
                                </FWTypography>
                                {/* <SingleDateFieldRenderer
                                    viewMode
                                    
                                    value={
                                        fieldInfoFromEventDetails?.value as
                                            | string
                                            | null
                                    }
                                    rendererProperty={{
                                        gridSize: 12,
                                        label: fieldInfoFromTemplate?.label,
                                        required:
                                            fieldInfoFromEventDetails?.is_required ??
                                            false,
                                        type: 'DATE',
                                        is_hidden:
                                            !fieldInfoFromTemplate
                                                ?.buyerSettings.showField,
                                    }}
                                    error={
                                        isFieldNegotiable
                                            ? errors?.custom_fields_negotiate &&
                                              fieldInfoFromTemplate?.label in
                                                  errors?.custom_fields_negotiate
                                                ? true
                                                : false
                                            : errors?.custom_fields &&
                                              fieldInfoFromTemplate?.label in
                                                  errors?.custom_fields
                                            ? true
                                            : false
                                    }
                                    helper={{
                                        text:
                                            (isFieldNegotiable
                                                ? errors?.custom_fields_negotiate &&
                                                  errors
                                                      .custom_fields_negotiate[
                                                      fieldInfoFromTemplate
                                                          .label
                                                  ]?.message
                                                : errors?.custom_fields &&
                                                  errors?.custom_fields[
                                                      fieldInfoFromTemplate
                                                          .label
                                                  ]?.message) ?? '',
                                    }}
                                    updateHandler={(
                                        newValue: string | null
                                    ) => {
                                        if (
                                            fieldSectionInEventDetails &&
                                            fieldInfoFromEventDetails
                                        ) {
                                            let fieldIndex =
                                                fieldSectionInEventDetails?.fields.findIndex(
                                                    (field) =>
                                                        field.name ===
                                                        fieldInfoFromTemplate?.label
                                                );
                                            let abc = cloneDeep(
                                                fieldSectionInEventDetails
                                            );

                                            abc.fields[fieldIndex].value =
                                                newValue as string;

                                            if (isFieldNegotiable) {
                                                updateEventDetails(
                                                    'custom_fields_negotiate',
                                                    {
                                                        section_list: [
                                                            ...restOfTheSections,
                                                            abc,
                                                        ],
                                                    }
                                                );
                                            } else {
                                                updateEventDetails(
                                                    'custom_fields',
                                                    {
                                                        section_list: [
                                                            ...restOfTheSections,
                                                            abc,
                                                        ],
                                                    }
                                                );
                                            }
                                        }
                                    }}
                                /> */}
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'FLOAT':
                        return (accessedBy === 'VENDOR' &&
                            fieldInfoFromTemplate.sellerSettings.showField) ||
                            accessedBy === 'BUYER' ? (
                            <Grid item lg={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>

                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-info-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {Boolean(fieldInfoFromEventDetails?.value)
                                        ? fieldInfoFromEventDetails?.value
                                        : '-'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'INTEGER':
                        return (accessedBy === 'VENDOR' &&
                            fieldInfoFromTemplate.sellerSettings.showField) ||
                            accessedBy === 'BUYER' ? (
                            <Grid item lg={gridSize}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>

                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-info-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>
                                <FWTypography>
                                    {Boolean(fieldInfoFromEventDetails?.value)
                                        ? fieldInfoFromEventDetails?.value
                                        : '-'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'BOOLEAN':
                        return (accessedBy === 'VENDOR' &&
                            fieldInfoFromTemplate.sellerSettings.showField) ||
                            accessedBy === 'BUYER' ? (
                            <Grid
                                sx={{ width: 'fit-content' }}
                                item
                                lg={gridSize}
                            >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <FWTypography color={'text.secondary'}>
                                        {fieldInfoFromTemplate?.label}{' '}
                                    </FWTypography>

                                    {fieldInfoFromTemplate?.description && (
                                        <FWTooltip
                                            title={
                                                fieldInfoFromTemplate?.description
                                            }
                                            placement="right"
                                        >
                                            <Box color="primary.main">
                                                <i className="bi bi-info-circle" />
                                            </Box>
                                        </FWTooltip>
                                    )}
                                </Grid>

                                <FWTypography>
                                    {fieldInfoFromEventDetails?.value === true
                                        ? 'Yes'
                                        : fieldInfoFromEventDetails?.value ===
                                          false
                                        ? 'No'
                                        : 'NA(None)'}
                                </FWTypography>
                            </Grid>
                        ) : (
                            <></>
                        );
                    case 'CHOICE':
                        const is_required =
                            fieldInfoFromTemplate.buyerSettings.required;
                        if (
                            fieldInfoFromTemplate?.constraints.choiceType ===
                            'DROPDOWN'
                        ) {
                            return (accessedBy === 'VENDOR' &&
                                fieldInfoFromTemplate.sellerSettings
                                    .showField) ||
                                accessedBy === 'BUYER' ? (
                                <Grid item lg={gridSize}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}{' '}
                                        </FWTypography>

                                        {fieldInfoFromTemplate?.description && (
                                            <FWTooltip
                                                title={
                                                    fieldInfoFromTemplate?.description
                                                }
                                                placement="right"
                                            >
                                                <Box color="primary.main">
                                                    <i className="bi bi-info-circle" />
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Grid>

                                    <FWTypography>
                                        {Boolean(
                                            fieldInfoFromEventDetails?.value
                                        )
                                            ? fieldInfoFromEventDetails?.value
                                            : is_required
                                            ? '-'
                                            : 'NA(None)'}
                                    </FWTypography>
                                </Grid>
                            ) : (
                                <></>
                            );
                        } else {
                            return (accessedBy === 'VENDOR' &&
                                fieldInfoFromTemplate.sellerSettings
                                    .showField) ||
                                accessedBy === 'BUYER' ? (
                                <Grid item lg={gridSize}>
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <FWTypography color={'text.secondary'}>
                                            {fieldInfoFromTemplate?.label}{' '}
                                        </FWTypography>

                                        {fieldInfoFromTemplate?.description && (
                                            <FWTooltip
                                                title={
                                                    fieldInfoFromTemplate?.description
                                                }
                                                placement="right"
                                            >
                                                <Box color="primary.main">
                                                    <i className="bi bi-info-circle" />
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Grid>
                                    <FWTypography>
                                        {(
                                            fieldSectionInEventDetails?.custom_fields?.find(
                                                (field) =>
                                                    field.name ===
                                                    fieldInfoFromTemplate?.label
                                            )?.value as string[]
                                        )?.length > 0
                                            ? (
                                                  fieldSectionInEventDetails?.custom_fields?.find(
                                                      (field) =>
                                                          field.name ===
                                                          fieldInfoFromTemplate?.label
                                                  )?.value as string[]
                                              )?.map((val) => (
                                                  <span
                                                      style={{
                                                          marginRight: '5px',
                                                      }}
                                                  >
                                                      {val};
                                                  </span>
                                              ))
                                            : '-'}
                                    </FWTypography>
                                </Grid>
                            ) : (
                                <></>
                            );
                        }
                    default:
                        return <></>;
                }
            })}
        </>
    );
};
