import { createSlice } from '@reduxjs/toolkit';
// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import { IPurchaseOrderItem } from '../../Models/AwardsAndPO.model';
import { IMeasurementUnitDetails } from '../../Models/MeasurementUnit.model';
import {
    IBomItemDeliverScheduleStructure,
    ICustomEventDetails,
    ICustomEventItemDetails,
    // IEventItemCustomFieldsBackend,
    IEventItemCustomFieldSections,
} from '../Interfaces/Buyer/RFQ.model';
import {
    IBOMForBomAdminForEvent,
    IBOMItemForAdminForEvent,
} from '../Interfaces/Buyer/RFQBOM.model';
import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import {
    BackendInvoiceItemPaymentTerms,
    BackendPaymentType,
    BackendPoItemsPaymentTerms,
} from '../../Global/Interfaces/PaymentTermsInterface';
import { IEntityViewBuyerMaster } from '../../Organizations/SellerAdmin/BuyerMaster/Interfaces/SellerAdminBuyerInterfaces.model';
import { PeriodEnum } from '../../Global/Interfaces/PeriodInterface';
import { roundToNDecimalPlace } from '../../Common/CurrencyUtilsComponent';
import { IRfqAnalyticsStoreListOfBomStructWIthHeiriacy } from './RfqAnalyticsSlice';
import { ICustomAttributeNew } from '../../Models/Attributes.model';
import { ICustomSection } from '../../Global/Interfaces/TemplateInterface';

// allPOItemsAreFullfilled: boolean

// poItemsGroupedByItemEntry: {
//     [key: string]: {
//         po_id: string;
//         custom_po_id: string;
//         po_status: string;
//         po_notes: string;
//         po_last_action_notes: string;
//         po_seller_notes: string;
//     }[];
// }

export interface IEventCreationStore {
    isLoading: boolean;
    isAnyItemUpdated: boolean;
    selectedBomForFiltering: string | null;
    selectedSubBomForFiltering: string | null;
    eventDetails: ICustomEventDetails | null;
    event_save_state: {
        save_state: 'SAVED' | 'UNSAVED' | 'ERROR';
        validity: 'INVALID' | 'VALID';
    };
    eventItems: ICustomEventItemDetails[];
    dictOfEventItems: {
        [event_item_entry_id: string]: ICustomEventItemDetails;
    };
    measurementUnits: {
        [event_item_entry_id: string]: IMeasurementUnitDetails[];
    };
    poItemsGroupedByItemEntry: {
        [event_item_entry_id: string]: {
            po_id: string;
            custom_po_id: string;
            po_status: string;
            po_notes: string;
            po_last_action_notes: string;
            po_seller_notes: string;
        }[];
    };
    allPOItemsAreFullfilled: boolean;
    lastPOsForItems: {
        [event_item_entry_id: string]: IPurchaseOrderItem | null;
    };
    item_ids: string[];
    enterprise_item_ids: string[];
    item_state: {
        save_state: 'SAVED' | 'UNSAVED' | 'ERROR';
        validity: 'INVALID' | 'VALID';
    }[];
    item_vendors: {
        [key: string]: string[];
    };
    dictOfBoms: {
        [itemUid: string]: IBomItemDeliverScheduleStructure[];
    };
    listOfBomsOfCurrentEventsWithSubBomOptions: IRfqAnalyticsStoreListOfBomStructWIthHeiriacy[];
    showBomConfigurationType: 'MANUAL' | 'NONE' | 'AUTOMATIC' | 'MANUAL_UPDATE';
    selectBomIdBomConfigurationPopup: string | null;
    dictOfAllTheBomsDetails: {
        [bomEnterpriseId: string]: IBOMForBomAdminForEvent;
    };
    currentPage: number;
    openAddItemContainer: boolean;
    searchQuery: string;
    searchQueryType: 'ITEMS' | 'VENDORS';
    listOfErrorsAccrossPages: {
        rfq_item_entery_id: string;
        itemDetails: ICustomEventItemDetails | null;
        validity: 'INVALID' | 'VALID';
        noOfVendorsSelected: number;
        pageNoItem: number;
        itemNumber: number;
        itemNo: number;
    }[];
    listOfErrorsAccrossPagesCopy: {
        // this will be used to make sure the page doesnt refreshes
        rfq_item_entery_id: string;
        itemDetails: ICustomEventItemDetails | null;
        validity: 'INVALID' | 'VALID';
        noOfVendorsSelected: number;
        pageNoItem: number;
        itemNumber: number;
        itemNo: number;
    }[];
    /**THIS LIST WILL CONTAIN ITEM IDS THAT HAVE BEEN COMBINED FROM (IE NOT ANYMORE IN THE EVENT;
     * WILL BE USED TO CLEAR THE LISTOFERRORSACCROSSPAGES OF ITEMS THAT DO NOT EXIST IN THE EVENT ANYMORE)
     */
    listOfItemIdsCombined: string[];
    filteringType: 'ALL_ITEMS' | 'ITEMS_WITH_ERRORS' | 'ITEMS_WITH_WARNINGS';
    filteringCurrentPage: number;
    template_custom_field_sections: IEventItemCustomFieldSections | null;
    cacheSetting: {
        curentPage: number;
        isLoading: boolean;
    };
    arePoAvailable: boolean;
    buyerEntities: IEntityViewBuyerMaster[];
    changesMadeInShowBomConfiguration: number;
    showCombineItemsPopup: boolean;
    applyToBomItemsValues: {
        measurement_unit_primary_name: any;
        quantity: string;
        measurement_unit: string;
        cost_per_unit: string; // rate
        custom_sections: ICustomSection[];
        delivery_schedule: {
            bom_item: IBomItemDeliverScheduleStructure | null;
            delivery_date: string | null;
            quantity: string;
            project_id: string | null;
            cost_centre_id: string | null;
            general_ledger_id: string | null;
        }[];
        requisition_number: string;
        requisition_approved: boolean;
        incoterm_id: string;
        lead_time: string;
        lead_time_period: string | null;
        taxes: IAdditionalCostsBackend[];
        additional_costs: IAdditionalCostsBackend[];
        discounts: IAdditionalCostsBackend[];
        incoterm_name: string;
        payment_type: BackendPaymentType | null;
        prepayment_percentage: string | number;
        payment_terms: BackendInvoiceItemPaymentTerms | null;
        deliverables_payment_terms: BackendPoItemsPaymentTerms[] | null;
        item_additional_information: string;
        quantity_tolerance_percentage: string;
    };
}

export interface IUpdateEventStoreAction {
    type: string;
    payload:
        | { type: 'UPATE_IS_LOADING'; value: boolean }
        | { type: 'UPDATE_IS_ANY_ITEM_UPDATED_STATUS'; value: boolean }
        | { type: 'UPDATE_EVENT_DETAILS'; value: ICustomEventDetails }
        | {
              type: 'UPDATE_EVENT_SAVE_STATE';
              value: 'SAVED' | 'UNSAVED' | 'ERROR';
          }
        | { type: 'UPDATE_EVENT_VALIDITY'; value: 'INVALID' | 'VALID' }
        | { type: 'UPDATE_ALL_EVENT_ITEMS'; value: ICustomEventItemDetails[] }
        | { type: 'UPDATE_ALL_ITEM_ENTERPRISE_IDS'; value: string[] }
        | {
              type: 'UPDATE_SINGLE_EVENT_ITEM';
              value: {
                  idx: number;
                  data: ICustomEventItemDetails;
              };
          }
        | {
              type: 'DELETE_SINGLE_EVENT_ITEM';
              value: number;
          }
        | {
              type: 'UPDATE_ITEM_SAVE_STATE';
              value: {
                  idx1: number;
                  data1: 'SAVED' | 'UNSAVED' | 'ERROR';
              };
          }
        | {
              type: 'UPDATE_ALL_ITEM_SAVE_STATE';
              value: {
                  allData: 'SAVED' | 'UNSAVED' | 'ERROR';
              };
          }
        | {
              type: 'UPDATE_ITEM_VALIDITY';
              value: {
                  idx2: number;
                  data2: 'VALID' | 'INVALID';
              };
          }
        | {
              type: 'UPDATE_ALL_ITEM_VALIDITY';
              value: {
                  allData2: 'VALID' | 'INVALID';
              };
          }
        | {
              type: 'UPDATE_VENDOR_FOR_ITEM';
              value: {
                  item_entry_id: string;
                  vendor: string[];
              };
          }
        | {
              type: 'UPDATE_VENDOR_FOR_ALL_ITEM';
              value: {
                  [item_entry_id: string]: string[];
              };
          }
        | {
              type: 'REVERT_EVENT_STATUS_TO_DRAFT';
              value: null;
          }
        | {
              type: 'UPDATE_SLECTED_ENTERPRISE_SUB_BOM_ID';
              value: string | null;
          }
        | {
              type: 'UPDATE_ADMIN_CUSTOM_FIELD_SECTIONS';
              value: IEventItemCustomFieldSections;
          }
        | {
              type: 'RESET';
              value: null;
          }
        | {
              type: 'UPDATE_CURRENT_PAGE';
              value: number;
          }
        | {
              // filteringCurrentPage
              type: 'UPDATE_FILTERING_CURRENT_PAGE';
              value: number;
          }
        | {
              type: 'UPDATE_SEARCH_QUERY';
              value: string;
          }
        | {
              type: 'UPDATE_LIST_OF_ITEM_IDS_COMBINED_TO';
              value: string[];
          }
        | {
              type: 'UPDATE_CACHE_ERRORS';
              value:
                  | {
                        type_of_action: 'REMOVE_ITEM';
                        item_id: string;
                    }
                  | {
                        itemNumber?: number;
                        type_of_action: 'UPDATE_SINGLE_ITEM';
                        rfq_item_entery_id: string;
                        itemDetails?: ICustomEventItemDetails;
                        validity?: 'INVALID' | 'VALID';
                        noOfVendorsSelected?: number;
                        pageNoItem?: number;
                        itemNo?: number;
                    }
                  | {
                        type_of_action: 'UPDATE_MULTIPLE_ITEM';
                        listOfItems: {
                            itemNumber?: number;
                            rfq_item_entery_id: string;
                            itemDetails?: ICustomEventItemDetails;
                            validity?: 'INVALID' | 'VALID';
                            noOfVendorsSelected?: number;
                            pageNoItem?: number;
                            itemNo?: number;
                        }[];
                    };
          }
        | { type: 'REMOVE_CACHE_ERROS'; value: null }
        | {
              type: 'UPDATE_SEARCH_FILTERS';
              value: {
                  filteringType?:
                      | 'ALL_ITEMS'
                      | 'ITEMS_WITH_ERRORS'
                      | 'ITEMS_WITH_WARNINGS';
                  currentPage?: number;
              };
          }
        | {
              type: 'UPDATE_ALL_ITEM_ENTERPRISE_IDS';
              value: string[];
          }
        | {
              type: 'UPDATE_ALL_MEASUREMENTS_UNITS';
              value: {
                  [key: string]: IMeasurementUnitDetails[];
              };
          }
        | {
              type: 'UPDATE_ALL_PO_ITEMS_ARE_FULL_fILLED';
              value: boolean;
          }
        | {
              // type: "UDPATE_poItemsGroupedByItemEntry",
              type: 'UPDATE_PO_ITEMS_GROUPED_BY_ITEM_ENTRY';
              value: {
                  [key: string]: {
                      po_id: string;
                      custom_po_id: string;
                      po_status: string;
                      po_notes: string;
                      po_last_action_notes: string;
                      po_seller_notes: string;
                  }[];
              };
          }
        | {
              // type: "lastPOsForItems"
              type: 'UPDATE_LAST_POS_FOR_ITEMS';
              value: {
                  [event_item_entry_id: string]: IPurchaseOrderItem | null;
              };
          }
        | {
              // type: 'openAddItemContainer',
              type: 'UPDATE_OPEN_ADD_ITEM_CONTAINER';
              value: boolean;
          }
        | {
              type: 'UPDATE_SINGLE_DICT_OF_EVENT_ITEMS';
              value: ICustomEventItemDetails;
          }
        | {
              type: 'UPDATE_MULTIPLE_DICT_OF_EVENT_ITEMS';
              value: {
                  [event_item_entry_id: string]: ICustomEventItemDetails;
              };
          }
        | {
              type: 'UPDATE_SEARCH_QERY_TYPE';
              value: 'ITEMS' | 'VENDORS';
          }
        | {
              type: 'UPDATE_CACHING_SETTING_PAGE';
              value: number;
          }
        | {
              type: 'UPDATE_CACHING_SETTING_LOADING';
              value: boolean;
          }
        | {
              type: 'UPDATE_DICT_OF_BOM';
              value: {
                  [itemUid: string]: IBomItemDeliverScheduleStructure[];
              };
          }
        | {
              type: 'UPDATE_SELECTED_BOM_ID_FOR_BOM_CONFIGURATION';
              value: string | null;
          }
        | { type: 'UPDATE_PO_AVAILABILITY'; value: boolean }
        | {
              type: 'UPDATE_SHOW_BOM_CONFIGURATION_TYPE';
              value: 'MANUAL' | 'NONE' | 'AUTOMATIC' | 'MANUAL_UPDATE';
          }
        | {
              type: 'UPDATE_ENTITY_VIEW_BUYER_MASTER';
              value: IEntityViewBuyerMaster[];
          }
        | {
              type: 'UDPATE_SELECTED_BOM_FOR_FILTERING';
              value: string | null;
          }
        | { type: 'UPDATE_SHOW_WARNING'; value: boolean }
        | {
              type: 'UPDATE_ATTRIBUTES';
              value: { attributes: ICustomAttributeNew[]; idx: number };
          };
}

export interface IUpdateEventBomStoreAction {
    type: string;
    payload:
        | {
              type: 'UPDATE_DICT_OF_ALL_THE_BOMS_DETAILS';
              value: { [bomEnterpriseId: string]: IBOMForBomAdminForEvent };
          }
        | {
              type: 'UPDATE_BOM_QUANTITY';
              value: string | number;
          }
        | {
              type: 'RESET';
              value: null;
          }
        | {
              type: 'SELECT_AN_ALTERNATIVE';
              value: {
                  bomItemIndex: number;
                  alternativeIndex: number;
              };
          }
        | {
              type: 'UPDATE_ITEM_LEVEL_DETAILS';
              value: {
                  listOfIndex: number[];
                  updatePayload:
                      | {
                            field:
                                | 'quantity'
                                | 'cost_per_unit'
                                | 'item_additional_information'
                                | 'quantity_tolerance_percentage'
                                | 'incoterm_name'
                                | 'incoterm_id'
                                | 'requisition_number'
                                | 'lead_time'
                                | 'lead_time_period'
                                | 'measurement_unit';
                            value: string;
                        }
                      | {
                            field: 'Taxes' | 'Discounts' | 'AdditionalCost';
                            value: IAdditionalCostsBackend[];
                        }
                      | {
                            field: 'payment_type';
                            value: BackendPaymentType | null;
                        }
                      | {
                            field: 'prepayment_percentage';
                            value: string;
                        }
                      | {
                            field: 'requisition_approved' | 'selected';
                            value: boolean;
                        }
                      | {
                            field: 'payment_terms';
                            value: BackendInvoiceItemPaymentTerms | null;
                        }
                      | {
                            field: 'deliverables_payment_terms';
                            value: BackendPoItemsPaymentTerms[] | null;
                        }
                      | {
                            field: 'delivery_schedule';
                            value: {
                                bom_item: IBomItemDeliverScheduleStructure | null;
                                delivery_date: string | null;
                                quantity: string;
                                project_id: string | null;
                                cost_centre_id: string | null;
                                general_ledger_id: string | null;
                            }[];
                        };
              };
          }
        | {
              type: 'UPDATE_ITEM_LEVEL_DETAILS_FOR_ALTERNATIVES';
              value: {
                  listOfIndex: number[];
                  alternativeIndex: number;
                  updatePayload:
                      | {
                            field:
                                | 'quantity'
                                | 'cost_per_unit'
                                | 'item_additional_information'
                                | 'quantity_tolerance_percentage'
                                | 'incoterm_name'
                                | 'incoterm_id'
                                | 'requisition_number'
                                | 'lead_time'
                                | 'lead_time_period';
                            value: string;
                        }
                      | {
                            field: 'Taxes' | 'Discounts' | 'AdditionalCost';
                            value: IAdditionalCostsBackend[];
                        }
                      | {
                            field: 'Taxes' | 'Discounts' | 'AdditionalCost';
                            value: IAdditionalCostsBackend[];
                        }
                      | {
                            field: 'payment_type';
                            value: BackendPaymentType | null;
                        }
                      | {
                            field: 'prepayment_percentage';
                            value: string;
                        }
                      | {
                            field: 'requisition_approved' | 'selected';
                            value: boolean;
                        }
                      | {
                            field: 'payment_terms';
                            value: BackendInvoiceItemPaymentTerms | null;
                        }
                      | {
                            field: 'deliverables_payment_terms';
                            value: BackendPoItemsPaymentTerms[] | null;
                        }
                      | {
                            field: 'delivery_schedule';
                            value: {
                                bom_item: IBomItemDeliverScheduleStructure | null;
                                delivery_date: string | null;
                                quantity: string;
                                project_id: string | null;
                                cost_centre_id: string | null;
                                general_ledger_id: string | null;
                            }[];
                        };
              };
          }
        | {
              type: 'UPDATE_ITEM_LEVEL_CUSTOM_FIELDSDETAILS';
              value: {
                  listOfIndex: number[];
                  sectionIndex: number;
                  fieldIndex: number;
                  isNeogitable: boolean;
                  updatePayload: {
                      value: string | null | string[] | boolean;
                  };
              };
          }
        | {
              type: 'UPDATE_ITEM_LEVEL_CUSTOM_FIELDSDETAILS_FOR_ALTERNATIVES';
              value: {
                  listOfIndex: number[];
                  alternativeIndex: number;
                  sectionIndex: number;
                  fieldIndex: number;
                  isNeogitable: boolean;
                  updatePayload: {
                      value: string | null | string[] | boolean;
                  };
              };
          }
        | {
              type: 'APPLY_TO_ALL_ACTION';
              value: null;
          }
        | {
              type: 'UPDATE_APPLY_FOR_ALL';
              value: {
                  updatePayload:
                      | {
                            field:
                                | 'quantity'
                                | 'cost_per_unit'
                                | 'item_additional_information'
                                | 'quantity_tolerance_percentage'
                                | 'incoterm_name'
                                | 'incoterm_id'
                                | 'requisition_number'
                                | 'lead_time'
                                | 'lead_time_period';
                            value: string;
                        }
                      | {
                            field: 'Taxes' | 'Discounts' | 'AdditionalCost';
                            value: IAdditionalCostsBackend[];
                        }
                      | {
                            field: 'payment_type';
                            value: BackendPaymentType | null;
                        }
                      | {
                            field: 'prepayment_percentage';
                            value: string;
                        }
                      | {
                            field: 'requisition_approved';
                            value: boolean;
                        }
                      | {
                            field: 'payment_terms';
                            value: BackendInvoiceItemPaymentTerms | null;
                        }
                      | {
                            field: 'deliverables_payment_terms';
                            value: BackendPoItemsPaymentTerms[] | null;
                        }
                      | {
                            field: 'delivery_schedule';
                            value: {
                                bom_item: IBomItemDeliverScheduleStructure | null;
                                delivery_date: string | null;
                                quantity: string;
                                project_id: string | null;
                                cost_centre_id: string | null;
                                general_ledger_id: string | null;
                            }[];
                        }
                      | {
                            field: 'custom_sections';
                            value: ICustomSection[];
                        };
              };
          }
        | {
              type: 'UPDATE_SHOW_COMBINE_ITEMS_POPUP';
              value: boolean;
          }
        | {
              type: 'UPDATE_BOM_TOTAL';
              value: number;
          }
        | {
              type: 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT_WITH_HEIRARY';
              value: IRfqAnalyticsStoreListOfBomStructWIthHeiriacy[];
          };
}

export const EventCreationSlice = createSlice<
    IEventCreationStore,
    {
        updateEventDataStore: (
            state: IEventCreationStore,
            action: IUpdateEventStoreAction
        ) => void;
        updateEventBoomDataStore: (
            state: IEventCreationStore,
            action: IUpdateEventBomStoreAction
        ) => void;
    },
    string
>({
    name: 'CreateEventStore',
    initialState: {
        isLoading: false,
        isAnyItemUpdated: false,
        eventDetails: null,
        eventItems: [],
        dictOfEventItems: {},
        event_save_state: {
            save_state: 'SAVED',
            validity: 'VALID',
        },
        dictOfBoms: {},
        // selectBomIdBomConfigurationPopup:
        //     '671a9382-6f72-43b1-806e-02db3c7a73ab',
        selectBomIdBomConfigurationPopup: null,
        poItemsGroupedByItemEntry: {},
        allPOItemsAreFullfilled: true,
        lastPOsForItems: {},
        measurementUnits: {},
        item_ids: [],
        enterprise_item_ids: [],
        item_state: [],
        item_vendors: {},
        searchQuery: '',
        searchQueryType: 'ITEMS',
        listOfErrorsAccrossPages: [],
        listOfErrorsAccrossPagesCopy: [],
        listOfItemIdsCombined: [],
        filteringType: 'ALL_ITEMS',
        filteringCurrentPage: 1,
        template_custom_field_sections: null,
        currentPage: 1,
        openAddItemContainer: false,
        cacheSetting: {
            curentPage: 1,
            isLoading: true,
        },
        showCombineItemsPopup: false,
        // showBomConfigurationType: 'MANUAL',
        showBomConfigurationType: 'NONE',
        dictOfAllTheBomsDetails: {},
        listOfBomsOfCurrentEventsWithSubBomOptions: [],
        arePoAvailable: false,
        buyerEntities: [],
        selectedBomForFiltering: null,
        selectedSubBomForFiltering: null,
        changesMadeInShowBomConfiguration: 0,
        applyToBomItemsValues: {
            additional_costs: [],
            cost_per_unit: '',
            custom_sections: [],
            deliverables_payment_terms: null,
            delivery_schedule: [],
            discounts: [],
            incoterm_id: 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
            incoterm_name: 'None',
            item_additional_information: '',
            lead_time: '',
            lead_time_period: null,
            measurement_unit: '',
            payment_terms: {
                applied_from: 'INVOICE_DATE',
                period: PeriodEnum.DAYS,
                term: 0,
            },
            payment_type: 'PER_INVOICE_ITEM',
            prepayment_percentage: '',
            quantity: '',
            quantity_tolerance_percentage: '',
            requisition_approved: false,
            requisition_number: '',
            taxes: [],
            measurement_unit_primary_name: '',
        },
    },
    reducers: {
        updateEventDataStore: (
            state: IEventCreationStore,
            action: IUpdateEventStoreAction
        ) => {
            try {
                switch (action.payload.type) {
                    case 'UPATE_IS_LOADING':
                        state.isLoading = action.payload.value;
                        break;
                    case 'UPDATE_IS_ANY_ITEM_UPDATED_STATUS':
                        state.isAnyItemUpdated = action.payload.value;
                        break;
                    case 'UPDATE_EVENT_DETAILS':
                        state.eventDetails = action.payload
                            .value as ICustomEventDetails;
                        break;
                    case 'UPDATE_EVENT_SAVE_STATE':
                        state.event_save_state.save_state = action.payload
                            .value as 'SAVED' | 'UNSAVED' | 'ERROR';
                        break;

                    case 'UPDATE_EVENT_VALIDITY':
                        state.event_save_state.validity = action.payload
                            .value as 'INVALID' | 'VALID';
                        break;
                    case 'UPDATE_ALL_EVENT_ITEMS':
                        // state.item_vendors = {};
                        state.eventItems = action.payload
                            .value as ICustomEventItemDetails[];
                        let item_id_arr: string[] = [];
                        let save_state_arr: any[] = [];
                        (
                            action.payload.value as ICustomEventItemDetails[]
                        ).forEach((item, idx) => {
                            item_id_arr.push(item.event_item_entry_id);
                            save_state_arr.push({
                                save_state: 'SAVED',
                                validity: 'VALID',
                            });
                        });
                        state.item_ids = item_id_arr;
                        state.item_state = save_state_arr;
                        break;
                    case 'UPDATE_ALL_ITEM_ENTERPRISE_IDS':
                        state.enterprise_item_ids = action.payload
                            .value as string[];
                        break;
                    case 'UPDATE_SINGLE_EVENT_ITEM':
                        const { idx, data } = action.payload.value as {
                            idx: number;
                            data: ICustomEventItemDetails;
                        };
                        if (idx === -1) {
                            state.eventItems.push(data);
                            state.item_ids.push(data.event_item_entry_id);
                            state.enterprise_item_ids.push(
                                data.enterprise_item_id
                            );
                            state.item_state.push({
                                save_state: 'SAVED',
                                validity: 'INVALID',
                            });
                        } else {
                            state.eventItems[idx] = data;
                            if (state.item_ids[idx] === '') {
                                state.item_ids[idx] = data.event_item_entry_id;
                            }
                        }
                        break;

                    case 'DELETE_SINGLE_EVENT_ITEM':
                        delete state.item_vendors[
                            state.eventItems[action.payload.value as number]
                                .event_item_entry_id
                        ];
                        state.eventItems.splice(
                            action.payload.value as number,
                            1
                        );
                        state.item_ids.splice(
                            action.payload.value as number,
                            1
                        );
                        state.enterprise_item_ids.splice(
                            action.payload.value as number,
                            1
                        );
                        state.item_state.splice(
                            action.payload.value as number,
                            1
                        );
                        break;
                    case 'UPDATE_ITEM_SAVE_STATE':
                        const { idx1, data1 } = action.payload.value as {
                            idx1: number;
                            data1: 'SAVED' | 'UNSAVED' | 'ERROR';
                        };

                        if (state.item_state[idx1]) {
                            state.item_state[idx1].save_state = data1;
                        }
                        break;
                    case 'UPDATE_ATTRIBUTES':
                        state.eventItems[action.payload.value.idx].attributes =
                            action.payload.value.attributes;
                        break;
                    case 'UPDATE_ALL_ITEM_SAVE_STATE':
                        const { allData } = action.payload.value as {
                            allData: 'SAVED' | 'UNSAVED' | 'ERROR';
                        };

                        state.item_state.forEach((singleItemState) => {
                            singleItemState.save_state = allData;
                        });

                        break;
                    case 'UPDATE_ITEM_VALIDITY':
                        const { idx2, data2 } = action.payload.value as {
                            idx2: number;
                            data2: 'VALID' | 'INVALID';
                        };

                        if (state.item_state[idx2]) {
                            state.item_state[idx2].validity = data2;
                        }
                        break;
                    case 'UPDATE_ALL_ITEM_VALIDITY':
                        const { allData2 } = action.payload.value as {
                            allData2: 'VALID' | 'INVALID';
                        };

                        state.item_state.forEach((singleItemState) => {
                            singleItemState.validity = allData2;
                        });
                        break;
                    case 'UPDATE_VENDOR_FOR_ITEM':
                        const { item_entry_id, vendor } = action.payload
                            .value as {
                            item_entry_id: string;
                            vendor: string[];
                        };
                        state.item_vendors[item_entry_id] = vendor;
                        break;
                    case 'UPDATE_VENDOR_FOR_ALL_ITEM':
                        state.item_vendors = action.payload.value;

                        break;
                    case 'REVERT_EVENT_STATUS_TO_DRAFT':
                        state.eventDetails!.event_status = 'DRAFT';
                        state.eventDetails!.sub_event_status = 'DRAFT';
                        break;
                    case 'UPDATE_ADMIN_CUSTOM_FIELD_SECTIONS':
                        state.template_custom_field_sections = action.payload
                            .value as IEventItemCustomFieldSections;
                        break;

                    case 'UPDATE_CURRENT_PAGE':
                        state.currentPage = action.payload.value;

                        break;
                    case 'UPDATE_FILTERING_CURRENT_PAGE':
                        state.filteringCurrentPage = action.payload.value;
                        break;

                    case 'UPDATE_SEARCH_QUERY':
                        state.searchQuery = action.payload.value as string;
                        break;
                    case 'UPDATE_LIST_OF_ITEM_IDS_COMBINED_TO':
                        state.listOfItemIdsCombined = action.payload.value;
                        break;
                    case 'UPDATE_CACHE_ERRORS':
                        const actionValue = action.payload.value;
                        let newListOfErrors = cloneDeep(
                            state.listOfErrorsAccrossPages
                        );

                        if (actionValue.type_of_action === 'REMOVE_ITEM') {
                            const itemId = actionValue.item_id;
                            newListOfErrors = newListOfErrors.filter(
                                (item) => item.rfq_item_entery_id !== itemId
                            );
                        } else if (
                            actionValue.type_of_action ===
                            'UPDATE_MULTIPLE_ITEM'
                        ) {
                            for (let item of actionValue.listOfItems) {
                                const foundIndex = newListOfErrors.findIndex(
                                    (i) =>
                                        i.rfq_item_entery_id ===
                                        item.rfq_item_entery_id
                                );

                                if (foundIndex >= 0) {
                                    newListOfErrors[foundIndex] = {
                                        noOfVendorsSelected:
                                            item.noOfVendorsSelected ??
                                            newListOfErrors[foundIndex]
                                                .noOfVendorsSelected,

                                        pageNoItem:
                                            item.pageNoItem ??
                                            newListOfErrors[foundIndex]
                                                .pageNoItem,
                                        rfq_item_entery_id:
                                            item.rfq_item_entery_id ??
                                            newListOfErrors[foundIndex]
                                                .rfq_item_entery_id,
                                        itemDetails:
                                            item.itemDetails ??
                                            newListOfErrors[foundIndex]
                                                .itemDetails,
                                        validity:
                                            item.validity === 'VALID'
                                                ? (item.noOfVendorsSelected ??
                                                      newListOfErrors[
                                                          foundIndex
                                                      ].noOfVendorsSelected) > 0
                                                    ? 'VALID'
                                                    : 'INVALID'
                                                : 'INVALID' ??
                                                  newListOfErrors[foundIndex]
                                                      .validity,
                                        itemNumber:
                                            item.itemNumber ??
                                            newListOfErrors[foundIndex]
                                                .itemNumber,
                                        itemNo:
                                            item.itemNo ??
                                            newListOfErrors[foundIndex].itemNo,
                                    };
                                } else {
                                    newListOfErrors = [
                                        ...newListOfErrors,
                                        {
                                            rfq_item_entery_id:
                                                item.rfq_item_entery_id,
                                            itemDetails:
                                                item.itemDetails ?? null,
                                            noOfVendorsSelected:
                                                item.noOfVendorsSelected ?? 0,
                                            pageNoItem: item.pageNoItem ?? 0,
                                            validity:
                                                item.validity === 'VALID' &&
                                                (item.noOfVendorsSelected ??
                                                    0) > 0
                                                    ? 'VALID'
                                                    : 'INVALID' ?? 'INVALID',
                                            itemNumber: item.itemNumber ?? -1,
                                            itemNo: item.itemNo ?? 0,
                                        },
                                    ];
                                }
                            }
                        } else if (
                            actionValue.type_of_action === 'UPDATE_SINGLE_ITEM'
                        ) {
                            const foundIndex = newListOfErrors.findIndex(
                                (i) =>
                                    i.rfq_item_entery_id ===
                                    actionValue.rfq_item_entery_id
                            );
                            const listOfItemsCombinedTo = cloneDeep(
                                state.listOfItemIdsCombined
                            );
                            const isItemCombinedToAnotherItem =
                                listOfItemsCombinedTo.includes(
                                    actionValue.rfq_item_entery_id
                                );
                            if (foundIndex >= 0) {
                                newListOfErrors[foundIndex] = {
                                    noOfVendorsSelected:
                                        actionValue.noOfVendorsSelected ??
                                        newListOfErrors[foundIndex]
                                            .noOfVendorsSelected,
                                    itemDetails:
                                        actionValue.itemDetails ??
                                        newListOfErrors[foundIndex].itemDetails,
                                    pageNoItem:
                                        actionValue.pageNoItem ??
                                        newListOfErrors[foundIndex].pageNoItem,
                                    rfq_item_entery_id:
                                        actionValue.rfq_item_entery_id ??
                                        newListOfErrors[foundIndex]
                                            .rfq_item_entery_id,
                                    validity:
                                        actionValue.validity === 'VALID' &&
                                        (actionValue.noOfVendorsSelected ??
                                            newListOfErrors[foundIndex]
                                                .noOfVendorsSelected) > 0
                                            ? 'VALID'
                                            : actionValue.validity === 'INVALID'
                                            ? 'INVALID'
                                            : newListOfErrors[foundIndex]
                                                  .validity ?? 'INVALID',
                                    itemNumber:
                                        actionValue.itemNumber ??
                                        newListOfErrors[foundIndex].itemNumber,
                                    itemNo:
                                        actionValue.itemNo ??
                                        newListOfErrors[foundIndex].itemNo,
                                };
                            } else {
                                if (!isItemCombinedToAnotherItem) {
                                    newListOfErrors.push({
                                        rfq_item_entery_id:
                                            actionValue.rfq_item_entery_id,
                                        itemDetails:
                                            actionValue.itemDetails ?? null,
                                        noOfVendorsSelected:
                                            actionValue.noOfVendorsSelected ??
                                            0,
                                        pageNoItem: actionValue.pageNoItem ?? 0,
                                        validity:
                                            actionValue.validity === 'VALID' &&
                                            (actionValue.noOfVendorsSelected ??
                                                0) > 0
                                                ? 'VALID'
                                                : 'INVALID' ?? 'INVALID',
                                        itemNumber:
                                            actionValue.itemNumber ?? -1,
                                        itemNo: actionValue.itemNumber ?? 0,
                                    });
                                }
                            }
                        }

                        state.listOfErrorsAccrossPages = newListOfErrors;

                        if (state.filteringType === 'ALL_ITEMS') {
                            state.listOfErrorsAccrossPagesCopy =
                                newListOfErrors;
                        }

                        // state.listOfErrorsAccrossPages = newListOfErrors;

                        break;

                    case 'REMOVE_CACHE_ERROS':
                        state.listOfErrorsAccrossPages = [];
                        state.listOfErrorsAccrossPagesCopy = [];

                        break;
                    case 'UPDATE_SEARCH_FILTERS':
                        if (action.payload.value.filteringType) {
                            state.filteringType =
                                action.payload.value.filteringType;
                            state.listOfErrorsAccrossPagesCopy =
                                state.listOfErrorsAccrossPages;
                        }

                        if (action.payload.value.currentPage)
                            state.filteringCurrentPage =
                                action.payload.value.currentPage;

                        break;

                    case 'UPDATE_ALL_MEASUREMENTS_UNITS':
                        state.measurementUnits = {
                            ...state.measurementUnits,
                            ...action.payload.value,
                        };

                        break;

                    case 'RESET':
                        state.isLoading = false;
                        state.isAnyItemUpdated = false;
                        state.eventDetails = null;
                        state.eventItems = [];
                        state.event_save_state = {
                            save_state: 'SAVED',
                            validity: 'VALID',
                        };
                        state.listOfBomsOfCurrentEventsWithSubBomOptions = [];
                        state.item_ids = [];
                        state.item_state = [];
                        state.template_custom_field_sections = null;
                        state.currentPage = 1;
                        state.measurementUnits = {};
                        state.searchQuery = '';
                        state.listOfErrorsAccrossPages = [];
                        state.enterprise_item_ids = [];
                        state.filteringCurrentPage = 1;
                        state.filteringType = 'ALL_ITEMS';
                        state.poItemsGroupedByItemEntry = {};
                        state.allPOItemsAreFullfilled = true;
                        state.lastPOsForItems = {};
                        state.poItemsGroupedByItemEntry = {};
                        state.dictOfEventItems = {};
                        state.openAddItemContainer = false;
                        state.item_vendors = {};
                        state.cacheSetting = {
                            curentPage: 1,
                            isLoading: true,
                        };
                        state.dictOfBoms = {};
                        state.dictOfAllTheBomsDetails = {};
                        state.buyerEntities = [];
                        state.selectedBomForFiltering = null;

                        state.isLoading = false;
                        state.eventDetails = null;
                        state.eventItems = [];
                        state.dictOfEventItems = {};
                        state.event_save_state = {
                            save_state: 'SAVED',
                            validity: 'VALID',
                        };
                        state.dictOfBoms = {};
                        // selectBomIdBomConfigurationPopup:
                        //     '671a9382-6f72-43b1-806e-02db3c7a73ab',
                        state.selectBomIdBomConfigurationPopup = null;
                        state.poItemsGroupedByItemEntry = {};
                        state.allPOItemsAreFullfilled = true;
                        state.lastPOsForItems = {};
                        state.measurementUnits = {};
                        state.item_ids = [];
                        state.enterprise_item_ids = [];
                        state.item_state = [];
                        state.item_vendors = {};
                        state.searchQuery = '';
                        state.searchQueryType = 'ITEMS';
                        state.listOfErrorsAccrossPages = [];
                        state.listOfErrorsAccrossPagesCopy = [];
                        state.filteringType = 'ALL_ITEMS';
                        state.filteringCurrentPage = 1;
                        state.template_custom_field_sections = null;
                        state.currentPage = 1;
                        state.openAddItemContainer = false;
                        state.cacheSetting = {
                            curentPage: 1,
                            isLoading: true,
                        };
                        state.showCombineItemsPopup = false;
                        // showBomConfigurationType: 'MANUAL',
                        state.showBomConfigurationType = 'NONE';
                        state.dictOfAllTheBomsDetails = {};
                        state.arePoAvailable = false;
                        state.buyerEntities = [];
                        state.selectedBomForFiltering = null;
                        state.changesMadeInShowBomConfiguration = 0;
                        state.applyToBomItemsValues = {
                            additional_costs: [],
                            cost_per_unit: '',
                            custom_sections: [],
                            deliverables_payment_terms: null,
                            delivery_schedule: [],
                            discounts: [],
                            incoterm_id: '',
                            incoterm_name: '',
                            item_additional_information: '',
                            lead_time: '',
                            lead_time_period: null,
                            measurement_unit: '',
                            payment_terms: {
                                applied_from: 'INVOICE_DATE',
                                period: PeriodEnum.DAYS,
                                term: 0,
                            },
                            payment_type: 'PER_INVOICE_ITEM',
                            prepayment_percentage: '',
                            quantity: '',
                            quantity_tolerance_percentage: '',
                            requisition_approved: false,
                            requisition_number: '',
                            taxes: [],
                            measurement_unit_primary_name: '',
                        };

                        break;
                    case 'UPDATE_ALL_PO_ITEMS_ARE_FULL_fILLED':
                        state.allPOItemsAreFullfilled =
                            state.allPOItemsAreFullfilled &&
                            action.payload.value;
                        break;
                    case 'UPDATE_LAST_POS_FOR_ITEMS':
                        state.lastPOsForItems = {
                            ...state.lastPOsForItems,
                            ...action.payload.value,
                        };
                        break;
                    case 'UPDATE_PO_ITEMS_GROUPED_BY_ITEM_ENTRY':
                        state.poItemsGroupedByItemEntry = {
                            ...state.poItemsGroupedByItemEntry,
                            ...action.payload.value,
                        };
                        break;
                    case 'UPDATE_OPEN_ADD_ITEM_CONTAINER':
                        state.openAddItemContainer = action.payload.value;
                        break;

                    case 'UPDATE_MULTIPLE_DICT_OF_EVENT_ITEMS':
                        state.dictOfEventItems = {
                            ...state.dictOfEventItems,
                            ...action.payload.value,
                        };
                        break;
                    case 'UPDATE_SINGLE_DICT_OF_EVENT_ITEMS':
                        // state.dictOfEventItems[
                        //     action.payload.value.event_item_entry_id
                        // ] = action.payload.value;

                        state.dictOfEventItems = {
                            ...state.dictOfEventItems,
                            [action.payload.value.event_item_entry_id]:
                                action.payload.value,
                        };

                        break;
                    case 'UPDATE_SEARCH_QERY_TYPE':
                        state.searchQueryType = action.payload.value;
                        break;

                    case 'UPDATE_CACHING_SETTING_LOADING':
                        state.cacheSetting.isLoading = action.payload.value;
                        break;
                    case 'UPDATE_CACHING_SETTING_PAGE':
                        state.cacheSetting.curentPage = action.payload.value;
                        break;

                    case 'UPDATE_DICT_OF_BOM':
                        state.dictOfBoms = {
                            ...state.dictOfBoms,
                            ...action.payload.value,
                        };
                        break;

                    case 'UPDATE_SELECTED_BOM_ID_FOR_BOM_CONFIGURATION':
                        state.selectBomIdBomConfigurationPopup =
                            action.payload.value;
                        break;

                    case 'UPDATE_SHOW_BOM_CONFIGURATION_TYPE':
                        state.showBomConfigurationType = action.payload.value;
                        break;

                    case 'UPDATE_ENTITY_VIEW_BUYER_MASTER':
                        state.buyerEntities = action.payload.value;
                        break;

                    case 'UDPATE_SELECTED_BOM_FOR_FILTERING':
                        state.selectedBomForFiltering =
                            action.payload.value !== 'null'
                                ? action.payload.value
                                : null;
                        break;

                    case 'UPDATE_PO_AVAILABILITY':
                        state.arePoAvailable = action.payload.value;
                        break;
                    case 'UPDATE_SHOW_WARNING':
                        if (state.eventDetails)
                            state.eventDetails.showWarnings =
                                action.payload.value;
                        break;
                    case 'UPDATE_SLECTED_ENTERPRISE_SUB_BOM_ID':
                        state.selectedSubBomForFiltering = action.payload.value;
                        break;
                    default:
                        break;
                }
            } catch (err) {
                console.error('errors in event creation slice', err);
            }
        },
        updateEventBoomDataStore: (
            state: IEventCreationStore,
            action: IUpdateEventBomStoreAction
        ) => {
            switch (action.payload.type) {
                case 'UPDATE_DICT_OF_ALL_THE_BOMS_DETAILS':
                    state.dictOfAllTheBomsDetails = {
                        ...state.dictOfAllTheBomsDetails,
                        ...action.payload.value,
                    };
                    break;
                case 'RESET':
                    state.dictOfAllTheBomsDetails = {};
                    state.selectBomIdBomConfigurationPopup = null;
                    state.changesMadeInShowBomConfiguration = 0;
                    break;
                case 'UPDATE_BOM_QUANTITY':
                    state.changesMadeInShowBomConfiguration += 1;
                    if (
                        state.selectBomIdBomConfigurationPopup &&
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ]
                    ) {
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].quantity = action.payload.value.toString();

                        helperFUnctionForUpdatingQuantityOfBoms(
                            state.dictOfAllTheBomsDetails[
                                state.selectBomIdBomConfigurationPopup
                            ].bom_items,
                            state.dictOfAllTheBomsDetails[
                                state.selectBomIdBomConfigurationPopup
                            ].quantity
                        );
                    }

                    break;

                case 'UPDATE_ITEM_LEVEL_DETAILS':
                    state.changesMadeInShowBomConfiguration += 1;

                    if (
                        state.selectBomIdBomConfigurationPopup &&
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ]
                    ) {
                        if (action.payload.value.listOfIndex.length === 1) {
                            switch (action.payload.value.updatePayload.field) {
                                case 'quantity':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].quantity =
                                        action.payload.value.updatePayload.value;

                                    if (
                                        state.dictOfAllTheBomsDetails[
                                            state
                                                .selectBomIdBomConfigurationPopup
                                        ].bom_items[
                                            action.payload.value.listOfIndex[0]
                                        ].delivery_schedule.length === 1
                                    ) {
                                        state.dictOfAllTheBomsDetails[
                                            state.selectBomIdBomConfigurationPopup
                                        ].bom_items[
                                            action.payload.value.listOfIndex[0]
                                        ].delivery_schedule[0].quantity =
                                            action.payload.value.updatePayload.value;
                                    }

                                    const subboms =
                                        state.dictOfAllTheBomsDetails[
                                            state
                                                .selectBomIdBomConfigurationPopup
                                        ].bom_items[
                                            action.payload.value.listOfIndex[0]
                                        ].sub_bom_items;

                                    if (subboms) {
                                        helperFUnctionForUpdatingQuantityOfBoms(
                                            subboms,
                                            state.dictOfAllTheBomsDetails[
                                                state
                                                    .selectBomIdBomConfigurationPopup
                                            ].bom_items[
                                                action.payload.value
                                                    .listOfIndex[0]
                                            ].quantity
                                        );
                                    }

                                    break;
                                case 'delivery_schedule':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].delivery_schedule =
                                        action.payload.value.updatePayload.value;

                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].quantity = action.payload.value.updatePayload.value
                                        .reduce(
                                            (acc, next) => acc + +next.quantity,
                                            0
                                        )
                                        .toString();
                                    break;

                                case 'cost_per_unit':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].cost_per_unit =
                                        action.payload.value.updatePayload.value;

                                    break;
                                case 'item_additional_information':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].item_additional_information =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'quantity_tolerance_percentage':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].quantity_tolerance_percentage =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'incoterm_name':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].incoterm_name =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'incoterm_id':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].incoterm_id =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'requisition_number':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].requisition_number =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'requisition_approved':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].requisition_approved =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'selected':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].selected =
                                        action.payload.value.updatePayload.value;

                                    const subBoms =
                                        state.dictOfAllTheBomsDetails[
                                            state
                                                .selectBomIdBomConfigurationPopup
                                        ].bom_items[
                                            action.payload.value.listOfIndex[0]
                                        ].sub_bom_items;

                                    if (subBoms)
                                        helperFUnctionForUpdatingSelectedValueOfBoms(
                                            subBoms,
                                            action.payload.value.updatePayload
                                                .value
                                        );

                                    break;
                                case 'lead_time':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].lead_time =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'lead_time_period':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].lead_time_period =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'Taxes':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].taxes =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'Discounts':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].discounts =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'AdditionalCost':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].additional_costs =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'payment_type':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].payment_type =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'prepayment_percentage':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].prepayment_percentage =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'payment_terms':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].payment_terms =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'deliverables_payment_terms':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].deliverables_payment_terms =
                                        action.payload.value.updatePayload.value;
                                    break;
                                default:
                                    break;
                            }
                        } else if (
                            action.payload.value.listOfIndex.length > 1
                        ) {
                            const listOfSubBomInternalIndex =
                                action.payload.value.listOfIndex.slice(1);
                            let currentSubBomItems: IBOMItemForAdminForEvent[] =
                                state.dictOfAllTheBomsDetails[
                                    state.selectBomIdBomConfigurationPopup
                                ].bom_items[action.payload.value.listOfIndex[0]]
                                    .sub_bom_items !== null
                                    ? state.dictOfAllTheBomsDetails[
                                          state.selectBomIdBomConfigurationPopup
                                      ].bom_items[
                                          action.payload.value.listOfIndex[0]
                                      ].sub_bom_items!
                                    : [];

                            for (let innerIndex of listOfSubBomInternalIndex.slice(
                                0,
                                -1
                            )) {
                                if (currentSubBomItems[innerIndex]) {
                                    currentSubBomItems =
                                        currentSubBomItems[innerIndex]
                                            .sub_bom_items !== null
                                            ? currentSubBomItems[innerIndex]
                                                  .sub_bom_items!
                                            : [];
                                } else {
                                    return;
                                }
                            }

                            const currentSuBItemWeAreRefereing =
                                currentSubBomItems[
                                    listOfSubBomInternalIndex[
                                        listOfSubBomInternalIndex.length - 1
                                    ]
                                ];

                            switch (action.payload.value.updatePayload.field) {
                                case 'quantity':
                                    currentSuBItemWeAreRefereing.quantity =
                                        action.payload.value.updatePayload.value;
                                    if (
                                        currentSuBItemWeAreRefereing
                                            .delivery_schedule.length === 1
                                    ) {
                                        currentSuBItemWeAreRefereing.delivery_schedule[0].quantity =
                                            action.payload.value.updatePayload.value;
                                    }

                                    const subBomsOfCurrentSuBItem =
                                        currentSuBItemWeAreRefereing.sub_bom_items;

                                    if (subBomsOfCurrentSuBItem) {
                                        helperFUnctionForUpdatingQuantityOfBoms(
                                            subBomsOfCurrentSuBItem,
                                            currentSuBItemWeAreRefereing.quantity
                                        );
                                    }
                                    break;
                                case 'delivery_schedule':
                                    currentSuBItemWeAreRefereing.delivery_schedule =
                                        action.payload.value.updatePayload.value;

                                    currentSuBItemWeAreRefereing.quantity =
                                        action.payload.value.updatePayload.value
                                            .reduce(
                                                (acc, next) =>
                                                    acc + +next.quantity,
                                                0
                                            )
                                            .toString();

                                    break;
                                case 'cost_per_unit':
                                    currentSuBItemWeAreRefereing.cost_per_unit =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'item_additional_information':
                                    currentSuBItemWeAreRefereing.item_additional_information =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'quantity_tolerance_percentage':
                                    currentSuBItemWeAreRefereing.quantity_tolerance_percentage =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'incoterm_name':
                                    currentSuBItemWeAreRefereing.incoterm_name =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'incoterm_id':
                                    currentSuBItemWeAreRefereing.incoterm_id =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'requisition_number':
                                    currentSuBItemWeAreRefereing.requisition_number =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'requisition_approved':
                                    currentSuBItemWeAreRefereing.requisition_approved =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'selected':
                                    currentSuBItemWeAreRefereing.selected =
                                        action.payload.value.updatePayload.value;

                                    const subBoms =
                                        currentSuBItemWeAreRefereing.sub_bom_items;

                                    if (subBoms)
                                        helperFUnctionForUpdatingSelectedValueOfBoms(
                                            subBoms,
                                            action.payload.value.updatePayload
                                                .value
                                        );
                                    break;
                                case 'lead_time':
                                    currentSuBItemWeAreRefereing.lead_time =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'lead_time_period':
                                    currentSuBItemWeAreRefereing.lead_time_period =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'Taxes':
                                    currentSuBItemWeAreRefereing.taxes =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'Discounts':
                                    currentSuBItemWeAreRefereing.discounts =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'AdditionalCost':
                                    currentSuBItemWeAreRefereing.additional_costs =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'payment_type':
                                    currentSuBItemWeAreRefereing.payment_type =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'prepayment_percentage':
                                    currentSuBItemWeAreRefereing.prepayment_percentage =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'payment_terms':
                                    currentSuBItemWeAreRefereing.payment_terms =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'deliverables_payment_terms':
                                    currentSuBItemWeAreRefereing.deliverables_payment_terms =
                                        action.payload.value.updatePayload.value;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    break;

                case 'UPDATE_ITEM_LEVEL_DETAILS_FOR_ALTERNATIVES':
                    state.changesMadeInShowBomConfiguration += 1;

                    if (
                        state.selectBomIdBomConfigurationPopup &&
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ]
                    ) {
                        if (action.payload.value.listOfIndex.length === 1) {
                            switch (action.payload.value.updatePayload.field) {
                                case 'quantity':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].quantity =
                                        action.payload.value.updatePayload.value;

                                    if (
                                        state.dictOfAllTheBomsDetails[
                                            state
                                                .selectBomIdBomConfigurationPopup
                                        ].bom_items[
                                            action.payload.value.listOfIndex[0]
                                        ].delivery_schedule.length === 1
                                    ) {
                                        state.dictOfAllTheBomsDetails[
                                            state.selectBomIdBomConfigurationPopup
                                        ].bom_items[
                                            action.payload.value.listOfIndex[0]
                                        ].alternates.alternate_bom_item[
                                            action.payload.value.alternativeIndex
                                        ].delivery_schedule[0].quantity =
                                            action.payload.value.updatePayload.value;
                                    }

                                    const subboms =
                                        state.dictOfAllTheBomsDetails[
                                            state
                                                .selectBomIdBomConfigurationPopup
                                        ].bom_items[
                                            action.payload.value.listOfIndex[0]
                                        ].sub_bom_items;

                                    if (subboms) {
                                        helperFUnctionForUpdatingQuantityOfBoms(
                                            subboms,
                                            state.dictOfAllTheBomsDetails[
                                                state
                                                    .selectBomIdBomConfigurationPopup
                                            ].bom_items[
                                                action.payload.value
                                                    .listOfIndex[0]
                                            ].quantity
                                        );
                                    }

                                    break;
                                case 'delivery_schedule':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].delivery_schedule =
                                        action.payload.value.updatePayload.value;

                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].quantity = action.payload.value.updatePayload.value
                                        .reduce(
                                            (acc, next) => acc + +next.quantity,
                                            0
                                        )
                                        .toString();

                                    break;

                                case 'cost_per_unit':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].cost_per_unit =
                                        action.payload.value.updatePayload.value;

                                    break;
                                case 'item_additional_information':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].item_additional_information =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'quantity_tolerance_percentage':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].quantity_tolerance_percentage =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'incoterm_name':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].incoterm_name =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'incoterm_id':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].incoterm_id =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'requisition_number':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].requisition_number =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'requisition_approved':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].requisition_approved =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'selected':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].selected =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'lead_time':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].lead_time =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'lead_time_period':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].lead_time_period =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'Taxes':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].taxes =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'Discounts':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].discounts =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'AdditionalCost':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].additional_costs =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'payment_type':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].payment_type =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'prepayment_percentage':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].prepayment_percentage =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'payment_terms':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].payment_terms =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'deliverables_payment_terms':
                                    state.dictOfAllTheBomsDetails[
                                        state.selectBomIdBomConfigurationPopup
                                    ].bom_items[
                                        action.payload.value.listOfIndex[0]
                                    ].alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].deliverables_payment_terms =
                                        action.payload.value.updatePayload.value;
                                    break;
                                default:
                                    break;
                            }
                        } else if (
                            action.payload.value.listOfIndex.length > 1
                        ) {
                            const listOfSubBomInternalIndex =
                                action.payload.value.listOfIndex.slice(1);
                            let currentSubBomItems: IBOMItemForAdminForEvent[] =
                                state.dictOfAllTheBomsDetails[
                                    state.selectBomIdBomConfigurationPopup
                                ].bom_items[action.payload.value.listOfIndex[0]]
                                    .sub_bom_items !== null
                                    ? state.dictOfAllTheBomsDetails[
                                          state.selectBomIdBomConfigurationPopup
                                      ].bom_items[
                                          action.payload.value.listOfIndex[0]
                                      ].sub_bom_items!
                                    : [];

                            for (let innerIndex of listOfSubBomInternalIndex.slice(
                                0,
                                -1
                            )) {
                                if (currentSubBomItems[innerIndex]) {
                                    currentSubBomItems =
                                        currentSubBomItems[innerIndex]
                                            .sub_bom_items !== null
                                            ? currentSubBomItems[innerIndex]
                                                  .sub_bom_items!
                                            : [];
                                } else {
                                    return;
                                }
                            }

                            const currentSuBItemWeAreRefereing =
                                currentSubBomItems[
                                    listOfSubBomInternalIndex[
                                        listOfSubBomInternalIndex.length - 1
                                    ]
                                ];

                            switch (action.payload.value.updatePayload.field) {
                                case 'quantity':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].quantity =
                                        action.payload.value.updatePayload.value;
                                    if (
                                        currentSuBItemWeAreRefereing.alternates
                                            .alternate_bom_item[
                                            action.payload.value
                                                .alternativeIndex
                                        ].delivery_schedule.length === 1
                                    ) {
                                        currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                            action.payload.value.alternativeIndex
                                        ].delivery_schedule[0].quantity =
                                            action.payload.value.updatePayload.value;
                                    }
                                    break;
                                case 'delivery_schedule':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].delivery_schedule =
                                        action.payload.value.updatePayload.value;

                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].quantity = action.payload.value.updatePayload.value
                                        .reduce(
                                            (acc, next) => acc + +next.quantity,
                                            0
                                        )
                                        .toString();

                                    break;
                                case 'cost_per_unit':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].cost_per_unit =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'item_additional_information':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].item_additional_information =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'quantity_tolerance_percentage':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].quantity_tolerance_percentage =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'incoterm_name':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].incoterm_name =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'incoterm_id':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].incoterm_id =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'requisition_number':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].requisition_number =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'requisition_approved':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].requisition_approved =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'selected':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].selected =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'lead_time':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].lead_time =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'lead_time_period':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].lead_time_period =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'Taxes':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].taxes =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'Discounts':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].discounts =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'AdditionalCost':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].additional_costs =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'payment_type':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].payment_type =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'prepayment_percentage':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].prepayment_percentage =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'payment_terms':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].payment_terms =
                                        action.payload.value.updatePayload.value;
                                    break;
                                case 'deliverables_payment_terms':
                                    currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                        action.payload.value.alternativeIndex
                                    ].deliverables_payment_terms =
                                        action.payload.value.updatePayload.value;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    break;

                case 'UPDATE_ITEM_LEVEL_CUSTOM_FIELDSDETAILS':
                    state.changesMadeInShowBomConfiguration += 1;

                    if (
                        state.selectBomIdBomConfigurationPopup &&
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ]
                    )
                        if (action.payload.value.listOfIndex.length === 1) {
                            if (true) {
                                state.dictOfAllTheBomsDetails[
                                    state.selectBomIdBomConfigurationPopup
                                ].bom_items[
                                    action.payload.value.listOfIndex[0]
                                ].custom_sections[
                                    action.payload.value.sectionIndex
                                ].custom_fields[
                                    action.payload.value.fieldIndex
                                ].value =
                                    action.payload.value.updatePayload.value;
                            }
                            // else {
                            //     state.dictOfAllTheBomsDetails[
                            //         state.selectBomIdBomConfigurationPopup
                            //     ].bom_items[
                            //         action.payload.value.listOfIndex[0]
                            //     ].custom_fields.section_list[
                            //         action.payload.value.sectionIndex
                            //     ].fields[
                            //         action.payload.value.fieldIndex
                            //     ].value =
                            //         action.payload.value.updatePayload.value;
                            // }
                        } else if (
                            action.payload.value.listOfIndex.length > 1
                        ) {
                            const listOfSubBomInternalIndex =
                                action.payload.value.listOfIndex.slice(1);
                            let currentSubBomItems: IBOMItemForAdminForEvent[] =
                                state.dictOfAllTheBomsDetails[
                                    state.selectBomIdBomConfigurationPopup
                                ].bom_items[action.payload.value.listOfIndex[0]]
                                    .sub_bom_items !== null
                                    ? state.dictOfAllTheBomsDetails[
                                          state.selectBomIdBomConfigurationPopup
                                      ].bom_items[
                                          action.payload.value.listOfIndex[0]
                                      ].sub_bom_items!
                                    : [];

                            for (let innerIndex of listOfSubBomInternalIndex.slice(
                                0,
                                -1
                            )) {
                                if (currentSubBomItems[innerIndex]) {
                                    currentSubBomItems =
                                        currentSubBomItems[innerIndex]
                                            .sub_bom_items !== null
                                            ? currentSubBomItems[innerIndex]
                                                  .sub_bom_items!
                                            : [];
                                } else {
                                    return;
                                }
                            }

                            const currentSuBItemWeAreRefereing =
                                currentSubBomItems[
                                    listOfSubBomInternalIndex[
                                        listOfSubBomInternalIndex.length - 1
                                    ]
                                ];

                            if (true) {
                                currentSuBItemWeAreRefereing.custom_sections[
                                    action.payload.value.sectionIndex
                                ].custom_fields[
                                    action.payload.value.fieldIndex
                                ].value =
                                    action.payload.value.updatePayload.value;
                            }
                            // else {
                            //     currentSuBItemWeAreRefereing.custom_fields.section_list[
                            //         action.payload.value.sectionIndex
                            //     ].fields[
                            //         action.payload.value.fieldIndex
                            //     ].value =
                            //         action.payload.value.updatePayload.value;
                            // }
                        }
                    break;

                case 'UPDATE_ITEM_LEVEL_CUSTOM_FIELDSDETAILS_FOR_ALTERNATIVES':
                    state.changesMadeInShowBomConfiguration += 1;

                    if (
                        state.selectBomIdBomConfigurationPopup &&
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ]
                    )
                        if (action.payload.value.listOfIndex.length === 1) {
                            if (true) {
                                state.dictOfAllTheBomsDetails[
                                    state.selectBomIdBomConfigurationPopup
                                ].bom_items[
                                    action.payload.value.listOfIndex[0]
                                ].alternates.alternate_bom_item[
                                    action.payload.value.alternativeIndex
                                ].custom_sections[
                                    action.payload.value.sectionIndex
                                ].custom_fields[
                                    action.payload.value.fieldIndex
                                ].value =
                                    action.payload.value.updatePayload.value;
                            }
                            // else {
                            //     state.dictOfAllTheBomsDetails[
                            //         state.selectBomIdBomConfigurationPopup
                            //     ].bom_items[
                            //         action.payload.value.listOfIndex[0]
                            //     ].alternates.alternate_bom_item[
                            //         action.payload.value.alternativeIndex
                            //     ].custom_fields.section_list[
                            //         action.payload.value.sectionIndex
                            //     ].fields[
                            //         action.payload.value.fieldIndex
                            //     ].value =
                            //         action.payload.value.updatePayload.value;
                            // }
                        } else if (
                            action.payload.value.listOfIndex.length > 1
                        ) {
                            const listOfSubBomInternalIndex =
                                action.payload.value.listOfIndex.slice(1);
                            let currentSubBomItems: IBOMItemForAdminForEvent[] =
                                state.dictOfAllTheBomsDetails[
                                    state.selectBomIdBomConfigurationPopup
                                ].bom_items[action.payload.value.listOfIndex[0]]
                                    .sub_bom_items !== null
                                    ? state.dictOfAllTheBomsDetails[
                                          state.selectBomIdBomConfigurationPopup
                                      ].bom_items[
                                          action.payload.value.listOfIndex[0]
                                      ].sub_bom_items!
                                    : [];

                            for (let innerIndex of listOfSubBomInternalIndex.slice(
                                0,
                                -1
                            )) {
                                if (currentSubBomItems[innerIndex]) {
                                    currentSubBomItems =
                                        currentSubBomItems[innerIndex]
                                            .sub_bom_items !== null
                                            ? currentSubBomItems[innerIndex]
                                                  .sub_bom_items!
                                            : [];
                                } else {
                                    return;
                                }
                            }

                            const currentSuBItemWeAreRefereing =
                                currentSubBomItems[
                                    listOfSubBomInternalIndex[
                                        listOfSubBomInternalIndex.length - 1
                                    ]
                                ];

                            if (true) {
                                currentSuBItemWeAreRefereing.alternates.alternate_bom_item[
                                    action.payload.value.alternativeIndex
                                ].custom_sections[
                                    action.payload.value.sectionIndex
                                ].custom_fields[
                                    action.payload.value.fieldIndex
                                ].value =
                                    action.payload.value.updatePayload.value;
                            }
                            // else {
                            //     currentSuBItemWeAreRefereing.custom_fields.section_list[
                            //         action.payload.value.sectionIndex
                            //     ].fields[
                            //         action.payload.value.fieldIndex
                            //     ].value =
                            //         action.payload.value.updatePayload.value;
                            // }
                        }
                    break;

                case 'SELECT_AN_ALTERNATIVE':
                    state.changesMadeInShowBomConfiguration += 1;

                    if (
                        state.selectBomIdBomConfigurationPopup &&
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ]
                    ) {
                        const alternativeThatIsSelected = cloneDeep(
                            state.dictOfAllTheBomsDetails[
                                state.selectBomIdBomConfigurationPopup
                            ].bom_items[action.payload.value.bomItemIndex]
                                .alternates.alternate_bom_item[
                                action.payload.value.alternativeIndex
                            ]
                        );
                        const bomItemThatIsToBeEditedClone = cloneDeep(
                            state.dictOfAllTheBomsDetails[
                                state.selectBomIdBomConfigurationPopup
                            ].bom_items[action.payload.value.bomItemIndex]
                        );

                        // over riding bom item value from alternatives
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].bom_item_id = alternativeThatIsSelected.bom_item_id;
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].measurement_unit =
                            alternativeThatIsSelected.measurement_unit;

                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].cost_per_unit =
                            alternativeThatIsSelected.cost_per_unit;
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].quantity = alternativeThatIsSelected.quantity;

                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].raw_material_item =
                            alternativeThatIsSelected.raw_material_item;

                        // over riding bom item value from orignal to alternatives
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].is_alternate = alternativeThatIsSelected.is_alternate;
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].alternates.alternate_bom_item[
                            action.payload.value.alternativeIndex
                        ].is_alternate =
                            bomItemThatIsToBeEditedClone.is_alternate;
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].alternates.alternate_bom_item[
                            action.payload.value.alternativeIndex
                        ].bom_item_id =
                            bomItemThatIsToBeEditedClone.bom_item_id;
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].alternates.alternate_bom_item[
                            action.payload.value.alternativeIndex
                        ].measurement_unit =
                            bomItemThatIsToBeEditedClone.measurement_unit;

                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].alternates.alternate_bom_item[
                            action.payload.value.alternativeIndex
                        ].cost_per_unit =
                            bomItemThatIsToBeEditedClone.cost_per_unit;
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].alternates.alternate_bom_item[
                            action.payload.value.alternativeIndex
                        ].quantity = bomItemThatIsToBeEditedClone.quantity;

                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items[
                            action.payload.value.bomItemIndex
                        ].alternates.alternate_bom_item[
                            action.payload.value.alternativeIndex
                        ].raw_material_item =
                            bomItemThatIsToBeEditedClone.raw_material_item;
                    }

                    break;
                case 'UPDATE_APPLY_FOR_ALL':
                    switch (action.payload.value.updatePayload.field) {
                        case 'quantity':
                            state.applyToBomItemsValues.quantity =
                                action.payload.value.updatePayload.value;
                            if (
                                state.applyToBomItemsValues.delivery_schedule
                                    .length === 1
                            ) {
                                state.applyToBomItemsValues.delivery_schedule[0].quantity =
                                    action.payload.value.updatePayload.value;
                            }
                            break;
                        case 'delivery_schedule':
                            state.applyToBomItemsValues.delivery_schedule =
                                action.payload.value.updatePayload.value;

                            state.applyToBomItemsValues.quantity =
                                action.payload.value.updatePayload.value
                                    .reduce(
                                        (acc, next) => acc + +next.quantity,
                                        0
                                    )
                                    .toString();

                            break;
                        case 'cost_per_unit':
                            state.applyToBomItemsValues.cost_per_unit =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'custom_sections':
                            state.applyToBomItemsValues.custom_sections =
                                action.payload.value.updatePayload.value;
                            break;
                        // case 'custom_fields_negotiate':
                        //     state.applyToBomItemsValues.custom_fields_negotiate =
                        //         action.payload.value.updatePayload.value;
                        //     break;
                        case 'item_additional_information':
                            state.applyToBomItemsValues.item_additional_information =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'quantity_tolerance_percentage':
                            state.applyToBomItemsValues.quantity_tolerance_percentage =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'incoterm_name':
                            state.applyToBomItemsValues.incoterm_name =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'incoterm_id':
                            state.applyToBomItemsValues.incoterm_id =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'requisition_number':
                            state.applyToBomItemsValues.requisition_number =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'requisition_approved':
                            state.applyToBomItemsValues.requisition_approved =
                                action.payload.value.updatePayload.value;
                            break;

                        case 'lead_time':
                            state.applyToBomItemsValues.lead_time =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'lead_time_period':
                            state.applyToBomItemsValues.lead_time_period =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'Taxes':
                            state.applyToBomItemsValues.taxes =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'Discounts':
                            state.applyToBomItemsValues.discounts =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'AdditionalCost':
                            state.applyToBomItemsValues.additional_costs =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'payment_type':
                            state.applyToBomItemsValues.payment_type =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'prepayment_percentage':
                            state.applyToBomItemsValues.prepayment_percentage =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'payment_terms':
                            state.applyToBomItemsValues.payment_terms =
                                action.payload.value.updatePayload.value;
                            break;
                        case 'deliverables_payment_terms':
                            state.applyToBomItemsValues.deliverables_payment_terms =
                                action.payload.value.updatePayload.value;
                            break;

                        default:
                            break;
                    }

                    break;
                case 'APPLY_TO_ALL_ACTION':
                    state.changesMadeInShowBomConfiguration += 1;

                    if (
                        state.selectBomIdBomConfigurationPopup &&
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ]
                    ) {
                        for (let items of state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].bom_items) {
                            if (items.sub_bom_items) {
                                helperFUnctionForApplyToAll(
                                    items.sub_bom_items,
                                    state
                                );
                            } else {
                                if (
                                    items.alternates.alternate_bom_item.length >
                                    0
                                ) {
                                    helperFunctionForAlternates(
                                        items.alternates.alternate_bom_item,
                                        state
                                    );
                                }
                                items.additional_costs =
                                    state.applyToBomItemsValues.additional_costs;
                                // items.cost_per_unit =
                                //     state.applyToBomItemsValues.cost_per_unit;
                                items.deliverables_payment_terms =
                                    state.applyToBomItemsValues.deliverables_payment_terms;
                                // items.delivery_schedule =
                                //     state.applyToBomItemsValues.delivery_schedule;
                                items.discounts =
                                    state.applyToBomItemsValues.discounts;

                                items.incoterm_id =
                                    state.applyToBomItemsValues.incoterm_id;
                                items.incoterm_name =
                                    state.applyToBomItemsValues.incoterm_name;
                                items.item_additional_information =
                                    state.applyToBomItemsValues.item_additional_information;
                                items.lead_time =
                                    state.applyToBomItemsValues.lead_time;
                                items.lead_time_period =
                                    state.applyToBomItemsValues.lead_time_period;
                                // items.measurement_unit =
                                //     state.applyToBomItemsValues.measurement_unit;
                                items.payment_terms =
                                    state.applyToBomItemsValues.payment_terms;
                                items.payment_type =
                                    state.applyToBomItemsValues.payment_type;
                                items.prepayment_percentage =
                                    state.applyToBomItemsValues.prepayment_percentage;
                                // items.quantity =
                                //     state.applyToBomItemsValues.quantity;
                                items.quantity_tolerance_percentage =
                                    state.applyToBomItemsValues.quantity_tolerance_percentage;
                                items.requisition_approved =
                                    state.applyToBomItemsValues.requisition_approved;
                                items.requisition_number =
                                    state.applyToBomItemsValues.requisition_number;
                                items.taxes = state.applyToBomItemsValues.taxes;
                                items.custom_sections =
                                    state.applyToBomItemsValues.custom_sections;
                                // items.custom_fields_negotiate =
                                //     state.applyToBomItemsValues.custom_fields_negotiate;
                            }
                        }
                    }

                    break;

                case 'UPDATE_BOM_TOTAL':
                    if (
                        state.selectBomIdBomConfigurationPopup &&
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ]
                    ) {
                        state.dictOfAllTheBomsDetails[
                            state.selectBomIdBomConfigurationPopup
                        ].total = action.payload.value.toString();
                    }
                    break;
                case 'UPDATE_SHOW_COMBINE_ITEMS_POPUP':
                    state.showCombineItemsPopup = action.payload.value;
                    break;
                case 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT_WITH_HEIRARY':
                    state.listOfBomsOfCurrentEventsWithSubBomOptions =
                        action.payload.value;
                    break;
                default:
                    break;
            }
        },
    },
});

export const { updateEventDataStore, updateEventBoomDataStore } =
    EventCreationSlice.actions;

const EventCreationStore = EventCreationSlice.reducer;
export default EventCreationStore;

const helperFUnctionForUpdatingQuantityOfBoms = (
    items: IBOMItemForAdminForEvent[],
    bomItemQUantity: string | number
) => {
    for (let item of items) {
        item.quantity =
            +item.rawMaterialQuantityPerUnitBom * +bomItemQUantity === Infinity
                ? '1'
                : (
                      +item.rawMaterialQuantityPerUnitBom * +bomItemQUantity
                  ).toString();

        if (item.delivery_schedule.length === 1) {
            item.delivery_schedule[0].quantity = item.quantity;
        } else {
            const ogQuantity = item.delivery_schedule.reduce(
                (acc, next) => acc + +next.quantity,
                0
            );

            for (let i = 0; i < item.delivery_schedule.length - 1; i++) {
                item.delivery_schedule[i].quantity = roundToNDecimalPlace(
                    (+item.delivery_schedule[i] / ogQuantity) * +item.quantity,
                    4
                ).toString();
            }
            item.delivery_schedule[item.delivery_schedule.length - 1].quantity =
                (
                    +item.quantity -
                    item.delivery_schedule
                        .filter((ds, idx) =>
                            idx === item.delivery_schedule.length - 1
                                ? false
                                : true
                        )
                        .reduce((acc, next) => acc + +next.quantity, 0)
                ).toString();
        }

        for (let alternative of item.alternates.alternate_bom_item) {
            alternative.quantity =
                +alternative.rawMaterialQuantityPerUnitBom *
                    +bomItemQUantity ===
                Infinity
                    ? '1'
                    : (
                          +alternative.rawMaterialQuantityPerUnitBom *
                          +bomItemQUantity
                      ).toString();

            if (alternative.delivery_schedule.length === 1) {
                alternative.delivery_schedule[0].quantity =
                    alternative.quantity;
            } else if (alternative.delivery_schedule.length !== 0) {
                const ogQuantity = alternative.delivery_schedule.reduce(
                    (acc, next) => acc + +next.quantity,
                    0
                );

                for (
                    let i = 0;
                    i < alternative.delivery_schedule.length - 1;
                    i++
                ) {
                    alternative.delivery_schedule[i].quantity =
                        roundToNDecimalPlace(
                            (+alternative.delivery_schedule[i] / ogQuantity) *
                                +alternative.quantity,
                            4
                        ).toString();
                }
                alternative.delivery_schedule[
                    alternative.delivery_schedule.length - 1
                ].quantity = (
                    +alternative.quantity -
                    alternative.delivery_schedule
                        .filter((ds, idx) =>
                            idx === alternative.delivery_schedule.length - 1
                                ? false
                                : true
                        )
                        .reduce((acc, next) => acc + +next.quantity, 0)
                ).toString();
            }
        }

        if (item.sub_bom_items) {
            helperFUnctionForUpdatingQuantityOfBoms(
                item.sub_bom_items,
                item.quantity
            );
        }
    }
};

const helperFUnctionForUpdatingSelectedValueOfBoms = (
    items: IBOMItemForAdminForEvent[],
    selected: boolean
) => {
    for (let item of items) {
        item.selected = selected;

        if (item.sub_bom_items) {
            helperFUnctionForUpdatingSelectedValueOfBoms(
                item.sub_bom_items,
                selected
            );
        }
    }
};

const helperFUnctionForApplyToAll = (
    items: IBOMItemForAdminForEvent[],
    state: IEventCreationStore
) => {
    for (let item of items) {
        if (item.sub_bom_items) {
            helperFUnctionForApplyToAll(item.sub_bom_items, state);
        } else {
            if (item.alternates.alternate_bom_item.length > 0) {
                helperFunctionForAlternates(
                    item.alternates.alternate_bom_item,
                    state
                );
            }
            item.additional_costs =
                state.applyToBomItemsValues.additional_costs;
            // item.cost_per_unit = state.applyToBomItemsValues.cost_per_unit;
            item.deliverables_payment_terms =
                state.applyToBomItemsValues.deliverables_payment_terms;
            // item.delivery_schedule =
            //     state.applyToBomItemsValues.delivery_schedule;
            item.discounts = state.applyToBomItemsValues.discounts;
            item.incoterm_id = state.applyToBomItemsValues.incoterm_id;
            item.incoterm_name = state.applyToBomItemsValues.incoterm_name;
            item.item_additional_information =
                state.applyToBomItemsValues.item_additional_information;
            item.lead_time = state.applyToBomItemsValues.lead_time;
            item.lead_time_period =
                state.applyToBomItemsValues.lead_time_period;
            // item.measurement_unit =
            //     state.applyToBomItemsValues.measurement_unit;
            item.payment_terms = state.applyToBomItemsValues.payment_terms;
            item.payment_type = state.applyToBomItemsValues.payment_type;
            item.prepayment_percentage =
                state.applyToBomItemsValues.prepayment_percentage;
            // item.quantity = state.applyToBomItemsValues.quantity;
            item.quantity_tolerance_percentage =
                state.applyToBomItemsValues.quantity_tolerance_percentage;
            item.requisition_approved =
                state.applyToBomItemsValues.requisition_approved;
            item.requisition_number =
                state.applyToBomItemsValues.requisition_number;
            item.taxes = state.applyToBomItemsValues.taxes;
            item.custom_sections = state.applyToBomItemsValues.custom_sections;
        }
    }
};

const helperFunctionForAlternates = (
    items: IBOMItemForAdminForEvent[],
    state: IEventCreationStore
) => {
    for (let item of items) {
        item.additional_costs = state.applyToBomItemsValues.additional_costs;
        // item.cost_per_unit = state.applyToBomItemsValues.cost_per_unit;
        item.deliverables_payment_terms =
            state.applyToBomItemsValues.deliverables_payment_terms;
        // item.delivery_schedule =
        //     state.applyToBomItemsValues.delivery_schedule;
        item.discounts = state.applyToBomItemsValues.discounts;
        item.incoterm_id = state.applyToBomItemsValues.incoterm_id;
        item.incoterm_name = state.applyToBomItemsValues.incoterm_name;
        item.item_additional_information =
            state.applyToBomItemsValues.item_additional_information;
        item.lead_time = state.applyToBomItemsValues.lead_time;
        item.lead_time_period = state.applyToBomItemsValues.lead_time_period;
        // item.measurement_unit =
        //     state.applyToBomItemsValues.measurement_unit;
        item.payment_terms = state.applyToBomItemsValues.payment_terms;
        item.payment_type = state.applyToBomItemsValues.payment_type;
        item.prepayment_percentage =
            state.applyToBomItemsValues.prepayment_percentage;
        // item.quantity = state.applyToBomItemsValues.quantity;
        item.quantity_tolerance_percentage =
            state.applyToBomItemsValues.quantity_tolerance_percentage;
        item.requisition_approved =
            state.applyToBomItemsValues.requisition_approved;
        item.requisition_number =
            state.applyToBomItemsValues.requisition_number;
        item.taxes = state.applyToBomItemsValues.taxes;
        item.custom_sections = state.applyToBomItemsValues.custom_sections;
    }
};
