import { Grid, IconButton } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWDataGrid from '../../../../Common/FWDataGrid';
import FWIcon from '../../../../Common/FWIcon';
import { FWInput } from '../../../../Common/FWInput';
import { IGlobalAppStore } from '../../../../Redux/Store';

import { debounce, isEqual } from 'lodash';
import { AuthContext } from '../../../../Contexts/AuthContext';
import CustomDashboardGridHeader from '../../../../Global/Components/CustomDashboardGridHeader';
import { PaymentsItemPopup } from '../../../../Payments/Components/PaymentsItemPopup';
import ItemDirectoryDetailsPopover from '../../Components/ItemDirectory/ItemDetailsPopover';
import ItemDirectoryEntitiesPopover from '../../Components/ItemDirectory/ItemEntitiesPopover';
import ItemPreferredVendorsPopover from '../../Components/ItemDirectory/ItemPreferredVendorsPopover';
import PageHeader, { HeaderType } from '../../Components/PageHeader';
import { entityItemsListColumns } from '../../Constants/ItemDirectoryConstants';
import {
    IEntityListProviders,
    useEntitiesList,
} from '../../Hooks/EnterpriseHook';
import {
    IEntityItemDirectoryProviders,
    useDownloadItemsAndVendors,
    useEntityItemsList,
} from '../../Hooks/ItemDirectory/ItemDirectoryHook';
import {
    IItemMeasurementUnits,
    IItemSummary,
    ItemEntityStatus,
    ItemStatus,
    PreferredVendorAssignedStatus,
} from '../../Interfaces/ItemDirectoryInterface';
import {
    fetchItemMeasurements,
    fetchNotInEntityItemsList,
    newFetchEntityItemsList,
} from '../../Services/ItemDirectoryService';
import EntityItemLinkPopup from './EntityItemLinkPopup';

export enum ItemCellActions {
    NONE = 'NONE',
    NAME = 'NAME',
    ENTITY = 'ENTITY',
    VENDOR = 'VENDOR',
    ENTER = 'ENTER',
    LEAVE = 'LEAVE',
}

const sortColumnsNames = {
    itemCode: 'code',
    name: 'name',
    preferredVendors: 'preferred_vendors_count',
    entities: 'entity_count',
    modifiedDateTime: 'modified_datetime',
};

const initialSelected: IItemSummary = {
    id: '',
    itemCode: '',
    name: '',
    entities: [],
    entities_id: [],
    locked: false,
    lastPrice: '',
    description: '',
    status: ItemStatus.ACTIVE,
    specifications: [],
    measurementUnits: [],
    notes: '',
    tags: [],
    customIds: [],
    preferredVendorAssignedStatus: PreferredVendorAssignedStatus.ASSIGNED,
    preferredVendorsCount: 0,
    preferredVendors: [],
    entityStatus: ItemEntityStatus.ACTIVE,
    modifiedDateTime: '',
    buyerPricingInformation: null,
    sellerPricingInformation: null,
    isBuyer: false,
    isSeller: false,
    bom_template: null,
};

export type sortFields =
    | 'itemCode'
    | 'name'
    | 'preferredVendors'
    | 'modifiedDateTime';

const ItemsListPage = () => {
    const history = useHistory();
    const { entityUid }: { [key: string]: string } = useParams();
    const [rowData, setRowData] = useState<IItemSummary[] | null>(null);
    const [sortFields, setSortFields] = useState<
        {
            field: sortFields;
            ascending: boolean;
        }[]
    >([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [popupToggle, setPopupToggle] = useState<boolean>(false);
    const [filteredAllItemsList, setFilteredAllItemsList] = useState<
        IItemSummary[]
    >([]);

    const [allFilteredItemsCount, setAllFilteredItemsCount] = useState(0);

    const [searchItemToAdd, setSearchItemToAdd] = useState({
        searchText: '',
        currentPage: 1,
    });

    const SINGLE_ENTITY = useSelector(
        (store: IGlobalAppStore) => store.VerificationStore.hasSignleEntity
    );

    const { entities }: IEntityListProviders = useEntitiesList();

    const {
        entityItemsList,
        updateItemDirectoryList,
        linkItem,
    }: IEntityItemDirectoryProviders = useEntityItemsList(entityUid);

    const { authData } = useContext(AuthContext);

    const fetchFilterdData = useCallback(
        async (currentPage, searchText) => {
            try {
                let resp = await fetchNotInEntityItemsList({
                    searchText: searchText,
                    itemsPerPage: 15,
                    pageNumber: currentPage,
                    sortFields: [],
                    entityUid: entityUid,
                });
                if (searchText === '') {
                    setAllFilteredItemsCount(resp.pageDetails.count);
                }
                if (currentPage === 1) {
                    setFilteredAllItemsList((prev) => [...resp.items]);
                } else {
                    setFilteredAllItemsList((prev) => [
                        ...prev.slice(0, prev.length - 1),
                        ...resp.items,
                    ]);
                }
                if (resp.pageDetails.totalPages > currentPage) {
                    const hasNext = {
                        entities_id: [],
                        id: '',
                        itemCode: '',
                        name: '',
                        entities: [],
                        locked: false,
                        lastPrice: '',
                        description: '',
                        status: ItemStatus.ACTIVE,
                        specifications: [],
                        measurementUnits: [],
                        notes: '',
                        tags: [],
                        customIds: [],
                        preferredVendorAssignedStatus:
                            PreferredVendorAssignedStatus.ASSIGNED,
                        preferredVendorsCount: 0,
                        preferredVendors: [],
                        entityStatus: ItemEntityStatus.ACTIVE,
                        modifiedDateTime: '',
                        hasNext: true,
                        bom_template: null,
                    };
                    setFilteredAllItemsList((prev) => [...prev, hasNext]);
                }
            } catch (e) {
                console.error('Some error', e);
                toast.error('Some error');
            }
        },
        [entityUid]
    );

    useEffect(() => {
        fetchFilterdData(1, '');
        //Disabled cause only want it to run once for entity change
        //eslint-disable-next-line
    }, [entityUid]);

    useEffect(() => {
        fetchFilterdData(
            searchItemToAdd.currentPage,
            searchItemToAdd.searchText
        );
        //Disabled cause only want it to run once for search and page change
        //eslint-disable-next-line
    }, [searchItemToAdd]);

    const onSearchItem = (searchText: string) => {
        setSearchItemToAdd({ searchText: searchText, currentPage: 1 });
    };

    const onShowMore = () => {
        setSearchItemToAdd((prev) => {
            return { ...prev, currentPage: prev.currentPage + 1 };
        });
    };

    const fetchData = useCallback(
        async (currentPage, searchText, sortFields) => {
            try {
                const sortFieldsArray = sortFields.map(
                    (sortField: { field: sortFields; ascending: boolean }) => {
                        return {
                            field: sortColumnsNames[sortField.field],
                            ascending: sortField.ascending,
                        };
                    }
                );
                let resp = await newFetchEntityItemsList({
                    searchText: searchText,
                    itemsPerPage: 10,
                    pageNumber: currentPage,
                    sortFields: sortFieldsArray,
                    entityUid: entityUid,
                });
                setCurrentPage((prev) => {
                    if (prev > resp.pageDetails.totalPages) {
                        return 1;
                    } else {
                        return prev;
                    }
                });
                setRowData(resp.items);
                setTotalPages(resp.pageDetails.totalPages);
            } catch (e) {
                toast.error('Some error');
            }
        },
        [entityUid]
    );

    // useEffect to get data on initial render
    useEffect(() => {
        fetchData(1, searchText, []);
        // disabled because we don't want searchText as a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    useEffect(() => {
        // setRowData(null);
        setCurrentPage(1);
        setSortFields([]);
        fetchData(1, searchText, []);
    }, [fetchData, searchText]);

    //MrTea:Made a seprate useEffect so that it runs on page change only.
    //Otherwise the data is loaded on the previous set page when other datas is changed
    useEffect(
        () => {
            // setRowData(null);
            fetchData(currentPage, searchText, sortFields);
        },
        // disabled because we want to run only for page change
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentPage]
    );

    useEffect(() => {
        // setRowData(null);
        setCurrentPage(1);
        fetchData(1, searchText, sortFields);
        // disabled because we want to run only for sort columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortFields]);

    const debouncedSearch = useMemo(
        () =>
            debounce((text: string) => {
                setSearchText(text);
            }, 1000),
        []
    );

    const [showRefreshIcon, setShowRefreshIcon] = useState(false);
    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const sortFieldsArray = sortFields.map(
                    (sortField: { field: sortFields; ascending: boolean }) => {
                        return {
                            field: sortColumnsNames[sortField.field],
                            ascending: sortField.ascending,
                        };
                    }
                );
                let resp = await newFetchEntityItemsList({
                    searchText: searchText,
                    itemsPerPage: 10,
                    pageNumber: currentPage,
                    sortFields: sortFieldsArray,
                    entityUid: entityUid,
                });
                const isListEqual = isEqual(resp.items, rowData);
                //
                setShowRefreshIcon(!isListEqual);
            } catch (err) {
                // toast.error('Some error');
            }
        }, 1000 * 120); // fetch every 120 seconds and compare with previous data
        return () => {
            clearInterval(interval);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData]);
    // u

    const [selectedItem, setSelectedItem] = useState<IItemSummary>({
        ...initialSelected,
    });

    const [allMeasurementUnits, setAllMeasurementUnits] =
        useState<IItemMeasurementUnits>({});
    const [open, setOpen] = useState<boolean>(false);
    const [itemDetails, setItemDetails] = useState<IItemSummary | null>(null);

    const getItemMeasurements = useCallback(async () => {
        try {
            const resp = await fetchItemMeasurements();
            setAllMeasurementUnits(resp);
        } catch (err) {}
    }, []);

    useEffect(() => {
        getItemMeasurements();
    }, [getItemMeasurements]);

    const rowClickHandler = ({ data }: { data: IItemSummary }) => {
        if (authData?.details?.role === 'ADMIN') {
            if (entityItemsList && data.status !== ItemStatus.INACTIVE)
                history.push(
                    `/admin/items/item-directory/edit/${data.id}/${entityUid}`
                );
        } else {
            setItemDetails(data);
        }
    };

    useEffect(() => {
        if (itemDetails) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [itemDetails]);

    const handleItemEmitAction = () => {
        updateItemDirectoryList();
    };

    const handleCellHover = (
        type: ItemCellActions,
        row: IItemSummary,
        target: HTMLElement,
        popover: ItemCellActions
    ) => {
        switch (popover) {
            case ItemCellActions.NAME:
                switch (type) {
                    case ItemCellActions.ENTER:
                        setNamePopoverAnchorEl(target);
                        break;
                    case ItemCellActions.LEAVE:
                        setNamePopoverAnchorEl(null);
                        break;
                }
                break;
            case ItemCellActions.ENTITY:
                switch (type) {
                    case ItemCellActions.ENTER:
                        if (row.entities.length)
                            setEntityPopoverAnchorEl(target);
                        break;
                    case ItemCellActions.LEAVE:
                        if (row.entities.length) setEntityPopoverAnchorEl(null);
                        break;
                }
                break;
            case ItemCellActions.VENDOR:
                switch (type) {
                    case ItemCellActions.ENTER:
                        if (row.preferredVendors.length)
                            setPreferredVendorsPopoverAnchorEl(target);
                        break;
                    case ItemCellActions.LEAVE:
                        if (row.preferredVendors.length)
                            setPreferredVendorsPopoverAnchorEl(null);
                        break;
                }
        }
        setSelectedItem(row);
    };

    const [namePopoverAnchorEl, setNamePopoverAnchorEl] =
        useState<HTMLElement | null>(null);
    const openNamePopover = Boolean(namePopoverAnchorEl);

    const [entityPopoverAnchorEl, setEntityPopoverAnchorEl] =
        useState<HTMLElement | null>(null);
    const openEntityPopover = Boolean(entityPopoverAnchorEl);
    const [
        preferredVendorsPopoverAnchorEl,
        setPreferredVendorsPopoverAnchorEl,
    ] = useState<HTMLElement | null>(null);
    const openPreferredVendorsPopover = Boolean(
        preferredVendorsPopoverAnchorEl
    );

    const handleEntityChange = (event: any) => {
        const entityId = event.target.value;
        if (event.target.value === 'global') {
            history.go(-1);
        } else {
            history.replace(`/admin/items/item-directory/${entityId}`);
        }
    };

    const entityName = entities?.length
        ? entities.filter((entity) => entity?.entityId === entityUid)[0]
              ?.entityName
        : null;

    const handleLinkItem = async (item: IItemSummary) => {
        setPopupToggle(false);
        const itemAdded = await linkItem(item.id);
        if (!itemAdded) toast.error(`Error adding item to ${entityName}`);
    };

    const { ItemsOrBuyersOrVendorsListCSV } = useDownloadItemsAndVendors(
        'ITEM',
        entityUid
    );

    return (
        <>
            <PageHeader
                title={
                    `Item Directory: ` + (entityName ? entityName : `Entity`)
                }
                switchEntity={
                    !SINGLE_ENTITY
                        ? {
                              selectedEntity: entityUid,
                              entityList: entities,
                          }
                        : undefined
                }
                type={HeaderType.GLOBAL}
                emitEntityChange={handleEntityChange}
                back={!SINGLE_ENTITY}
            />
            <Grid container className="p--20">
                <Grid container className="flex flex--jcfe flex--aic mb--5">
                    {authData.details?.role === 'ADMIN' && (
                        <FWTooltip title="Export as CSV">
                            <div>
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        ItemsOrBuyersOrVendorsListCSV()
                                    }
                                    sx={{
                                        marginRight: '12px',
                                        fontSize: '24px',
                                    }}
                                >
                                    <i className="bi bi-box-arrow-up"></i>
                                </IconButton>
                            </div>
                        </FWTooltip>
                    )}
                    <FWInput
                        onChange={(e: any) => debouncedSearch(e.target.value)}
                        placeholder={'Search'}
                        iconStart={<i className="bi bi-search" />}
                        style={{ width: 400 }}
                    />
                    {authData.details?.role === 'ADMIN' && (
                        <div className="ml--5">
                            <FWTooltip
                                title={
                                    allFilteredItemsCount === 0
                                        ? `All items added to ${entityName}`
                                        : 'Add new item'
                                }
                            >
                                <div>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setPopupToggle(true);
                                        }}
                                        disabled={allFilteredItemsCount === 0}
                                    >
                                        <FWIcon
                                            name="bi bi-plus-circle"
                                            size={24}
                                        />
                                    </IconButton>
                                </div>
                            </FWTooltip>
                        </div>
                    )}
                    <EntityItemLinkPopup
                        allItemsList={filteredAllItemsList}
                        popupToggle={popupToggle}
                        entityName={entityName || ''}
                        handlePopupToggle={() => {
                            setPopupToggle(false);
                        }}
                        handleLinkItem={handleLinkItem}
                        onSearchItem={onSearchItem}
                        onShowMore={onShowMore}
                    />
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <FWDataGrid
                            disableFESearch
                            onTextChange={debouncedSearch}
                            defaultColDef={{
                                resizable: true,
                                headerComponent: (props: any) => (
                                    <CustomDashboardGridHeader
                                        {...props}
                                        sortFields={sortFields}
                                        setSortFields={setSortFields}
                                    />
                                ),
                            }}
                            rows={rowData}
                            columns={entityItemsListColumns(
                                authData.details?.role === 'ADMIN'
                            )}
                            onRowClicked={rowClickHandler}
                            context={{
                                handleCellHover,
                                handleItemEmitAction,
                                entityUid,
                                entityName,
                            }}
                            suppressPaginationPanel
                            useCustomPagination={totalPages}
                            customPaginationOnChange={(e, pageNumber) => {
                                setCurrentPage(pageNumber);
                            }}
                            customPaginationPageNumber={currentPage}
                            showRefreshIcon={showRefreshIcon}
                            onRefreshIconClick={() => {
                                setShowRefreshIcon(false);
                                fetchData(1, '', []);
                            }}
                            showFilterColumnMenu
                        />
                    </Grid>
                </Grid>
            </Grid>

            <ItemDirectoryDetailsPopover
                data={selectedItem}
                open={openNamePopover}
                anchor={namePopoverAnchorEl}
                handleClose={() => {
                    setNamePopoverAnchorEl(null);
                }}
            />

            <ItemDirectoryEntitiesPopover
                data={selectedItem}
                open={openEntityPopover}
                anchor={entityPopoverAnchorEl}
                handleClose={() => {
                    setEntityPopoverAnchorEl(null);
                }}
            />

            <ItemPreferredVendorsPopover
                data={selectedItem}
                open={openPreferredVendorsPopover}
                anchor={preferredVendorsPopoverAnchorEl}
                handleClose={() => {
                    setPreferredVendorsPopoverAnchorEl(null);
                }}
            ></ItemPreferredVendorsPopover>

            <PaymentsItemPopup
                open={open}
                setOpen={setOpen}
                itemDetails={{
                    type: 'ITEM_SUMMARY',
                    data: itemDetails,
                    allMeasurementUnits,
                }}
            />
        </>
    );
};

export default ItemsListPage;
