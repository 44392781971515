import { isEmpty, isUndefined } from 'lodash';
import { ICustomTemplateList } from '../../../Global/Interfaces/CustomTemplate.interface';
import { del, get, post, put } from '../../../Utils/api';
import { asModuleValuesMap } from '../Constants/AdminSettingsConstants';
import {
    ASModule,
    ASModuleSetting,
    ASOptionType,
    IAdminSettingModule,
    IASSettings,
} from '../Interfaces/AdminSettingsInterface';

interface ISettingListItem {
    type: ASOptionType;
    key: ASModuleSetting;
    max_value: number | null;
    selected: boolean;
    currency_id: null;
    currency_code_abbreviation: null;
    currency_name: null;
    currency_symbol: null;
    selected_option: string;
}

const setOptionData = ({
    type,
    key: option,
    max_value: value,
    selected: enabled,
    currency_id: currencyUid,
    currency_code_abbreviation: currency,
    currency_symbol: currencySymbol,
    currency_name: currencyName,
    selected_option,
}: ISettingListItem): IASSettings => {
    switch (type) {
        case ASOptionType.BOOLEAN:
            const booleanOpt: IASSettings = {
                option,
                settings: {
                    type,
                    text: asModuleValuesMap[option].text,
                    enabled,
                    tooltip: asModuleValuesMap[option].tooltip,
                },
            };
            return booleanOpt;
        case ASOptionType.MAX_CURRENCY:
            const integerOpt: IASSettings = {
                option,
                settings: {
                    type,
                    text: asModuleValuesMap[option].text,
                    value: value ? value.toString() : '',
                    enabled,
                    label: asModuleValuesMap[option].label,
                    tooltip: asModuleValuesMap[option].tooltip,
                    currency: currency ? currency : '',
                    currencyUid: currencyUid ? currencyUid : '',
                    currencySymbol: currencySymbol ? currencySymbol : '',
                    currencyName: currencyName ? currencyName : '',
                },
            };
            return integerOpt;
        case ASOptionType.MAX_VALUE:
            const decimalOpt: IASSettings = {
                option,
                settings: {
                    type,
                    text: asModuleValuesMap[option].text,
                    value: value ? value.toString() : '',
                    enabled,
                    label: asModuleValuesMap[option].label,
                    tooltip: asModuleValuesMap[option].tooltip,
                },
            };
            return decimalOpt;
        case ASOptionType.CHOICE:
            console.log('bear', selected_option);
            const choiceOpt: IASSettings = {
                option,
                settings: {
                    type,
                    text: asModuleValuesMap[option].text,
                    value: value ? value.toString() : '',
                    enabled,
                    label: asModuleValuesMap[option].label,
                    tooltip: asModuleValuesMap[option].tooltip,
                    selected_option: selected_option,
                },
            };
            return choiceOpt;
    }
};

const setModuleData = (data: any) => {
    const settings = data.settings
        .filter(
            (s: any) =>
                !isUndefined(asModuleValuesMap[s.key as ASModuleSetting])
        )
        .map((setting: ISettingListItem) => {
            return setOptionData(setting);
        });

    const setting: IAdminSettingModule = {
        module: data.module as ASModule,
        settings,
    };

    return setting;
};

export const fetchEntitySettings = async (
    entityUid: string
): Promise<IAdminSettingModule[]> => {
    const response: any = await get(
        `/organization/entity/${entityUid}/settings/`
    );
    const data: any = response.data;
    if (!isEmpty(entityUid)) {
        if (data) {
            const settingsList = data.entity_settings_list.map(
                (moduleData: any) => {
                    return setModuleData(moduleData);
                }
            );
            return settingsList;
        }
    }
    return [];
};

interface ISettingUpdatePayload {
    entity_settings_list: Array<{
        module: string;
        settings: Array<{
            key: string;
            type: string;
            max_value: number | null;
            selected: boolean;
            currency_id: string | null;
            currency_code_abbreviation: string | null;
            currency_name: string | null;
            currency_symbol: string | null;
            selected_option?: string;
        }>;
    }>;
}

export const updateEntitySettings = async (
    settings: IAdminSettingModule[],
    entityUid: string
): Promise<boolean> => {
    try {
        const payload: ISettingUpdatePayload = {
            entity_settings_list: settings.map((module) => {
                return {
                    module: module.module,
                    settings: module.settings.map(({ option, settings }) => {
                        switch (settings.type) {
                            case ASOptionType.CHOICE:
                                return {
                                    key: option,
                                    type: settings.type,
                                    selected_option: settings.selected_option,
                                    selected: settings.enabled,
                                    currency_id: null,
                                    currency_code_abbreviation: null,
                                    currency_name: null,
                                    currency_symbol: null,
                                    max_value: null,
                                };
                            case ASOptionType.MAX_CURRENCY:
                                return {
                                    key: option,
                                    type: settings.type,
                                    max_value: settings.value
                                        ? parseFloat(settings.value)
                                        : null,
                                    selected: settings.enabled,
                                    currency_id: settings.currencyUid || null,
                                    currency_code_abbreviation:
                                        settings.currency || null,
                                    currency_name:
                                        settings.currencyName || null,
                                    currency_symbol:
                                        settings.currencySymbol || null,
                                };
                            case ASOptionType.MAX_VALUE:
                                return {
                                    key: option,
                                    type: settings.type,
                                    max_value: settings.value
                                        ? parseFloat(settings.value)
                                        : null,
                                    selected: settings.enabled,
                                    currency_id: null,
                                    currency_code_abbreviation: null,
                                    currency_name: null,
                                    currency_symbol: null,
                                };
                            default: // in case BOOLEAN
                                return {
                                    key: option,
                                    type: settings.type,
                                    max_value: null,
                                    selected: settings.enabled,
                                    currency_id: null,
                                    currency_code_abbreviation: null,
                                    currency_name: null,
                                    currency_symbol: null,
                                };
                        }
                    }),
                };
            }),
        };
        await put(
            `/organization/entity/${entityUid}/settings/update/`,
            payload
        );
        return true;
    } catch {
        return false;
    }
};

// An asynchronous function that hits templates/entity/{{entity_id}}/create/ endpoint
// and returns the response
export const createCustomTemplate = async (
    data: ICustomTemplateList
): Promise<any> => {
    let payload = {
        template_name: data.template_name,
        template_type: data.type,
        template_items: data.items.map((item) => ({
            template_item_name: item.template_item_name,
            template_item_type: item.template_item_type,
            is_required: item.is_required,
            // adding addition info for events only
            ...(data.type === 'RFQ_ITEM_CUSTOM_FIELDS'
                ? {
                      additional_information: {
                          is_negotiable:
                              item.additional_information?.is_negotiable ??
                              false,
                          is_visible:
                              item.additional_information?.is_visible ?? true,
                      },
                  }
                : {
                      additional_information: {
                          is_visible:
                              item.additional_information?.is_visible ?? true,
                      },
                  }),
        })),
    };

    const response: any = await post(
        `/templates/entity/${data.entity}/create/`,
        payload
    );
    return {
        ...response.data,
        items: response.data.template_items,
    };
};

export const updateCustomTemplate = async (
    data: ICustomTemplateList
): Promise<any> => {
    let payload = {
        template_name: data.template_name,
        template_type: data.type,
        template_items: data.items.map((item) => ({
            template_item_name: item.template_item_name,
            template_item_type: item.template_item_type,
            template_item_id:
                item.template_item_id === '' ? null : item.template_item_id,
            is_required: item.is_required,
            // adding addition info for events only
            ...(data.type === 'RFQ_ITEM_CUSTOM_FIELDS'
                ? {
                      additional_information: {
                          is_negotiable:
                              item.additional_information?.is_negotiable ??
                              false,
                          is_visible:
                              item.additional_information?.is_visible ?? true,
                      },
                  }
                : {
                      additional_information: {
                          is_visible:
                              item.additional_information?.is_visible ?? true,
                      },
                  }),
        })),
    };
    const response: any = await put(
        `/templates/entity/${data.entity}/${data.template_id}/update/`,
        payload
    );
    return {
        ...response.data,
        items: response.data.template_items,
    };
};

export const deleteCustomTemplate = async (
    entity_id: string,
    template_id: string
): Promise<void> => {
    await del(`/templates/entity/${entity_id}/${template_id}/delete/`);
};
