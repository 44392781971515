import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, isEqual, isNaN, isNumber } from 'lodash';
import moment from 'moment';
import {
    AllocationTypeEnum,
    CostTypeEnum,
    IAdditionalCostInformation,
} from '../../AdditionalCost/models/AdditionalCost.model';
import { calculateAdditionalCostForFormulaCustomFields } from '../../AdditionalCost/shared/AdditionalCostAutofill';
import { IGetFormulaResponse } from '../../Formula/Models/formula.model';
import { ICurrencyDetails } from '../../Models/Currency.model';
import { IEntityViewBuyerMaster } from '../../Organizations/SellerAdmin/BuyerMaster/Interfaces/SellerAdminBuyerInterfaces.model';
import {
    AdditionalFinalCostingDataInterface,
    CostingSheetStatusEnum,
    IQuoteDetails,
} from '../Interfaces/CostingInterfaces.interface.';
import {
    blankCostingTemplate,
    ICostingTemplate,
} from '../Interfaces/CostingTemplate.interface';
import { IQuoteApprover } from '../Interfaces/QuoteApprovals.interface';
import {
    IQuoteBomResponse,
    IQuoteSubBom,
} from '../Interfaces/QuoteBom.interface';
import { quoteSaveStatus } from '../Interfaces/QuoteItem.interface';
import { calculateTotalForAnItem } from '../Misc/helperFunctions';
import {
    getAllSubBomsFromEnterpriseBOM,
    getBomitemIndexMappingusingSplitItems,
    quoteBoMUpdateQUantityOfBOMs,
    quoteUpdateSubBOMAdditionalCosts,
} from '../Misc/QuoteBOMHelper';
import {
    calculateFormulaAdditionalCostForQuoteItem,
    combineMultipleQuoteSheetItemsWithoutCalcultion,
    getAllTheParentSubBOmDetails,
    getSheetTotal,
    recursivelyUpdateBomParents,
} from '../Misc/QuoteHelperFunctions';
import {
    convertICostingCalculatorApiResponseListMappingToICostingCalculatorApiResponseMapping,
    updateAllCostingSheetItem,
} from '../Misc/QuoteItemHelper';
import {
    ICostingCalculatorApiResponse,
    IQuoteItemError,
    IQuoteItemID,
} from '../Services/CostingApisCalls.service';
// import { EnergySavingsLeaf } from '@mui/icons-material';
// import { idText } from 'typescript';

export interface IQuoteCalculatorStore {
    currentPage: number;
    totalPages: number;
    itemsPerPage: number;
    searchQuery: string;
    quoteDetails: IQuoteDetails;
    quoteItemIds: IQuoteItemID[];
    quoteItemIndex: {
        [quoteItemId: string]: number;
    };
    quoteItemDetails: {
        [quoteItemId: string]: ICostingCalculatorApiResponse[];
    };
    quoteItemCombined: {
        [costing_sheet_item_id: string]: {
            combinedItem: ICostingCalculatorApiResponse;
            totalQuantity: number;
        };
    };
    enterpriseBomData: {
        [enterpriseBOMIdList: string]: {
            index: any;
            [subBomId: string]: IQuoteSubBom;
            parentBom: IQuoteSubBom;
        };
    };
    quoteBOMDetails: {
        [enterprise_bom_id: string]: IQuoteItemID[]; // store the item ids of bomItems and  subBomItems
    };
    uniqueEvents: {
        [custom_event_id: string]: {
            event_id: string;
            rfq_entry_id: string;
        };
    };
    selectedEnterpriseBomId: {
        enterpriseBomId: string | null;
        bomEntryId: string | null;
    };
    addingNewItem: boolean;
    showAllItemsInLoadingState: boolean;
    showBlankQuoteItem: boolean;
    callDashboardForNextPage: {
        currentPage: number;
        hasNext: boolean;
        numberOfItems: number;
    };
    buyerEntities: IEntityViewBuyerMaster[];
    templateDetails: ICostingTemplate;
    hideResetAllRatesButton: boolean;
    hideDeleteCostingSheetButton: boolean;
    showPageLoading: boolean;
    disableExportBtn: boolean;
    disableGlobalResetToPOPriceBtn: boolean;
    disableReviseChangesButton: boolean;
    costingPageMode: 'EDIT' | 'VIEW';
    quoteSheetId: string;
    quoteCurrencyDetails: {
        currency_code_abbreviation: string;
        currency_name: string;
        currency_symbol: string;
        entry_id: string;
        decimals: number;
    };
    currencies: ICurrencyDetails[];
    additionalCostInitialStatus: {
        [quoteItemId: string]: {
            conversion_factor: string;
            additional_costs: {
                [additionalCostLinkageId: string]: string;
            };
        };
    };
    quoteLevelErrors: {
        quote_name?: string;
        quote_currency?: string;
        validity_datetime?: string;
        deadline_datetime?: string;
        customer_name?: string;
        custom_fields?: {
            [field: string]: string;
        };
    };
    quoteItemErrors: {
        [itemId: string]: {
            [itemIndex: number]: IQuoteItemError;
        };
    };
    adminAdditionalCostData: IAdditionalCostInformation[];
    nextApproverList: {
        user_id: string;
        name: string;
    }[];
    finalApproverList: {
        user_id: string;
        name: string;
    }[];
    subTotal: number;
    bomTotal: number;
    agreedSubTotal: number;
    agreedBomTotal: number;
    quoteSaveStatus: quoteSaveStatus;
    quoteStatusTooltips: {
        approver: string;
        approver_name: string;
        notes: string;
    }[];
    quoteApprovers: IQuoteApprover[];
    listOfFormulaForCustomFields: IGetFormulaResponse[];
}

const quoteStoreInitialData: IQuoteCalculatorStore = {
    currentPage: 1,
    selectedEnterpriseBomId: {
        bomEntryId: null,
        enterpriseBomId: null,
    },
    quoteItemCombined: {},
    quoteStatusTooltips: [],
    quoteSaveStatus: 'SAVED',
    totalPages: 1,
    itemsPerPage: 10,
    adminAdditionalCostData: [],
    showBlankQuoteItem: false,
    showAllItemsInLoadingState: false,
    callDashboardForNextPage: {
        currentPage: 1,
        hasNext: false,
        numberOfItems: 10,
    },
    enterpriseBomData: {},
    searchQuery: '',
    additionalCostInitialStatus: {},
    templateDetails: blankCostingTemplate,
    uniqueEvents: {},
    quoteSheetId: '',
    addingNewItem: false,
    quoteItemIds: [],
    currencies: [],
    quoteItemIndex: {},
    showPageLoading: false,
    disableExportBtn: false,
    disableGlobalResetToPOPriceBtn: false,
    hideDeleteCostingSheetButton: false,
    hideResetAllRatesButton: false,
    disableReviseChangesButton: false,
    costingPageMode: 'EDIT',
    quoteItemDetails: {},
    quoteBOMDetails: {},
    buyerEntities: [],
    quoteCurrencyDetails: {
        decimals: 2,
        currency_code_abbreviation: '',
        currency_name: '',
        currency_symbol: '',
        entry_id: '',
    },
    quoteDetails: {
        view: 'ADVANCED',
        additional_costs: [],
        costing_sheet_name: '',
        additional_details: {
            template_id: '',
        },
        costing_sheet_id: '',
        custom_costing_sheet_id: '',
        currency_details: {
            decimals: 2,
            currency_code_abbreviation: '',
            currency_name: '',
            currency_symbol: '',
            entry_id: '',
        },
        project_details: null,
        custom_fields: {
            section_list: [],
        },
        custom_fields_negotiate: {
            section_list: [],
        },
        custom_sections: [],
        customer_entity_details: {
            entity_id: '',
            entity_name: '',
        },
        deadline_datetime: '',
        seller_entity: '',
        seller_entity_name: '',
        status: CostingSheetStatusEnum.DRAFT,
        total: 0,
        agreedTotal: 0,
        validity_datetime: '',
        customer_contact_ids: [],
        version: 1,
        created_datetime: '',
        created_by_user_details: { name: '', user_id: '' },
        modified_by_user_details: { name: '', user_id: '' },
        quote_managers: [],
        attachment_ids: [],
    },
    nextApproverList: [],
    finalApproverList: [],
    quoteApprovers: [],
    quoteLevelErrors: {},
    quoteItemErrors: {},
    subTotal: 0,
    bomTotal: 0,
    agreedSubTotal: 0,
    agreedBomTotal: 0,
    listOfFormulaForCustomFields: [],
};
export interface IUpdateQuoteStoreAction {
    type: string;
    payload:
        | {
              type: 'UPDATE_QUOTE_PAGENUMBER';
              value: number;
          }
        | {
              type: 'UPDATE_QUOTE_BOM_QUANTITY';
              value: {
                  enterpriseBomId: string;
                  entryId: string;
                  quantity: string;
              };
          }
        | {
              type: 'UPDATE_QUOTE_BOM_ADDITIONAL_COSTS';
              value: {
                  enterpriseBomId: string;
                  entryId: string;
                  additionalCosts: AdditionalFinalCostingDataInterface[];
              };
          }
        | {
              type: 'UPDATE_RAW_MATERIAL_RATIO';
              value: {
                  enterpriseBomId: string;
                  entryId: string;
                  quantity: string;
              };
          }
        | {
              type: 'UPDATE_QUOTE_STATUS';
              value: CostingSheetStatusEnum;
          }
        | {
              type: 'UPDATE_QUOTE_STATUS_TOOLTIP';
              value: {
                  approver: string;
                  approver_name: string;
                  notes: string;
              }[];
          }
        | {
              type: 'UPDATE_QUOTE_TOTALPAGES';
              value: number;
          }
        | {
              type: 'UPDATE_QUOTE_ITEMSPERPAGE';
              value: number;
          }
        | {
              type: 'UPDATE_QUOTE_SAVE_STATUS';
              value: quoteSaveStatus;
          }
        | {
              type: 'UPDATE_QUOTE_NEXT_APPROVERS';
              value: {
                  user_id: string;
                  name: string;
              }[];
          }
        | {
              type: 'UPDATE_QUOTE_FINAL_APPROVERS';
              value: {
                  user_id: string;
                  name: string;
              }[];
          }
        | {
              type: 'DELETE_QUOTE_ITEM';
              value: {
                  itemId: string;
                  itemIndex: number;
              }; // costing_sheet_item_id;
          }
        | {
              type: 'UPDATE_ADDITIONAL_COST_DATA';
              value: IAdditionalCostInformation[];
          }
        | {
              type: 'UPDATE_QUOTE_ERRORS';
              value: {
                  quote_name?: string;
                  quote_currency?: string;
                  validity_datetime?: string;
                  deadline_datetime?: string;
                  customer_name?: string;
                  custom_fields?: {
                      [field: string]: string;
                  };
              };
          }
        | {
              type: 'RESET_ERROR_STATE';
          }
        | {
              type: 'UPDATE_QUOTE_ITEM_ERRORS';
              value: {
                  itemId: string;
                  itemIndex: number;
                  errorObj: {
                      quantity?: string;
                      rate?: string;
                      lead_time?: string;
                      notes?: string;
                      additionalCost?: string;
                      custom_fields?: {
                          [field: string]: string;
                      };
                  };
              };
          }
        | {
              type: 'UPDATE_QUOTE_SEARCH';
              value: string;
          }
        | {
              type: 'UPDATE_QUOTE_APPROVAL_LOG';
              value: IQuoteApprover[];
          }
        | {
              type: 'UPDATE_QUOTE_ADD_NEW_ITEM_STATE';
              value: boolean;
          }
        | {
              type: 'UPDATE_QUOTE_LOADER_STATE';
              value: boolean;
          }
        | {
              type: 'UPDATE_QUOTE_ITEM_LOADING_STATE';
              value: boolean;
          }
        | {
              type: 'UPDATE_QUOTE_SHOW_BLANK_ITEM_STATE';
              value: boolean;
          }
        | {
              type: 'UPDATE_QUOTE_CALL_DASHBOARD_FOR_NEXT_PAGE';
              value: {
                  currentPage: number;
                  hasNext: boolean;
                  numberOfItems: number;
              };
          }
        | {
              type: 'UPDATE_CURRENCY';
              value: ICurrencyDetails[];
          }
        | {
              type: 'UPDATE_QUOTE_SHEET_ID';
              value: string;
          }
        | {
              type: 'UPDATE_QUOTE_VIEW_MODE';
              value: 'VIEW' | 'EDIT';
          }
        | {
              type: 'UPDATE_QUOTE_SHEET_VIEW';
              value: 'SIMPLE' | 'ADVANCED';
          }
        | {
              type: 'UPDATE_BUYER_ENTITIES';
              value: IEntityViewBuyerMaster[];
          }
        | {
              type: 'UPDATE_ENTERPRISE_BOM_DATA';
              value: {
                  [enterpriseBOMIdList: string]: IQuoteBomResponse;
              };
          }
        | {
              type: 'RESTORE_ENTERPRISE_BOM_DATA';
              value: {
                  [enterpriseBOMIdList: string]: {
                      index: any;
                      [subBomId: string]: IQuoteSubBom;
                      parentBom: IQuoteSubBom;
                  };
              };
          }
        | {
              type: 'UPDATE_QUOTE_DETAILS';
              value: IQuoteDetails;
          }
        | {
              type: 'UPDATE_QUOTE_MANAGERS';
              value: { user_id: string; name: string; permission: string }[];
          }
        | {
              type: 'UPDATE_QUOTE_CURRENCY';
              value: {
                  currency_details: {
                      currency_code_abbreviation: string;
                      currency_name: string;
                      currency_symbol: string;
                      entry_id: string;
                      decimals: number;
                  };
              };
          }
        | {
              type: 'UPDATE_QUOTE_SECTION_STATUS';
              value: {
                  sectionName: string;
                  status: 'ASSIGNING_USERS' | 'DRAFT' | 'SUBMITTED' | 'REVISED';
              };
          }
        | {
              type: 'UPDATE_QUOTE_SECTION_START_TIME';
              value: {
                  sectionName: string;
                  start_time: string;
              };
          }
        | {
              type: 'RESET_QUOTE_DETAIL';
          }
        | {
              type: 'QUOTE_SET_SELECTED_BOM';
              value: {
                  enterpriseBomId: string | null;
                  bomEntryId: string | null;
              };
          }
        | {
              type: 'UPDATE_QUOTE_ITEM';
              value: {
                  updateAllItemsWithSameid?: boolean;
                  itemId: string;
                  itemIndex: number;
                  value: ICostingCalculatorApiResponse;
                  enterpriseBOMEntryId?: string;
                  subBOMEntryId?: string;
              };
          }
        | {
              type: 'APPEND_NEW_QUOTE_ITEM';
              value: {
                  itemId: string;
                  value: ICostingCalculatorApiResponse;
              };
          }
        | {
              type: 'UPDATE_QUOTE_TEMPLATE';
              value: ICostingTemplate;
          }
        | {
              type: 'UPDATE_QUOTE_ITEM_ADDITIONAL_COST';
              value: ICostingTemplate;
          }
        | {
              type: 'UPDATE_QUOTE_ITEMS';
              value: ICostingCalculatorApiResponse[];
          }
        | {
              type: 'RESTORE_QUOTE_ITEMS_STATE';
              value: {
                  [quoteItemId: string]: ICostingCalculatorApiResponse[];
              };
          }
        | {
              type: 'UDATE_QUOTE_ITEMS_WITH_SAME_ID';
              value: {
                  itemId: string;
                  updatedItemList: ICostingCalculatorApiResponse[];
              };
          }
        | {
              type: 'UPDATE_QUOTE_ITEMS_REVERT_CHANGE';
              value: ICostingCalculatorApiResponse[];
          }
        | {
              type: 'UPDATE_QUOTE_CURRENCY_ITEM_UPDATE';
              value: {
                  [quoteItemId: string]: ICostingCalculatorApiResponse[];
              };
          }
        | {
              type: 'UPDATE_LIST_OF_FORMULA_FOR_CUSTOM_FIELDS';
              value: IGetFormulaResponse[];
          };
}

export const QuoteCalculatorSlice = createSlice<
    IQuoteCalculatorStore,
    {
        updateQuoteDataStore: (
            state: IQuoteCalculatorStore,
            action: IUpdateQuoteStoreAction
        ) => void;
    },
    string
>({
    name: 'QuoteCalculatorStore',
    initialState: quoteStoreInitialData,
    reducers: {
        updateQuoteDataStore: (
            state: IQuoteCalculatorStore,
            action: IUpdateQuoteStoreAction
        ) => {
            try {
                switch (action.payload.type) {
                    case 'UPDATE_QUOTE_PAGENUMBER':
                        state.currentPage = action.payload.value;
                        break;
                    case 'UDATE_QUOTE_ITEMS_WITH_SAME_ID':
                        const total = calculateTotalForAnItem(
                            action.payload.value.updatedItemList[0].additional_costs.filter(
                                (cost) => cost.is_calculated
                            ),
                            action.payload.value.updatedItemList[0].rate ?? 0,
                            +action.payload.value.updatedItemList[0].quantity
                        )?.total;
                        let updatedItems =
                            action.payload.value.updatedItemList.map((item) => {
                                return {
                                    ...item,
                                    total: total,
                                };
                            });

                        state.quoteItemDetails[action.payload.value.itemId] =
                            updatedItems;

                        const {
                            finalTotal: sheetTotal,
                            bomTotal: sheetBomTotal,
                            subTotal: sheetSubTotal,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        state.quoteItemIds.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId?.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId?.bomEntryId
                        );

                        const {
                            finalTotal: agreedSheetTotal,
                            bomTotal: agreedSheetBomTotal,
                            subTotal: agreedSheetSubTotal,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        state.quoteItemIds.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId?.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId?.bomEntryId,
                            true
                        );

                        state.totalPages = Math.ceil(
                            state.quoteItemIds?.length / state.itemsPerPage
                        );

                        state.quoteDetails.total = sheetTotal;
                        state.bomTotal = sheetBomTotal;
                        state.subTotal = sheetSubTotal;
                        state.quoteDetails.agreedTotal = agreedSheetTotal;
                        state.agreedBomTotal = agreedSheetBomTotal;
                        state.agreedSubTotal = agreedSheetSubTotal;

                        break;
                    case 'UPDATE_QUOTE_BOM_QUANTITY':
                        const currentEnterpriseData = cloneDeep(
                            state.enterpriseBomData[
                                action.payload.value.enterpriseBomId
                            ]
                        );

                        const currentSubBomItemToBeUpdated = cloneDeep(
                            currentEnterpriseData[action.payload.value.entryId]
                        );

                        const qunatityConversionFactor =
                            +currentSubBomItemToBeUpdated.quantity
                                ? +action.payload.value.quantity /
                                  +currentSubBomItemToBeUpdated.quantity
                                : 1;
                        currentSubBomItemToBeUpdated.quantity =
                            +action.payload.value.quantity;
                        let newQuoteItemDetails = cloneDeep(
                            state.quoteItemDetails
                        );

                        let subBomData: {
                            [subBomId: string]: IQuoteSubBom;
                        } = {};

                        quoteBoMUpdateQUantityOfBOMs(
                            currentSubBomItemToBeUpdated,
                            currentEnterpriseData,
                            qunatityConversionFactor,
                            newQuoteItemDetails,
                            subBomData,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state,
                            action.payload.value.enterpriseBomId
                        );

                        // state.enterpriseBomData[
                        //     action.payload.value.enterpriseBomId
                        // ].parentBom = currentSubBomItemToBeUpdated;

                        // state.enterpriseBomData[
                        //     action.payload.value.enterpriseBomId
                        // ][action.payload.value.enterpriseBomId] =
                        //     currentSubBomItemToBeUpdated;

                        const newEnterpriseBomData =
                            recursivelyUpdateBomParents(
                                action.payload.value.enterpriseBomId,
                                action.payload.value.entryId,
                                currentSubBomItemToBeUpdated,
                                state.enterpriseBomData[
                                    action.payload.value.enterpriseBomId
                                ]
                            );

                        state.enterpriseBomData[
                            action.payload.value.enterpriseBomId
                        ] = cloneDeep(newEnterpriseBomData);
                        state.enterpriseBomData.parentBom =
                            cloneDeep(newEnterpriseBomData);

                        // if (
                        //     state.enterpriseBomData[
                        //         action.payload.value.enterpriseBomId
                        //     ] &&
                        //     state.enterpriseBomData[
                        //         action.payload.value.enterpriseBomId
                        //     ][action.payload.value.entryId]
                        // ) {
                        //     state.enterpriseBomData[
                        //         action.payload.value.enterpriseBomId
                        //     ][action.payload.value.entryId].quantity =
                        //         +action.payload.value.quantity;

                        //     state.enterpriseBomData[
                        //         action.payload.value.enterpriseBomId
                        //     ][action.payload.value.entryId].bom_items =
                        //         currentSubBomItemToBeUpdated.bom_items;
                        //     state.enterpriseBomData[
                        //         action.payload.value.enterpriseBomId
                        //     ] = {
                        //         ...cloneDeep(
                        //             state.enterpriseBomData[
                        //                 action.payload.value.enterpriseBomId
                        //             ]
                        //         ),
                        //         ...subBomData,
                        //     };
                        // }

                        let updatedDSData = cloneDeep(newQuoteItemDetails);

                        Object.keys(updatedDSData).forEach((itemId) => {
                            const currentItems = updatedDSData[itemId];

                            for (let item of currentItems) {
                                if (item.delivery_schedule.length === 1) {
                                    item.delivery_schedule[0].quantity =
                                        +item.quantity;
                                }
                            }
                        });

                        state.quoteItemDetails = updatedDSData;

                        const {
                            finalTotal: quoteTotal7,
                            bomTotal: quoteBomTotal7,
                            subTotal: quoteSubTotal7,
                        } = getSheetTotal(
                            state.quoteItemIds.map(
                                (item) => item.costing_sheet_item_id
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId
                        );

                        const {
                            finalTotal: agreedQuoteTotal7,
                            bomTotal: agreedQuoteBomTotal7,
                            subTotal: agreedQuoteSubTotal7,
                        } = getSheetTotal(
                            state.quoteItemIds.map(
                                (item) => item.costing_sheet_item_id
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId,
                            true
                        );

                        state.quoteDetails.total = quoteTotal7;
                        state.bomTotal = quoteBomTotal7;
                        state.subTotal = quoteSubTotal7;
                        state.quoteDetails.agreedTotal = agreedQuoteTotal7;
                        state.agreedBomTotal = agreedQuoteBomTotal7;
                        state.agreedSubTotal = agreedQuoteSubTotal7;

                        break;

                    case 'UPDATE_QUOTE_BOM_ADDITIONAL_COSTS':
                        state.enterpriseBomData[
                            action.payload.value.enterpriseBomId
                        ][action.payload.value.entryId].additional_costs =
                            action.payload.value.additionalCosts;

                        const currEnterpriseBomData = cloneDeep(
                            state.enterpriseBomData[
                                action.payload.value.enterpriseBomId
                            ]
                        );
                        for (let key of Object.keys(currEnterpriseBomData)) {
                            if (key === action.payload.value.enterpriseBomId) {
                                for (let bomItem of currEnterpriseBomData[key]
                                    .bom_items) {
                                    if (!bomItem.isRawMaterial) {
                                        if (
                                            bomItem.entry_id ===
                                            action.payload.value.entryId
                                        )
                                            bomItem.additional_costs =
                                                action.payload.value.additionalCosts;
                                        else
                                            quoteUpdateSubBOMAdditionalCosts(
                                                bomItem,
                                                action.payload.value.entryId,
                                                action.payload.value
                                                    .additionalCosts
                                            );
                                    }
                                }
                            }
                        }
                        state.enterpriseBomData[
                            action.payload.value.enterpriseBomId
                        ] = currEnterpriseBomData;

                        const {
                            finalTotal: quoteTotal8,
                            bomTotal: quoteBomTotal8,
                            subTotal: quoteSubTotal8,
                        } = getSheetTotal(
                            state.quoteItemIds.map(
                                (item) => item.costing_sheet_item_id
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId
                        );

                        const {
                            finalTotal: agreedQuoteTotal8,
                            bomTotal: agreedQuoteBomTotal8,
                            subTotal: agreedQuoteSubTotal8,
                        } = getSheetTotal(
                            state.quoteItemIds.map(
                                (item) => item.costing_sheet_item_id
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId,
                            true
                        );

                        state.quoteDetails.total = quoteTotal8;
                        state.bomTotal = quoteBomTotal8;
                        state.subTotal = quoteSubTotal8;
                        state.quoteDetails.agreedTotal = agreedQuoteTotal8;
                        state.agreedBomTotal = agreedQuoteBomTotal8;
                        state.agreedSubTotal = agreedQuoteSubTotal8;

                        break;

                    case 'RESTORE_ENTERPRISE_BOM_DATA':
                        state.enterpriseBomData = action.payload.value;
                        break;

                    case 'UPDATE_ENTERPRISE_BOM_DATA':
                        const currentQuoteItemList = cloneDeep(
                            state.quoteItemDetails
                        );

                        const combinedItemMapped =
                            convertICostingCalculatorApiResponseListMappingToICostingCalculatorApiResponseMapping(
                                currentQuoteItemList
                            );

                        const uniqueIdList = Array.from(
                            new Set(
                                state.quoteItemIds.map(
                                    (id) => id.costing_sheet_item_id
                                )
                            )
                        );

                        const bomItemIdsMappedToIndex =
                            getBomitemIndexMappingusingSplitItems(
                                currentQuoteItemList,
                                uniqueIdList
                            );

                        const enterpriseBOMData =
                            getAllSubBomsFromEnterpriseBOM(
                                action.payload.value,
                                combinedItemMapped,
                                bomItemIdsMappedToIndex
                            );

                        state.enterpriseBomData = {
                            ...state.enterpriseBomData,
                            ...enterpriseBOMData,
                        };

                        const {
                            finalTotal: quoteTotal10,
                            bomTotal: quoteBomTotal10,
                            subTotal: quoteSubTotal10,
                        } = getSheetTotal(
                            uniqueIdList,
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId
                        );

                        const {
                            finalTotal: agreedQuoteTotal10,
                            bomTotal: agreedQuoteBomTotal10,
                            subTotal: agreedQuoteSubTotal10,
                        } = getSheetTotal(
                            uniqueIdList,
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId,
                            true
                        );

                        // state.quoteItemIds = uniqueIdList;
                        state.quoteDetails.total = quoteTotal10;
                        state.bomTotal = quoteBomTotal10;
                        state.subTotal = quoteSubTotal10;
                        state.quoteDetails.agreedTotal = agreedQuoteTotal10;
                        state.agreedBomTotal = agreedQuoteBomTotal10;
                        state.agreedSubTotal = agreedQuoteSubTotal10;

                        break;
                    case 'UPDATE_QUOTE_STATUS':
                        state.quoteDetails.status = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_STATUS_TOOLTIP':
                        state.quoteStatusTooltips = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_APPROVAL_LOG':
                        state.quoteApprovers = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_SAVE_STATUS':
                        state.quoteSaveStatus = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_NEXT_APPROVERS':
                        state.nextApproverList = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_FINAL_APPROVERS':
                        state.finalApproverList = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_LOADER_STATE':
                        state.showPageLoading = action.payload.value;
                        break;
                    case 'RESET_ERROR_STATE':
                        state.quoteItemErrors = {};
                        state.quoteLevelErrors = {};
                        break;
                    case 'UPDATE_QUOTE_VIEW_MODE':
                        state.costingPageMode = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_SHEET_VIEW':
                        state.quoteDetails = {
                            ...state.quoteDetails,
                            view: action.payload.value,
                        };
                        break;
                    case 'RESTORE_QUOTE_ITEMS_STATE':
                        state.quoteItemDetails = {
                            ...state.quoteItemDetails,
                            ...action.payload.value,
                        };
                        break;
                    case 'UPDATE_ADDITIONAL_COST_DATA':
                        state.adminAdditionalCostData = action.payload.value;
                        break;
                    case 'DELETE_QUOTE_ITEM':
                        let newItemIdList = cloneDeep(state.quoteItemIds);

                        const itemIdToBeDeleted = action.payload.value.itemId;
                        const itemIndexToBeDeleted =
                            action.payload.value.itemIndex;

                        const numberOfItemsWithCurrentId =
                            state.quoteItemDetails[action.payload.value.itemId]
                                .length;

                        const itemIndex = newItemIdList.findIndex(
                            (id) =>
                                id.costing_sheet_item_id ===
                                    itemIdToBeDeleted &&
                                id.item_index === itemIndexToBeDeleted
                        );
                        if (itemIndex !== -1) {
                            newItemIdList.splice(itemIndex, 1);
                        }

                        if (numberOfItemsWithCurrentId === 1) {
                            if (
                                state.selectedEnterpriseBomId
                                    .enterpriseBomId === null &&
                                Object.keys(state.quoteBOMDetails).length > 0
                            ) {
                                let enterprise_bom_id = state.quoteItemIds.find(
                                    (id) =>
                                        id.costing_sheet_item_id ===
                                        itemIdToBeDeleted
                                )?.bom_entry_id;
                                if (enterprise_bom_id) {
                                    const bomItemIndex = state.quoteBOMDetails[
                                        enterprise_bom_id
                                    ].findIndex(
                                        (item) =>
                                            item.costing_sheet_item_id ===
                                            itemIdToBeDeleted
                                    );
                                    if (bomItemIndex !== -1) {
                                        state.quoteBOMDetails[
                                            enterprise_bom_id
                                        ].splice(bomItemIndex, 1);
                                    }
                                }
                            }

                            delete state.quoteItemDetails[
                                action.payload.value.itemId
                            ];
                        } else {
                            let newItemArray = cloneDeep(
                                state.quoteItemDetails[
                                    action.payload.value.itemId
                                ]
                            );

                            newItemArray.splice(
                                action.payload.value.itemIndex,
                                1
                            );
                            state.quoteItemDetails[
                                action.payload.value.itemId
                            ] = newItemArray;
                        }

                        if (
                            state.quoteItemErrors[itemIdToBeDeleted] &&
                            state.quoteItemErrors[itemIdToBeDeleted][
                                action.payload.value.itemIndex
                            ]
                        ) {
                            delete state.quoteItemErrors[itemIdToBeDeleted][
                                action.payload.value.itemIndex
                            ];
                        }

                        let newBomIdList = cloneDeep(
                            state.selectedEnterpriseBomId.enterpriseBomId
                                ? state.quoteBOMDetails[
                                      state.selectedEnterpriseBomId
                                          .enterpriseBomId
                                  ]
                                : []
                        );

                        const itemIdIndexInEnterpriseBOMList =
                            newBomIdList.findIndex(
                                (id) =>
                                    id.costing_sheet_item_id ===
                                        itemIdToBeDeleted &&
                                    id.item_index === itemIndexToBeDeleted
                            );

                        if (itemIdIndexInEnterpriseBOMList !== -1) {
                            newBomIdList.splice(
                                itemIdIndexInEnterpriseBOMList,
                                1
                            );
                            if (state.selectedEnterpriseBomId.enterpriseBomId)
                                state.quoteBOMDetails[
                                    state.selectedEnterpriseBomId.enterpriseBomId
                                ] = newBomIdList;
                        }

                        let itemsInEnterpriseBom = cloneDeep(
                            state.selectedEnterpriseBomId.enterpriseBomId
                                ? state.quoteBOMDetails[
                                      state.selectedEnterpriseBomId
                                          .enterpriseBomId
                                  ].length
                                : 0
                        );
                        if (itemsInEnterpriseBom === 0) {
                            state.selectedEnterpriseBomId = {
                                enterpriseBomId: null,
                                bomEntryId: null,
                            };
                        }

                        const itemIdsForCurrentEnterpriseBomAfterDeletingItem =
                            state.selectedEnterpriseBomId.enterpriseBomId
                                ? state.quoteBOMDetails[
                                      state.selectedEnterpriseBomId
                                          .enterpriseBomId
                                  ]
                                : newItemIdList;

                        const {
                            finalTotal: quoteTotal,
                            bomTotal: quoteBomTotal,
                            subTotal: quoteSubTotal,
                        } = getSheetTotal(
                            Array.from(
                                new Set(
                                    itemIdsForCurrentEnterpriseBomAfterDeletingItem.map(
                                        (item) => item.costing_sheet_item_id
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId
                        );

                        const {
                            finalTotal: agreedQuoteTotal,
                            bomTotal: agreedQuoteBomTotal,
                            subTotal: agreedQuoteSubTotal,
                        } = getSheetTotal(
                            Array.from(
                                new Set(
                                    itemIdsForCurrentEnterpriseBomAfterDeletingItem.map(
                                        (item) => item.costing_sheet_item_id
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId,
                            true
                        );

                        state.quoteItemIds = newItemIdList;
                        state.quoteDetails.total = quoteTotal;
                        state.bomTotal = quoteBomTotal;
                        state.subTotal = quoteSubTotal;
                        state.quoteDetails.agreedTotal = agreedQuoteTotal;
                        state.agreedBomTotal = agreedQuoteBomTotal;
                        state.agreedSubTotal = agreedQuoteSubTotal;

                        break;
                    case 'UPDATE_QUOTE_ERRORS':
                        state.quoteLevelErrors = action.payload.value;
                        break;
                    case 'QUOTE_SET_SELECTED_BOM':
                        state.selectedEnterpriseBomId = action.payload.value;
                        state.currentPage = 1;

                        const subBomId = action.payload.value.bomEntryId;

                        const itemIdsForCurrentEnterpriseBom = action.payload
                            .value?.enterpriseBomId
                            ? state.quoteBOMDetails[
                                  action.payload.value.enterpriseBomId
                              ].filter((id) =>
                                  subBomId === null
                                      ? true
                                      : id.list_parent_bom_entry_id.includes(
                                            subBomId
                                        )
                              )
                            : state.quoteItemIds;

                        const {
                            finalTotal: enterpriseBomFinalTotal,
                            bomTotal: enterpriseBomTotal,
                            subTotal: enterpriseBomSubTotal,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            action.payload.value?.enterpriseBomId,
                            state.enterpriseBomData,
                            action.payload.value?.bomEntryId
                        );

                        const {
                            finalTotal: agreedEnterpriseBomFinalTotal,
                            bomTotal: agreedEnterpriseBomTotal,
                            subTotal: agreedEnterpriseBomSubTotal,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            action.payload.value?.enterpriseBomId,
                            state.enterpriseBomData,
                            action.payload.value?.bomEntryId,
                            true
                        );

                        state.totalPages = Math.ceil(
                            itemIdsForCurrentEnterpriseBom?.length /
                                state.itemsPerPage
                        );

                        state.quoteDetails.total = enterpriseBomFinalTotal;
                        state.bomTotal = enterpriseBomTotal;
                        state.subTotal = enterpriseBomSubTotal;
                        state.quoteDetails.agreedTotal =
                            agreedEnterpriseBomFinalTotal;
                        state.agreedBomTotal = agreedEnterpriseBomTotal;
                        state.agreedSubTotal = agreedEnterpriseBomSubTotal;

                        break;
                    case 'UPDATE_QUOTE_ITEM_LOADING_STATE':
                        state.showAllItemsInLoadingState = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_CALL_DASHBOARD_FOR_NEXT_PAGE':
                        state.callDashboardForNextPage = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_ADD_NEW_ITEM_STATE':
                        state.addingNewItem = action.payload.value;
                        if (action.payload.value)
                            state.currentPage = state.totalPages
                                ? state.totalPages
                                : 1;
                        break;
                    case 'UPDATE_QUOTE_SHOW_BLANK_ITEM_STATE':
                        state.showBlankQuoteItem = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_ITEM_ERRORS':
                        let newErrorObject = cloneDeep(state.quoteItemErrors);
                        if (newErrorObject === undefined) {
                            newErrorObject = {
                                [action.payload.value.itemIndex]:
                                    action.payload.value.errorObj,
                            };
                        } else if (
                            !isEqual(action.payload.value.errorObj, {})
                        ) {
                            newErrorObject[action.payload.value.itemId] = {
                                ...(newErrorObject[
                                    action.payload.value.itemId
                                ] ?? {}),
                                [action.payload.value.itemIndex]:
                                    action.payload.value.errorObj,
                            };
                        } else {
                            if (newErrorObject[action.payload.value.itemId]) {
                                delete newErrorObject[
                                    action.payload.value.itemId
                                ][action.payload.value.itemIndex];
                            }

                            if (
                                isEqual(
                                    newErrorObject[action.payload.value.itemId],
                                    {}
                                )
                            ) {
                                delete newErrorObject[
                                    action.payload.value.itemId
                                ];
                            }
                        }

                        state.quoteItemErrors = newErrorObject;
                        break;
                    case 'UPDATE_LIST_OF_FORMULA_FOR_CUSTOM_FIELDS':
                        state.listOfFormulaForCustomFields =
                            action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_TOTALPAGES':
                        state.totalPages = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_ITEMSPERPAGE':
                        state.itemsPerPage = action.payload.value;
                        break;

                    case 'UPDATE_QUOTE_SHEET_ID':
                        state.quoteSheetId = action.payload.value;
                        break;
                    case 'UPDATE_CURRENCY':
                        state.currencies = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_SEARCH':
                        state.searchQuery = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_ITEM':
                        // const combinedItem

                        const oldData = cloneDeep(
                            state.quoteItemDetails[action.payload.value.itemId][
                                action.payload.value.itemIndex
                            ]
                        );

                        const itemId = action.payload.value.itemId;
                        const itemIndexOfCurrentItem =
                            action.payload.value.itemIndex;

                        let updatedItemValueWithFormula =
                            calculateFormulaAdditionalCostForQuoteItem(
                                action.payload.value.value,
                                state?.quoteCurrencyDetails
                                    ?.currency_code_abbreviation,
                                +action.payload.value.value.quantity
                            );

                        let updatednewItemValueWithFormulaWithCustomFields =
                            calculateAdditionalCostForFormulaCustomFields({
                                item: updatedItemValueWithFormula,
                                listOfFormulaForCustomFields:
                                    state.listOfFormulaForCustomFields,
                            });

                        // here the item of selected id and index is updated. But the rest of the items based on the current item update.
                        state.quoteItemDetails[action.payload.value.itemId][
                            action.payload.value.itemIndex
                        ] = updatednewItemValueWithFormulaWithCustomFields;

                        // update rest of the items based on the updated item. DO the following based on the flag to avoid unnecessary re-renders
                        if (
                            action.payload.value.updateAllItemsWithSameid ===
                                undefined ||
                            action.payload.value.updateAllItemsWithSameid
                        )
                            state.quoteItemDetails[
                                action.payload.value.itemId
                            ] = updateAllCostingSheetItem(
                                cloneDeep(
                                    state.quoteItemDetails[
                                        action.payload.value.itemId
                                    ]
                                ),
                                updatednewItemValueWithFormulaWithCustomFields,
                                state.quoteCurrencyDetails
                                    .currency_code_abbreviation,
                                state.listOfFormulaForCustomFields,
                                action.payload.value.itemIndex
                            );

                        // updating the ratio in enterpriseBOMData too
                        if (
                            action.payload.value.enterpriseBOMEntryId &&
                            action.payload.value.subBOMEntryId
                        ) {
                            const currentSubBomData = cloneDeep(
                                state.enterpriseBomData[
                                    action.payload.value.enterpriseBOMEntryId
                                ][action.payload.value.subBOMEntryId]
                            );

                            const indexOfCurrentItemInList =
                                currentSubBomData.bom_items.findIndex(
                                    (item) =>
                                        item.costing_sheet_item_id === itemId &&
                                        item.item_index ===
                                            itemIndexOfCurrentItem
                                );

                            if (indexOfCurrentItemInList !== -1) {
                                currentSubBomData.bom_items[
                                    indexOfCurrentItemInList
                                ].rawMaterialQuantityPerUnitBom =
                                    (currentSubBomData.bom_items[
                                        indexOfCurrentItemInList
                                    ].rawMaterialQuantityPerUnitBom /
                                        +oldData.quantity) *
                                    +action.payload.value.value.quantity;

                                state.enterpriseBomData[
                                    action.payload.value.enterpriseBOMEntryId
                                ][action.payload.value.subBOMEntryId] =
                                    currentSubBomData;
                            }
                        }

                        const itemIdsForCurrentEnterpriseBom2 = state
                            .selectedEnterpriseBomId.enterpriseBomId
                            ? state.quoteBOMDetails[
                                  state.selectedEnterpriseBomId.enterpriseBomId
                              ]
                            : state.quoteItemIds;

                        const { finalTotal, bomTotal, subTotal } =
                            getSheetTotal(
                                cloneDeep(
                                    Array.from(
                                        new Set(
                                            itemIdsForCurrentEnterpriseBom2.map(
                                                (item) =>
                                                    item.costing_sheet_item_id
                                            )
                                        )
                                    )
                                ),
                                state.quoteItemDetails,
                                state.quoteDetails.additional_costs,
                                state.quoteCurrencyDetails
                                    .currency_code_abbreviation,
                                state.listOfFormulaForCustomFields,
                                state.selectedEnterpriseBomId.enterpriseBomId,
                                state.enterpriseBomData,
                                state.selectedEnterpriseBomId.bomEntryId
                            );

                        const {
                            finalTotal: agreedFinalTotal,
                            bomTotal: agreedBomTotal,
                            subTotal: agreedSubTotal,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom2.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId,
                            true
                        );

                        state.quoteDetails.total = finalTotal;
                        state.bomTotal = bomTotal;
                        state.subTotal = subTotal;
                        state.quoteDetails.agreedTotal = agreedFinalTotal;
                        state.agreedBomTotal = agreedBomTotal;
                        state.agreedSubTotal = agreedSubTotal;

                        break;
                    case 'APPEND_NEW_QUOTE_ITEM':
                        try {
                            let updatednewItemValueWithFormula =
                                calculateFormulaAdditionalCostForQuoteItem(
                                    action.payload.value.value,
                                    state?.quoteCurrencyDetails
                                        ?.currency_code_abbreviation,
                                    +action.payload.value.value.quantity
                                );

                            let updatednewItemValueWithFormulaWithCustomFields =
                                calculateAdditionalCostForFormulaCustomFields({
                                    item: updatednewItemValueWithFormula,
                                    listOfFormulaForCustomFields:
                                        state.listOfFormulaForCustomFields,
                                });

                            if (
                                state.quoteItemDetails[
                                    action.payload.value.itemId
                                ] === undefined
                            )
                                state.quoteItemDetails[
                                    action.payload.value.itemId
                                ] = [];

                            state.quoteItemDetails[
                                action.payload.value.itemId
                            ].push(
                                updatednewItemValueWithFormulaWithCustomFields
                            );

                            let newItemIds = cloneDeep(
                                Array.from(new Set(state.quoteItemIds))
                            );

                            const bomEntryId =
                                updatednewItemValueWithFormulaWithCustomFields.delivery_schedule.find(
                                    (dsItem) =>
                                        dsItem.bom_item?.enterprise_bom
                                            ?.entry_id
                                ) ?? null;

                            newItemIds.push({
                                parent_bom_entry_id:
                                    bomEntryId?.bom_item?.parent_sub_bom_item
                                        ?.entry_id ?? null,
                                bom_entry_id:
                                    bomEntryId?.bom_item?.enterprise_bom
                                        ?.entry_id ?? null,
                                costing_sheet_item_id:
                                    action.payload.value.itemId,
                                item_index: 0,
                                list_parent_bom_entry_id:
                                    getAllTheParentSubBOmDetails(
                                        bomEntryId?.bom_item ?? null
                                    ),
                            });

                            newItemIds = Array.from(new Set(newItemIds));

                            const totalNumberOfItems = newItemIds?.length;

                            const itemIdsForCurrentEnterpriseBom3 = state
                                .selectedEnterpriseBomId.enterpriseBomId
                                ? state.quoteBOMDetails[
                                      state.selectedEnterpriseBomId
                                          .enterpriseBomId
                                  ]
                                : newItemIds;
                            state.totalPages = Math.ceil(
                                itemIdsForCurrentEnterpriseBom3?.length /
                                    state.itemsPerPage
                            );

                            state.currentPage =
                                totalNumberOfItems % 10 === 1 &&
                                totalNumberOfItems > 10
                                    ? state.currentPage + 1
                                    : state.currentPage;

                            state.quoteItemIds = newItemIds;

                            const {
                                finalTotal: finalTotal2,
                                bomTotal: bomTotal2,
                                subTotal: subTotal2,
                            } = getSheetTotal(
                                cloneDeep(
                                    Array.from(
                                        new Set(
                                            itemIdsForCurrentEnterpriseBom3.map(
                                                (item) =>
                                                    item.costing_sheet_item_id
                                            )
                                        )
                                    )
                                ),
                                state.quoteItemDetails,
                                state.quoteDetails.additional_costs,
                                state.quoteCurrencyDetails
                                    .currency_code_abbreviation,
                                state.listOfFormulaForCustomFields,
                                state.selectedEnterpriseBomId.enterpriseBomId,
                                state.enterpriseBomData,
                                state.selectedEnterpriseBomId.bomEntryId
                            );

                            const {
                                finalTotal: agreedFinalTotal2,
                                bomTotal: agreedBomTotal2,
                                subTotal: agreedSubTotal2,
                            } = getSheetTotal(
                                cloneDeep(
                                    Array.from(
                                        new Set(
                                            itemIdsForCurrentEnterpriseBom3.map(
                                                (item) =>
                                                    item.costing_sheet_item_id
                                            )
                                        )
                                    )
                                ),
                                state.quoteItemDetails,
                                state.quoteDetails.additional_costs,
                                state.quoteCurrencyDetails
                                    .currency_code_abbreviation,
                                state.listOfFormulaForCustomFields,
                                state.selectedEnterpriseBomId.enterpriseBomId,
                                state.enterpriseBomData,
                                state.selectedEnterpriseBomId.bomEntryId,
                                true
                            );

                            state.quoteDetails.total = finalTotal2;
                            state.bomTotal = bomTotal2;
                            state.subTotal = subTotal2;
                            state.quoteDetails.agreedTotal = agreedFinalTotal2;
                            state.agreedBomTotal = agreedBomTotal2;
                            state.agreedSubTotal = agreedSubTotal2;
                        } catch (err) {
                            console.error(err);
                        }
                        break;

                    case 'UPDATE_BUYER_ENTITIES':
                        state.buyerEntities = action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_DETAILS':
                        state.quoteDetails = {
                            ...action.payload.value,
                            attachment_ids: action.payload.value.attachment_ids,
                            validity_datetime: action.payload.value
                                .validity_datetime
                                ? moment(
                                      action.payload.value.validity_datetime
                                  ).format('YYYY-MM-DDTHH:mm')
                                : null,
                            deadline_datetime: action.payload.value
                                .deadline_datetime
                                ? moment(
                                      action.payload.value.deadline_datetime
                                  ).format('YYYY-MM-DDTHH:mm')
                                : null,
                        };
                        let sheetMode: 'EDIT' | 'VIEW' = [
                            CostingSheetStatusEnum.SUBMITTED,
                            CostingSheetStatusEnum.REJECTED,
                            CostingSheetStatusEnum.CUSTOMER_REJECTED,
                        ].includes(action.payload.value.status)
                            ? 'VIEW'
                            : 'EDIT';
                        state.costingPageMode = sheetMode;

                        state.disableReviseChangesButton =
                            state.costingPageMode === 'EDIT' &&
                            // state.quoteDetails.custom_sections.find(
                            //     (section) =>
                            //         section.name ===
                            //         state.templateDetails.sections
                            //             .ESSENTIAL_TERMS.label
                            // )?.status !== 'SUBMITTED' &&
                            action.payload.value.version !== 1;

                        const statusesToCheck = [
                            CostingSheetStatusEnum.SUBMITTED,

                            CostingSheetStatusEnum.CUSTOMER_ACCEPTED,
                            CostingSheetStatusEnum.CUSTOMER_REJECTED,
                        ];

                        state.hideDeleteCostingSheetButton =
                            statusesToCheck.includes(
                                action.payload.value.status
                            ) ||
                            (action.payload.value.status ===
                                CostingSheetStatusEnum.DRAFT &&
                                action.payload.value.version !== 1);

                        // state.hideDeleteCostingSheetButton =
                        //     action.payload.value.status ===
                        //         CostingSheetStatusEnum.SUBMITTED ||
                        //     (action.payload.value.status ===
                        //         CostingSheetStatusEnum.DRAFT &&
                        //         action.payload.value.version !== 1)||action.payload.value.status===CostingSheetStatusEnum.CUSTOMER_ACCEPTED||action.payload.value.status===CostingSheetStatusEnum.CUSTOMER_REJECTED;

                        state.hideResetAllRatesButton =
                            action.payload.value.status ===
                                CostingSheetStatusEnum.SUBMITTED ||
                            (action.payload.value.status ===
                                CostingSheetStatusEnum.DRAFT &&
                                action.payload.value.version !== 1);
                        const itemIdsForCurrentEnterpriseBom4 = state
                            .selectedEnterpriseBomId.enterpriseBomId
                            ? state.quoteBOMDetails[
                                  state.selectedEnterpriseBomId.enterpriseBomId
                              ]
                            : state.quoteItemIds;

                        const {
                            finalTotal: finalTotal3,
                            bomTotal: bomTotal3,
                            subTotal: subTotal3,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom4.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            action.payload.value.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId
                        );

                        const {
                            finalTotal: agreedFinalTotal3,
                            bomTotal: agreedBomTotal3,
                            subTotal: agreedSubTotal3,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom4.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            action.payload.value.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId,
                            true
                        );

                        state.quoteDetails.total = finalTotal3;
                        state.bomTotal = bomTotal3;
                        state.subTotal = subTotal3;
                        state.quoteDetails.agreedTotal = agreedFinalTotal3;
                        state.agreedBomTotal = agreedBomTotal3;
                        state.agreedSubTotal = agreedSubTotal3;
                        break;
                    case 'UPDATE_QUOTE_MANAGERS':
                        state.quoteDetails.quote_managers =
                            action.payload.value;
                        break;
                    case 'UPDATE_QUOTE_SECTION_STATUS':
                        const templateSection =
                            action.payload.value.sectionName;
                        const quoteSectionIdx =
                            state.quoteDetails.custom_sections.findIndex(
                                (section) => templateSection === section.name
                            );
                        if (quoteSectionIdx !== -1)
                            state.quoteDetails.custom_sections[
                                quoteSectionIdx
                            ].status = action.payload.value.status;
                        break;
                    case 'UPDATE_QUOTE_SECTION_START_TIME':
                        const templateSection1 =
                            action.payload.value.sectionName;
                        const quoteSectionIdx1 =
                            state.quoteDetails.custom_sections.findIndex(
                                (section) => templateSection1 === section.name
                            );
                        if (quoteSectionIdx1 !== -1)
                            state.quoteDetails.custom_sections[
                                quoteSectionIdx1
                            ].start_time = action.payload.value.start_time;
                        break;

                    case 'UPDATE_QUOTE_CURRENCY':
                        state.quoteCurrencyDetails =
                            action.payload.value.currency_details;
                        break;
                    case 'UPDATE_QUOTE_CURRENCY_ITEM_UPDATE':
                        try {
                            state.quoteItemDetails = action.payload.value;
                            const itemIdsForCurrentEnterpriseBom5 = state
                                .selectedEnterpriseBomId.enterpriseBomId
                                ? state.quoteBOMDetails[
                                      state.selectedEnterpriseBomId
                                          .enterpriseBomId
                                  ]
                                : state.quoteItemIds;

                            const {
                                finalTotal: finalTotal4,
                                bomTotal: bomTotal4,
                                subTotal: subTotal4,
                            } = getSheetTotal(
                                cloneDeep(
                                    Array.from(
                                        new Set(
                                            itemIdsForCurrentEnterpriseBom5.map(
                                                (item) =>
                                                    item.costing_sheet_item_id
                                            )
                                        )
                                    )
                                ),
                                state.quoteItemDetails,
                                state.quoteDetails.additional_costs,
                                state.quoteCurrencyDetails
                                    .currency_code_abbreviation,
                                state.listOfFormulaForCustomFields,
                                state.selectedEnterpriseBomId.enterpriseBomId,
                                state.enterpriseBomData,
                                state.selectedEnterpriseBomId.bomEntryId
                            );

                            const {
                                finalTotal: agreedFinalTotal4,
                                bomTotal: agreedBomTotal4,
                                subTotal: agreedSubTotal4,
                            } = getSheetTotal(
                                cloneDeep(
                                    Array.from(
                                        new Set(
                                            itemIdsForCurrentEnterpriseBom5.map(
                                                (item) =>
                                                    item.costing_sheet_item_id
                                            )
                                        )
                                    )
                                ),
                                state.quoteItemDetails,
                                state.quoteDetails.additional_costs,
                                state.quoteCurrencyDetails
                                    .currency_code_abbreviation,
                                state.listOfFormulaForCustomFields,
                                state.selectedEnterpriseBomId.enterpriseBomId,
                                state.enterpriseBomData,
                                state.selectedEnterpriseBomId.bomEntryId,
                                true
                            );

                            state.quoteDetails.total = finalTotal4;
                            state.bomTotal = bomTotal4;
                            state.subTotal = subTotal4;
                            state.quoteDetails.agreedTotal = agreedFinalTotal4;
                            state.agreedBomTotal = agreedBomTotal4;
                            state.agreedSubTotal = agreedSubTotal4;
                        } catch (err) {
                            console.error(err);
                        }

                        break;
                    case 'UPDATE_QUOTE_ITEMS_REVERT_CHANGE':
                        let itemids: IQuoteItemID[] = [];

                        let revertedItemDetails: {
                            [
                                quoteItemId: string
                            ]: ICostingCalculatorApiResponse[];
                        } = {};

                        action.payload.value.forEach((item, index) => {
                            const bomEntryId =
                                item.delivery_schedule.find(
                                    (dsItem) =>
                                        dsItem.bom_item?.enterprise_bom
                                            ?.entry_id
                                ) ?? null;

                            itemids.push({
                                parent_bom_entry_id:
                                    bomEntryId?.bom_item?.parent_sub_bom_item
                                        ?.entry_id ?? null,
                                bom_entry_id:
                                    bomEntryId?.bom_item?.enterprise_bom
                                        ?.entry_id ?? null,
                                costing_sheet_item_id:
                                    item.costing_sheet_item_id,
                                item_index: index,
                                list_parent_bom_entry_id:
                                    getAllTheParentSubBOmDetails(
                                        bomEntryId?.bom_item ?? null
                                    ),
                            });
                            revertedItemDetails[item.costing_sheet_item_id] = [
                                item,
                            ];
                        });

                        state.quoteItemIds = itemids;
                        state.quoteItemDetails = revertedItemDetails;

                        const itemIdsForCurrentEnterpriseBom6 = state
                            .selectedEnterpriseBomId.enterpriseBomId
                            ? state.quoteBOMDetails[
                                  state.selectedEnterpriseBomId.enterpriseBomId
                              ]
                            : itemids;

                        const {
                            finalTotal: finalTotal5,
                            bomTotal: bomTotal5,
                            subTotal: subTotal5,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom6.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId
                        );

                        const {
                            finalTotal: agreedFinalTotal5,
                            bomTotal: agreedBomTotal5,
                            subTotal: agreedSubTotal5,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom6.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId,
                            true
                        );

                        state.quoteDetails.total = finalTotal5;
                        state.bomTotal = bomTotal5;
                        state.subTotal = subTotal5;
                        state.quoteDetails.agreedTotal = agreedFinalTotal5;
                        state.agreedBomTotal = agreedBomTotal5;
                        state.agreedSubTotal = agreedSubTotal5;
                        break;
                    case 'UPDATE_QUOTE_ITEMS':
                        let itemIds: IQuoteItemID[] = cloneDeep(
                            Array.from(new Set(state.quoteItemIds))
                        );

                        let quoteItemDetails: {
                            [
                                quoteItemId: string
                            ]: ICostingCalculatorApiResponse[];
                        } = cloneDeep(state.quoteItemDetails);

                        let combinedItemMap: {
                            [costing_sheet_item_id: string]: {
                                combinedItem: ICostingCalculatorApiResponse;
                                totalQuantity: number;
                            };
                        } = cloneDeep(state.quoteItemCombined);

                        let itemListOfCurrentId =
                            action.payload.value.length > 0
                                ? [action.payload.value[0]]
                                : [];
                        for (let item of action.payload.value.slice(1)) {
                            if (
                                item.costing_sheet_item_id ===
                                itemListOfCurrentId[
                                    itemListOfCurrentId.length - 1
                                ].costing_sheet_item_id
                            ) {
                                itemListOfCurrentId.push(item);
                            } else if (itemListOfCurrentId?.length > 1) {
                                // combine and push

                                if (
                                    combinedItemMap[
                                        itemListOfCurrentId[0]
                                            .costing_sheet_item_id
                                    ] === undefined
                                ) {
                                    const combinedItem =
                                        combineMultipleQuoteSheetItemsWithoutCalcultion(
                                            itemListOfCurrentId,
                                            true
                                        );

                                    combinedItemMap = {
                                        ...combinedItemMap,
                                        [combinedItem.itemForCalculation
                                            .costing_sheet_item_id]: {
                                            combinedItem:
                                                combinedItem.itemForCalculation,
                                            totalQuantity:
                                                +combinedItem.itemForCalculation
                                                    .quantity,
                                        },
                                    };
                                } else {
                                    const currentItemsWithSameId =
                                        state.quoteItemDetails[
                                            itemListOfCurrentId[0]
                                                .costing_sheet_item_id
                                        ];
                                    const combinedItem =
                                        combineMultipleQuoteSheetItemsWithoutCalcultion(
                                            [
                                                ...itemListOfCurrentId,
                                                ...currentItemsWithSameId,
                                            ],
                                            true
                                        );

                                    combinedItemMap = {
                                        ...combinedItemMap,
                                        [combinedItem.itemForCalculation
                                            .costing_sheet_item_id]: {
                                            combinedItem:
                                                combinedItem.itemForCalculation,
                                            totalQuantity:
                                                +combinedItem.itemForCalculation
                                                    .quantity,
                                        },
                                    };
                                }

                                itemListOfCurrentId = [item];
                            } else {
                                // if only one item is present in the list. no need to combine it.. insert it and re-initialize the next set of items with current item.

                                combinedItemMap = {
                                    ...combinedItemMap,
                                    [itemListOfCurrentId[0]
                                        .costing_sheet_item_id]: {
                                        combinedItem: cloneDeep(
                                            itemListOfCurrentId[0]
                                        ),
                                        totalQuantity: +cloneDeep(
                                            itemListOfCurrentId[0]
                                        ).quantity,
                                    },
                                };

                                itemListOfCurrentId = [item];
                            }
                        }

                        // append the last item is pending
                        if (itemListOfCurrentId.length > 0) {
                            const lastItemCombined =
                                combineMultipleQuoteSheetItemsWithoutCalcultion(
                                    itemListOfCurrentId,
                                    true
                                );

                            combinedItemMap = {
                                ...combinedItemMap,
                                [lastItemCombined.itemForCalculation
                                    .costing_sheet_item_id]: {
                                    combinedItem:
                                        lastItemCombined.itemForCalculation,
                                    totalQuantity:
                                        +lastItemCombined.itemForCalculation
                                            .quantity,
                                },
                            };
                        }

                        state.quoteItemCombined = combinedItemMap;

                        action.payload.value.forEach((item, index) => {
                            const bomEntryId =
                                item.delivery_schedule.find(
                                    (dsItem) =>
                                        dsItem.bom_item?.enterprise_bom
                                            ?.entry_id
                                ) ?? null;

                            let updatedItemValueWithFormula =
                                calculateFormulaAdditionalCostForQuoteItem(
                                    item,
                                    state?.quoteCurrencyDetails
                                        ?.currency_code_abbreviation,
                                    +combinedItemMap[item.costing_sheet_item_id]
                                        .totalQuantity
                                );

                            updatedItemValueWithFormula.additional_costs.map(
                                (cost) => {
                                    if (
                                        cost.cost_type ===
                                            CostTypeEnum.ABSOLUTE_VALUE &&
                                        cost.allocation_type ===
                                            AllocationTypeEnum.OVERALL_QUANTITY &&
                                        cost.formula !== null
                                    ) {
                                        if (isNaN(+cost.cost_value)) {
                                            return cost;
                                        }
                                        let newCost = (
                                            (+cost.cost_value /
                                                +combinedItemMap[
                                                    item.costing_sheet_item_id
                                                ].totalQuantity) *
                                            +item.quantity
                                        ).toFixed(10);

                                        cost.cost_value = newCost;

                                        return cost;
                                    }

                                    return cost;
                                }
                            );

                            const currentItemOriginalQuantity = cloneDeep(
                                +updatedItemValueWithFormula.quantity
                            );

                            let updatednewItemValueWithFormulaWithCustomFields =
                                calculateAdditionalCostForFormulaCustomFields({
                                    item: {
                                        ...updatedItemValueWithFormula,
                                        quantity: item.quantity,
                                    },
                                    listOfFormulaForCustomFields:
                                        state.listOfFormulaForCustomFields,
                                });

                            updatednewItemValueWithFormulaWithCustomFields = {
                                ...updatednewItemValueWithFormulaWithCustomFields,
                                quantity:
                                    currentItemOriginalQuantity.toString(),
                                additional_costs:
                                    updatednewItemValueWithFormulaWithCustomFields.additional_costs.map(
                                        (cost) => {
                                            if (
                                                !isNumber(+cost.cost_value) ||
                                                isNaN(cost.cost_value)
                                            ) {
                                                cost.cost_value = '' as any;
                                            }

                                            return cost;
                                        }
                                    ),
                            };

                            if (
                                quoteItemDetails[item.costing_sheet_item_id] ===
                                undefined
                            ) {
                                quoteItemDetails[item.costing_sheet_item_id] =
                                    [];
                            }

                            const itemIdData: IQuoteItemID = {
                                parent_bom_entry_id:
                                    bomEntryId?.bom_item?.parent_sub_bom_item
                                        ?.entry_id ?? null,
                                bom_entry_id:
                                    bomEntryId?.bom_item?.enterprise_bom
                                        ?.entry_id ?? null,
                                costing_sheet_item_id:
                                    item.costing_sheet_item_id,
                                item_index: cloneDeep(
                                    quoteItemDetails[item.costing_sheet_item_id]
                                ).length,
                                list_parent_bom_entry_id:
                                    getAllTheParentSubBOmDetails(
                                        bomEntryId?.bom_item ?? null
                                    ),
                            };

                            const bomItemIDOfCurrentitem =
                                updatednewItemValueWithFormulaWithCustomFields.delivery_schedule.find(
                                    (ds) => ds.bom_item?.entry_id
                                )?.bom_item?.entry_id ?? null;

                            if (bomItemIDOfCurrentitem !== null) {
                                const indexOfCurrentItemInList =
                                    quoteItemDetails[
                                        item.costing_sheet_item_id
                                    ].findIndex((item) =>
                                        item.delivery_schedule.some(
                                            (dsItem) =>
                                                dsItem.bom_item?.entry_id ===
                                                bomItemIDOfCurrentitem
                                        )
                                    );

                                if (indexOfCurrentItemInList === -1) {
                                    itemIds.push(itemIdData);

                                    quoteItemDetails[
                                        item.costing_sheet_item_id
                                    ].push(
                                        updatednewItemValueWithFormulaWithCustomFields
                                    );
                                } else {
                                    quoteItemDetails[
                                        item.costing_sheet_item_id
                                    ][indexOfCurrentItemInList] =
                                        updatednewItemValueWithFormulaWithCustomFields;
                                }
                            } else {
                                itemIds.push(itemIdData);

                                quoteItemDetails[
                                    item.costing_sheet_item_id
                                ].push(
                                    updatednewItemValueWithFormulaWithCustomFields
                                );
                            }

                            const enterprieBomIdOfDSItem =
                                item.delivery_schedule[0]?.bom_item
                                    ?.enterprise_bom?.entry_id ?? null;

                            const parentIdOfDSItem =
                                item.delivery_schedule[0]?.bom_item
                                    ?.parent_sub_bom_item?.entry_id ?? null;

                            const bomItemIdOfDSItem =
                                item.delivery_schedule[0]?.bom_item?.entry_id ??
                                null;

                            if (enterprieBomIdOfDSItem !== null) {
                                if (!state.selectedEnterpriseBomId) {
                                    state.selectedEnterpriseBomId = cloneDeep({
                                        bomEntryId: parentIdOfDSItem,
                                        enterpriseBomId: enterprieBomIdOfDSItem,
                                    });
                                }
                                let newQuoteBomDetails = cloneDeep(
                                    state.quoteBOMDetails
                                );

                                if (
                                    newQuoteBomDetails[
                                        enterprieBomIdOfDSItem
                                    ] === undefined
                                ) {
                                    newQuoteBomDetails[enterprieBomIdOfDSItem] =
                                        [];
                                }
                                if (bomItemIdOfDSItem !== null) {
                                    newQuoteBomDetails[
                                        enterprieBomIdOfDSItem
                                    ].push(itemIdData);

                                    newQuoteBomDetails[enterprieBomIdOfDSItem] =
                                        Array.from(
                                            new Set(
                                                newQuoteBomDetails[
                                                    enterprieBomIdOfDSItem
                                                ]
                                            )
                                        );
                                }
                                state.quoteBOMDetails =
                                    cloneDeep(newQuoteBomDetails);
                            }
                        });
                        const uniqueQuoteItemIds = Array.from(new Set(itemIds));
                        state.quoteItemIds = uniqueQuoteItemIds;
                        state.quoteItemDetails = quoteItemDetails;
                        state.disableGlobalResetToPOPriceBtn =
                            action.payload.value?.length === 0;

                        state.disableExportBtn =
                            action.payload.value?.length === 0;

                        let uniqueEvents: {
                            [custom_event_id: string]: {
                                event_id: string;
                                rfq_entry_id: string;
                            };
                        } = {};
                        Object.keys(quoteItemDetails).forEach((itemId) => {
                            const temp =
                                quoteItemDetails[itemId][0].rfq_event_details;
                            if (temp !== null) {
                                const {
                                    custom_event_id,
                                    event_id,
                                    rfq_entry_id,
                                } = temp;

                                if (!uniqueEvents[custom_event_id]) {
                                    uniqueEvents[custom_event_id] = {
                                        event_id,
                                        rfq_entry_id,
                                    };
                                }
                            }
                        });

                        state.uniqueEvents = uniqueEvents;
                        const itemIdsForCurrentEnterpriseBom7 = state
                            .selectedEnterpriseBomId.enterpriseBomId
                            ? state.quoteBOMDetails[
                                  state.selectedEnterpriseBomId.enterpriseBomId
                              ]
                            : uniqueQuoteItemIds;

                        const {
                            finalTotal: finalTotal6,
                            bomTotal: bomTotal6,
                            subTotal: subTotal6,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom7.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId
                        );

                        const {
                            finalTotal: agreedFinalTotal6,
                            bomTotal: agreedBomTotal6,
                            subTotal: agreedSubTotal6,
                        } = getSheetTotal(
                            cloneDeep(
                                Array.from(
                                    new Set(
                                        itemIdsForCurrentEnterpriseBom7.map(
                                            (item) => item.costing_sheet_item_id
                                        )
                                    )
                                )
                            ),
                            state.quoteItemDetails,
                            state.quoteDetails.additional_costs,
                            state.quoteCurrencyDetails
                                .currency_code_abbreviation,
                            state.listOfFormulaForCustomFields,
                            state.selectedEnterpriseBomId.enterpriseBomId,
                            state.enterpriseBomData,
                            state.selectedEnterpriseBomId.bomEntryId,
                            true
                        );

                        state.totalPages = Math.ceil(
                            itemIdsForCurrentEnterpriseBom7?.length /
                                state.itemsPerPage
                        );

                        state.quoteDetails.total = finalTotal6;
                        state.bomTotal = bomTotal6;
                        state.subTotal = subTotal6;
                        state.quoteDetails.agreedTotal = agreedFinalTotal6;
                        state.agreedBomTotal = agreedBomTotal6;
                        state.agreedSubTotal = agreedSubTotal6;

                        break;
                    case 'UPDATE_QUOTE_TEMPLATE':
                        state.templateDetails = action.payload.value;
                        break;
                    case 'RESET_QUOTE_DETAIL':
                        state.currentPage = 1;
                        state.selectedEnterpriseBomId = {
                            bomEntryId: null,
                            enterpriseBomId: null,
                        };
                        state.quoteStatusTooltips = [];
                        state.quoteSaveStatus = 'SAVED';
                        state.totalPages = 1;
                        state.itemsPerPage = 10;
                        state.adminAdditionalCostData = [];
                        state.showBlankQuoteItem = false;
                        state.showAllItemsInLoadingState = false;
                        state.callDashboardForNextPage = {
                            currentPage: 1,
                            hasNext: false,
                            numberOfItems: 10,
                        };
                        state.enterpriseBomData = {};
                        state.searchQuery = '';
                        state.additionalCostInitialStatus = {};
                        state.templateDetails = blankCostingTemplate;
                        state.uniqueEvents = {};
                        state.quoteSheetId = '';
                        state.addingNewItem = false;
                        state.quoteItemIds = [];
                        state.currencies = [];
                        state.quoteItemIndex = {};
                        state.showPageLoading = false;
                        state.disableExportBtn = false;
                        state.disableGlobalResetToPOPriceBtn = false;
                        state.hideDeleteCostingSheetButton = false;
                        state.hideResetAllRatesButton = false;
                        state.disableReviseChangesButton = false;
                        state.costingPageMode = 'EDIT';
                        state.quoteItemDetails = {};
                        state.quoteItemCombined = {};
                        state.quoteBOMDetails = {};
                        state.buyerEntities = [];
                        state.listOfFormulaForCustomFields = [];
                        state.quoteCurrencyDetails = {
                            decimals: 2,
                            currency_code_abbreviation: '',
                            currency_name: '',
                            currency_symbol: '',
                            entry_id: '',
                        };
                        state.quoteDetails = {
                            view: 'ADVANCED',
                            attachment_ids: [],
                            additional_costs: [],
                            costing_sheet_name: '',
                            additional_details: {
                                template_id: '',
                            },
                            costing_sheet_id: '',
                            custom_costing_sheet_id: '',
                            currency_details: {
                                decimals: 2,
                                currency_code_abbreviation: '',
                                currency_name: '',
                                currency_symbol: '',
                                entry_id: '',
                            },
                            project_details: null,
                            custom_fields: {
                                section_list: [],
                            },
                            custom_fields_negotiate: {
                                section_list: [],
                            },
                            custom_sections: [],
                            customer_entity_details: {
                                entity_id: '',
                                entity_name: '',
                            },
                            deadline_datetime: '',
                            seller_entity: '',
                            seller_entity_name: '',
                            status: CostingSheetStatusEnum.DRAFT,
                            total: 0,
                            agreedTotal: 0,
                            validity_datetime: '',
                            customer_contact_ids: [],
                            version: 1,
                            created_datetime: '',
                            created_by_user_details: { name: '', user_id: '' },
                            modified_by_user_details: { name: '', user_id: '' },
                            quote_managers: [],
                        };
                        state.nextApproverList = [];
                        state.finalApproverList = [];
                        state.quoteApprovers = [];
                        state.quoteLevelErrors = {};
                        state.quoteItemErrors = {};
                        state.subTotal = 0;
                        state.bomTotal = 0;
                        state.agreedSubTotal = 0;
                        state.agreedBomTotal = 0;

                        break;
                }
            } catch (err) {
                console.error('myErr', err);
            }
        },
    },
});

export const { updateQuoteDataStore } = QuoteCalculatorSlice.actions;
const QuoteCalculatorStore = QuoteCalculatorSlice.reducer;
export default QuoteCalculatorStore;
