import { convertAdditionalCostBackendToFrontend } from '../../AdditionalCost/helpers';
import { transformPaymentTermsFromBackendToFrontend } from '../../Global/Helpers/paymentTermsHelpers';
import { modifyCustomSectionForUpdate } from '../../Global/Helpers/templateHelpers';
import { JsonNumsToStrings } from '../../Global/Services/JsonProcessingService';
import { IIncotermDetails } from '../../Models/Incoterms.model';
import { IGetRFQItemDetails, IPageDetails } from '../../Models/RFQ.model';
import {
    IGetSellerRFQBid,
    IPostSellerBid,
    IPostSellerBidDetails,
    ISellerRFQDetails,
    IStatusMap,
    SellerRFQList,
} from '../../Models/SellerRFQ.model';
import {
    DashBoardFilter,
    TEventSellerList,
} from '../../ProjectGlCostCenter/interface/project.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { get, post, put } from '../../Utils/api';

//MrTea:Updated function for tab count
export const getTabCount = (): Promise<{
    invitation: string;
    ongoing: string;
    all: string;
    finished: string;
}> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<
                {
                    dashboard_view: string;
                },
                {
                    tab_counts: {
                        invitation: string;
                        ongoing: string;
                        all: string;
                        finished: string;
                    };
                }
            >(`/dashboard/tab_counts/`, {
                dashboard_view: 'rfq_event_seller',
            });
            const { invitation, ongoing, all, finished } = resp.data.tab_counts;
            resolve({
                invitation: invitation,
                ongoing: ongoing,
                all: all,
                finished: finished,
            });
        } catch (err) {
            reject();
        }
    });
};

//MrTea:Setting keys for filter
const statuseMap: IStatusMap = {
    all: 'all',
    invitation: 'invitation',
    ongoing: 'ongoing',
    finished: 'finished',
};

interface IFetchDashboardDataPayload {
    dashboard_view: string;
    tab: string;
    search_text: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    items_per_page: number;
    page_number: number;
    filters: DashBoardFilter | null;
}

export const fetchEventDashboardData = async ({
    tab,
    sortFields,
    searchText,
    pageNumber,
    itemsPerPage,
    filters,
}: {
    tab: keyof IStatusMap;
    sortFields: {
        field: string;
        ascending: boolean;
    }[];
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    filters: DashBoardFilter | null;
}): Promise<{ eventList: SellerRFQList[]; pageDetails: IPageDetails }> => {
    const postData: IFetchDashboardDataPayload = {
        dashboard_view: 'rfq_event_seller',
        tab: statuseMap[tab],
        search_text:
            searchText?.length && searchText?.length > 0 ? searchText : '',
        sort_fields: sortFields ?? [],
        items_per_page: itemsPerPage,
        page_number: pageNumber,
        filters: filters,
    };

    const resp = await post<IFetchDashboardDataPayload, any>(
        `/dashboard/`,
        postData
    );
    const sellerDashboardData: SellerRFQList[] = resp.data.data.map(
        (eventDetails: any) => {
            return {
                id: '',
                custom_event_id: eventDetails.custom_event_id,
                status: eventDetails.event_status,
                event_name: eventDetails.event_name,
                event_type: eventDetails.event_type,
                live_event_start_datetime:
                    eventDetails.live_event_start_datetime,
                event_end_datetime: eventDetails.event_end_datetime,
                item_count: eventDetails.additional_details.item_count ?? 0,
                buyer_entity_name: eventDetails.buyer_entity.buyer_entity_name,
                seller_entity_name: eventDetails.seller_entity_name,
                seller_status: eventDetails.status,
                pause_information:
                    eventDetails.event_details.pause_information?.notes,
                seller_entity_id: eventDetails.seller_entity_id,
                event_id: eventDetails.rfq_event_id,
                created_by: eventDetails.event_details.created_by_user,
                created_by_user_name:
                    eventDetails.event_details.created_by_user_name,
                customer_entity: eventDetails.customer_entity,
            };
        }
    );

    return {
        eventList: sellerDashboardData,
        pageDetails: {
            ...resp.data.metadata,
            total_count: resp.data.counts.all,
        },
    };
};

export const { useGetEventsSellerListOfAllUserListQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            getEventsSellerListOfAllUserList: build.query<TEventSellerList, {}>(
                {
                    query: () => ({
                        url: '/events/rfq/columns/?enterprise_type=SELLER',
                    }),
                }
            ),
        }),
    });

// export const fetchDashboardData = (
//     status: string | null
// ): Promise<SellerRFQList[]> => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             let resp;
//             if (status === 'invitation') {
//                 resp = await get<any>(
//                     `/events/dashboard/seller/?status=[INVITATION_PENDING]`
//                 );
//             } else if (status === 'ongoing') {
//                 resp = await get<any>(
//                     `/events/dashboard/seller/?status=[PO_PENDING, PO_RECEIVED, PO_REVIEWED, BID_SUBMITTED, BID_RECEIVED, BID_PENDING]`
//                 );
//             } else if (status === 'finished') {
//                 resp = await get<any>(
//                     `/events/dashboard/seller/?status=[INVITATION_REJECTED, INVITATION_LAPSED, DEADLINE_PASSED, DEAL_WON, DEAL_LOST]`
//                 );
//             } else {
//                 resp = await get<any>(`/events/dashboard/seller/`);
//             }
//             resolve(
//                 resp.data.map((row: any, idx: number) => ({
//                     id: idx,
//                     event_id: row.rfq_event_id,
//                     custom_event_id: row.custom_event_id,
//                     status: row.status,
//                     event_status: row.event_details.status,
//                     event_name: row.event_name,
//                     event_type: row.event_type,
//                     event_start_datetime: row.live_event_start_datetime,
//                     event_end_datetime: row.event_end_datetime,
//                     item_count: row.additional_details.item_count,
//                     entity_name: row.buyer_entity.buyer_entity_name,
//                     seller_entity_name: row.seller_entity_name,
//                     seller_entity_id: row.seller_entity_id,
//                     pause_information: row.pause_information,
//                 }))
//             );
//         } catch (err) {
//             reject();
//         }
//     });
// };

// export const getTabCount = (): Promise<{
//     invitation: string;
//     ongoing: string;
//     all: string;
//     finished: string;
// }> => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             let resp = await get<any[]>(`/events/dashboard/seller/`);
//             resolve({
//                 invitation: resp.data
//                     .filter((row: any) => row.status === 'INVITATION_PENDING')
//                     .length.toString(),
//                 ongoing: resp.data
//                     .filter(
//                         (row: any) =>
//                             row.status === 'PO_PENDING' ||
//                             row.status === 'PO_RECEIVED' ||
//                             row.status === 'PO_REVIEWED' ||
//                             row.status === 'BID_SUBMITTED' ||
//                             row.status === 'BID_RECEIVED' ||
//                             row.status === 'BID_PENDING'
//                         // row.status === "INVITATION_REQUESTED"
//                     )
//                     .length.toString(),
//                 finished: resp.data
//                     .filter(
//                         (row: any) =>
//                             row.status === 'INVITATION_REJECTED' ||
//                             row.status === 'INVITATION_LAPSED' ||
//                             row.status === 'DEADLINE_PASSED' ||
//                             row.status === 'DEAL_WON' ||
//                             row.status === 'DEAL_LOST'
//                     )
//                     .length.toString(),
//                 all: resp.data.length.toString(),
//             });
//         } catch (err) {
//             reject();
//         }
//     });
// };

export const fetchRFQDetails = (
    rfq_id: string,
    entity_id: string
): Promise<ISellerRFQDetails> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<ISellerRFQDetails>(
                `/events/rfq/${rfq_id}/?enterprise_type=SELLER&seller_entity_id=${entity_id}`
            );

            resp.data.rfq_default_information.defaultPaymentTerms =
                transformPaymentTermsFromBackendToFrontend({
                    deliverables_payment_terms:
                        resp.data.rfq_default_information
                            .default_event_deliverables_payment_terms,
                    payment_terms:
                        resp.data.rfq_default_information
                            .default_event_payment_terms,
                    prepayment_percentage:
                        resp.data.rfq_default_information
                            .default_event_prepayment_percentage,
                });

            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchRFQItemDetails = (
    rfq_id: string,
    entity_id: string
): Promise<IGetRFQItemDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IGetRFQItemDetails[]>(
                `/events/rfq/${rfq_id}/items/?seller_entity_id=${entity_id}&enterprise_type=SELLER`
            );

            JsonNumsToStrings(resp.data);
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const fetchSellerRFQBids = (
    rfq_id: string,
    entity_id: string
): Promise<IGetSellerRFQBid[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IGetSellerRFQBid[]>(
                `/events/rfq/${rfq_id}/bids/items/?seller_entity_id=${entity_id}&enterprise_type=SELLER`
            );

            resp.data.forEach((bid) => {
                bid.rfq_bid_items.forEach((item: any) => {
                    item.paymentTerms =
                        transformPaymentTermsFromBackendToFrontend({
                            prepayment_percentage: item.prepayment_percentage,
                            payment_terms: item.payment_terms,
                            deliverables_payment_terms:
                                item.deliverables_payment_terms,
                        });

                    item.delivery_information = item.delivery_schedule;

                    if (item.pricing_information) {
                        item.pricing_information.taxes =
                            convertAdditionalCostBackendToFrontend(
                                item.pricing_information?.taxes ?? []
                            );
                        item.pricing_information.discounts =
                            convertAdditionalCostBackendToFrontend(
                                item.pricing_information?.discounts ?? []
                            );
                        item.pricing_information.additional_costs =
                            convertAdditionalCostBackendToFrontend(
                                item.pricing_information?.additional_costs ?? []
                            );
                    } else {
                        item.pricing_information = {
                            currency_name: null,
                            currency_symbol: null,
                            currency_code_id: null,
                            bulk_discount_percentage: null,
                            currency_code_abbreviation: null,
                            bulk_discount_minimum_order_value: null,
                            taxes: [],
                            discounts: [],
                            additional_costs: [],
                        };
                    }
                });
            });
            JsonNumsToStrings(resp.data);

            resolve(resp.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const fetchIncoterms = (): Promise<IIncotermDetails[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IIncotermDetails[]>('/backbone/incoterms');
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

interface ISellerInvitationApiData {
    status: 'ACCEPTED' | 'REJECTED';
    notes?: string;
}

export const updateSellerInvitationStatus = (
    rfq_id: string,
    seller_entity_id: string,
    status: 'ACCEPTED' | 'REJECTED',
    msg?: string
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            let putData: ISellerInvitationApiData = {
                status: status,
            };
            if (msg) {
                putData.notes = msg;
            }
            let resp = await put<ISellerInvitationApiData, void>(
                `/events/rfq/${rfq_id}/seller/${seller_entity_id}/invitation/`,
                {
                    status: status,
                    notes: msg,
                }
            );
            if (resp.status >= 200 && resp.status < 300) resolve();
            else reject();
        } catch (err) {
            reject();
        }
    });
};

export const autoFillSellerBidsWhileAcceptingInvitation = async ({
    payload,
    rfq_event_id,
    seller_entity_id,
}: {
    rfq_event_id: string;
    seller_entity_id: string;
    payload: {
        internal_vendor_quote_id: string;
        currency_code_id: string;
        action: 'SAVE_DRAFT_OPENING_QUOTE';
    };
}) => {
    try {
        await post(
            `/events/rfq/${rfq_event_id}/bids/seller/${seller_entity_id}/create/auto/`,
            payload
        );
        return;
    } catch (err) {
        throw err;
    }
};

export const postSellerRFQBid = (
    rfq_id: string,
    object: IPostSellerBid
): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<IPostSellerBid, { rfq_bid_id: string }>(
                `/events/rfq/${rfq_id}/bids/create/`,
                object
            );
            resolve(resp.data.rfq_bid_id);
        } catch (err) {
            reject();
        }
    });
};

export const putSellerRFQBid = (
    rfq_id: string,
    rfq_bid_id: string,
    object: IPostSellerBid
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put<IPostSellerBid, void>(
                `/events/rfq/${rfq_id}/bids/${rfq_bid_id}/update/`,
                object
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const putSellerRFQBidDetails = (
    rfq_id: string,
    rfq_bid_id: string,
    object: IPostSellerBidDetails
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put<IPostSellerBid, void>(
                `/events/rfq/${rfq_id}/bids/details/${rfq_bid_id}/update/`,
                object
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const putSellerRFQBidState = (
    rfq_id: string,
    rfq_bid_id: string,
    seller_id: string | null,
    notes: string
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await put<any, void>(
                `/events/rfq/${rfq_id}/bids/${rfq_bid_id}/state/`,
                {
                    seller_entity_id: seller_id,
                    notes: notes,
                    action: 'MOVE_DRAFT_OPENING_QUOTE_TO_ONGOING',
                }
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const postDraftBid = async (
    obj: IPostSellerBid,
    rfq_event_id: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<IPostSellerBid, any>(
                `/events/rfq/${rfq_event_id}/bids/create/`,
                obj
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};

export const putDraftBid = async (
    obj: IPostSellerBid,
    rfq_event_id: string,
    rfq_bid_id: string
): Promise<any> => {
    let payload = {
        ...obj,
        custom_sections: modifyCustomSectionForUpdate(obj.custom_sections),
    };
    payload.bid_items.forEach((bid) => {
        bid.custom_sections = modifyCustomSectionForUpdate(bid.custom_sections);
    });
    await put<IPostSellerBid, any>(
        `/events/rfq/${rfq_event_id}/bids/${rfq_bid_id}/update/`,
        payload
    );
};

export const moveDraftQuoteToOngoing = async (
    rfq_event_id: string,
    seller_entity_id: string,
    rfq_bid_id: string
): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            let newObj = {
                seller_entity_id: seller_entity_id,
                action: 'MOVE_DRAFT_QUOTE_TO_ONGOING',
                notes: '',
            };
            let resp = await put<any, any>(
                `/events/rfq/${rfq_event_id}/bids/${rfq_bid_id}/state/`,
                newObj
            );
            resolve(resp.data);
        } catch (err) {
            reject();
        }
    });
};
