import {
    DialogContent,
    DialogActions,
    CircularProgress,
    Grid,
    IconButton,
} from '@mui/material';
import { FormikProvider, FieldArray, useFormik } from 'formik';
import * as Yup from 'yup';
import { clone, cloneDeep, isEmpty } from 'lodash';
import { FWButton } from '../../../../Common/FWButton';
import { FWInput } from '../../../../Common/FWInput';
import { FWPopupWithChildren } from '../../../../Common/FWPopupWithChildren';
import { FWTypography } from '../../../../Common/FWTypograhy';
import {
    IVendorContact,
    IVendorContactAdd,
} from '../../Interfaces/VendorsInterface';
import { addContactToVendorDirectory } from '../../Services/VendorsService';
import { useEffect, useState } from 'react';
import { useGetSellerContactForBuyerEntityQuery } from '../../../../Services/events/eventOperations';
// import FWSelect from '../../../../Common/FWSelect';
// import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import FWIcon from '../../../../Common/FWIcon';
import { isValidEmail } from '../../../../Common/Utils/ValidationUtils';
import { toast } from 'react-toastify';

const initialContactDetails: IVendorContactAdd = {
    fullName: '',
    email: '',
    buyerContacts: [
        {
            number: '',
            visible: true,
        },
    ],
    CCEmails: [],
    secondaryEmails: [],
    isPrimary: false,
    department: '',
    designation: '',
    notes: '',
};

export interface IAddContactFunParams {
    vendor_contact_id: string;
    fullName: string;
    email: string;
    phone_numbers: string[];
    isPrimary: boolean;
    CCEmails: string[];
    secondaryEmails: string[];
    department: string;
    designation: string;
    notes: string;
}

interface IAddVendorContactPopupProps {
    open: boolean;
    entityUid: string;
    vendorUid: string;
    allContacts: IVendorContact[];
    handleClose: () => void;
    onContactAddSuccess?: (vendorContact: IAddContactFunParams) => void;
    portal: 'VENDOR_MASTER' | 'BUYER_MASTER';
}

const AddVendorContactPopup = ({
    open,
    handleClose,
    entityUid,
    vendorUid,
    onContactAddSuccess,
    allContacts,
    portal,
}: IAddVendorContactPopupProps) => {
    const handleClosePopup = () => {
        formController.resetForm();
        formController.setValues(cloneDeep(initialContactDetails));
        handleClose();
    };

    const handleSubmit = async (values: IVendorContactAdd) => {
        const contactAdded = await addContactToVendorDirectory(
            entityUid,
            vendorUid,
            values,
            portal
        );
        if (contactAdded && onContactAddSuccess) {
            toast.success('Vendor contact added successfully');
            onContactAddSuccess({
                vendor_contact_id: contactAdded,
                fullName: values.fullName,
                email: values.email,
                phone_numbers: values.buyerContacts.map((c) => c.number),
                isPrimary: values.isPrimary,
                CCEmails: values.CCEmails,
                secondaryEmails: values.secondaryEmails,
                designation: values.designation ?? '',
                department: values.department ?? '',
                notes: values.notes ?? '',
            });
            formController.resetForm();
            handleClose();
        }
    };

    const formController = useFormik<IVendorContactAdd>({
        initialValues: cloneDeep(initialContactDetails),
        validationSchema: Yup.object({
            fullName: Yup.string().trim().required('Full name is required'),
            email: Yup.string()
                .required('Email is required')
                .email('Must be a valid email')
                .max(255),
            buyerContacts: Yup.array().of(
                Yup.object().shape({
                    number: Yup.string()
                        // .required('Number is required')
                        .min(8, 'Min 8 chars')
                        .max(10, 'Max 10 chars'),
                })
            ),
        }),
        onSubmit: handleSubmit,
    });

    const isSecondaryEmailValid = () => {
        if (formController.values.secondaryEmails.length === 0) return true;

        return !formController.values.secondaryEmails
            // eslint-disable-next-line array-callback-return
            .map((email, idx) => {
                const temp = [...formController.values.secondaryEmails];

                temp.splice(idx, 1);

                if (
                    email.length === 0 ||
                    !isValidEmail(email) ||
                    temp.includes(email) ||
                    formController.values.secondaryEmails.includes(
                        formController.values.email
                    )
                ) {
                    return false;
                }
            })
            .includes(false);
    };

    const [, setVendorEMails] = useState<string[]>([]);

    const { data: getSellerContactForBuyerEntityData } =
        useGetSellerContactForBuyerEntityQuery({
            buyer_entity_id: entityUid,
            portal,
            seller_entity_id: vendorUid,
        });

    useEffect(() => {
        if (getSellerContactForBuyerEntityData) {
            setVendorEMails(
                getSellerContactForBuyerEntityData
                    .map((v) => (v.primary_email ? v.primary_email : ''))
                    .filter((v) => v.length !== 0)
            );
        }
    }, [getSellerContactForBuyerEntityData]);
    const allContactNames = allContacts.map((contact) =>
        contact.fullName.toLowerCase()
    );
    const allContactEmails = allContacts.map((contact) =>
        contact.sellerEmails[0].email.toLowerCase()
    );

    const handleSecondaryEmailUpdate = (e: any) => {
        const temp = clone(formController.values);
        temp.secondaryEmails[e.target.name.split('-')[1]] = e.target.value;
        formController.setValues(temp);
    };

    const handleRemovingSecondaryEmail = (index: number) => {
        const temp = clone(formController.values);
        temp.secondaryEmails.splice(index, 1);
        formController.setValues(temp);
    };

    const secondayEmailAlreadyExists = (email: string, index: number) => {
        return formController.values.secondaryEmails.some(
            (e, idx) => index !== idx && e === email
        );
    };

    return (
        <>
            <FWPopupWithChildren
                open={open}
                handleClose={handleClosePopup}
                title={`Add contact`}
            >
                <FormikProvider value={formController}>
                    <form onSubmit={formController.handleSubmit}>
                        <DialogContent sx={{ padding: '0 24px 12px' }}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12} marginTop="15px">
                                    <Grid
                                        container
                                        columnSpacing={2}
                                        rowGap={2}
                                    >
                                        <Grid item xs={12}>
                                            <FWInput
                                                label="Full name"
                                                value={
                                                    formController.values
                                                        .fullName
                                                }
                                                name="fullName"
                                                onChange={
                                                    formController.handleChange
                                                }
                                                onBlur={
                                                    formController.handleBlur
                                                }
                                                error={
                                                    (formController.touched
                                                        .fullName &&
                                                        !isEmpty(
                                                            formController
                                                                .errors.fullName
                                                        )) ||
                                                    allContactNames.includes(
                                                        formController.values.fullName.toLowerCase()
                                                    )
                                                }
                                                helper={{
                                                    text: formController.touched
                                                        .fullName
                                                        ? allContactNames.includes(
                                                              formController.values.fullName
                                                                  .toLowerCase()
                                                                  .trim()
                                                          )
                                                            ? 'Contact already present'
                                                            : formController
                                                                  .errors
                                                                  .fullName ??
                                                              ''
                                                        : '',
                                                }}
                                                // allowOnly="TEXT"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FWInput
                                                label="Email"
                                                value={
                                                    formController.values.email
                                                }
                                                name="email"
                                                onChange={
                                                    formController.handleChange
                                                }
                                                onBlur={
                                                    formController.handleBlur
                                                }
                                                error={
                                                    (formController.touched
                                                        .email &&
                                                        !isEmpty(
                                                            formController
                                                                .errors.email
                                                        )) ||
                                                    allContactEmails.includes(
                                                        formController.values.email.toLowerCase()
                                                    )
                                                }
                                                helper={{
                                                    text: formController.touched
                                                        .email
                                                        ? allContactEmails.includes(
                                                              formController.values.email.toLowerCase()
                                                          )
                                                            ? 'Contact already present'
                                                            : formController
                                                                  .errors
                                                                  .email ?? ''
                                                        : '',
                                                }}
                                                allowOnly="EMAIL"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FieldArray
                                                name="buyerContacts"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        <div className="flex flex--aic">
                                                            <FWTypography
                                                                sx={{
                                                                    marginBottom:
                                                                        '5px',
                                                                    color: 'text.secondary',
                                                                }}
                                                            >
                                                                Phone (optional)
                                                            </FWTypography>
                                                        </div>
                                                        {formController.values.buyerContacts.map(
                                                            (
                                                                { number },
                                                                index
                                                            ) => {
                                                                const touched: any =
                                                                    formController
                                                                        .touched
                                                                        .buyerContacts &&
                                                                    formController
                                                                        .touched
                                                                        .buyerContacts[
                                                                        index
                                                                    ];
                                                                const isTouched =
                                                                    touched &&
                                                                    touched.number;
                                                                const error: any =
                                                                    formController
                                                                        .errors
                                                                        .buyerContacts &&
                                                                    formController
                                                                        .errors
                                                                        .buyerContacts[
                                                                        index
                                                                    ];
                                                                const hasError =
                                                                    !isEmpty(
                                                                        error &&
                                                                            error.number
                                                                    );
                                                                return (
                                                                    <div
                                                                        className="mb--10"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <FWInput
                                                                            value={
                                                                                number
                                                                            }
                                                                            allowOnly="TEL"
                                                                            name={`buyerContacts.${index}.number`}
                                                                            onChange={
                                                                                formController.handleChange
                                                                            }
                                                                            onBlur={
                                                                                formController.handleBlur
                                                                            }
                                                                            // iconEnd={
                                                                            //     <IconButton
                                                                            //         color="error"
                                                                            //         onClick={() => {
                                                                            //             arrayHelpers.remove(
                                                                            //                 index
                                                                            //             );
                                                                            //         }}
                                                                            //         sx={{
                                                                            //             padding: 0,
                                                                            //         }}
                                                                            //         disabled={
                                                                            //             formController
                                                                            //                 .values
                                                                            //                 .buyerContacts
                                                                            //                 .length ===
                                                                            //             1
                                                                            //         }
                                                                            //     >
                                                                            //         <FWIcon
                                                                            //             name="bi bi-dash-circle"
                                                                            //             size={
                                                                            //                 20
                                                                            //             }
                                                                            //         />
                                                                            //     </IconButton>
                                                                            // }
                                                                            error={
                                                                                isTouched &&
                                                                                hasError
                                                                            }
                                                                            helper={{
                                                                                text:
                                                                                    (isTouched &&
                                                                                        error &&
                                                                                        error.number) ||
                                                                                    '',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        {portal === 'BUYER_MASTER' && (
                                            <Grid item xs={12}>
                                                <FWInput
                                                    label="Designation"
                                                    value={
                                                        formController.values
                                                            .designation ?? ''
                                                    }
                                                    name="designation"
                                                    onChange={
                                                        formController.handleChange
                                                    }
                                                    onBlur={
                                                        formController.handleBlur
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        {portal === 'BUYER_MASTER' && (
                                            <Grid item xs={12}>
                                                <FWInput
                                                    label="Department"
                                                    value={
                                                        formController.values
                                                            .department ?? ''
                                                    }
                                                    name="department"
                                                    onChange={
                                                        formController.handleChange
                                                    }
                                                    onBlur={
                                                        formController.handleBlur
                                                    }
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <FWInput
                                                multiline
                                                maxRows={4}
                                                minRows={2}
                                                label="Notes"
                                                value={
                                                    formController.values
                                                        .notes ?? ''
                                                }
                                                name="notes"
                                                onChange={
                                                    formController.handleChange
                                                }
                                                onBlur={
                                                    formController.handleBlur
                                                }
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Switch />}
                                                    label="Primary"
                                                    name="isPrimary"
                                                    onChange={
                                                        formController.handleChange
                                                    }
                                                    onBlur={
                                                        formController.handleBlur
                                                    }
                                                    checked={
                                                        formController.values
                                                            .isPrimary
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <FWTypography
                                                    paddingTop={'8px'}
                                                >
                                                    Secondary email
                                                </FWTypography>
                                                <FWTooltip
                                                    title="Add secondary email"
                                                    className="addIcon"
                                                >
                                                    <div>
                                                        <IconButton
                                                            onClick={() => {
                                                                formController.setValues(
                                                                    {
                                                                        ...formController.values,
                                                                        secondaryEmails:
                                                                            [
                                                                                ...formController
                                                                                    .values
                                                                                    .secondaryEmails,
                                                                                '',
                                                                            ],
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <FWIcon
                                                                color="primary.main"
                                                                name="bi bi-plus-circle"
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </FWTooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    {formController.values.secondaryEmails.map(
                                                        (email, idx) => (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    xs={11}
                                                                >
                                                                    <FWInput
                                                                        name={`name-${idx}`}
                                                                        value={
                                                                            email
                                                                        }
                                                                        onChange={
                                                                            handleSecondaryEmailUpdate
                                                                        }
                                                                        error={
                                                                            !isValidEmail(
                                                                                email
                                                                            ) ||
                                                                            email.length ===
                                                                                0 ||
                                                                            secondayEmailAlreadyExists(
                                                                                email,
                                                                                idx
                                                                            )
                                                                        }
                                                                        helper={{
                                                                            text:
                                                                                email.length ===
                                                                                0
                                                                                    ? 'Email is Required'
                                                                                    : !isValidEmail(
                                                                                          email
                                                                                      )
                                                                                    ? 'Enter a valid email'
                                                                                    : secondayEmailAlreadyExists(
                                                                                          email,
                                                                                          idx
                                                                                      )
                                                                                    ? 'Email already exists'
                                                                                    : email ===
                                                                                      formController
                                                                                          .values
                                                                                          .email
                                                                                    ? 'Email already exists'
                                                                                    : '',
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={1}
                                                                >
                                                                    <FWTooltip
                                                                        title="Remove secondary email"
                                                                        className="removeIcon"
                                                                    >
                                                                        <div>
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleRemovingSecondaryEmail(
                                                                                        idx
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FWIcon
                                                                                    color="error.main"
                                                                                    name="bi bi-dash-circle"
                                                                                />
                                                                            </IconButton>
                                                                        </div>
                                                                    </FWTooltip>
                                                                </Grid>
                                                            </>
                                                        )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ padding: '12px 24px 24px 24px' }}>
                            <FWButton
                                variant="outlined"
                                onClick={handleClosePopup}
                            >
                                Cancel
                            </FWButton>
                            <span style={{ width: '0.75rem' }}></span>
                            <FWButton
                                variant="contained"
                                type="submit"
                                disabled={
                                    !formController.isValid ||
                                    formController.isSubmitting ||
                                    allContactNames.includes(
                                        formController.values.fullName
                                            .toLowerCase()
                                            .trim()
                                    ) ||
                                    allContactEmails.includes(
                                        formController.values.email.toLowerCase()
                                    ) ||
                                    !isSecondaryEmailValid()
                                }
                            >
                                {formController.isSubmitting ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: '#c4c4c8' }}
                                    />
                                ) : (
                                    'Save'
                                )}
                            </FWButton>
                        </DialogActions>
                    </form>
                </FormikProvider>
            </FWPopupWithChildren>
        </>
    );
};

export default AddVendorContactPopup;
